<template>
  <div
    class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto"
  >
    <div
      class="w-full max-w-[328px] flex h-[608px] md:h-[716px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col gap-6 relative justify-center items-center mx-auto"
    >
      <div class="flex flex-col items-start gap-2 md:gap-4 self-stretch">
        <svn-pro-button
          :text="$t('Back to login')"
          preprend-icon="mingcute:arrow-left-line"
          variant="text"
          @click="router.back()"
        />

        <div class="w-full flex flex-col gap-6 items-start">
          <div class="flex flex-col gap-2">
            <svn-pro-title :h5="!isMobile" :h6="isMobile" color="onSurface" medium>
              {{ $t('Request a demo') }}
            </svn-pro-title>

            <svn-pro-text body-medium color="onSurface" regular>
              {{
                $t(
                  "Would you like to learn about Aleph's features and how it can benefit your company? Our team is ready to show you with a demo!",
                )
              }}
            </svn-pro-text>
          </div>

          <!-- Input fields -->
          <div class="w-full flex flex-col gap-4">
            <!-- Firstname & lastname -->
            <div class="w-full flex gap-4">
              <svn-pro-text-field
                v-model="firstname"
                :error="firstnameError.error"
                :error-messages="firstnameError.errorMessage"
                :placeholder="$t('Firstname*')"
                variant="outlined"
              />

              <svn-pro-text-field
                v-model="lastname"
                :error="lastnameError.error"
                :error-messages="lastnameError.errorMessage"
                :placeholder="$t('Lastname*')"
                variant="outlined"
              />
            </div>

            <!-- Email input -->
            <svn-pro-text-field
              v-model="email"
              :error="emailError.error"
              :error-messages="emailError.errorMessage"
              :placeholder="$t('Email*')"
              type="email"
              variant="outlined"
            />

            <!-- Message input -->
            <svn-pro-text-area
              v-model="message"
              :error="messageError.error"
              :error-messages="messageError.errorMessage"
              :max-rows="4"
              :placeholder="$t('Your message*')"
              :rows="4"
              variant="outlined"
            />
          </div>
        </div>
      </div>

      <!-- Send button -->
      <svn-pro-button
        :loading="primaryButtonLoading"
        :text="$t('Send')"
        class="w-full"
        variant="flat"
        @click="sendRequest"
      />

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          :style="{ 'background-image': `url('${logo}')` }"
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen hidden xl:flex">
      <div
        :style="{ 'background-image': `url('${bgHome}')` }"
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import bgHome from '@/assets/bghome.png';
import { computed, ref, watch } from 'vue';
import logo from '@/assets/logo_aleph_white.svg';
import { useSnackbar } from '@/store/snackbar.js';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';

const router = useRouter();
const snackbar = useSnackbar();

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const email = ref('');
const message = ref('');
const lastname = ref('');
const firstname = ref('');
const primaryButtonLoading = ref(false);
const firstnameError = ref({ error: false, errorMessage: '', hideDetails: true });
const lastnameError = ref({ error: false, errorMessage: '', hideDetails: true });
const emailError = ref({ error: false, errorMessage: '', hideDetails: true });
const messageError = ref({ error: false, errorMessage: '', hideDetails: true });

const sendRequest = async () => {
  primaryButtonLoading.value = true;
  if (!firstname?.value) {
    firstnameError.value = { error: true, errorMessage: '', hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!lastname?.value) {
    lastnameError.value = { error: true, errorMessage: '', hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!email?.value) {
    emailError.value = { error: true, errorMessage: '', hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: '', hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!emailHasCorrectFormat?.value) {
    emailError.value = { error: true, errorMessage: 'Wrong email format', hideDetails: false };
  }
  if (
    firstname?.value &&
    lastname?.value &&
    email?.value &&
    message?.value &&
    emailHasCorrectFormat?.value
  ) {
    try {
      await axiosService.post('/global_mailer/comp_sales_demo_request', {
        user_lastname: lastname?.value,
        user_firstname: firstname?.value,
        user_email: email?.value,
        user_message: message?.value,
      });

      await axiosService.post('/global_mailer/comp_sales_you_requested_demo', {
        user_firstname: firstname?.value,
        user_email: email?.value,
      });

      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();

      router.push('/auth/check');

      resetForm();
    } catch (error) {
      console.log(error);
      snackbar.setMsg('Error sending email.');
      snackbar.setBgColor('error');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();
    }
  }
  primaryButtonLoading.value = false;
};

const resetForm = () => {
  firstname.value = '';
  lastname.value = '';
  email.value = '';
  message.value = '';
};

const emailHasCorrectFormat = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email?.value,
  );
});

const displayRequiredFieldsSnackbar = () => {
  snackbar.setMsg('Please fill out all the required(*) fields.');
  snackbar.setBgColor('onSurface');
  snackbar.setCustomClass('mb-4');
  snackbar.displaySnackBar();
};

watch(firstname, (newValue, oldValue) => {
  if (newValue !== '' && firstnameError?.value?.error === true) {
    firstnameError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(lastname, (newValue, oldValue) => {
  if (newValue !== '' && lastnameError?.value?.error === true) {
    lastnameError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(email, (newValue, oldValue) => {
  if (newValue !== '' && emailError?.value?.error === true) {
    emailError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(message, (newValue, oldValue) => {
  if (newValue !== '' && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(emailHasCorrectFormat, (newValue, oldValue) => {
  if (newValue === true) {
    emailError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});
</script>
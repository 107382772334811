import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '../tools/genericFunctions.js';

export const useMyInterviewsStore = defineStore({
  id: 'myInterviews',
  state: () => ({
    interviews: null,
    interviewsTmp: null,
    interviewsMeta: {},
    interviewsToDo: [],
    interviewsToDoTmp: null,
    interviewsToDoMeta: {},
    interviewsDone: [],
    interviewsDoneTmp: null,
    interviewsDoneMeta: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async fetchMyInterviews(page) {
      try {
        this.updateParams({ interview_status: 'all', 'page[size]': 5, 'page[number]': page });
        await this.fetchEntity(`/interview_app/my_interviews`, 'interviews', 'interviewsTmp');

        if (this.interviews) {
          this.interviews = this.interviews.concat(this.interviewsTmp);
        } else {
          this.interviews = this.interviewsTmp;
        }

        this.interviewsMeta = this.pagination;

        if (this.error) return 'error';
        else if (this.pagination.total_pages <= page) return 'empty';
        return 'ok';
      } catch (e) {
        console.error(e);
      }
    },
    async fetchMyInterviewsToDo(page) {
      const myInterviewsStore = useMyInterviewsStore();
      myInterviewsStore.updateParams({
        interview_status: 'todo',
        'page[size]': 5,
        'page[number]': page,
      });
      await myInterviewsStore.fetchEntity(
        `/interview_app/my_interviews`,
        'interviews',
        'interviewsToDoTmp',
      );
      if (this.interviewsToDo) {
        this.interviewsToDo = this.interviewsToDo.concat(this.interviewsToDoTmp);
      } else {
        this.interviewsToDo = this.interviewsToDoTmp;
      }

      this.interviewsToDoMeta = this.pagination;

      if (this.error) return 'error';
      else if (this.pagination.total_pages <= page) return 'empty';
      return 'ok';
    },
    async fetchMyInterviewsDone(page) {
      const myInterviewsStore = useMyInterviewsStore();
      myInterviewsStore.updateParams({
        interview_status: 'done',
        'page[size]': 5,
        'page[number]': page,
      });
      await myInterviewsStore.fetchEntity(
        `/interview_app/my_interviews`,
        'interviews',
        'interviewsDoneTmp',
      );
      if (this.interviewsDone) {
        this.interviewsDone = this.interviewsDone.concat(this.interviewsDoneTmp);
      } else {
        this.interviewsDone = this.interviewsDoneTmp;
      }

      this.interviewsDoneMeta = this.pagination;

      if (this.error) return 'error';
      else if (this.pagination.total_pages <= page) return 'empty';
      return 'ok';
    },
  },
});

import EvaluationApi from './input.api';
import Quiz from '@/models/quiz';

class QuizApi extends EvaluationApi {
  constructor() {
    super(Quiz, '/learn/quizzes');
  }
}

const instance = new QuizApi();

export default instance;

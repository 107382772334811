import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';

export const useRoadmapEmployeeStore = defineStore('roadmap_employee', {
  state: () => ({
    ...genericStates,
    roadmapEmployees: null,
    roadmapEmployee: null,
  }),
  actions: {
    ...genericFunctions,
    async fetchRoadmapEmployees() {
      const interviewFormStore = useRoadmapEmployeeStore();

      await interviewFormStore.fetchEntity('/roadmaps/employees', 'users', 'roadmapEmployees');
    },
    async fetchRoadmapEmployee(id) {
      const interviewFormStore = useRoadmapEmployeeStore();

      await interviewFormStore.fetchEntity(`/roadmaps/employees/${id}`, 'user', 'roadmapEmployee');
      return this.roadmapEmployee;
    },
    async deleteRoadmapEmployee(id) {
      const interviewFormStore = useRoadmapEmployeeStore();

      await interviewFormStore.deleteEntity(`/roadmaps/employees/${id}`);
    },
  },
});

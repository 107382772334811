import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';
import axiosService from '@/tools/axios-service.js';

export const useTargetSuggestionsStore = defineStore('targets_suggestions', {
  state: () => ({
    ...genericStates,
    snapshotsUpdated: null,
    snapshotsCreated: null,
  }),
  actions: {
    ...genericFunctions,
    clearTargetsAndSuggestions() {
      this.snapshotsCreated = null;
      this.snapshotsUpdated = null;
    },
    async fetchUpdatedSnapshots(employeeId, campaignId, interviewId) {
      const res = await axiosService.get(
        `/roadmaps/employees/${employeeId}/snapshot_targets?campaign_id=${campaignId}&interview_id=${interviewId}`,
      );

      this.snapshotsUpdated = res?.data?.objective_elements;
      return res?.data?.objective_elements;
    },
    async fetchCreatedSnapshots(employeeId, id) {
      const res = await axiosService.get(
        `/roadmaps/employees/${employeeId}/target_drafts?interview_id=${id}`,
      );

      this.snapshotsCreated = res?.data?.objective_elements;
    },
    async deleteTargetDraftFromInterviewAnswer(targetDraftId) {
      await axiosService.delete(`/roadmaps/target_drafts/${targetDraftId}`);
    },
  },
});

<template>
  <v-fade-transition>
    <!-- Skeleton loaders -->
    <div
      v-if="playlistsPagesIsPending"
      class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-center self-stretch sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <skeleton-loader-playlist-card v-for="item in 8" :key="item" />
    </div>

    <!-- Finished loading -->
    <div v-else class="mx-6 md:!mx-0">
      <!-- Search & Select -->
      <div class="w-full flex gap-4 my-4 flex-wrap justify-start items-center">
        <!-- Search -->
        <svn-pro-text-field
          v-model="searchTermPlaylists"
          :placeholder="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line"
          class="w[320px]"
          clearable
          clear-icon="custom:mingcute:close-line"
          @update:model-value="search"
        />

        <!-- Sort & Filter -->
        <div class="flex justify-center items-center">
          <!-- <svn-pro-select
              v-model="selectFilterPlaylists"
              :label="$t('Sort by')"
              class="max-w[320px] min-w[112px]"
              :items="selectFilterList"
              item-title="display"
              item-value="value"
              @update:model-value="search"
            /> -->

          <!-- Filter -->
          <svn-pro-menu :close-on-content-click="false">
            <template #activator="{ props }">
              <svn-pro-button
                v-bind="props"
                :active="selectedThemesListPlaylists?.length > 0"
                :text="`${$t('Filter')} ${selectedThemesListPlaylists?.length ? `(${selectedThemesListPlaylists?.length})` : ''}`"
                prepend-icon="custom:mingcute:filter-2-line"
                variant="text"
              />
            </template>

            <template #dropdown-block>
              <div class="bg-surface rounded-[8px] shadow">
                <svn-pro-combobox
                  v-model="selectedThemesListPlaylists"
                  :items="themes"
                  item-title="name"
                  :label="$t('Themes')"
                  class="!w-[210px] p-4"
                  :clearable="false"
                  multiple
                  return-object
                  @update:model-value="search"
                />
              </div>
            </template>
          </svn-pro-menu>
        </div>
      </div>

      <!-- Filter results -->
      <div
        v-if="selectedThemesListPlaylists?.length > 0"
        class="flex justify-between items-start self-stretch pt-4 xl:!px-0 xl:flex-wrap"
      >
        <!-- Themes list -->
        <div v-if="selectedThemesListPlaylists?.length" class="flex flex-wrap gap-2">
          <svn-pro-chip
            v-for="theme in selectedThemesListPlaylists"
            key="entityTag.id"
            class=""
            :text="theme.name"
            :is-slot-append="true"
          >
            <template #append>
              <Icon
                icon="mingcute:close-line"
                width="18"
                height="18"
                class="ml-2 cursor-pointer"
                @click="toggleThemeSelection(theme)"
              />
            </template>
          </svn-pro-chip>
        </div>

        <!-- Clear all themes selected -->
        <svn-pro-button
          v-if="selectedThemesListPlaylists?.length > 1"
          @click="clearAllSelectedThemes"
          variant="text"
          :text="$t('Clear all')"
        />
      </div>

      <!-- Playlists card list -->
      <v-infinite-scroll class="overflow-hidden w-full" color="primary" @load="loadMoreData">
        <div class="flex flex-wrap md:!grid md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 w-full gap-4 justify-center md:!justify-start items-center">
          <template v-for="page in playlistsPages?.pages">
            <svn-pro-card-learn
              v-for="playlist in page.learnPlaylists"
              :key="playlist?.id"
              card-type="playlist"
              :playlistImages="playlist?.coverUrls?.map((cover_url) => cover_url['size500'])"
              :cardTitle="playlist?.title"
              :shareable="true"
              :favoriteIcon="playlist?.connectedUserFavorited ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
              :editable="amICreatorOfEntityOrAdmin(playlist?.creatorId)"
              :count="playlist?.learnModulesCount"
              :is-favorited="playlist?.connectedUserFavorited"
              shareableIcon="custom:mingcute:link-line"
              @on-favorite="togglePlaylistFavorite(playlist)"
              @on-share="copyPlaylistLink(playlist?.id)"
              @go-to-item-show="goToPlaylistShowEdit(playlist?.id)"
            >
              <template #menu>
                <svn-pro-menu>
                  <template #activator>
                    <svn-pro-icon-button
                      icon="custom:mingcute:more-2-line"
                      variant="elevated"
                      color="surface"
                    />
                  </template>
  
                  <template #dropdown>
                    <v-list-item
                      value="edit_module"
                      density="compact"
                      :active="false"
                      @click="editPlaylist(playlist)"
                    >
                      {{ $t('Update playlist') }}
                    </v-list-item>
  
                    <v-list-item
                      value="delete_module"
                      density="compact"
                      :active="false"
                      @click="openDialogDeletePlaylist(playlist)"
                      class="text-error"
                    >
                      {{ $t('Delete playlist') }}
                    </v-list-item>
                  </template>
                </svn-pro-menu>
              </template>
            </svn-pro-card-learn>
          </template>
        </div>

        <template #empty />
      </v-infinite-scroll>

      <!-- Empty states -->
      <div
        v-if="!playlistsPages?.pages[0]?.learnPlaylists?.length"
        class="flex text-center justify-center items-center"
      >
        <svn-pro-empty-states
          :actions="isManagerCreatorOrAbove()"
          :variant="searchTermPlaylists ? 'results' : 'index'"
          :title="$t('No playlist yet')"
          :size="isMdScreen ? 'default' : 'compact'"
          :action-primary-title="$t('New Playlist')"
          @click-primary="emit('go-to-new-playlist')"
          prepend-primary="custom:mingcute:add-line"
          :supporting-text="emptyStateSupportingText"
        />
      </div>
    </div>
  </v-fade-transition>

  <!-- Dialog edit Playlist -->
  <dialog-delete-content
    v-if="playlistToDelete?.trainings?.length"
    ref="deletePlaylistDialog"
    :items="playlistToDelete?.trainings"
    :title="$t(`Playlist will be deleted with their trainings`)"
    :description="$t(`If this playlist is the only content of a training, the training will be deleted. Training(s) containing only this playlist :`)"
    @delete-content="deleteLearnPlaylist"
  />

  <svn-pro-dialog-validation
    v-else
    ref="deletePlaylistDialog"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Playlist will be deleted`)"
    :content-text="$t('Deleted playlists are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteLearnPlaylist"
  >
    <template #activator="{ props }">
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>

  <!-- Dialog edit Playlist -->
  <dialog-edit-playlist
    ref="editPlaylistDialog"
    :loading="editPlaylistLoading"
    :title="selectedLearnPlaylist?.title"
    :description="selectedLearnPlaylist?.description"
    @update-playlist="updateLearnPlaylist"
  />
</template>

<script setup>
import router from '@/router';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { ref, computed } from 'vue';
import PlaylistApi from '@/apis/playlist.api';
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from '@/store/user.js';
import { useMobileStore } from '@/store/mobile';
import { useQueryClient, useInfiniteQuery } from '@tanstack/vue-query';
import DialogEditPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogEditPlaylist.vue';
import DialogDeleteContent from '@/components/BktPopUp/Dialogs/learn/DialogDeleteContent.vue';
import SkeletonLoaderPlaylistCard from '@/components/skeletonLoaders/SkeletonLoaderPlaylistCard.vue';

const props = defineProps({
  themes: { type: Array, default: null },
});

const emit = defineEmits(['go-to-new-playlist']);

const snackbar = useSnackbar();
const queryClient = useQueryClient();
const { isManagerCreatorOrAbove, amICreatorOfEntityOrAdmin } = useUserStore();

const { isMdScreen } = storeToRefs(useMobileStore());

const playlistToDelete = ref(null);
const searchTermPlaylists = ref('');
const editPlaylistDialog = ref(false);
const editPlaylistLoading = ref(false);
const deletePlaylistDialog = ref(false);
const selectedLearnPlaylist = ref(null);
const searchTermPlaylistsDebounce = ref('');
const selectedThemesListPlaylists = ref([]);
const selectedThemesListPlaylistsDebounce = ref([]);
const playlistCacheKey = computed(() => {
  return [
    'learn',
    'catalog',
    'playlists',
    searchTermPlaylists.value,
    selectedThemesListPlaylistsDebounce.value,
  ];
});

const {
  data: playlistsPages,
  fetchNextPage: playlistsPagesFetchNextPage,
  hasNextPage: playlistsPagesHasNextPage,
  isPending: playlistsPagesIsPending,
  refetch: playlistRefetch
} = useInfiniteQuery({
  queryKey: playlistCacheKey,
  queryFn: ({ pageParam = 1, queryKey }) => {
    const [one, two, three, text, themeIds, sortBy] = queryKey;

    return PlaylistApi.index({
      page: pageParam,
      text: text,
      themeIds: themeIds,
    });
  },
  getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage,
});

const editPlaylist = (playlist) => {
  selectedLearnPlaylist.value = playlist;
  editPlaylistDialog.value.dialogEditPlaylist = true;
};

const updateLearnPlaylist = async (title, description) => {
  editPlaylistLoading.value = true
  
  try {
    await PlaylistApi.update(selectedLearnPlaylist?.value?.id, title, description)
    await playlistRefetch();

    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Playlist has been updated successfully.');
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Error updating the playlist.');
  }

  editPlaylistLoading.value = false
  editPlaylistDialog.value.dialogEditPlaylist = false;
};

const copyPlaylistLink = (id) => {
  const link = router.resolve({ name: 'playlist_show', params: { id: id } });

  navigator.clipboard
    .writeText(new URL(link.href, window.location.origin).href)
    .then(() => {
      snackbar.setBgColor('onSurface');
      snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
      snackbar.setMsg('Link copied to clipboard.');
      snackbar.displaySnackBar();
    })
    .catch(() => {
      snackbar.setBgColor('onSurface');
      snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
      snackbar.displaySnackBar('Error copying link to clipboard');
    });
};

const toggleThemeSelection = debounce(async (item) => {
  const found = selectedThemesListPlaylists?.value?.find((theme) => theme?.id === item?.id);

  if (found) {
    selectedThemesListPlaylists?.value?.splice(
      selectedThemesListPlaylists?.value?.findIndex((theme) => theme?.id === item?.id),
      1,
    );
  } else {
    selectedThemesListPlaylists?.value?.push(item);
  }
  search();
}, 300);

const togglePlaylistFavorite = debounce(async(playlist) => {
  try {
    if (!playlist) return;

    queryClient.setQueryData(playlistCacheKey.value, (oldData) => {
      // WARN: need to recreate objects otherwise vue query won't update cache !
      const tmpPages = [];

      for (const page of oldData?.pages) {
        const tmpLearnPlaylists = [];

        for (const learnPlaylist of page?.learnPlaylists) {
          if (learnPlaylist?.id === playlist?.id) {
            tmpLearnPlaylists.push(
              learnPlaylist.copyWith({
                connectedUserFavorited: !learnPlaylist.connectedUserFavorited,
              }),
            );
          } else {
            tmpLearnPlaylists.push(learnPlaylist);
          }
        }

        tmpPages.push(
          page.copyWith({
            learnPlaylists: tmpLearnPlaylists,
          }),
        );
      }

      return {
        pageParams: tmpPages,
        pages: tmpPages,
      };
    });

    // Call api optimistic request
    playlist = await PlaylistApi.toggleFavorite(playlist);

    snackbar.setBgColor('onSurface');

    if (playlist?.connectedUserFavorited) {
      snackbar.setMsg('Playlist removed from favorites.');
    } else {
      snackbar.setMsg('Playlist added to favorites.');
    }
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Error toggling favorite');
  }
}, 300);

const asignPlaylistToDelete = async (playlist) => {
  playlistToDelete.value = playlist;
};

const openDialogDeletePlaylist = async (playlist) => {
  await asignPlaylistToDelete(playlist);

  if (playlist?.trainings?.length) {
    deletePlaylistDialog.value.deleteDialog = true;
  } else {
    deletePlaylistDialog.value.dialogRef.dialog = true;
  }
};

const deleteLearnPlaylist = async () => {
  snackbar.setBgColor('onSurface');
  snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');

  try {
    await PlaylistApi.delete(playlistToDelete.value?.id)

    deletePlaylistDialog.value = false;

    await playlistRefetch();

    snackbar.setMsg('Playlist has been deleted successfully.');
  } catch (error) {
    snackbar.setMsg('Error deleting playlist');
  }

  snackbar.displaySnackBar();
  playlistToDelete.value = null;
};

const goToPlaylistShowEdit = (id) => {
  router.push({ name: 'playlist_show', params: { id: id } });
};

const clearAllSelectedThemes = () => {
  selectedThemesListPlaylists.value = [];
};

const search = debounce(async () => {
  searchTermPlaylistsDebounce.value = searchTermPlaylists.value;
  selectedThemesListPlaylistsDebounce.value = selectedThemesListPlaylists?.value?.map((theme) => theme?.id);
}, 300);

const loadMoreData = async ({ done }) => {
  if (playlistsPagesHasNextPage.value) {
    await playlistsPagesFetchNextPage();
    done('ok');
  } else {
    done('empty');
  }
};

const emptyStateSupportingText = computed(() => {
  return searchTermPlaylists.value
    ? i18n.global.t('Oops, we didn’t find any results matching your search.')
    : isManagerCreatorOrAbove()
      ? i18n.global.t(`There are no playlists. Create your first playlist !`)
      : i18n.global.t(`All playlists availables will show up here.`);
});
</script>
<template>
  <svn-pro-modal
    ref="dialogRef"
    :actionOneTitle="$t('Save')"
    :primary-loading="loading"
    :title="$t('Schedule a meeting')"
    :width="480"
    @click-primary-button="updateDate"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-6">
        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-1">
            <div>
              <Icon class="text-onSurface" height="24" icon="mingcute:time-line" width="24" />
            </div>

            <svn-pro-text body-large regular>
              {{ $t('Date and time') }}
            </svn-pro-text>
          </div>

          <div class="flex flex-col gap-2">
            <pop-up-edit-date @save="updateDateValue">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-model="dateInput"
                  :error="errorDate"
                  :error-messages="errorMessageDate"
                  :label="$t('Select a date*')"
                  append-inner-icon="custom:mingcute:calendar-2-line"
                  class="w-full"
                  clear-icon="mdi-close"
                  clearable
                  color="primary"
                  v-bind="props"
                  variant="outlined"
                />
              </template>
            </pop-up-edit-date>

            <div class="flex gap-2 items-center">
              <pop-up-edit-time @save="updateStartTimeValue">
                <template #activator="{ props }">
                  <svn-pro-text-field
                    v-model="startTimeInput"
                    :error="errorStartTime"
                    :label="$t('Start time*')"
                    class="w-full"
                    clear-icon="mdi-close"
                    clearable
                    color="primary"
                    prepend-inner-icon="custom:mingcute:time-line"
                    v-bind="props"
                    variant="outlined"
                  />
                </template>
              </pop-up-edit-time>

              <pop-up-edit-time @save="updateEndTimeValue">
                <template #activator="{ props }">
                  <svn-pro-text-field
                    v-model="endTimeInput"
                    :error="errorEndTime"
                    :label="$t('End time*')"
                    class="w-full"
                    clear-icon="mdi-close"
                    clearable
                    color="primary"
                    prepend-inner-icon="custom:mingcute:time-line"
                    v-bind="props"
                    variant="outlined"
                  />
                </template>
              </pop-up-edit-time>
            </div>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import moment from 'moment';
import { Icon } from '@iconify/vue';
import { onMounted, ref, watch } from 'vue';
import PopUpEditTime from './PopUpEditTime.vue';
import axios from '../../tools/axios-service.js';
import { useSnackbar } from '@/store/snackbar.js';
import PopUpEditDate from './PopUpEditDate.vue';

onMounted(() => {
  dateInput.value = props?.date
    ? moment(props?.date).format('YYYY-MM-DD')
    : moment(new Date()).format('YYYY-MM-DD');
  startSelectedDate.value = props?.startDate;
  endSelectedDate.value = props?.endDate;
});

const props = defineProps({
  campaignId: { type: Number, default: null },
  crossId: { type: Number, default: null },
  startDate: { type: String, default: null },
  endDate: { type: String, default: null },
  date: { type: String, default: null },
});

const emit = defineEmits(['callback']);

const snackbar = useSnackbar();

const dateInput = ref();
const loading = ref(false);
const endTimeInput = ref();
const dialogRef = ref(null);
const startTimeInput = ref();
const endSelectedDate = ref();
const startSelectedDate = ref();
const errorDate = ref(false);
const errorMessageDate = ref(null);
const errorStartTime = ref(false);
const errorEndTime = ref(false);

const updateStartTimeValue = (value) => {
  startTimeInput.value = value;
  if (errorStartTime?.value) {
    errorStartTime.value = false;
  }
};

const updateEndTimeValue = (value) => {
  endTimeInput.value = value;
  if (errorEndTime?.value) {
    errorEndTime.value = false;
  }
};

const updateDateValue = (value) => {
  dateInput.value = moment(value).format('YYYY-MM-DD');
};

const updateDate = async () => {
  const startTimeSeconds =
    Number(startTimeInput?.value?.split(':')?.[0] * 3600) +
    Number(startTimeInput?.value?.split(':')?.[1] * 60);
  const endTimeSeconds =
    Number(endTimeInput?.value?.split(':')?.[0] * 3600) +
    Number(endTimeInput?.value?.split(':')?.[1] * 60);

  if (!dateInput?.value) {
    errorDate.value = true;
  }
  if (!startTimeInput?.value) {
    errorStartTime.value = true;
  }
  if (!endTimeInput?.value) {
    errorEndTime.value = true;
  }
  if (endTimeSeconds < startTimeSeconds) {
    errorEndTime.value = true;
    snackbar
      .setMsg('End time cannot be before start time !')
      .setCustomClass('mb-4')
      .setBgColor('onSurface')
      .displaySnackBar();
    return;
  }
  if (errorMessageDate?.value) {
    snackbar
      .setMsg('Please choose a date in the future !')
      .setCustomClass('mb-4')
      .setBgColor('onSurface')
      .displaySnackBar();
    return;
  }
  if (errorDate?.value || errorStartTime?.value || errorEndTime?.value) {
    snackbar
      .setMsg('Please fill all required fields.')
      .setCustomClass('mb-4')
      .setBgColor('onSurface')
      .displaySnackBar();
  } else {
    loading.value = true;
    try {
      await axios.patch(
        `/interview_app/campaigns/${props?.campaignId}/interviews/${props?.crossId}.json`,
        {
          interview: {
            start_date: moment(dateInput?.value + ' ' + startTimeInput?.value, 'YYYY-MM-DD HH:mm'),
            end_date: moment(dateInput?.value + ' ' + endTimeInput?.value, 'YYYY-MM-DD HH:mm'),
          },
        },
      );

      dateInput.value = null;
      startTimeInput.value = null;
      endTimeInput.value = null;
      dialogRef.value.dialog = false;
      snackbar
        .setMsg('Meeting has been scheduled.')
        .setCustomClass('mb-4')
        .setBgColor('onSurface')
        .displaySnackBar();

      emit('callback');
    } catch (e) {
      snackbar
        .setMsg('Error scheduling meeting.')
        .setCustomClass('mb-4')
        .setBgColor('onSurface')
        .displaySnackBar();
    }
    loading.value = false;
  }
};

watch(dateInput, (newValue, oldValue) => {
  const now = moment();
  const date = moment(newValue);
  const diff = date.diff(now);
  if (newValue) {
    if (String(now.format('YYYY-MM-DD')) === String(newValue)) {
      errorDate.value = false;
      errorMessageDate.value = null;
    }
    if (diff < 0 && String(now.format('YYYY-MM-DD')) !== String(newValue)) {
      errorDate.value = true;
      errorMessageDate.value = 'Date cannot be before today !';
    }
    if (diff > 0 && errorDate?.value) {
      errorDate.value = false;
      errorMessageDate.value = null;
    }
  }
});
</script>
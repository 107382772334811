import { defineStore } from 'pinia';
import axiosService from '@/tools/axios-service.js';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';

export const useSurveyStore = defineStore({
  id: 'survey',
  state: () => ({
    isSurveyLoading: false,
    questions: [],
    answers: [],
    pinned_answers: [],
    messages: [],
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    clearSurvey() {
      this.questions = null;
      this.answers = null;
      this.pinned_answers = null;
      this.messages = null;
    },
    async fetchSurveyQuestions(campaign_id) {
      try {
        this.isSurveyLoading = true;
        const res = await axiosService.get(
          `/interview_app/campaigns/${campaign_id}/interview_questions`,
        );
        this.questions = res.data;
      } catch (e) {
        console.log('fail while fetching survey questions', e);
      } finally {
        this.isSurveyLoading = false;
      }
    },
    async fetchSurveyAnswers(interview_form_id, question_id, tag_id = null) {
      try {
        this.isSurveyLoading = true;
        const res = await axiosService.get(
          `/interview_app/forms/templates/${interview_form_id}/questions/${question_id}/answers`,
          {
            params: {
              tag_id: tag_id,
            },
          },
        );
        const survey = res.data;
        this.answers = survey.all_answers.map((answer, idx) => {
          return {
            ...answer,
            open: this.answers?.[idx]?.open || false,
          };
        });
        this.pinned_answers = survey.pinned_answers.map((answer, idx) => {
          return {
            ...answer,
            open: this.pinned_answers?.[idx]?.open || false,
          };
        });
      } catch (e) {
        console.log('fail while fetching survey answers', e);
      } finally {
        this.isSurveyLoading = false;
      }
    },
    async surveyPinAnswers(question_id, interview_form_id, interview_answer_id, pin_answer = true) {
      try {
        this.isSurveyLoading = true;
        const res = await axiosService.post(
          `/interview_app/forms/templates/${interview_form_id}/questions/${question_id}/answers/${interview_answer_id}/toggle_pin`,
          {
            params: {
              pin_answer: pin_answer,
            },
          },
        );
      } catch (e) {
        console.log('fail while fetching survey pinned answers', e);
      } finally {
        this.isSurveyLoading = false;
      }
    },
    async fetchSurveyMessages() {
      try {
        this.isSurveyLoading = true;
        const res = await axiosService.get(`/interview_app/campaign_packs/${id}.json`);
        const survey = res.data;
        this.questions = survey.interview_questions;
      } catch (e) {
        console.log('fail while fetching survey messages', e);
      } finally {
        this.isSurveyLoading = false;
      }
    },
  },
});

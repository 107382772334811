<template>
  <svn-pro-comment-list
    :drawer-large="drawerLarge"
    :has-list-data="isInThread || learnModulesComments?.length"
    :header-title="
      isInThread
        ? `Thread (${selectedComment?.replies?.length})`
        : $t('Comments ()', { count: learnModulesComments?.length || 0 })
    "
    :is-mobile="isMobile"
    :isInThread="isInThread"
    :selectedComment="selectedComment"
    @add-comment-reply="$emit('add-comment-reply', $event)"
    @add-comment-thread-reply="$emit('add-comment-thread-reply', $event)"
    @close-thread="closeThread"
    @close-drawer="closeDrawerAndThread"
  >
    <template #list-data>
      <!-- Reply is clicked, enter Thread -->
      <div v-if="isInThread" class="w-full h-full flex flex-col gap-4">
        <svn-pro-card-comment
          :avatar="selectedComment?.user?.avatar?.['50']"
          :comment="selectedComment?.text"
          :date="selectedComment?.created_at"
          :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
          :has-cta="false"
          :is-module-author="selectedComment?.['same_current_user_messageable_creator?']"
          :username="selectedComment?.user?.fullname"
          @delete-clicked="onDeleteComment(selectedComment)"
        />

        <div class="w-full flex items-center gap-2">
          <svn-pro-text body-medium class="whitespace-nowrap" color="onSurfaceVariant">
            {{
              selectedComment?.replies?.length
                ? selectedComment?.replies?.length > 1
                  ? `${selectedComment?.replies?.length} ${$t('replies')}`
                  : `${selectedComment?.replies?.length} ${$t('reply')}`
                : `${selectedComment?.replies?.length} ${$t('reply')}`
            }}
          </svn-pro-text>

          <svn-pro-divider class="w-full" color="[#767680]" />
        </div>

        <div v-if="selectedComment?.replies?.length" class="w-full flex flex-col gap-6">
          <svn-pro-card-comment
            v-for="(comment, index) in selectedComment?.replies"
            :key="index"
            :avatar="comment?.user?.avatar?.['50']"
            :comment="comment?.text"
            :date="comment?.created_at"
            :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
            :has-cta="false"
            :is-module-author="comment?.['same_current_user_messageable_creator?']"
            :username="comment?.user?.fullname"
            @delete-clicked="onDeleteCommentInThread(comment)"
          />
        </div>
      </div>

      <!-- List of all comments (NOT IN THREAD) -->
      <div
        v-for="(comment, index) in learnModulesComments"
        v-else-if="learnModulesComments?.length"
        :key="index"
        class="w-full flex flex-col gap-4"
      >
        <svn-pro-card-comment
          :avatar="comment?.user?.avatar?.['50']"
          :comment="comment?.text"
          :date="comment?.created_at"
          :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
          :is-module-author="comment?.['same_current_user_messageable_creator?']"
          :replies="comment?.replies?.length"
          :reply-button-text="getReplyText(comment?.replies?.length)"
          :tooltip-text="$t('Delete comment')"
          :username="comment?.user?.fullname"
          has-cta
          @action-clicked="openThreadMenu(comment)"
          @delete-clicked="onDeleteComment(comment)"
        />

        <svn-pro-divider
          v-if="index !== learnModulesComments?.length - 1"
          class="w-full"
          color="[#767680]"
        />
      </div>
    </template>

    <!-- No comments -->
    <template #empty-list>
      <div class="w-full h-full flex flex-col justify-center items-center self-stretch flex-1">
        <svn-pro-text body-large regular>
          {{ $t('No comments yet') }}
        </svn-pro-text>
      </div>
    </template>
  </svn-pro-comment-list>
</template>

<script setup>
import { ref, watch } from 'vue';
import i18n from '@/plugins/i18n.js';

const props = defineProps({
  learnModule: { type: Array },
  userId: { type: Number },
  learnModulesComments: { type: Array },
  isMobile: { type: Boolean, default: false },
  amICreatorOfEntityOrAdmin: { type: Boolean, default: false },
  drawerLarge: { type: Boolean, default: false },
  HeaderTitle: { type: String, default: '' },
});

const emit = defineEmits([
  'delete-comment',
  'delete-comment-in-thread',
  'close-drawer',
  'update:modelValue',
]);

const userComment = ref('');
const isInThread = ref(false);
const selectedComment = ref({});

const closeDrawerAndThread = () => {
  emit('close-drawer');
  if (isInThread?.value) {
    isInThread.value = false;
    selectedComment.value = {};
  }
};

const openThreadMenu = (comment) => {
  selectedComment.value = comment;
  isInThread.value = true;
};

const closeThread = () => {
  selectedComment.value = {};
  isInThread.value = false;
};

const onDeleteComment = (comment) => {
  emit('delete-comment', comment);
};

const onDeleteCommentInThread = (comment) => {
  emit('delete-comment-in-thread', comment);
};

const getReplyText = (replies) => {
  if (replies === 0) {
    return i18n.global.t('Reply');
  } else if (replies === 1) {
    return i18n.global.t('1 reply');
  } else {
    return i18n.global.t(`${replies} replies`);
  }
};

watch(props, (newValue, oldValue) => {
  if (newValue.learnModulesComments.length) {
    selectedComment.value.replies = newValue.learnModulesComments?.find(
      (comment) => comment?.id === selectedComment?.value?.id,
    )?.replies;
  }
});
</script>

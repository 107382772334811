<template>
  <div class="flex flex-col gap-[15px]">
    <div class="flex flex-col gap-2">
      <div class="relative flex justify-center items-center gap-2 self-stretch">
        <svn-icon-button
          button-size="lg"
          class="rotate-90 drag"
          icon="ic:baseline-drag-indicator"
          variant="noOutlined"
          @mousedown="toggleDragState(true)"
        />

        <div class="flex justify-center items-start gap-2 absolute right-0">
          <svn-icon-button
            button-size="lg"
            icon="mdi:trash-can-outline"
            variant="noOutlined"
            @click="removeFreeContentBlock"
          />
        </div>
      </div>

      <!-- Rich text editor -->
      <svn-tiptap
        v-if="input?.editor_content?.id"
        :create-image-url="`/api/v2/editor_contents/${input.editor_content.id}/upload_image`"
        :extension-left-menu="!isDragged"
        :extension-selection="AllTipTapPlugins"
        :extension-slash-command="AllTipTapPlugins"
        :html-data="
          Array.isArray(input?.editor_content?.blocks) ? '' : input?.editor_content?.blocks
        "
        :is-editable="!isDragged"
        @click="toggleDragState(false)"
        @on-save="debounceEditorContentUpdate"
      />
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, ref } from 'vue';
import { useToastStore } from '@/store/toast.js';
import { useUserStore } from '@/store/user.js';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { useActionCable } from '@/store/cable.js';
import i18n from '@/plugins/i18n.js';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';

const { addToast } = useToastStore();
const { id: userId } = storeToRefs(useUserStore());
const { cable } = storeToRefs(useActionCable());

const props = defineProps({
  input: { type: Object, default: null },
  isDragged: { type: Boolean, default: false },
  moduleId: { type: Number },
});

const editorContentChannel = ref(null);

const emit = defineEmits(['on-save', 'remove-paragraph', 'toggle-drag-state']);

const toggleDragState = (event) => {
  emit('toggle-drag-state', event);
};

const removeFreeContentBlock = () => {
  emit('remove-paragraph');
};

const updateEditorContent = debounce(async () => {
  const subscribeOptions = {
    channel: 'EditorContentChannel',
    id: props.input.editor_content.id,
  };

  editorContentChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (
        data.status === 'update' &&
        data.current_user.id !== userId.value &&
        props.input.editor_content.id === data.editor_content_id
      ) {
        addToast(
          'info',
          i18n.global.t(`This module has just been updated !`),
          i18n.global.t(
            `The lastest version of this content will be visible if you reload this page.`,
          ),
          false,
          {
            name: i18n.global.t(`Reload this page`),
            link: '/learns/module/' + props.moduleId + '/edit',
          },
        );
      }
    },

    update: async function (event) {
      const data = {
        blocks: event,
      };

      editorContentChannel.value.perform('update', { data });
    },
  });
}, 200);

updateEditorContent();

const debounceEditorContentUpdate = debounce((e) => editorContentChannel?.value?.update(e), 300);

onBeforeUnmount(() => {
  editorContentChannel?.value?.unsubscribe();
});
</script>

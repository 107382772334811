<template>
  <svn-pro-modal
    ref="otherParticipant"
    :actionOneTitle="$t('Add')"
    :actionTwoTitle="$t('Cancel')"
    :horizontal-buttons="!isMdScreen"
    :primary-loading="loading"
    :title="$t('Add participant')"
    :width="isMdScreen ? 480 : undefined"
    @click-close="closeAndReset"
    @click-primary-button="
      campaign?.campaign_type === 'OneToOne' || campaign?.campaign_type === 'Feedback'
        ? addFeeback()
        : addParticipantsAndClosePopUp()
    "
    @click-secondary-button="closeAndReset"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-6">
        <svn-pro-text body-large regular>
          {{ $t('Choose a participant and a person in charge to add to this campaign') }}
        </svn-pro-text>

        <div class="flex flex-col gap-4">
          <svn-pro-autocomplete
            v-if="campaign?.campaign_type === 'OneToOne' || campaign?.campaign_type === 'Feedback'"
            v-model="participant"
            :error="participantError"
            :error-messages="errorMessage"
            :items="usersNotInCurrentCampaign"
            :label="$t('Participant*')"
            item-title="fullname"
            item-value="id"
            @input="customSearchUsers($event)"
          />

          <svn-pro-autocomplete
            v-else
            v-model="participants"
            :error="participantsError"
            :error-messages="errorMessage"
            :items="usersNotInCurrentCampaign"
            :label="$t('Participants*')"
            item-title="fullname"
            item-value="id"
            multiple
            @input="participantsSearch($event)"
          />

          <svn-pro-autocomplete
            v-if="campaign?.campaign_type === 'OneToOne' || campaign?.campaign_type === 'Feedback'"
            v-model="personInCharge"
            :error="personInChargeError"
            :error-messages="personInChargeErrorMessage"
            :items="managers"
            :label="$t('Person in charge*')"
            item-title="fullname"
            item-value="id"
            @input="customSearchManagers($event)"
          />
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile.js';
import { useCampaignStore } from '@/store/campaign';
import axiosService from '@/tools/axios-service.js';

const route = useRoute();

const { isMdScreen } = storeToRefs(useMobileStore());
const { campaign } = storeToRefs(useCampaignStore());

const loading = ref(true);
const errorMessage = ref('');
const participants = ref([]);
const participant = ref(null);
const snackbar = useSnackbar();
const personInCharge = ref(null);
const totalPagesUsers = ref(null);
const otherParticipant = ref(null);
const participantError = ref(false);
const participantsError = ref(false);
const totalPagesManagers = ref(null);
const personInChargeError = ref(false);
const personInChargeErrorMessage = ref('');

const props = defineProps({
  set: { type: Array, required: true },
  campaignId: { type: Number, default: null },
});

const emit = defineEmits(['refetch-page']);

const managers = ref([]);
const usersNotInCurrentCampaign = ref([]);

onMounted(async () => {
  try {
    const { data } = await axiosService.get(`/users/users_search`, {
      params: {
        not_campaign_id: props?.campaignId || route?.params?.id,
        'page[size]': 10,
      },
    });
    usersNotInCurrentCampaign.value = data?.users;
    totalPagesUsers.value = data?.meta?.pagination?.total_pages;
    if (data?.users?.length) {
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const { data } = await axiosService.get(`/users/users_search`, {
      params: {
        only_managers: true,
        'page[size]': 10,
      },
    });
    managers.value = data?.users;
    totalPagesManagers.value = data?.meta?.pagination?.total_pages;
    if (data?.users?.length) {
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
  }
});

const customSearchUsers = async (search) => {
  try {
    const { data } = await axiosService.get(`/users/users_search`, {
      params: {
        text: search,
        not_campaign_id: props?.campaignId,
        'page[size]': 10,
      },
    });
    participant.value = null;
    usersNotInCurrentCampaign.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};

const customSearchManagers = async (search) => {
  try {
    const { data } = await axiosService.get(`/users/users_search`, {
      params: {
        text: search,
        only_managers: true,
        'page[size]': 10,
      },
    });
    personInCharge.value = null;
    managers.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};

const participantsSearch = async (search) => {
  try {
    const { data } = await axiosService.get(`/users/users_search`, {
      params: {
        text: search,
        not_campaign_id: props?.campaignId,
        'page[size]': 10,
      },
    });
    participant.value = null;
    usersNotInCurrentCampaign.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};

const addFeeback = async () => {
  loading.value = true;
  if (!participant?.value) {
    participantError.value = true;
    errorMessage.value = '*required';
  }
  if (!personInCharge?.value) {
    personInChargeError.value = true;
    personInChargeErrorMessage.value = '*required';
  }
  if (!personInChargeError?.value && !participantError?.value) {
    try {
      await axiosService.post(
        `/interview_app/campaigns/${props?.campaignId}/interview_sets/create_sets.json`,
        {
          employee_id: participant?.value,
          interviewer_id: personInCharge?.value,
        },
      );
      participant.value = null;
      personInCharge.value = null;
      otherParticipant.value.dialog = false;

      emit('refetch-page');

      await customSearchUsers();
      participants.value = [];
      participant.value = null;

      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
        .setMsg('Participant has been added successfully.')
        .displaySnackBar();
    } catch (e) {
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
        .setMsg('Error adding participant')
        .displaySnackBar();
    }
  }
  loading.value = false;
};

const addParticipantsAndClosePopUp = async () => {
  loading.value = true;
  if (!participants?.value?.length) {
    participantsError.value = true;
    errorMessage.value = '*required';
  } else {
    for (const participantId of participants?.value) {
      try {
        await axiosService.post(
          `/interview_app/campaigns/${props?.campaignId}/interview_sets/create_sets.json`,
          { employee_id: participantId },
        );

        emit('refetch-page');

        await customSearchUsers();
        participants.value = [];
        participant.value = null;
        otherParticipant.value.dialog = false;

        snackbar
          .setBgColor('onSurface')
          .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
          .setMsg('Participants have been added successfully.')
          .displaySnackBar();
      } catch (error) {
        snackbar
          .setBgColor('onSurface')
          .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
          .setMsg('Error adding participant')
          .displaySnackBar();
      }
    }
  }
  loading.value = false;
};

const closeAndReset = () => {
  participant.value = null;
  personInCharge.value = null;
  participants.value = [];
  participantError.value = false;
  personInChargeError.value = false;
  participantsError.value = false;
  otherParticipant.value.dialog = false;
  errorMessage.value = '';
  personInChargeErrorMessage.value = '';
};

watch(participant, (newValue, oldValue) => {
  if (newValue && participantError.value) {
    participantError.value = false;
    errorMessage.value = '';
  }
});

watch(participants, (newValue, oldValue) => {
  if (newValue && participantsError.value) {
    participantsError.value = false;
    errorMessage.value = '';
  }
});

watch(personInCharge, (newValue, oldValue) => {
  if (newValue && personInChargeError.value) {
    personInChargeError.value = false;
    personInChargeErrorMessage.value = '';
  }
});

defineExpose({
  otherParticipant,
});
</script>

<template>
  <pop-up-edit-date ref="startlineModal" :date-value="startline" min @save="updateStartline">
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-edit-date>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { usePopUpStore } from '../../store/pop-up.js';
import axios from '../../tools/axios-service.js';
import filters from '../../tools/filters.js';
import { useSnackbar } from '@/store/snackbar';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import PopUpEditDate from '@/components/popUpComponents/PopUpEditDate.vue';

const { closePopUp } = usePopUpStore();

const startlineModal = ref(null);

onMounted(async () => {
  startlineModal.value.dialog = true;
});

const snackbar = useSnackbar();

const { data } = storeToRefs(usePopUpStore());

const props = defineProps({
  campaignId: { type: String, default: null },
  startline: { type: String, default: null },
});
const currentStartline = ref(null);

const date = moment();

let today = date.format('YYYY-MM-DD');

currentStartline.value = props.startline
  ? props.startline?.split('/').reverse().join('-')
  : data?.value?.startline?.split('/').reverse().join('-');

const checkStartline = (data) => {
  if (currentStartline.value <= today) {
    startlineModal.value.dialog = true;
  } else {
    updateStartline(data);
  }
};

const updateStartline = async (date) => {
  const newCurrentStartline = moment(date).format('DD/MM/YYYY');
  const year = filters.formatDate(newCurrentStartline, 'YYYY');
  const month = filters.formatDate(newCurrentStartline, 'M');
  const day = filters.formatDate(newCurrentStartline, 'DD');

  try {
    await axios.patch(`/interview_app/campaigns/${props.campaignId}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day,
      },
    });

    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Staring date has been changed successfully.');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error updating starting date !');
    snackbar.displaySnackBar();
  } finally {
    closePopUp();
  }
};

watch(startlineModal, () => {
  if (!startlineModal || !startlineModal.value?.dialog) {
    closePopUp();
  }
});
</script>
<template>
  <div
    v-if="learnModule?.id"
    class="w-full max-w-[1524px] mx-auto flex flex-col gap-[120px] flex-1"
  >
    <div class="flex items-start justify-between self-stretch">
      <svn-title bold class="whitespace-nowrap" h2>
        {{ learnModule?.title }}
      </svn-title>

      <svn-title class="whitespace-nowrap" color="dark-grey" h3 regular>
        {{ $t('Time Zone: ') }} {{ timezone }} - {{ timezoneInfo }}
      </svn-title>
    </div>

    <div class="w-full flex flex-col items-start self-stretch gap-[180px]">
      <div class="w-full flex flex-col gap-12">
        <p class="text-[54px] text-fake-black font-normal leading-[150%]">
          <span class="font-semibold">
            {{ learnModule?.submission?.user?.fullname }}
          </span>
          {{ $t('assessment by') }}
          <span class="font-semibold">
            {{ learnModule?.submission?.expert?.fullname || `<< Expert name >>` }}
          </span>
        </p>

        <v-divider :thickness="2" class="w-full border border-opacity-100 border-[#111]" />

        <div class="flex flex-col items-start gap-6 self-stretch">
          <p class="text-[32px] font-normal leading-8">
            <span class="font-semibold">
              {{ learnModule?.submission?.user?.fullname }}
            </span>
            {{ $t('started at') }}
            {{ moment(learnModule?.submission?.created_at).tz('Europe/Paris').format('hh:mma') }}
            {{ $t('on') }}
            {{ moment(learnModule?.submission?.created_at).format('DD/MM/YYYY') }}
          </p>

          <p class="text-[32px] font-normal leading-8">
            <span class="font-semibold">
              {{ learnModule?.submission?.user?.fullname }}
            </span>
            {{ $t('validated answers at') }}
            {{ moment(learnModule?.submission?.updated_at).tz('Europe/Paris').format('hh:mma') }}
            {{ $t('on') }}
            {{ moment(learnModule?.submission?.updated_at).format('DD/MM/YYYY') }}
          </p>
        </div>
      </div>

      <div class="w-full flex flex-col items-start gap-20">
        <!-- Rich text editor -->
        <svn-tiptap
          v-if="editorContentData?.blocks?.length"
          :create-image-url="`/api/v2/editor_contents/${route?.params?.editor_id}/upload_image`"
          :extension-selection="AllTipTapPlugins"
          :extension-slash-command="AllTipTapPlugins"
          :html-data="editorContentData?.blocks"
          :is-editable="false"
          :with-border="false"
        />

        <v-divider :thickness="1" class="w-full border border-opacity-100 border-[#333333]" />

        <div class="w-full flex flex-col items-start gap-[160px]">
          <div
            v-for="(question, index) in inputsFaceToFace"
            class="w-full flex flex-col items-start gap-20"
          >
            <div
              v-if="question?.type === LearnInputType.OPEN"
              class="w-full flex flex-col items-start gap-12"
            >
              <div class="flex flex-col items-start gap-6 self-stretch">
                <p class="text-fake-black text-[48px] font-medium leading-normal">
                  {{ question?.title }}
                </p>

                <p class="text-fake-black text-[28px] font-normal leading-[48px]">
                  {{ question?.description }}
                </p>
              </div>

              <div class="w-full flex flex-col items-start gap-4">
                <p class="text-fake-black text-[28px] font-semibold leading-[28px]">
                  •{{ ` ${learnModule?.submission?.user?.fullname}` }} {{ $t('answer') }}:
                </p>

                <div class="flex items-center self-stretch">
                  <v-divider
                    :thickness="1"
                    class="border-opacity-100 text-dark-grey py-3 pl-5"
                    vertical
                  ></v-divider>

                  <quill-editor
                    v-if="
                      learnModule?.submission?.learn_responses_answers?.find(
                        (el) => el?.input_id === question?.id,
                      ) && findQuestionAnswer(question?.id).text
                    "
                    v-model:content="findQuestionAnswer(question?.id).text"
                    :placeholder="' '"
                    :read-only="true"
                    class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
                    content-type="html"
                  />

                  <svn-text v-else class="pl-6" medium sm> No answer for the moment.</svn-text>
                </div>
              </div>
            </div>

            <div
              v-else-if="
                question?.type === LearnInputType.PARAGRAPH &&
                question?.editor_content?.blocks?.length
              "
            >
              <svn-tiptap
                v-if="question?.editor_content?.blocks?.length"
                :create-image-url="`/api/v2/editor_contents/fake-id/upload_image`"
                :html-data="question?.editor_content?.blocks"
                :is-editable="false"
              />
            </div>

            <v-divider
              :thickness="1"
              class="w-full border border-opacity-100 border-[#333333]"
              color="fakeBlack"
            />
          </div>

          <img
            alt="Logo Aleph"
            class="self-center bg-cover aspect-square w-[105px] h-[94px]"
            src="@/assets/logo_aleph_dark_grey.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import moment from 'moment-timezone';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user.js';
import { useLearnModuleStore } from '@/store/learn-module';
import { useRoute } from 'vue-router';
import { LearnInputType } from '@/constants/types';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';

const { learnModule, inputsFaceToFace, editorContentData } = storeToRefs(useLearnModuleStore());
const { timezone, timezoneInfo } = storeToRefs(useUserStore());

const { fetchModule, getEditorContent, fetchInputsFaceToFace } = useLearnModuleStore();
const route = useRoute();

onMounted(async () => {
  try {
    await fetchModule(route?.params?.id);

    if (learnModule?.value?.editor_content_id) {
      await getEditorContent(route?.params?.editor_id);
    }

    if (learnModule?.value?.has_learn_pieces_face_to_face_evaluation) {
      await fetchInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id);
    }
  } catch (error) {
    console.log(error);
  }
});

const findQuestionAnswer = (questionId) => {
  return learnModule?.value?.submission?.learn_responses_answers?.find(
    (el) => el?.input_id === questionId,
  );
};
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}
</style>

<template>
  <svn-pro-modal
    ref="personInChargeModal"
    :actionOneTitle="$t('Apply')"
    :actionTwoTitle="$t('Cancel')"
    :horizontal-buttons="!isMdScreen"
    :primary-loading="loading"
    :title="$t('Person in charge')"
    :width="isMdScreen ? 600 : undefined"
    @click-close="closeAndReset"
    @click-primary-button="setInterviewer"
    @click-secondary-button="closeAndReset"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-2">
        <svn-pro-text medium subtitle-large>
          {{ $t('Set another person in charge') }}
        </svn-pro-text>

        <svn-pro-autocomplete
          v-model="userSelectedId"
          :error="personInChargeError"
          :error-messages="personInChargeErrorMessage"
          :items="userInterviewers"
          :label="$t('Search by name*')"
          item-title="fullname"
          item-value="id"
          @input="search($event)"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>
<script setup>
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useMobileStore } from '@/store/mobile';
import axios from '../../tools/axios-service.js';
import { useSnackbar } from '@/store/snackbar.js';

const props = defineProps({
  campaignId: { type: Number, default: null },
  employeeId: { type: Number, default: null },
});

const { isMdScreen } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();

const loading = ref(false);
const userSelectedId = ref();
const userInterviewers = ref([]);
const personInChargeModal = ref(null);
const personInChargeError = ref(false);
const personInChargeErrorMessage = ref('');

onMounted(async () => {
  try {
    if (!userInterviewers?.value?.length) {
      await search();
    }
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[116px]')
      .displaySnackBar(i18n.global.t('Error fetching users.'));
  }
});

const emit = defineEmits(['refetch-campaigns']);

const search = debounce(async (value) => {
  try {
    const res = await axios.get(`users/users_search`, {
      params: {
        text: value,
        only_managers: true,
        limit: 10,
      },
    });
    userInterviewers.value = res?.data?.users;
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar(i18n.global.t('Error fetching users'));
  }
}, 300);

const setInterviewer = async () => {
  loading.value = true;
  if (userSelectedId?.value) {
    try {
      await axios.patch(
        `/interview_app/campaigns/${props.campaignId}/interview_sets/update_sets.json`,
        {
          employee_id: props?.employeeId,
          interviewer_id: userSelectedId?.value,
        },
      );
      personInChargeModal.value.dialog = false;
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[64px]')
        .displaySnackBar('Person in charge has been changed successfully.');
      emit('refetch-campaigns');
    } catch (e) {
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[64px]')
        .displaySnackBar('Error changing person in charge.');
    }
  } else {
    personInChargeError.value = true;
    personInChargeErrorMessage.value = '*required';
  }
  loading.value = false;
};

const closeAndReset = () => {
  userSelectedId.value = null;
  personInChargeError.value = false;
  personInChargeModal.value.dialog = false;
};

watch(userSelectedId, (newValue, oldValue) => {
  if (newValue && personInChargeError.value) {
    personInChargeError.value = false;
    personInChargeErrorMessage.value = '';
  }
});
</script>

<template>
  <pop-up-skeleton>
    <template #body>
      <target-new
        v-if="displayTargetNew"
        :campaign_id="data?.campaignId"
        :create-draft="data?.createDraft"
        :objective-template-id="selectedTemplateId"
        :overlay-mode="data?.overlayMode"
        :users-from-overlay="data?.usersFromOverlay ? Object.values(data?.usersFromOverlay) : []"
        @close="closePopUp"
      />
      <div v-else class="flex flex-col -mt-12">
        <div class="border-b border-b-lightGrey px-4 pb-4">
          <p class="text-base text-start text-fakeBlack font-medium">
            {{ $t('New Target') }}
          </p>
        </div>

        <div class="flex flex-col px-4 mb-4">
          <p class="text-sm text-start font-normal text-fakeBlack mt-4 mb-2">
            {{ $t('Create your own target') }}
          </p>

          <v-card class="cursor-pointer rounded-lg sm:max-w-[265px] bkt-bg-light-grey9-hover">
            <div
              class="flex flex-row justify-between items-center p-4"
              @click="goToNewTargetCreation"
            >
              <div class="flex flex-row">
                <Icon class="mr-4" height="40" icon="noto-memo" width="40" />

                <div class="flex flex-col">
                  <p class="text-sm text-start text-fakeBlack font-normal">
                    {{ $t('Start from scratch') }}
                  </p>

                  <p class="text-xs text-start text-darkGrey font-normal">
                    {{ $t('Begin with a blank slate') }}
                  </p>
                </div>
              </div>

              <div class="justify-items-end">
                <Icon class="text-primary" height="24" icon="mdi-chevron-right" width="24" />
              </div>
            </div>
          </v-card>
        </div>

        <div class="flex flex-col pt-4 px-4">
          <p class="text-sm text-start text-fakeBlack font-medium mb-2">
            {{ $t('Use existing template') }}
          </p>

          <div class="flex flex-col sm:grid sm:grid-cols-2 sm:gap-4">
            <v-text-field
              v-model="title"
              :label="$t('Search')"
              class="text-darkGrey"
              clearable
              color="primary"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              @update:model-value="search"
            />

            <v-select
              v-model="typeSelected"
              :items="types"
              :label="$t('Indicator type')"
              class="text-darkGrey"
              color="primary"
              item-title="name"
              item-value="value"
              variant="outlined"
              @update:model-value="search"
            >
              <template #selection="{ item }">
                <div class="w-full truncate">
                  {{ item.title }}
                </div>
              </template>
            </v-select>
          </div>

          <div class="flex flex-col sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-4">
            <v-card
              v-for="(template, index) in templates"
              :key="index"
              class="flex flex-col p-4 border hover:!border hover:!border-[#5C95FF] rounded-lg cursor-pointer mb-4"
              @click="createTargetFromTemplate(template)"
            >
              <Icon
                :icon="getTemplateIconByIndicatorType(template.objective_indicator?.indicator_type)"
                class="text-primary"
                height="32"
                width="32"
              />

              <p class="text-xs text-start font-normal text-primary mb-3">
                {{
                  getTemplateIndicatorTextByIndicatorType(
                    template.objective_indicator?.indicator_type,
                  )
                }}
              </p>

              <p
                class="text-xs text-start font-normal text-fakeBlack mb-3 overflow-hidden whitespace-wrap text-ellipsis line-clamp-2"
              >
                {{ template.title }}
              </p>

              <div class="flex flex-row justify-start items-start">
                <Icon
                  class="text-start text-darkGrey mr-1"
                  height="12"
                  icon="material-symbols:today"
                  width="12"
                />

                <p class="text-xs text-start font-normal text-fakeBlack">
                  {{ template.due_date || $t('No deadline') }}
                </p>
              </div>
            </v-card>
          </div>

          <div
            v-if="templates.length === 0"
            class="flex flex-col justify-center items-center px-4 py-16"
          >
            <Icon height="24" icon="noto-winking-face" width="24" />

            <p class="text-sm font-normal text-fakeBlack mt-5">
              {{ $t('Hmm, it seems you have no template yet.') }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template #subtitle />
  </pop-up-skeleton>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { Icon } from '@iconify/vue';
import PopUpSkeleton from './PopUpSkeleton.vue';
import axiosService from '@/tools/axios-service.js';
import i18n from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { usePopUpStore } from '@/store/pop-up';
import { storeToRefs } from 'pinia';
import TargetNew from '@/views/roadmaps/Targets/New.vue';

const { closePopUp } = usePopUpStore();
const { data } = storeToRefs(usePopUpStore());

const router = useRouter();
const title = ref(null);
const templates = ref([]);
const selectedTemplateId = ref(null);
const typeSelected = ref(null);
const types = [
  { name: i18n.global.t('All'), value: null },
  { name: i18n.global.t('Boolean'), value: 'boolean' },
  { name: i18n.global.t('Numeric value'), value: 'numeric_value' },
  { name: i18n.global.t('Percentage'), value: 'percentage' },
  { name: i18n.global.t('Multi choice'), value: 'multi_choice' },
];
const displayTargetNew = ref(false);

onMounted(() => {
  getTemplates();
});

const getTemplates = async (params = null) => {
  const res = await axiosService.get('/companies/roadmap_templates', { params });

  templates.value = res.data['objective_elements'];
};

const search = () => {
  getTemplates({ title: title.value, indicator_type: typeSelected.value });
};

const getTemplateIconByIndicatorType = (indicatorType) => {
  if (indicatorType === 'numeric_value') {
    return 'ic-baseline-numbers';
  } else if (indicatorType === 'percentage') {
    return 'ic-baseline-percent';
  } else if (indicatorType === 'multi_choice') {
    return 'ic-baseline-format-list-bulleted';
  } else if (indicatorType === 'boolean') {
    return 'mdi-toggle-switch-outline';
  }
};

const getTemplateIndicatorTextByIndicatorType = (indicatorType) => {
  if (indicatorType === 'numeric_value') {
    return i18n.global.t('Number');
  } else if (indicatorType === 'percentage') {
    return i18n.global.t('Percent');
  } else if (indicatorType === 'multi_choice') {
    return i18n.global.t('Multi-choice');
  } else if (indicatorType === 'boolean') {
    return i18n.global.t('True/False');
  }
};

const goToNewTargetCreation = () => {
  if (data?.value?.overlayMode) {
    displayTargetNew.value = true;
  } else {
    router.push({
      name: 'roadmaps_targets_new',
      query: {
        usersFromOverlay: data?.value?.usersFromOverlay,
        createDraft: data?.value?.createDraft,
        campaign_id: data?.value?.campaignId,
        overlayMode: data?.value?.overlayMode,
        fromRoute: data.value?.from,
      },
    });
    closePopUp();
  }
};

const createTargetFromTemplate = (template) => {
  if (data?.value?.overlayMode) {
    selectedTemplateId.value = template.id;
    displayTargetNew.value = true;
  } else {
    router.push({
      name: 'roadmaps_targets_new',
      query: {
        overlayMode: data?.value?.overlayMode,
        objectiveTemplateId: template.id,
        usersFromOverlay: data?.value?.usersFromOverlay,
        createDraft: data?.value?.createDraft,
        campaign_id: data?.value?.campaignId,
        fromRoute: data.value?.from,
      },
    });
    closePopUp();
  }
};
</script>

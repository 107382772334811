<template>
  <svn-pro-data-table
    :headers="trainings?.length ? headers : []"
    :items="trainings ?? []"
    :items-per-page="-1"
    :loading="trainings ? loading : true"
    item-value="id"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTrainingShow(item?.id)">
        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            medium
            body-medium
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.module_count || 0"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.participant_count || 0"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.all_modules_duration || $t('No duration')"
          />
        </td>

        <td>
          <my-trainings-completion-indicator
            :acquired="item?.participants_completion"
            :total="item?.participant_count || 0"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.launched_at ? $filters.formatDate(item?.launched_at) : $t('Not yet')"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatDate(item?.updated_at)"
          />
        </td>

        <td v-if="isManagerCreatorOrAbove()">
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <v-list-item v-bind="props" :active="false" @click="goToTrainingEdit(item?.id)">
                <svn-pro-text body-large regular>
                  {{ $t('Edit training') }}
                </svn-pro-text>
              </v-list-item>

              <v-list-item v-bind="props" :active="false" value="delete" @click="openDialogDelete(item?.id)">
                <svn-pro-text body-large regular color="error">
                  {{ $t('Delete training') }}
                </svn-pro-text>
              </v-list-item>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('New training')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') :
          type === 'published' ? $t('Hmm, it seems you have no training yet. Create your first training !') : $t('Hmm, it seems you have no upcoming training.')"
        @click-primary="goToNewTraining"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>

  <!-- Delete training dialog -->
  <svn-pro-dialog-validation
    v-model="dialogDelete"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t('Training will be deleted')"
    :content-text="$t('Deleted trainings are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteTraining"
    @click-secondary-button="dialogDelete = false"
  >
    <template #activator="{ props }">
      <div class="hidden opacity-0" />
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n.js";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import MyTrainingsCompletionIndicator from "../MyTrainingsCompletionIndicator.vue";

const router = useRouter();
const { isManagerCreatorOrAbove } = useUserStore();

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  type: { type: String, required: true },
  search: { type: String, default: null },
  loading: { type: Boolean, default: false },
  trainings: { type: Array, default: () => [] },
  pagination: {type: Object, default: () => { return null }},
})

const dialogDelete = ref(false);
const trainingToDelete = ref(null);
const headers = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Title'),
    minWidth: 300
  },
  {
    align: "start",
    key: "module_count",
    sortable: false,
    title: i18n.global.t('Modules'),
    minWidth: 120
  },
  {
    align: "start",
    key: "participant_count",
    sortable: false,
    title: i18n.global.t('Participants'),
    minWidth: 120
  },
  {
    align: "start",
    key: "all_modules_duration",
    sortable: false,
    title: i18n.global.t('Estimated duration'),
    minWidth: 200
  },
  {
    align: "start",
    key: "module_completion",
    sortable: false,
    title: i18n.global.t('Completion'),
    minWidth: 150
  },
  {
    align: "start",
    key: "launched_at",
    sortable: false,
    title: i18n.global.t('Launch date'),
    minWidth: 150
  },
  {
    align: "start",
    key: "updated_at",
    sortable: false,
    title: i18n.global.t('Last update'),
    minWidth: 150
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    minWidth: 50
  }
]);

const emit = defineEmits(['update', 'reload-page', 'delete-training'])

const openDialogDelete = (trainingId) => {
  trainingToDelete.value = trainingId
  dialogDelete.value = true
}

const fetchNewPage = (e) => {
  emit('reload-page', e)
};

const goToTrainingShow = (id) => {
  router.push({ name: 'training_show', params: { id: id } })
}

const goToNewTraining = () => {
  router.push({ name: "training_new" })
}

const goToTrainingEdit = (id) => {
  router.push({ name: 'training_edit', params: { id: id } })
}

const deleteTraining = async () => {
  emit('delete-training', trainingToDelete?.value);
  dialogDelete.value = false;
  trainingToDelete.value = null;
}
</script>

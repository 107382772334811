import apiService from '@/tools/axios-service';

class ReactionApi {
  async put(id, data) {
    return apiService.put(`/learn/modules/${id}/reactions/toggle_reaction`, {
      type: data,
      text: '',
    });
  }
}

export const reactionAPi = new ReactionApi();

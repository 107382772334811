import SubmissionApi from '@/apis/submission.api';
import Submission from '@/models/submission';

class QuizSubmissionApi extends SubmissionApi {
  constructor() {
    super(Submission, '/learn/quizzes');
  }
}

const instance = new QuizSubmissionApi();

export default instance;

import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';

export const useMyTeamRoadmapUnarchivedStore = defineStore('myteamroadmapunarchived', {
  state: () => ({
    objectiveElements: null,
    ...genericStates,
  }),
  actions: {
    ...genericFunctions,
    async fetchMyTeamRoadmapUnarchived(id, page = 1, title = '') {
      this.params = {
        'page[number]': page,
        title,
      };
      await this.fetchEntity(
        `/roadmaps/employees/${id}/team_unarchived_targets`,
        'objective_elements',
        'objectiveElements',
      );
    },
  },
});

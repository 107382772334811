<template>
  <svn-pro-modal
    ref="modalImportUsers"
    :actionOneTitle="
      showResults
        ? ''
        : isImporting && importProgress === 100
          ? $t('Finish')
          : fileVerified && !errorNoData && !errorOtherData
            ? $t('Import file and apply changes')
            : fileVerified && (errorNoData || errorOtherData)
              ? $t('Upload another file')
              : ''
    "
    :actionTwoTitle="
      showResults
        ? ''
        : isImporting && importProgress === 100
          ? $t('See details')
          : fileVerified && !errorNoData && !errorOtherData
            ? $t('Cancel')
            : ''
    "
    :close-button-closes-modal="true"
    :sticky-bottom="true"
    :title="$t('Import users')"
    :width="isMdScreen ? 697 : '100%'"
    @click-close="
      showResults
        ? closeModal()
        : isImporting && importProgress === 100
          ? openConfirmExitDialog()
          : fileVerified && !errorNoData && !errorOtherData
            ? openConfirmExitDialog()
            : (modalImportUsers.dialog = false)
    "
    @click-primary-button="
      showResults
        ? closeModal()
        : isImporting && importProgress === 100
          ? closeModal()
          : fileVerified && (errorNoData || errorOtherData)
            ? uploadAnotherFile()
            : fileVerified && !errorNoData && !errorOtherData
              ? startImporting()
              : closeModal()
    "
    @click-secondary-button="isImporting ? (showResults = true) : openConfirmExitDialog()"
    @click-outside=""
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <div class="w-full h-full flex flex-col">
        <!-- Import has started and no errors -->
        <div
          v-if="isImporting && !importError && !showResults"
          class="w-full h-full flex flex-col items-start flex-1 p-8"
        >
          <div
            class="flex flex-col md:!flex-row justify-center items-center gap-8 md:!gap-0 flex-1 self-stretch"
          >
            <!-- Progress -->
            <div class="w-[240px] flex flex-col justify-center items-center gap-3">
              <!-- Circular Progress bar -->
              <svn-pro-progress-circular :model-value="inspectProgress" :size="115" :width="10">
                <svn-pro-text body-large medium> {{ inspectProgress }}%</svn-pro-text>
              </svn-pro-progress-circular>

              <!-- Import completed text -->
              <svn-pro-text medium subtitle-large>
                {{ importProgress === 100 ? $t('Import completed') : $t('Importing...') }}
              </svn-pro-text>
            </div>

            <!-- List of modifications -->
            <v-list>
              <v-list-item
                v-for="(modif, index) in modifications"
                :key="index"
                :active="false"
                density="compact"
              >
                <div class="flex items-center gap-4">
                  <div>
                    <Icon
                      :icon="
                        modif?.progress === 100
                          ? 'mingcute:check-circle-fill'
                          : 'mingcute:check-circle-line'
                      "
                      class="text-onSurfaceVariant transition-all duration-600"
                      height="24"
                      width="24"
                    />
                  </div>

                  <v-fade-transition>
                    <svn-pro-text regular subtitle-large>
                      {{ $t(modif?.type) }} {{ modif?.progress === 100 ? $t(' imported') : '...' }}
                    </svn-pro-text>
                  </v-fade-transition>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </div>

        <!-- Import has started and there are errors -->
        <!-- <div
          v-else-if="isImporting && importError && !showResults"
          class="w-full h-full flex flex-col justify-center items-center flex-1 p-8"
        >
          <div class="flex flex-col justify-center items-center gap-4 sm:gap-0self-stretch">
            <div class="flex flex-col justify-center items-center gap-2 self-stretch">
              <div>
                <Icon
                  icon="material-symbols:info"
                  height="24"
                  width="24"
                  class="text-negativeRed"
                />
              </div>
      
              <p class="text-negativeRed text-base font-medium">
                {{ $t('Error during import') }}
              </p>
      
              <p class="text-fakeBlack text-sm text-center font-normal self-stretch">
                {{ $t('An error occured during import. Please try again.') }}
              </p>
            </div>
      
            <v-btn
              class="normal-case"
              variant="flat"
              :text="$t('Retry')"
              height="40"
              color="primary"
              @click="retryImport"
            />
          </div>
        </div> -->

        <!-- Import is done, show results -->
        <import-details
          v-else-if="isImporting && !importError && showResults"
          :details="importDetails"
        />

        <!-- Not in import state yet -->
        <div
          v-if="!isImporting"
          class="flex-col h-full flex-1 flex justify-center self-stretch gap-8 items-start"
        >
          <!-- File has been verified and there are arrors -->
          <div
            v-if="fileVerified && (errorNoData || errorOtherData)"
            class="w-full h-full flex flex-col justify-center gap-4 overflow-hidden"
          >
            <!-- File contains errors Text -->
            <div class="flex flex-col md:!flex-row gap-4 items-center">
              <div>
                <Icon height="32" icon="noto:warning" width="32" />
              </div>

              <div class="flex flex-col text-center md:!text-start">
                <svn-pro-title h6 medium>
                  {{ $t('Your file contains errors') }}
                </svn-pro-title>

                <svn-pro-text body-medium medium>
                  {{ $t('Please correct the errors below and reload the file.') }}
                </svn-pro-text>
              </div>
            </div>

            <!-- List of errors -->
            <div class="flex flex-col h-fit gap-2 self-stretch overflow-auto">
              <!-- Error empty data (Empty .xlsx file) -->
              <div
                v-if="errorNoData"
                class="flex flex-col justify-center items-start self-stretch px-2 py-1 rounded border border-darkGrey"
              >
                <svn-pro-text medium subtitle-medium>
                  {{ errorList?.[0]?.title }}
                </svn-pro-text>

                <svn-pro-text body-medium color="onSurfaceVariant" regular>
                  {{ errorList?.[0]?.description }}
                </svn-pro-text>
              </div>

              <!-- Other types of erros -->
              <div v-else-if="errorOtherData" class="flex flex-col gap-2 self-stretch">
                <!-- Error List -->
                <div
                  v-for="(error, index) in errorList"
                  :key="index"
                  class="flex flex-col justify-center items-start self-stretch px-2 py-1 rounded border border-[#C7C5D0] border-opacity-100"
                >
                  <svn-pro-text v-if="error?.title" medium subtitle-medium>
                    {{ error?.title }}
                  </svn-pro-text>

                  <svn-pro-text v-if="error?.line" body-medium color="error" medium>
                    {{ $t('On line') + error?.line }}
                  </svn-pro-text>

                  <svn-pro-text
                    v-if="error?.description"
                    body-medium
                    color="onSurfaceVariant"
                    regular
                  >
                    {{ error?.description }}
                  </svn-pro-text>
                </div>
              </div>
            </div>
          </div>

          <div
            v-else-if="isImporting == null"
            :class="isImporting == null ? '' : ' border border-dashed rounded-[8px]'"
            class="w-full h-full flex flex-col justify-center items-center flex-1 p-4"
          >
            <div class="flex flex-col gap-4 justify-center items-center">
              <div class="flex flex-col gap-2 justify-center items-center">
                <div>
                  <Icon height="48" icon="noto:warning" width="48" />
                </div>

                <div class="flex flex-col gap-1 text-center">
                  <svn-pro-title h6 medium>
                    {{ $t('Impossible to import') }}
                  </svn-pro-title>

                  <svn-pro-text body-medium class="flex flex-col text-center" medium>
                    {{ $t('Incorrect file format.') }}

                    <span>
                      {{ $t('Only .xlsx format is accepted.') }}
                    </span>
                  </svn-pro-text>
                </div>
              </div>

              <svn-pro-button
                :text="$t('Upload another file')"
                variant="flat"
                @click="uploadAnotherFile"
              />
            </div>
          </div>

          <!-- File is not verified or is being verified -->
          <div
            v-else-if="!fileVerified"
            :class="
              isVerifyingFile
                ? 'gap-8 md:!py-[73.5px]'
                : dragActive
                  ? 'border border-dashed rounded-[8px] !border-[#C7C5D0] !bg-[#F1EEEE] gap-4'
                  : 'border border-dashed rounded-[8px] !border-[#C7C5D0] gap-4'
            "
            class="w-full flex flex-col justify-center items-center flex-1 p-4"
            @drop.prevent="onDrop"
            @dragenter.prevent="setActiveDrag"
            @dragover.prevent="setActiveDrag"
            @dragleave.prevent="setInactiveDrag"
          >
            <div
              v-if="isVerifyingFile && !fileVerified"
              class="flex flex-col justify-center items-center gap-3"
            >
              <svn-pro-progress-circular :model-value="inspectProgress" :size="115" :width="10">
                <svn-pro-text body-large medium> {{ inspectProgress }}%</svn-pro-text>
              </svn-pro-progress-circular>

              <svn-pro-text medium subtitle-large>
                {{ $t('Inspecting file...') }}
              </svn-pro-text>
            </div>

            <svn-pro-button
              v-if="isVerifyingFile && !fileVerified"
              :text="$t('Cancel')"
              variant="outlined"
              @click="openConfirmExitDialog"
            />

            <!-- Drag & Drop block -->
            <div
              v-if="!isVerifyingFile && !fileVerified"
              class="flex flex-col gap-4 h-[256px] md:!h-[224px] justify-center items-center"
            >
              <div>
                <Icon
                  class="text-onSurfaceVariant"
                  height="24"
                  icon="mingcute:upload-2-line"
                  width="24"
                />
              </div>

              <div
                v-if="isImporting != null"
                class="flex flex-col justify-center items-center gap-1 self-stretch"
              >
                <svn-pro-text
                  v-if="!dragActive"
                  body-medium
                  class="flex flex-col text-center"
                  medium
                >
                  {{ $t('Drag & drop here or upload a file.') }}

                  <span>
                    {{ $t('Only .xlsx format is accepted.') }}
                  </span>
                </svn-pro-text>

                <svn-pro-title v-if="dragActive" color="onSurfaceVariant" h6 medium>
                  {{ $t('Drop your file here') }}
                </svn-pro-title>
              </div>

              <svn-pro-button
                v-if="!dragActive"
                :text="$t('Upload a file')"
                variant="flat"
                @click="openFileInput"
              />
              {{ fileInput.value }}
            </div>
          </div>

          <!-- File has beeen verified and there are no errors -->
          <div
            v-else-if="fileVerified && !errorNoData && !errorOtherData"
            class="w-full h-full flex flex-col gap-4 flex-1"
          >
            <!-- File Ready for import block -->
            <div class="flex flex-col md:!flex-row items-center p-4 md:!p-0 gap-4">
              <div>
                <Icon height="32" icon="noto:check-mark-button" width="32" />
              </div>

              <div class="flex flex-col gap-1">
                <svn-pro-title h6 medium>
                  {{ $t('File ready for import') }}
                </svn-pro-title>

                <svn-pro-text body-medium medium>
                  {{ $t('No errors detected.') }}
                </svn-pro-text>
              </div>
            </div>

            <!-- Modifications block -->
            <div class="flex flex-col gap-2 sm:gap-4 flex-1">
              <p class="text-fakeBlack text-base font-medium">
                {{ $t('Modifications') }}
              </p>

              <!-- List of modifications -->
              <div class="flex flex-1 flex-col items-start gap-2">
                <div
                  v-for="(modification, index) in modifications"
                  :key="index"
                  class="w-full flex flex-col items-start"
                >
                  <div class="w-full flex flex-col sm:flex-row">
                    <p class="text-fakeBlack text-base sm:w-[175px] font-normal">
                      {{ modification?.type }} :
                    </p>

                    <div class="w-full flex justify-between">
                      <div
                        v-for="(change, index) in modification?.changes"
                        :key="index"
                        class="flex items-center gap-1"
                      >
                        <div>
                          <Icon
                            :class="`text-${getModificationDetail(change?.type)?.[1]}`"
                            :icon="getModificationDetail(change?.type)?.[0]"
                            height="16"
                            width="16"
                          />
                        </div>

                        <p class="text-fakeBlack text-base font-normal">
                          {{ change?.count }} {{ change?.type }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="index !== modifications?.length - 1"
                    class="w-full border-t border-t-darkGrey mt-2"
                  />
                </div>
              </div>

              <!-- Send invitation emails block -->
              <div class="flex gap-4 items-center pr-6">
                <v-checkbox v-model="sendInvitations" color="primary" hide-details />

                <svn-pro-text body-medium medium>
                  {{ $t('Send invitation emails to all new users') }}
                </svn-pro-text>
              </div>
            </div>
          </div>

          <!-- Help text -->
          <div
            v-if="!fileVerified && !isVerifyingFile && isImporting !== null"
            class="w-full flex flex-col sm:flex-row justify-between items-center gap-4 self-stretch"
          >
            <div class="flex flex-col items-start self-stretch">
              <svn-pro-text medium subtitle-large>
                {{ $t("You don't have a file ?") }}
              </svn-pro-text>

              <div class="flex flex-col">
                <svn-pro-text body-medium class="flex flex-col" color="onSurfaceVariant" regular>
                  {{ $t('Download and complete the template provided.') }}

                  <svn-pro-text body-medium color="onSurfaceVariant" regular>
                    {{ $t("You can use the 'Help' tab in the file to fill in the template.") }}
                  </svn-pro-text>
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-button
              :target="'_blank'"
              :text="$t('Download template')"
              :to="'/template_import.xlsx'"
              prepend-icon="custom:mingcute:download-2-line"
              variant="text"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>

  <svn-pro-dialog-validation
    ref="dialog"
    :action-one-title="$t('Quit import')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('If you quit, you will have to restart the import again.')"
    :title="$t('Import won’t be done')"
    icon="noto:warning"
    @click-primary-button="cancelEverything"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <v-file-input
    id="fileInput"
    ref="fileInput"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    class="hidden"
    color="primary"
    counter
    variant="outlined"
    @change="handleFileChange"
    @click="resetInput"
  />
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import ImportDetails from '@/components/companyApp/ImportDetails.vue';
import axios from 'axios';
import { debounce } from 'lodash';
import { useActionCable } from '@/store/cable.js';
import { useCompanyStore } from '@/store/company.js';
import { useUserStore } from '@/store/user.js';

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, (e) => {
      e.preventDefault();
    });
  });
});

const snackbar = useSnackbar();
const { fetchCompany } = useCompanyStore();
const { fetchUsersForImport } = useUserStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { history, modifications } = storeToRefs(useCompanyStore());

const fileInput = ref(null);
const isVerifyingFile = ref(false);
const fileVerified = ref(false);
const inspectProgress = ref(0);
const interval = ref(0);
const errorNoData = ref(false);
const errorOtherData = ref(false);
const sendInvitations = ref(false);
const isImporting = ref(false);
const importProgress = ref(0);
const importError = ref(false);
const showResults = ref(false);
const draggedFile = ref(null);
const dragActive = ref(false);
const dragTimeout = ref(null);
const dialog = ref(null);
const modalImportUsers = ref(null);
const importUserChannel = ref(null);
const { cable } = storeToRefs(useActionCable());
const errorList = ref([]);
const importDetails = ref([]);
const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

const setUpImportDetails = (history) => {
  let created = [];
  if (history.created?.length) {
    created = history.created.concat(history.tags_added).concat(history.tag_categories_added);
  }
  importDetails.value = [
    {
      type: 'created',
      changes: created,
    },
    {
      type: 'updated',
      changes: history.updated,
    },
    {
      type: 'deleted',
      changes: history.removed,
    },
  ];
};

const handleFileChange = async (e) => {
  if (e.target.files.length == 0) return;

  const file = e.target.files[0];
  if (fileHasCorrectFormat(file)) {
    isVerifyingFile.value = true;

    if (isVerifyingFile.value) {
      importFile(file);
    }
  } else {
    isImporting.value = null;
    importError.value = true;

    displaySnackbarIncorrectFormat();
  }
};

const onDrop = (e) => {
  draggedFile.value = e.dataTransfer.files?.[0];
  if (fileHasCorrectFormat(draggedFile.value)) {
    isVerifyingFile.value = true;

    if (isVerifyingFile.value) {
      importFile(draggedFile.value);
    }
  } else {
    isImporting.value = null;
    importError.value = true;

    displaySnackbarIncorrectFormat();
  }
};

const importFile = async (file) => {
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    await axios.post(
      `/companies/import_file`,
      {
        import_file: file,
      },
      { headers },
    );
  } catch (e) {
    console.log(e);
  }
};

const importData = async () => {
  try {
    importProgress.value = 0;
    const headers = { 'Content-Type': 'application/json' };
    await axios.post(`/companies/import_data`, {
      history_id: history.value.id,
      send_invite: sendInvitations.value,
    });
  } catch (e) {
    console.log(e);
  }
};

const parseImportFile = debounce(async () => {
  const subscribeOptions = {
    channel: 'ImportUserChannel',
    room: 'parseImportFile',
  };
  importUserChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {},

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'ok') {
        inspectProgress.value = 100;
        history.value = data.history;
        modifications.value = data?.history?.modifications;
        fileVerified.value = true;
      } else if (data.status === 'unprocessable_entity') {
        errorList.value = data.message;
        errorOtherData.value = true;
        fileVerified.value = true;
        return;
      }
      inspectProgress.value = data.progression;
    },

    update: async function () {},
  });
}, 200);

parseImportFile();

const importFileData = debounce(async () => {
  const subscribeOptions = {
    channel: 'ImportUserChannel',
    room: 'importFileData',
  };
  importUserChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {},

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'ok') {
        importProgress.value = 100;
        history.value = data.history;
        modifications.value = data.history?.modifications;
        setUpImportDetails(data.history);
      } else if (data.status === 'unprocessable_entity') {
        showResults.value = true;
        importError.value = true;
      } else if (data.status === 'in_progress') {
        modifications.value = data.history?.modifications;
        importProgress.value = data.progression;
      }
    },

    update: async function () {},
  });
}, 200);

importFileData();

const uploadAnotherFile = () => {
  errorOtherData.value = false;
  fileVerified.value = false;
  isImporting.value = false;
  showResults.value = false;
  isVerifyingFile.value = false;
  importError.value = false;
  inspectProgress.value = 0;
  importProgress.value = 0;
  errorList.value = [];
  fileInput.value.click();
};

const openConfirmExitDialog = () => {
  dialog.value.dialogRef.dialog = true;
};

const cancelEverything = () => {
  clearInterval(interval.value);
  setInactiveDrag();
  isVerifyingFile.value = false;
  fileVerified.value = false;
  isImporting.value = false;
  importError.value = false;
  showResults.value = false;
  importProgress.value = 0;
  errorList.value = [];
  inspectProgress.value = 0;
  if (dialog.value?.dialogRef?.dialog) dialog.value.dialogRef.dialog = false;
  modalImportUsers.value.dialog = false;
  sendInvitations.value = false;
};

const getModificationDetail = (type) => {
  if (type === 'created') {
    return ['mdi-add', 'positiveGreen'];
  } else if (type === 'modified') {
    return ['grommet-icons:update', 'mediumOrange'];
  } else if (type === 'removed') {
    return ['mdi-trash-can-outline', 'negativeRed'];
  }
};

const startImporting = () => {
  isImporting.value = true;
  importData();
};

const retryImport = () => {
  isImporting.value = false;
  importProgress.value = 0;
  importError.value = false;
};

const closeModal = async () => {
  modalImportUsers.value.dialog = false;
  resetInput();
  setTimeout(() => {
    cancelEverything();
  }, 250);
  await fetchCompany();
  await fetchUsersForImport();
};

const setActiveDrag = () => {
  dragActive.value = true;
  clearTimeout(dragTimeout.value);
};

const setInactiveDrag = () => {
  dragTimeout.value = setTimeout(() => {
    dragActive.value = false;
  }, 50);
};

const fileHasCorrectFormat = (file) => {
  return file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
};

const displaySnackbarIncorrectFormat = () => {
  setInactiveDrag();
  snackbar
    .setBgColor('onSurface')
    .setMsg('Your file has the incorrect format, please select a file with the .xlsx format')
    .setCustomClass('mb-5')
    .displaySnackBar();
};

const openFileInput = () => {
  fileInput.value.value = '';
  fileInput.value.click();
};

const resetInput = () => {
  draggedFile.value = null;
  fileInput.value.value = '';
};

const backToDragNDrop = () => {
  showResults.value = false;
  fileVerified.value = false;
  dragActive.value = false;
  isVerifyingFile.value = false;
};

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, (e) => {
      e.preventDefault();
    });
  });
});

defineExpose({
  modalImportUsers,
});
</script>

<template>
  <svn-pro-modal
    ref="editField"
    :action-one-title="$t('Save')"
    :primary-loading="loading"
    :title="$t(`Update ${field || undefined}`)"
    @click-primary-button="saveNewValue(field)"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #text>
      <div class="w-full flex items-center justify-center">
        <svn-pro-text-field
          v-if="field === 'title'"
          v-model="title"
          :label="$t('Title')"
          variant="outlined"
        />

        <svn-pro-text-area
          v-else-if="field === 'description'"
          v-model="description"
          :label="$t('Description')"
          :max-rows="5"
          :no-resize="true"
          :rows="5"
          variant="outlined"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import axios from 'axios';
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';

onMounted(() => {
  title.value = props?.title;
  description.value = props?.description;
});

const props = defineProps({
  title: { type: String, default: null },
  description: { type: String, default: null },
  field: { type: String, default: null },
  targetId: { type: Number, default: null },
});

const emit = defineEmits(['callback']);

const { isMobile } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();

const title = ref('');
const loading = ref(false);
const description = ref('');
const editField = ref(null);

const saveNewValue = async (field) => {
  loading.value = true;
  try {
    if (field === 'title') {
      await axios.patch(`/roadmaps/targets/${props?.targetId}`, {
        title: title.value,
      });
    } else if (field === 'description') {
      await axios.patch(`/roadmaps/targets/${props?.targetId}`, {
        description: description.value,
      });
    } else if (field === 'Deadline') {
      await axios.patch(`/roadmaps/targets/${props?.targetId}`, {
        due_date: moment(deadline.value).format('DD MMM, YYYY'),
      });
    }

    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    if (field === 'title') {
      snackbar.setBgColor('onSurface').displaySnackBar(`Title has been updated successfully.`);
    } else if (field === 'description') {
      snackbar
        .setBgColor('onSurface')
        .displaySnackBar(`Description has been updated successfully.`);
    } else {
      snackbar.setBgColor('onSurface').displaySnackBar(`${field} has been updated successfully.`);
    }
    emit('callback');
  } catch (error) {
    console.log(error);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('error').displaySnackBar(`Error updating ${field}!`);
  }
  loading.value = false;
  editField.value.dialog = false;
};

watch(props, (newValue, oldValue) => {
  if (newValue.title) {
    title.value = newValue.title;
  }
  if (newValue.description) {
    description.value = newValue.description;
  }
});

defineExpose({
  editField,
});
</script>

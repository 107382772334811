<template>
  <div class="w-full flex flex-col items-center bg-surface">
    <trash-warnning
      v-if="isTrashed"
      title="This target is in trash."
      @restore="restoreDeletedItem"
      @delete-permanently="deleteTargetRef.dialogRef.dialog = true"
    />

    <div class="w-full bg-surface md:!px-8 border-b border-b-borderColor border-opacity-100">
      <svn-pro-breadcrumbs :items="breadcrumbItems" />
    </div>

    <div
      class="w-full max-w-[1136px] h-full max-h-[872px] flex lg:px-12 lg:py-6 flex-col lg:flex-row justify-center xl:!px-0"
    >
      <div
        class="w-full flex flex-col lg:max-w-[65%] border-[#767680] lg:border-t lg:border-l lg:border-b lg:border-r pb-16 lg:pb-0"
      >
        <!-- Header -->
        <div
          class="flex items-center justify-center gap-2 px-4 md:!px-12 h-20 border-b !border-[#767680]"
        >
          <div
            v-for="(item, idx) in [
              { kind: 'Employee', obj: employee },
              { kind: 'Manager', obj: employee?.manager },
              { kind: 'Creator', obj: creator },
            ]"
            :key="idx"
            class="flex flex-1 gap-2 items-center"
          >
            <svn-pro-avatar
              v-if="item.obj != null"
              :firstname="item.obj.firstname"
              :image="item.obj?.avatar?.['50']"
              :lastname="item.obj.lastname"
              :size="32"
              :type="item.obj?.avatar?.['50'] ? 'photo' : 'monogram'"
            />

            <svn-pro-avatar
              v-else
              :firstname="'N/A'"
              :lastname="''"
              :size="32"
              :type="'monogram'"
            />

            <!-- Person firstname + lastname & Role -->
            <div class="w-full flex flex-col">
              <svn-pro-text body-medium class="line-clamp-1 break-all" medium>
                {{ item.obj != null ? `${item.obj.firstname} ${item.obj.lastname}` : 'N/R' }}
              </svn-pro-text>

              <svn-pro-text caption class="line-clamp-1 break-all" color="onSurfaceVariant">
                {{ $t(item.kind) }}
              </svn-pro-text>
            </div>
          </div>

          <!-- Three dots button -->
          <svn-pro-menu :items="menuDesktop">
            <template #activator>
              <svn-pro-icon-button
                class="hidden lg:flex"
                color="onSurfaceVariant"
                icon="custom:mingcute:more-2-line"
                variant="text"
              />
            </template>
          </svn-pro-menu>
        </div>

        <!-- MOBILE design, hidden à partir de sm -->
        <div class="h-20 flex flex-row justify-end items-center lg:hidden px-4 md:!px-12">
          <!-- Target History -->
          <v-dialog
            v-model="lastUpdateDialog"
            :scrim="false"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template #activator="{ props }">
              <svn-pro-icon-button
                color="onSurfaceVariant"
                icon="custom:mingcute:time-line"
                v-bind="props"
                variant="text"
              />
            </template>

            <div class="flex flex-col justify-between overflow-hidden bg-surface">
              <!-- Main scrollable container -->
              <div class="flex flex-col h-full overflow-y-auto">
                <!-- Target list title -->
                <div
                  class="w-full flex bg-surface items-center sticky z-[5] top-0 py-2 pl-1 pr-2 border-b border-b-borderColor border-opacity-100"
                >
                  <svn-pro-icon-button
                    color="onSurfaceVariant"
                    icon="custom:mingcute:close-line"
                    variant="text"
                    @click="lastUpdateDialog = false"
                  />

                  <svn-pro-title h6 medium>
                    {{ $t('Last events') }}
                  </svn-pro-title>
                </div>

                <!-- Target logs list -->
                <div class="w-full h-screen">
                  <target-logs
                    v-if="indicator"
                    :indicator="indicator"
                    :logs="logs"
                    :target-title="title"
                  />
                </div>
              </div>

              <!-- Add comment input -->
              <div
                class="flex items-center p-4 gap-2 border-t border-t-borderColor border-opacity-100"
              >
                <svn-pro-text-area
                  v-model="commentToSend"
                  :label="$t('Add a comment')"
                  :max-rows="1"
                  :rows="1"
                  variant="outlined"
                />

                <svn-pro-icon-button
                  :disabled="!commentToSend"
                  color="onSurfaceVariant"
                  icon="custom:mingcute:send-line"
                  variant="text"
                  @click="addNewComment"
                />
              </div>
            </div>
          </v-dialog>

          <!-- Three dots button -->
          <svn-pro-menu :items="menuMobile">
            <template #activator>
              <svn-pro-icon-button
                class="lg:hidden"
                color="onSurfaceVariant"
                icon="custom:mingcute:more-2-line"
                variant="text"
              />
            </template>
          </svn-pro-menu>
        </div>

        <!-- Target Infos -->
        <div class="flex h-full flex-1 flex-col p-4 md:!p-0 md:!px-12 md:!py-4 gap-6">
          <div class="flex flex-col gap-2">
            <!-- Target title -->
            <div class="flex items-center w-full gap-2 cursor-default">
              <svn-pro-text class="flex-1 text-center lg:!text-start" medium subtitle-large>
                {{ title }}
              </svn-pro-text>

              <svn-pro-icon-button
                v-if="!isTrashed"
                class="hidden lg:flex"
                color="onSurfaceVariant"
                icon="custom:mingcute:edit-2-line"
                variant="text"
                @click="openModalEditField('title')"
              />
            </div>

            <!-- Target due date -->
            <div class="flex items-center w-full gap-2 cursor-default">
              <div class="w-full flex gap-2 items-center justify-center lg:justify-start">
                <svn-pro-text body-medium regular>
                  {{ $t('Due date : ') }}
                </svn-pro-text>

                <svn-pro-text body-medium class="lg:flex-1" regular>
                  {{
                    date
                      ? $filters.formatSpecificDate(date, 'YYYY-MM-DD', 'DD MMM, YYYY')
                      : $t('No due date')
                  }}
                </svn-pro-text>
              </div>

              <svn-pro-icon-button
                v-if="!isTrashed"
                class="hidden lg:flex"
                color="onSurfaceVariant"
                icon="custom:mingcute:edit-2-line"
                variant="text"
                @click="editDeadlineRef.dialog = true"
              />
            </div>

            <!-- Target description -->
            <div class="flex items-center w-full gap-2 cursor-default">
              <div class="w-full flex flex-col items-center lg:!items-start">
                <svn-pro-text
                  :class="descSeeAll ? '' : isMdScreen ? 'line-clamp-3' : 'line-clamp-6'"
                  body-medium
                  class="text-center lg:!text-start"
                  color="onSurfaceVariant"
                  regular
                >
                  {{ desc }}
                </svn-pro-text>

                <span
                  v-if="
                    desc?.split(' ')?.filter((word) => {
                      return word !== '';
                    })?.length >= 35
                  "
                  class="!text-primary cursor-pointer"
                  @click="descSeeAll = !descSeeAll"
                >
                  {{ descSeeAll ? $t('See less') : $t('See all') }}
                </span>
              </div>

              <svn-pro-icon-button
                v-if="!isTrashed"
                class="hidden lg:flex"
                color="onSurfaceVariant"
                icon="custom:mingcute:edit-2-line"
                variant="text"
                @click="openModalEditField('description')"
              />
            </div>
          </div>

          <!-- Target Completion -->
          <div class="flex flex-col justify-center items-center pt-4 pb-4 gap-4 lg:gap-8 flex-1">
            <div class="w-full flex flex-col items-center gap-4">
              <svn-pro-text medium subtitle-large>
                {{ $t('Completion') }}
              </svn-pro-text>
            </div>

            <div class="w-full flex flex-col justify-center items-center">
              <div class="flex flex-col gap-2 items-center">
                <svn-pro-title
                  :color="
                    getTargetProgressColor(
                      indicator?.options?.starting_value,
                      indicator?.options?.current_value,
                      indicator?.options?.target_value,
                      indicator?.indicator_type,
                      indicator?.options,
                    )
                  "
                  h5
                  medium
                >
                  {{
                    (indicator?.indicator_type === 'number' ||
                      indicator?.indicator_type === 'percentage') &&
                    roadmapTarget?.histories.length === 0
                      ? '0%'
                      : getTargetIndicatorValue(
                          indicator?.options?.starting_value,
                          indicator?.options?.current_value,
                          indicator?.options?.target_value,
                          indicator?.indicator_type,
                        )
                  }}
                </svn-pro-title>

                <svn-pro-progress-linear
                  :color="
                    getTargetProgressColor(
                      indicator?.options?.starting_value,
                      indicator?.options?.current_value,
                      indicator?.options?.target_value,
                      indicator?.indicator_type,
                      indicator?.options,
                    )
                  "
                  :model-value="getTargetModelValue(indicator?.indicator_type)"
                  class="min-w-[240px]"
                />
              </div>
            </div>

            <div class="w-full flex flex-col lg:flex-row gap-4 items-center justify-between px-12">
              <div class="flex flex-col flex-1 gap-2 items-center">
                <svn-pro-text body-medium medium>
                  {{ $t('Starting value') }}
                </svn-pro-text>

                <svn-pro-text
                  class="line-clamp-1 break-all"
                  color="onSurfaceVariant"
                  medium
                  subtitle-large
                >
                  {{ getStartingValue() }}
                </svn-pro-text>
              </div>

              <div class="flex flex-col flex-1 gap-2 items-center">
                <svn-pro-text body-medium medium>
                  {{ $t('Current value') }}
                </svn-pro-text>

                <svn-pro-text class="line-clamp-1 break-all" color="primary" medium subtitle-large>
                  {{ getCurrentValue() }}
                </svn-pro-text>
              </div>

              <div class="flex flex-col flex-1 gap-2 items-center">
                <svn-pro-text body-medium medium>
                  {{ $t('Target value') }}
                </svn-pro-text>

                <svn-pro-text
                  class="line-clamp-1 break-all"
                  color="onSurfaceVariant"
                  medium
                  subtitle-large
                >
                  {{ getTargetValue() }}
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-button
              :disabled="isTrashed"
              :text="$t('Update answer')"
              class="hidden lg:flex"
              variant="flat"
              @click="openEditTargetIndicatorAnswerPopUp"
            />
          </div>
        </div>
      </div>

      <!-- Target logs -->
      <!-- Design DESKTOP: caché de base, flex à partir de sm -->
      <div
        id="target-history"
        class="w-full max-w-[35%] overflow-x-hidden flex-col justify-between flex-1 border-[#767680] lg:border-t lg:border-r lg:border-b relative hidden lg:flex"
      >
        <!-- Target list title -->
        <div
          class="w-full flex flex-col justify-center min-h-[80px] items-center border-b !border-[#767680] p-2"
        >
          <svn-pro-title h6 medium>
            {{ $t('Last events') }}
          </svn-pro-title>
        </div>

        <!-- Target logs list -->
        <div class="w-full h-full overflow-y-auto">
          <target-logs v-if="indicator" :indicator="indicator" :logs="logs" :target-title="title" />
        </div>

        <!-- Add comment input -->
        <div class="flex items-center p-4 gap-2 border-t border-t-borderColor border-opacity-100">
          <v-textarea
            v-model="commentToSend"
            :label="$t('Add a comment')"
            :max-rows="1"
            :rows="1"
            auto-grow
            base-color="onSurfaceVariant"
            color="primary"
            hide-details="auto"
            variant="outlined"
          />

          <svn-pro-icon-button
            :disabled="!commentToSend"
            color="onSurfaceVariant"
            icon="custom:mingcute:send-line"
            variant="text"
            @click="addNewComment"
          />
        </div>
      </div>

      <v-dialog
        v-model="isFullScreenTargetValues"
        :scrim="false"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-spacer />

            <v-toolbar-title>Target values</v-toolbar-title>
            <v-toolbar-title>
              <v-btn dark icon @click="isFullScreenTargetValues = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-title>
          </v-toolbar>

          <v-list v-for="option in multiChoiceTargetValues" :key="option">
            <v-list-item :title="option" class="text-center" />
          </v-list>
        </v-card>
      </v-dialog>
    </div>

    <svn-pro-floating-action-button
      :disabled="isTrashed"
      :text="$t('Update answer')"
      class="fixed bottom-4 right-4 lg:hidden"
      extended
      prepend-icon="custom:mingcute:edit-2-line"
      variant="primary"
      @click="openEditTargetIndicatorAnswerPopUp"
    />

    <svn-pro-dialog-validation
      ref="archiveUnarchiveRef"
      :action-one-title="roadmapTarget?.status === 'opened' ? $t('Archive') : $t('Unarchive')"
      :action-two-title="$t('Cancel')"
      :content-text="roadmapTarget?.status === 'opened' ? $t(archivedText) : $t(unarchivedText)"
      :title="
        roadmapTarget?.status === 'opened'
          ? $t('Target will be archived')
          : $t('Target will be unarchived')
      "
      :width="312"
      @click-primary-button="archiveOrUnarchiveTarget"
    >
      <template #activator>
        {{ null }}
      </template>
    </svn-pro-dialog-validation>

    <svn-pro-dialog-validation
      ref="deleteRef"
      :action-one-title="$t('Delete')"
      :action-two-title="$t('Cancel')"
      :content-text="
        $t(
          'Deleted targets are stored for 30 days. After this period, they will be permanently deleted.',
        )
      "
      :title="$t('Target will be deleted')"
      :width="312"
      icon="noto:warning"
      @click-primary-button="deleteTarget"
    >
      <template #activator>
        {{ null }}
      </template>
    </svn-pro-dialog-validation>

    <pop-up-edit-target-field
      ref="editFieldRef"
      :deadline="roadmapTarget?.due_date"
      :description="roadmapTarget?.description"
      :field="editField"
      :target-id="roadmapTarget?.id"
      :title="roadmapTarget?.title"
      @callback="build"
    >
      <template #button>
        {{ null }}
      </template>
    </pop-up-edit-target-field>

    <pop-up-edit-date ref="editDeadlineRef" @save="updateDeadline">
      <template #activator>
        {{ null }}
      </template>
    </pop-up-edit-date>

    <pop-up-edit-target-indicator-answer
      v-if="indicator"
      ref="editAnswerRef"
      :indicator="indicator"
      :title="title"
    >
      <template #button>
        {{ null }}
      </template>
    </pop-up-edit-target-indicator-answer>

    <svn-pro-dialog-validation
      ref="deleteTargetRef"
      :action-one-title="$t('Delete')"
      :action-two-title="$t('Cancel')"
      :content-text="$t('This is a permanent action.')"
      :title="$t(`Target will be deleted`)"
      icon="noto:warning"
      @click-primary-button="deleteTargetPermanently"
      @click-secondary-button="deleteTargetRef.dialogRef.dialog = false"
    >
      <template #activator="{ props }">
        <div class="hidden" />
      </template>
    </svn-pro-dialog-validation>
  </div>
</template>

<script setup>
import { useRoadmapTargetStore } from '@/store/roadmap-target.js';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axiosService from '@/tools/axios-service.js';
import TargetLogs from '@/components/roadmapApp/target/TargetLogs.vue';
import i18n from '@/plugins/i18n.js';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar.js';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import useTools from '@/tools/useTools.js';
import TrashWarnning from '@/components/TrashWarnning.vue';
import PopUpEditDate from '../../../components/popUpComponents/PopUpEditDate.vue';
import PopUpEditTargetField from '../../../components/popUpComponents/PopUpEditTargetField.vue';
import PopUpEditTargetIndicatorAnswer from '../../../components/popUpComponents/PopUpEditTargetIndicatorAnswer.vue';

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const { roadmapTarget } = storeToRefs(useRoadmapTargetStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { mapRange } = useTools();
const { fetchRoadmapTarget, restoreRoadmapTarget, deletePermanentlyRoadmapTarget } =
  useRoadmapTargetStore();

const id = ref(null);
const title = ref(null);
const desc = ref(null);
const indicator = ref(null);
const date = ref(null);
const logs = ref(null);
const employee = ref(null);
const creator = ref(null);
const isSaveButtonDirty = ref(false);
const lastUpdateDialog = ref(false);
const commentToSend = ref(null);
const descriptionWordCount = ref(0);
const descriptionWordsArray = ref(null);
const cutDescription = ref('');
const attributeDisablement = ref({});
const emit = defineEmits(['update']);
const isFullScreenTargetValues = ref(null);
const editAnswerRef = ref(null);
const descSeeAll = ref(false);
const isTrashed = ref(false);
const deleteRef = ref(null);
const editFieldRef = ref(null);
const editField = ref(null);
const editDeadlineRef = ref(null);
const archiveUnarchiveRef = ref(null);
const archivedText = ref('You will find this target in the "Archived" tab.');
const unarchivedText = ref('You will find this target in the "Currents" tab.');
const deleteTargetRef = ref(null);
const menuDesktop = computed(() => {
  return [
    {
      value: 'archive_target',
      title:
        roadmapTarget?.value?.status === 'opened'
          ? i18n.global.t('Archive target')
          : i18n.global.t('Unarchive target'),
      onClick: () => openArchiveUnarchiveModal(),
    },
    {
      error: true,
      value: 'delete_target',
      title: i18n.global.t('Delete target'),
      onClick: () => openModalDelete(),
    },
  ];
});
const menuMobile = computed(() => {
  return [
    {
      value: 'edit_title',
      title: i18n.global.t('Edit title'),
      onClick: () => openModalEditField('title'),
    },
    {
      value: 'edit_due_date',
      title: i18n.global.t('Edit deadline'),
      onClick: () => (editDeadlineRef.value.dialog = true),
    },
    {
      value: 'edit_description',
      title: i18n.global.t('Edit description'),
      onClick: () => openModalEditField('description'),
    },
    {
      value: 'archive_target',
      title:
        roadmapTarget?.value?.status === 'opened'
          ? i18n.global.t('Archive target')
          : i18n.global.t('Unarchive target'),
      onClick: () => openArchiveUnarchiveModal(),
    },
    {
      error: true,
      value: 'delete_target',
      title: i18n.global.t('Delete target'),
      onClick: () => openModalDelete(),
    },
  ];
});

onMounted(() => {
  build();
});

watch(roadmapTarget, (newVal, _) => {
  mergeToCurrentTarget(newVal);
});

const breadcrumbItems = computed(() => {
  let from = null;
  let url = null;
  if (route?.query?.from === 'my_roadmap') {
    from = 'My Roadmap';
    url = '/roadmaps/my_roadmaps';
  } else if (route?.query?.from === 'my_team_roadmap') {
    from = 'My Team Roadmap';
    url = '/roadmaps/my_team_roadmaps';
  } else if (route?.query?.from === 'targets') {
    from = 'Targets';
    url = '/roadmaps/targets/index';
  } else {
    from = 'My Roadmap';
    url = '/roadmaps/my_roadmaps';
  }

  return [
    {
      title: from,
      disabled: false,
      href: url,
    },
    {
      title: title.value,
      disabled: true,
      href: '',
    },
  ];
});

const archiveOrUnarchiveTarget = async () => {
  try {
    await axiosService.put(`/roadmaps/targets/${route?.params?.id}`, {
      status: roadmapTarget?.value?.status === 'opened' ? 'archived' : 'opened',
    });
    await router.push({ name: 'my_roadmaps' });
    snackbar.setCustomClass(isMobile?.value ? '!mb-[88px]' : '');
    snackbar
      .setBgColor('onSurface')
      .displaySnackBar(
        roadmapTarget?.value?.status === 'opened'
          ? 'Target has been archived successfully.'
          : 'Target has been unarchived successfully.',
      );
  } catch (error) {
    snackbar.setCustomClass(isMobile?.value ? '!mb-[88px]' : '');
    snackbar
      .setBgColor('error')
      .displaySnackBar(
        roadmapTarget?.value?.status === 'opened'
          ? 'Error while archiving your target !'
          : 'Error while unarchiving your target !',
      );
  }
};

const deleteTarget = async () => {
  try {
    await axiosService.delete(`/roadmaps/targets/${route?.params?.id}`);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('onSurface').displaySnackBar('Target has been deleted successfully.');
    await router.push({ name: 'my_roadmaps' });
  } catch (error) {
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('error').displaySnackBar('Error while deleting your target !');
  }
};

const build = async () => {
  const entity = await fetchRoadmapTarget(route.params.id);
  mergeToCurrentTarget(entity);

  commentToSend.value = '';
  editField.value = null;

  descriptionWordCount.value = desc.value?.split(' ').filter((word) => {
    return word !== '';
  }).length;
  descriptionWordsArray.value = desc.value.split(' ');

  for (let i = 0; i < 75; i++) {
    cutDescription.value += descriptionWordsArray.value[i] + ' ';
  }
};

const multiChoiceTargetValues = computed(() => {
  if (indicator.value && indicator.value.indicator_type === 'multi_choice') {
    var selectedOptions = indicator.value?.options?.multi_choice_list?.filter(function (option) {
      return option.checkbox === true;
    });

    // Extract and return the option values
    var selectedValues = selectedOptions.map(function (option) {
      return option.option;
    });

    return selectedValues;
  } else {
    return [];
  }
});

const mergeToCurrentTarget = (target) => {
  id.value = target.id;
  title.value = target.title;
  isTrashed.value = target.status === 'trashed';
  desc.value = target.description;
  date.value = target.due_date;
  employee.value = target.objectivable;
  attributeDisablement.value = target.attribute_disablement;
  creator.value = target.creator;
  logs.value = target.histories.map((log) => ({ ...log, displayed: false }));
  indicator.value = target.objective_indicator;

  watch(title, activeSaveButton);
  watch(desc, activeSaveButton);
  watch(date, activeSaveButton);
};

const activeSaveButton = () => {
  isSaveButtonDirty.value = true;
};

const openArchiveUnarchiveModal = async () => {
  archiveUnarchiveRef.value.dialogRef.dialog = true;
};

const openModalDelete = async () => {
  deleteRef.value.dialogRef.dialog = true;
};

const getTargetProgressColor = (startingValue, currentValue, targetValue, indicatorType) => {
  if (indicatorType === 'numeric_value' || indicatorType === 'percentage') {
    if (parseInt(startingValue) === parseInt(currentValue)) {
      return 'error';
    } else if (mapRange(currentValue, startingValue, targetValue, 0, 100) >= 100) {
      return 'success';
    } else return 'warning';
  } else if (indicatorType === 'boolean') {
    if (currentValue === targetValue) {
      return 'success';
    } else {
      return 'error';
    }
  } else if (indicatorType === 'multi_choice') {
    if (!currentValue || currentValue === '') {
      return 'info';
    } else if (checkIfOptionSelected()) {
      return 'success';
    } else if (!checkIfOptionSelected()) {
      return 'warning';
    }
  } else {
    if (currentValue === '') {
      return 'info';
    } else if (currentValue !== '' && !multiChoiceTargetValues.value.includes(currentValue)) {
      return 'warning';
    } else {
      return 'success';
    }
  }
};

const getTargetModelValue = (indicatorType) => {
  if (indicatorType === 'numeric_value' || indicatorType === 'percentage') {
    if (
      indicator?.value?.options?.current_value === undefined ||
      !indicator?.value?.options?.current_value
    ) {
      return 0;
    }
    return mapRange(
      indicator?.value?.options?.current_value,
      indicator?.value?.options.starting_value,
      indicator?.value?.options.target_value,
      0,
      100,
    );
  } else {
    return 100;
  }
};

const checkIfOptionSelected = () => {
  const multiChoiceList = indicator?.value?.options?.multi_choice_list;

  // Iterate over the multi_choice_list
  for (var i = 0; i < multiChoiceList.length; i++) {
    var option = multiChoiceList[i];
    if (option.checkbox && option.option === indicator?.value?.options?.current_value) {
      return true; // Option matches and checkbox is true
    }
  }

  return false; // Option not found or checkbox is false
};

const getTargetIndicatorValue = (startingValue, currentValue, targetValue, indicatorType) => {
  if (indicatorType === 'numeric_value' || indicatorType === 'percentage') {
    if (currentValue === undefined || !currentValue) {
      return '0%';
    } else {
      return `${Math.round(mapRange(currentValue, startingValue, targetValue, 0, 100))}%`;
    }
  } else if (indicatorType === 'boolean') {
    return currentValue;
  } else if (indicatorType === 'multi_choice') {
    if (currentValue === undefined || !currentValue) {
      return i18n.global.t('Not set yet');
    }
    if (!checkIfOptionSelected()) {
      return i18n.global.t('Target not achieved yet');
    } else if (checkIfOptionSelected()) {
      return i18n.global.t('Target completed!');
    } else {
      return currentValue;
    }
  }
};

const getStartingValue = () => {
  if (indicator?.value?.indicator_type === 'percentage') {
    return `${indicator?.value?.options?.starting_value}%`;
  } else if (indicator?.value?.indicator_type === 'numeric_value') {
    return `${indicator?.value?.options?.starting_value}`;
  } else if (indicator?.value?.indicator_type === 'boolean') {
    return `${indicator?.value?.options?.starting_value}`;
  } else if (indicator?.value?.indicator_type === 'multi_choice') {
    return '-';
  }
};

const getCurrentValue = () => {
  if (indicator?.value?.indicator_type === 'percentage') {
    return (
      (indicator?.value?.options?.current_value || indicator?.value?.options?.starting_value) + '%'
    );
  } else if (indicator?.value?.indicator_type === 'numeric_value') {
    return indicator?.value?.options?.current_value || indicator?.value?.options?.starting_value;
  } else if (indicator?.value?.indicator_type === 'boolean') {
    return indicator?.value?.options?.current_value || indicator?.value?.options?.starting_value;
  } else if (indicator?.value?.indicator_type === 'multi_choice') {
    return indicator?.value?.options?.current_value !== undefined
      ? indicator?.value?.options?.current_value
      : '-';
  }
};

const getTargetValue = () => {
  if (indicator?.value?.indicator_type === 'percentage') {
    return `${indicator?.value?.options?.target_value}%`;
  } else if (indicator?.value?.indicator_type === 'numeric_value') {
    return `${indicator?.value?.options?.target_value}`;
  } else if (indicator?.value?.indicator_type === 'boolean') {
    return `${indicator?.value?.options?.target_value}`;
  } else if (indicator?.value?.indicator_type === 'multi_choice') {
    return multiChoiceTargetValues?.value.length == 1
      ? multiChoiceTargetValues?.value?.[0]
      : multiChoiceTargetValues?.value?.length + ' ' + i18n.global.t('possible answers');
  }
};

const openEditTargetIndicatorAnswerPopUp = () => {
  editAnswerRef.value.editTargetIndicatorAnswer.dialog = true;
};

const addNewComment = async () => {
  try {
    await axiosService.patch(`/roadmaps/indicators/${indicator.value?.id}`, {
      current_value: indicator?.value?.options?.current_value,
      comment: commentToSend.value,
    });
    commentToSend.value = null;
    snackbar.setBgColor('onSurface');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.setMsg('Comment added successfully.');
    snackbar.displaySnackBar();
    build();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.setMsg('Error! Your comment could not be saved.');
    snackbar.displaySnackBar();
  }
};

const openModalEditField = (field) => {
  editFieldRef.value.editField.dialog = true;

  editField.value = field;
};

const updateDeadline = async (date) => {
  try {
    await axiosService.patch(`/roadmaps/targets/${roadmapTarget?.value?.id}`, {
      due_date: moment(date).format('YYYY-MM-DD'),
    });
    build();
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Deadline has been updated successfully.');
    snackbar.displaySnackBar();
  } catch (error) {
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('error');
    snackbar.setMsg('Error updating Deadline');
    snackbar.displaySnackBar();
  }
};

const restoreDeletedItem = async () => {
  try {
    await restoreRoadmapTarget(id.value);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Target has been restored successfully.');
    snackbar.displaySnackBar();
    isTrashed.value = false;
  } catch (e) {
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while restoring this target');
    snackbar.displaySnackBar();
  }
};

const deleteTargetPermanently = async () => {
  try {
    await deletePermanentlyRoadmapTarget(id.value);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Target has been deleted successfully.');
    snackbar.displaySnackBar();
    if (route?.query?.from) {
      await router.push({ name: route?.query?.from });
    } else {
      await router.push({ name: 'my_roadmaps' });
    }
  } catch (e) {
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '');
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while deleting permanently a target');
    snackbar.displaySnackBar();
  }
};
</script>

<style scoped>
:deep(.v-field__append-inner) {
  display: flex;
  align-items: center !important;
  padding: 0px !important;
}

:deep(.v-field__input) {
  max-height: 90px;
}
</style>

<template>
  <svn-pro-data-table
    v-model="model"
    :headers="targets?.length ? headers : []"
    :items="targets ?? []"
    :items-per-page="-1"
    :loading="targets ? loading : true"
    :pagination-total-pages="pagination?.total_pages"
    item-value="id"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTarget(item?.id)">
        <td>
          <svn-pro-data-table-cell :text="item?.title" body-large cell-type="text" medium />
        </td>

        <td>
          <my-roadmaps-objective-indicator :objective-indicator="item?.objective_indicator" />
        </td>

        <td>
          <svn-pro-data-table-cell
            :text="$filters.formatSpecificDate(item?.due_date, 'YYYY-MM-DD', 'DD MMM, YYYY')"
            cell-type="text"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                color="surface"
                icon="custom:mingcute:more-2-line"
                variant="flat"
              />
            </template>

            <template #dropdown>
              <svn-pro-dialog-validation
                :action-one-title="type === 'unarchived' ? $t('Archive') : $t('Unarchive')"
                :action-two-title="$t('Cancel')"
                :content-text="type === 'unarchived' ? $t(archivedText) : $t(unarchivedText)"
                :title="
                  type === 'unarchived'
                    ? $t('Target will be archived')
                    : $t('Target will be unarchived')
                "
                @click-primary-button="
                  type === 'unarchived' ? archiveTarget(item?.id) : unarchiveTarget(item?.id)
                "
              >
                <template #activator="{ props }">
                  <v-list-item
                    :active="false"
                    :value="type === 'unarchived' ? 'unarchive' : 'archive'"
                    v-bind="props"
                  >
                    <svn-pro-text body-large regular>
                      {{ type === 'unarchived' ? $t('Archive target') : $t('Unarchive target') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>

              <svn-pro-dialog-validation
                :action-one-title="$t('Delete')"
                :action-two-title="$t('Cancel')"
                :content-text="
                  $t(
                    'Deleted templates are stored for 30 days. After this period, they will be permanently deleted.',
                  )
                "
                :title="$t('Target will be deleted')"
                icon="noto:warning"
                @click-primary-button="deleteTarget(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item :active="false" v-bind="props" value="delete">
                    <svn-pro-text body-large color="error" regular>
                      {{ $t('Delete target') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :action-primary-title="$t('New target')"
        :size="isMobile ? 'compact' : 'default'"
        :supporting-text="
          search
            ? $t('Oops, we didn’t find any results matching your search.')
            : type === 'unarchived'
              ? $t('Hmm, it seems you have no current target yet.')
              : $t('Hmm, it seems you have no archived target yet.')
        "
        :title="$t('Nothing to show for now')"
        :variant="search ? 'results' : 'index'"
        actions
        prepend-primary="custom:mingcute:add-line"
        @click-primary="emit('open-modal-new-target')"
      />
    </template>

    <template #loading></template>
  </svn-pro-data-table>
</template>

<script setup>
import MyRoadmapsObjectiveIndicator from '../MyRoadmapsObjectiveIndicator.vue';
import axiosService from '@/tools/axios-service.js';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import i18n from '@/plugins/i18n.js';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  type: { type: String, required: true },
  search: { type: String, default: null },
  targets: { type: Array, required: false },
  loading: { type: Boolean, required: true },
  pagination: {
    type: Object,
    default: () => {
      return null;
    },
  },
});

const router = useRouter();
const snackbar = useSnackbar();

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const model = ref([]);
const archivedText = ref('You will find this target in the "Archived" tab.');
const unarchivedText = ref('You will find this target in the "Currents" tab.');

const headers = [
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'completion',
    sortable: false,
    title: i18n.global.t('Completion'),
    minWidth: 150,
    width: 150,
  },
  {
    align: 'start',
    key: 'deadline',
    sortable: false,
    title: i18n.global.t('Deadline'),
    minWidth: 150,
    width: 150,
  },
  {
    align: 'start',
    key: 'button',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
];

const emit = defineEmits(['update', 'reload-page', 'delete-target', 'open-modal-new-target']);

const fetchNewPage = (e) => {
  emit('reload-page', e);
};

const goToTarget = (targetId) => {
  router.push({
    name: 'roadmaps_targets_show',
    query: { from: 'my_roadmap' },
    params: { id: targetId },
  });
};

const archiveTarget = async (id) => {
  try {
    await axiosService.put(`/roadmaps/targets/${id}`, { status: 'archived' });
    emit('update');
    snackbar
      .setCustomClass(!isMdScreen?.value ? '!mb-[88px]' : '')
      .setBgColor('onSurface')
      .displaySnackBar('Target has been archived successfully.');
  } catch (error) {
    snackbar
      .setCustomClass(!isMdScreen?.value ? '!mb-[88px]' : '')
      .setBgColor('error')
      .displaySnackBar('Error while archiving your target !');
  }
};

const unarchiveTarget = async (id) => {
  try {
    await axiosService.put(`/roadmaps/targets/${id}`, { status: 'opened' });
    emit('update');
    snackbar
      .setCustomClass(!isMdScreen?.value ? '!mb-[88px]' : '')
      .setBgColor('onSurface')
      .displaySnackBar('Target has been unarchived successfully.');
  } catch (error) {
    snackbar
      .setCustomClass(!isMdScreen?.value ? '!mb-[88px]' : '')
      .setBgColor('error')
      .displaySnackBar('Error while unarchiving your target !');
  }
};

const deleteTarget = (id) => {
  emit('delete-target', id);
};
</script>

import axiosService from '@/tools/axios-service';
import LearnModule, { LearnModulePagination } from '@/models/learn_module';

class ModuleApi {
  async index({ page = 1, text = '', themeIds = [], sortBy = 'desc-publication', draft = false }) {
    const res = await axiosService.get(`/learn/modules${draft ? '/drafts' : ''}`, {
      params: {
        'page[number]': page,
        text,
        theme_ids: themeIds,
        sort_by: sortBy,
        'page[size]': 12,
      },
    });

    return LearnModulePagination.fromJson(res.data);
  }

  async get(id: string) {
    const res = await axiosService.get(`/learn/modules/${id}`);

    return LearnModule.fromJson(res.data);
  }

  async update({ id, title, duration, cover_offset_left, cover_offset_top }: any) {
    const res = await axiosService.patch(`/learn/modules/${id}`, {
      title,
      duration,
      cover_offset_left,
      cover_offset_top,
    });

    return LearnModule.fromJson(res.data);
  }

  async delete(id: string) {
    const res = await axiosService.delete(`/learn/modules/${id}`);

    return LearnModule.fromJson(res.data);
  }

  async duplicate(id: string, title: string) {
    const res = await axiosService.post(`/learn/modules/${id}/duplicate_module`);

    await this.update({
      id: res?.data?.learn_module?.id,
      title: title,
    });

    return LearnModule.fromJson(res?.data?.learn_module);
  }

  async paginateByTraining(id: string, trainingId: string) {
    const res = await axiosService.get(`/learn/modules/${id}/paginate_by_training`, {
      params: {
        training_id: trainingId,
      },
    });

    return LearnModule.fromJson(res.data);
  }

  async toggleFavorite(module: LearnModule) {
    if (!module) return null;
    const res = await axiosService.post(`learn/modules/${module.id}/favorite`);

    // Create a shallow copy
    return module?.copyWith({
      connectedUserFavorited: module.connectedUserFavorited,
    });
  }

  async saveUserMessage(id: number, text = null) {
    const res = await axiosService.post(`/learn/modules/${id}/messages`, {
      text: text,
      role: 'user',
    });
    return res.data.message;
  }

  async getAiConversation(id: number) {
    const res = await axiosService.get(`/learn/modules/${id}/messages/ai_conversations`);
    return res.data.messages;
  }

  async deleteAiConversation(id: number) {
    await axiosService.delete(`/learn/modules/${id}/messages/destroy_ai_conversations`);
  }
}

const instance = new ModuleApi();

export default instance;

<template>
  <svn-pro-modal ref="manageCopilots" :title="$t('Manage co-pilots')" :width="480">
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-5 md:!gap-6">
        <svn-pro-autocomplete
          :items="searchManagersCreators"
          :menu-icon="'custom:mingcute:down-small-fill'"
          :placeholder="$t('Search for co-pilots')"
          item-title="fullname"
          item-value="id"
          @input="customSearch($event)"
          @update:model-value="addCopilot"
        />

        <v-list v-if="copilots?.length" class="md:h-[344px]">
          <v-list-item v-for="(copilot, idx) in copilots" :key="idx" :active="false">
            <div class="w-full flex items-center">
              <div class="flex gap-4 flex-1 items-center">
                <svn-pro-avatar
                  :firstname="copilot?.fullname"
                  :image="copilot?.avatar?.['50']"
                  :size="40"
                  :type="copilot?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div class="flex flex-col">
                  <svn-pro-text body-large class="line-clamp-1" regular>
                    {{ copilot?.fullname }}
                  </svn-pro-text>

                  <svn-pro-text body-medium color="onSurfaceVariant" regular>
                    {{ copilot?.job_title }}
                  </svn-pro-text>
                </div>
              </div>

              <svn-pro-button
                :text="$t('Remove')"
                variant="text"
                @click="removeCopilot(copilot?.id)"
              />
            </div>
          </v-list-item>
        </v-list>

        <div v-else class="w-full h-[344px] flex flex-col justify-center items-center gap-2">
          <div>
            <Icon height="32" icon="noto:busts-in-silhouette" width="32" />
          </div>

          <div class="flex flex-col gap-1">
            <svn-pro-title class="text-center" h6 medium>
              {{ $t('No co-pilots added') }}
            </svn-pro-title>

            <svn-pro-text body-medium class="text-center" color="onSurfaceVariant" regular>
              {{
                $t('It seems there is no co-pilots selected, search for co-pilots and add them.')
              }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>
<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import { useCompanyStore } from '@/store/company.js';

const route = useRoute();
const snackbar = useSnackbar();
const { searchCompanyManagersCreators } = useCompanyStore();

const { searchManagersCreators } = storeToRefs(useCompanyStore());

const props = defineProps({
  campaignId: { type: Number, default: null },
});

const copilots = ref([]);
const loading = ref(true);
const manageCopilots = ref(null);

onMounted(async () => {
  try {
    await searchCompanyManagersCreators('', 10);
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar('Error fetching manager creators.');
  }
  try {
    const response = await axiosService.get(
      `/interview_app/campaigns/${props?.campaignId || route?.params?.id}/copilots`,
    );
    copilots.value = response?.data?.users;
    loading.value = false;
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar('Error fetching users.');
  }
});

const addCopilot = debounce(async (copilotId) => {
  try {
    if (copilotId) {
      await axiosService.post(`/interview_app/campaigns/${props?.campaignId}/copilots`, {
        user_id: copilotId,
      });
      const copilot = searchManagersCreators?.value?.find((user) => user?.id === copilotId);
      copilots.value.push(copilot);
      snackbar
        .setBgColor('onSurface')
        .setCustomClass('mb-4')
        .displaySnackBar('Co-pilot added successfully.');
    }
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Error adding copilot !');
  }
}, 300);

const removeCopilot = debounce(async (copilotId) => {
  try {
    await axiosService.delete(
      `/interview_app/campaigns/${props?.campaignId}/copilots/${copilotId}`,
    );
    copilots.value.splice(
      copilots?.value?.findIndex((item) => item?.id === copilotId),
      1,
    );
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Co-pilot removed successfully.');
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .displaySnackBar('Error removing copilot !');
  }
}, 300);

const customSearch = async (search, limit = 10) => {
  await searchCompanyManagersCreators(search, limit);
};

defineExpose({
  manageCopilots,
});
</script>

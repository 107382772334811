<template>
  <svn-pro-modal
    ref="manageReviewers"
    :title="$t('Manage reviewers')"
    :width="isMdScreen ? 528 : undefined"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-2">
        <svn-pro-autocomplete
          :key="reviewerAutocomplete"
          v-model="userToAdd"
          :clearable="false"
          :items="computedSearchUsers"
          :menu-icon="'custom:mingcute:down-small-fill'"
          :placeholder="$t('Search for reviewers')"
          item-title="fullname"
          item-value="id"
          @input="customSearch($event)"
          @update:model-value="addPair"
        />

        <v-list v-if="interviews?.length" :height="388">
          <v-list-item v-for="(feedbackInterview, idx) in interviews" :key="idx" :active="false">
            <div class="w-full flex items-center">
              <div class="flex gap-4 flex-1 items-center">
                <svn-pro-avatar
                  :firstname="feedbackInterview?.employee?.fullname"
                  :image="feedbackInterview?.employee?.avatar?.['50']"
                  :size="40"
                  :type="feedbackInterview?.employee?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div class="flex flex-col">
                  <svn-pro-text body-large class="line-clamp-1" regular>
                    {{ feedbackInterview?.employee?.fullname }}
                  </svn-pro-text>

                  <svn-pro-text
                    :color="getColorByCampaignStatus(feedbackInterview?.status)"
                    body-medium
                    regular
                  >
                    {{ $t(getTextByCampaignStatus(feedbackInterview?.status)) }}
                  </svn-pro-text>
                </div>
              </div>

              <svn-pro-button
                v-if="feedbackInterview?.status === 'not_started'"
                :text="$t('Remove')"
                variant="text"
                @click="removePair(feedbackInterview?.id)"
              />

              <svn-pro-dialog-validation
                v-else
                ref="confirmRemoveReviewer"
                :action-one-title="$t('Yes, remove')"
                :action-two-title="$t('Cancel')"
                :content-text="
                  $t(
                    'This reviewer has started to answer. If you remove him/her, answers will be deleted.',
                  )
                "
                :title="$t('If you continue, answers will be deleted.')"
                :width="312"
                icon="noto:warning"
                @click-primary-button="removeAnsweredPair(feedbackInterview?.id)"
                @click-secondary-button="confirmRemoveReviewer.dialog = false"
              >
                <template #activator="{ props }">
                  <svn-pro-button :text="$t('Remove')" v-bind="props" variant="text" />
                </template>
              </svn-pro-dialog-validation>
            </div>
          </v-list-item>
        </v-list>

        <div v-else class="w-full h-[388px] flex flex-col justify-center items-center gap-2">
          <div>
            <Icon height="32" icon="noto:busts-in-silhouette" width="32" />
          </div>

          <div class="flex flex-col gap-1">
            <svn-pro-title class="text-center" h6 medium>
              {{ $t('No reviewers selected') }}
            </svn-pro-title>

            <svn-pro-text body-medium class="text-center" color="onSurfaceVariant" regular>
              {{ $t('Search for reviewers and add them.') }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { computed, onMounted, ref } from 'vue';
import { useSnackbar } from '@/store/snackbar.js';
import useCampaigns from '@/tools/useCampaigns.js';
import axiosService from '@/tools/axios-service.js';
import { useCompanyStore } from '@/store/company.js';
import { useMobileStore } from '@/store/mobile';

const snackbar = useSnackbar();
const { searchCompanyUsers } = useCompanyStore();
const { getTextByCampaignStatus, getColorByCampaignStatus } = useCampaigns();

const { searchUsers } = storeToRefs(useCompanyStore());
const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  participantId: { type: Number, default: null },
  feedbackId: { type: Number, default: null },
  feedback: { type: Object, default: null },
  canAutoEvaluation: { type: Boolean, default: false },
  campaignId: { type: Number, default: null },
  feedbackInterviews: { type: Array, default: null },
});

const interviews = ref([]);
const reviewerAutocomplete = ref(1);
const manageReviewers = ref(null);
const userToAdd = ref(null);
const confirmRemoveReviewer = ref(null);

onMounted(async () => {
  interviews.value = props?.feedbackInterviews?.filter((int) => int?.employee);
});

const computedSearchUsers = computed(() => {
  return searchUsers?.value.filter((user) => {
    if (!interviews?.value.find((interview) => interview?.employee?.id == user?.id)) {
      return user;
    }
  });
});

const addPair = debounce(async (pairId) => {
  if (!pairId) return;

  const response = await axiosService.post(
    `/interview_app/campaigns/${props?.campaignId}/interview_sets/generate_interview_feedback`,
    {
      feedback_id: props.feedbackId,
      pair_id: pairId,
    },
  );
  if (response.status === 422) {
    snackbar.setBgColor('onSurface').displaySnackBar(response.data.message);
  } else if (response.status === 200) {
    snackbar.setBgColor('onSurface').displaySnackBar('Reviewer added successfully.');
    interviews?.value?.push(response.data);
  }
  userToAdd.value = null;
  reviewerAutocomplete.value = reviewerAutocomplete.value + 1;
}, 300);

const removePair = debounce(async (interviewId) => {
  try {
    await axiosService.delete(
      `/interview_app/campaigns/${props?.campaignId}/interview_sets/remove_interview_feedback`,
      {
        params: {
          interview_id: interviewId,
        },
      },
    );

    let index = interviews?.value?.findIndex((item) => item?.id === interviewId);
    interviews?.value?.splice(index, 1);
    snackbar.setBgColor('onSurface').displaySnackBar('Reviewer removed successfully.');
  } catch (e) {
    snackbar.setBgColor('onSurface').displaySnackBar('Error removing pair');
  }
}, 300);

const removeAnsweredPair = (interviewId) => {
  removePair(interviewId);
};

const customSearch = async (search, limit = 10) => {
  await searchCompanyUsers(search, limit);
};

defineExpose({
  manageReviewers,
});
</script>

import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';

export const useCampaignTrashStore = defineStore({
  id: 'campaignTrash',
  state: () => ({
    trashedCampaigns: [],
    trashCampaignPagination: [],
    meta: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async restoreCampaign(id) {
      try {
        await this.updateEntity(`/interview_app/campaigns/${id}/restore`);
      } catch (e) {
        console.log(e);
      }
    },
    async destroyPermanentlyCampaign(id) {
      try {
        await this.deleteEntity(`/interview_app/campaigns/${id}/destroy_permanently`);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchTrashCampaign(search = null, page = 1) {
      try {
        this.updateParams({ title: search, 'page[number]': page });
        await this.fetchEntity('/interview_app/campaigns/trashes', 'campaigns', 'trashedCampaigns');
        this.trashCampaignPagination = this.pagination;
      } catch (e) {
        console.log(e);
      }
    },
  },
});

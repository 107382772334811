<template>
  <svn-pro-modal ref="modalNewTarget" :title="$t('New template')" :width="!isMobile ? 680 : ''">
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>

    <template #text>
      <div class="flex flex-col gap-6">
        <div class="flex gap-4 flex-col items-start">
          <svn-pro-title h6 medium>
            {{ $t('What type of template you want to create?') }}
          </svn-pro-title>

          <v-item-group v-model="templateType" mandatory @update:model-value="updateTemplateType">
            <div class="w-full flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-4">
              <svn-pro-extended-radio-button
                v-for="templateTypeBoxe in getTemplateTypeBoxes"
                :key="templateTypeBoxe?.id"
                :icon="templateTypeBoxe?.icon"
                :subtitle="templateTypeBoxe?.shortDesc"
                :title="templateTypeBoxe?.title"
                :value="templateTypeBoxe?.templateType"
              />
            </div>
          </v-item-group>
        </div>

        <div class="flex flex-col gap-4">
          <svn-pro-title h6 medium>
            {{ $t('Who will answer this template?') }}
          </svn-pro-title>

          <div
            v-if="templateType === CampaignTypeEnum.ONE_TO_ONE"
            class="md:w-[504px] w-full flex flex-col gap-4"
          >
            <svn-pro-select
              v-model="oneToOneSelectedItem"
              :items="oneToOneTemplateItems"
              :label="''"
              :messages="
                $t(oneToOneTemplateItems.find((item) => item.value === oneToOneSelectedItem)?.desc)
              "
              item-text="title"
              item-value="value"
              persistent-hint
            >
              <template #selection="{ item }">
                <div class="w-full truncate">
                  {{ item.title }}
                </div>
              </template>
            </svn-pro-select>
          </div>

          <div
            v-if="templateType === CampaignTypeEnum.FEEDBACK_360"
            class="md:w-[504px] w-full flex flex-col gap-4"
          >
            <svn-pro-select
              v-model="feedbackSelectedItem"
              :items="feedbackTemplateItems"
              :label="''"
              disabled
              item-text="title"
              item-value="value"
              persistent-hint
            >
              <template #selection="{ item }">
                <div class="w-full truncate">
                  {{ item.title }}
                </div>
              </template>
            </svn-pro-select>
          </div>

          <div
            v-if="templateType === CampaignTypeEnum.SURVEY"
            class="md:w-[504px] w-full flex flex-col gap-4"
          >
            <svn-pro-select
              v-model="surveySelectedItem"
              :items="surveyTemplateItems"
              :label="''"
              disabled
              item-text="title"
              item-value="value"
              persistent-hint
            >
              <template #selection="{ item }">
                <div class="w-full truncate">
                  {{ item.title }}
                </div>
              </template>
            </svn-pro-select>
          </div>

          <div class="flex justify-content-end items-center">
            <svn-pro-button :text="$t('Confirm')" variant="flat" @click="createInterviewForm()" />
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useMobileStore } from '@/store/mobile';
import axiosService from '@/tools/axios-service.js';
import CampaignTypeEnum from '@/constants/CampaignTypeEnum.js';

const { t } = useI18n();
const router = useRouter();

const { isMobile } = storeToRefs(useMobileStore());

const emit = defineEmits(['refresh']);

const modalNewTarget = ref(null);
const oneToOneTemplateItems = ref([
  {
    title: t('Interviewee only'),
    desc: t(
      'Only the interviewee will answer this template. The interviewer can still view the answers.',
    ),
    value: 'employee',
  },
  {
    title: t('Interviewer only'),
    desc: t('Only the interviewer will answer this template.'),
    value: 'manager',
  },
  {
    title: t('Both (interviewee + interviewer)'),
    desc: t(
      "Both can answer this template. Only the interviewer can view the interviewee's answers.",
    ),
    value: 'both',
  },
  {
    title: t('Cross review'),
    desc: t(
      'In addition of the both template, the Cross Review is a last step for a final version.',
    ),
    value: 'cross',
  },
]);
const feedbackTemplateItems = ref([
  {
    title: t('Reviewers'),
    desc: '',
    value: 'pairs',
  },
]);
const surveyTemplateItems = ref([
  {
    title: t('Survey participants'),
    desc: '',
    value: 'survey',
  },
]);
const templateType = ref(CampaignTypeEnum?.ONE_TO_ONE);
const oneToOneSelectedItem = ref(oneToOneTemplateItems.value[0].value);
const feedbackSelectedItem = ref(feedbackTemplateItems.value[0].value);
const surveySelectedItem = ref(surveyTemplateItems.value[0].value);
const boxes = ref([
  {
    icon: 'twemoji:handshake',
    templateType: 'OneToOne',
    title: 'One to one',
    shortDesc: i18n.global.t('Question between an interviewer and interviewee.'),
    selected: false,
  },
  {
    icon: 'noto:memo',
    templateType: 'Feedback',
    title: 'Feedback 360',
    shortDesc: i18n.global.t('Collect feedbacks from employees reviewers.'),
    selected: false,
  },
  {
    icon: 'noto:bar-chart',
    templateType: 'Survey',
    title: 'Survey',
    shortDesc: i18n.global.t('Collect data to take the temperature of employees.'),
    selected: false,
  },
]);

const createInterviewForm = debounce(async () => {
  let answerable_by = 'employee';
  if (templateType.value == 'OneToOne') {
    answerable_by = oneToOneSelectedItem.value;
  } else if (templateType.value == 'Feedback') {
    answerable_by = 'pairs';
  } else {
    answerable_by = 'survey';
  }
  try {
    const res = await axiosService.post('/interview_app/forms/templates/', {
      title: t('New template'),
      answerable_by: answerable_by,
    });

    await router.push({
      name: 'interviews_template_edit',
      params: { id: res.data.template_id },
    });
  } catch (e) {
    console.log('error', e);
  }
}, 300);

const getTemplateTypeBoxes = computed(() => {
  return boxes.value.map((box) => {
    return {
      ...box,
      selected: templateType.value == box.templateType,
    };
  });
});

const updateTemplateType = (item) => {
  templateType.value = item;
};

defineExpose({ modalNewTarget });
</script>
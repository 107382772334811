import axiosService from '@/tools/axios-service';
import User from '@/models/user';
import LearnModule from '@/models/learn_module';

class TrainingApi {
  async getMylearnings({ status = 'todo', page = 1 }) {
    const res = await axiosService.get(`learn/my_learnings`, {
      params: {
        status: status,
        'page[number]': page,
      },
    });

    return User.fromJson(res?.data?.['my_learnings']);
  }

  async getModulesToDiscover() {
    const res = await axiosService.get('learn/my_learnings/modules_to_discover');

    const toRet = [];

    res?.data?.forEach((item) => toRet.push(LearnModule.fromJson(item)));
    return toRet;
  }
}

const instance = new TrainingApi();

export default instance;

<template>
  <div
    class="w-full max-w-[928px] xl:max-w-[1136px] md:!px-12 mx-auto flex flex-col pt-5 desktop:!pt-10 pb-16 gap-5 bg-surface"
  >
    <!-- Header -->
    <div
      class="w-full mx-auto flex flex-col h-full gap-1 items-center md:!items-start px-[60px] md:!px-1"
    >
      <svn-pro-header-section
        :size="isMdScreen ? 'default' : 'compact'"
        :subtitle="$t('Here you can manage all Aleph accounts within your company.')"
        :title="$t('People')"
      />
    </div>

    <!-- Different tabs -->
    <div class="flex flex-col">
      <!-- Tabs -->
      <v-tabs v-model="tab" class="w-full max-w-[928px] xl:max-w-[1136px] mx-auto" color="primary">
        <!-- Users Tab -->
        <v-tab
          :text="$t('Users')"
          class="w-1/2 md:w-[180px]"
          prepend-icon="custom:mingcute:group-3-line"
          value="users_list"
        />

        <!-- Categories Tab -->
        <v-tab
          :text="$t('Categories')"
          class="w-1/2 md:w-[180px]"
          prepend-icon="custom:mingcute:tag-line"
          value="categories"
        />
      </v-tabs>

      <!-- Windows -->
      <v-window v-model="tab" class="w-full max-w-[928px] xl:max-w-[1136px] mx-auto">
        <!-- Users window -->
        <v-window-item value="users_list">
          <div class="flex flex-col gap-5 px-5 md:!px-0">
            <div />

            <div class="flex flex-col gap-4">
              <div class="w-full flex justify-between items-center">
                <svn-pro-title h6 medium>
                  {{ $t('Users') }}
                  <span v-if="pagination">({{ pagination?.total_count }})</span>
                </svn-pro-title>

                <div class="flex gap-4 items-center">
                  <svn-pro-menu :items="addUserMenuItems">
                    <template #activator>
                      <svn-pro-button
                        :text="$t('New user')"
                        class="hidden md:!flex"
                        prepend-icon="custom:mingcute:add-line"
                      />
                    </template>
                  </svn-pro-menu>

                  <svn-pro-menu :items="optionsMenuItems">
                    <template #activator>
                      <svn-pro-icon-button
                        color="onSurfaceVariant"
                        icon="custom:mingcute:more-2-fill"
                        variant="text"
                      />
                    </template>
                  </svn-pro-menu>
                </div>
              </div>

              <div class="w-full flex flex-col gap-4">
                <div class="w-full flex flex-col items-start md:!items-center md:!flex-row gap-4">
                  <svn-pro-text-field
                    v-model="searchText"
                    :label="$t('Search')"
                    class="w-full"
                    prepend-inner-icon="custom:mingcute:search-2-line"
                    variant="outlined"
                    @update:model-value="reloadAfterSearch"
                  />

                  <!-- Toggle Filter button -->
                  <svn-pro-button
                    :append="activeFilterCount ? true : false"
                    :prepend-icon="'custom:mingcute:filter-2-line'"
                    :text="$t('Filter')"
                    :variant="activeFilterCount ? 'tonal' : 'text'"
                    @click="toggleFilterVisibility"
                  >
                    <template v-if="activeFilterCount" #append>
                      {{ `(${activeFilterCount})` }}
                    </template>
                  </svn-pro-button>
                </div>

                <!-- Filters -->
                <svn-filter-results
                  :headers="filterHeaders"
                  :items="users"
                  :should-show="shouldShow"
                  table-name="users"
                  @clear-all-filters="clearAllFilters"
                  @refetch-data="fetchUsersAfterSearch"
                  @update-active-filter-count="updateActiveFilterCount"
                />
              </div>
            </div>

            <!-- Table -->
            <user-table
              :headers="headers"
              :loading="loading"
              :pagination="pagination"
              :search="searchText"
              :users="users"
              @reload-page="reloadAfterSearch('', $event)"
            />
          </div>
        </v-window-item>

        <!-- Categories window -->
        <v-window-item value="categories">
          <div class="w-full max-w-[752px] mx-auto flex flex-col gap-6 px-5 md:!px-0">
            <div class="flex flex-col gap-2 mt-8">
              <svn-pro-title h6 medium>
                {{ $t('Categories and Tags') }}
              </svn-pro-title>

              <svn-pro-text color="onSurfaceVariant" regular subtitle-medium>
                {{
                  $t(
                    'Use categories such as Country, Department... to organize Users data. Inside each category, you can create as many item (tags) as needed.',
                  )
                }}
              </svn-pro-text>
            </div>

            <div v-if="tagCategories?.length || addNewBlock" class="flex flex-col gap-4">
              <tag-category-card
                v-for="(tagCategory, index) in tagCategories"
                :current-tag-category="tagCategory"
                :index="index"
                :tag-categories="tagCategories"
                @recall-tagcategories="onRecallTagCategories"
              />

              <div
                v-if="addNewBlock"
                class="w-full flex flex-col p-4 items-start gap-4 border border-borderColor border-opacity-100 rounded-[8px]"
              >
                <svn-pro-title h6 medium>
                  {{ $t('Tag category') }}
                </svn-pro-title>

                <svn-pro-text-field
                  v-model="tagCategoryName"
                  :label="$t('category name')"
                  :rules="[
                    (v) => !!v || 'Category name cannot be empty',
                    (v) =>
                      (v && !tagCategories?.find((category) => category?.name === v)) ||
                      'This category name already exists',
                  ]"
                  class="w-full"
                  variant="outlined"
                />

                <div class="flex self-end items-center gap-2">
                  <svn-pro-button :text="$t('Cancel')" variant="outlined" @click="deleteLabel" />

                  <svn-pro-button
                    :disabled="!tagCategoryName"
                    :text="$t('Save')"
                    variant="flat"
                    @click="saveLabelAndExit(tagCategoryName)"
                  />
                </div>
              </div>
            </div>

            <svn-pro-button
              :text="$t('New category')"
              class="md:!w-fit md:!self-end"
              prepend-icon="custom:mingcute:add-line"
              variant="flat"
              @click="addLabel"
            />
          </div>
        </v-window-item>
      </v-window>
    </div>
  </div>

  <v-fade-transition :height="40" :width="40" class="fixed bottom-24 right-4 md:!hidden">
    <svn-pro-button
      :rounded="'lg'"
      class="text-primary"
      color="surface"
      elevation="4"
      icon="custom:mingcute:file-import-line"
      variant="elevated"
      @click="importUsersRef.modalImportUsers.dialog = true"
    />
  </v-fade-transition>

  <svn-pro-floating-action-button
    :text="$t('Add user')"
    class="fixed bottom-4 right-4 md:!hidden"
    extended
    prepend-icon="custom:mingcute:add-line"
    variant="primary"
    @click="modalCreateUser.modalAddUser.dialog = true"
  />

  <modal-import-users ref="importUsersRef">
    <template #button>
      {{ null }}
    </template>
  </modal-import-users>

  <modal-add-new-user ref="modalCreateUser" />

  <modal-view-deleted-items
    ref="deletedUsers"
    :headers="trashesHeaders"
    :items="inactiveUsers"
    :pagination="paginationInactive"
    :with-delete-column="false"
    content-type="user"
    @restore="restoreDeactivatedUser"
    @toggle-search="toggleSearchDeletedItems"
    @fetch-new-page="fetchNewPage"
  >
    <template #activator>
      {{ null }}
    </template>
  </modal-view-deleted-items>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import UserTable from './UserTable.vue';
import useTools from '../../tools/useTools';
import { computed, onMounted, ref } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from '@/store/user.js';
import { useMobileStore } from '@/store/mobile';
import { useCompanyStore } from '@/store/company';
import axiosService from '@/tools/axios-service.js';
import SvnFilterResults from '../../components/SvnFilterResults.vue';
import TagCategoryCard from '@/components/companyApp/TagCategoryCard.vue';
import ModalAddNewUser from '@/components/BktPopUp/Modals/ModalAddNewUser.vue';
import ModalImportUsers from '@/components/BktPopUp/Modals/ModalImportUsers.vue';
import ModalViewDeletedItems from '../../components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';

onMounted(async () => {
  fetchUsers();
  setCategories();

  const headersTmp = [
    {
      align: 'start',
      key: 'fullname',
      sortable: true,
      title: i18n.global.t('User'),
      minWidth: 225,
    },
    {
      align: 'start',
      key: 'access_level',
      sortable: true,
      title: i18n.global.t('Access level'),
      width: 170,
      minWidth: 170,
    },
    {
      align: 'start',
      key: 'manager',
      sortable: false,
      title: i18n.global.t('Manager'),
      minWidth: 225,
    },
    {
      align: 'start',
      key: 'hire_date',
      sortable: false,
      title: i18n.global.t('Hire date'),
      width: 160,
      minWidth: 160,
    },
  ];

  const formattedTagCat = tagCategories?.value?.map((obj) => {
    return {
      key: obj.name,
      sortable: false,
      title: obj.name,
      width: 175,
      minWidth: 175,
    };
  });
  headers.value = headersTmp.concat(formattedTagCat);

  if (!inactiveUsers?.value) {
    await fetchInactiveUsers();
  }
});

const snackbar = useSnackbar();
const { backendAutoLogin } = useTools();
const { createTagCategory, deleteTagCatagory, fetchCompany } = useCompanyStore();
const { fetchUsers, updateParams, setUsers, restoreInactiveUser, isAdminOrAbove } = useUserStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { tagCategories, id } = storeToRefs(useCompanyStore());
const { users, pagination, loading, inactiveUsers, selectedUsers, params } =
  storeToRefs(useUserStore());

const tab = ref('users_list');
const tagCategoryName = ref(null);
const addNewBlock = ref(false);
const allTagCategories = ref([]);
const searchText = ref(null);
const headers = ref([]);
const shouldShow = ref(false);
const deletedUsers = ref(null);
const importUsersRef = ref(null);
const modalCreateUser = ref(null);
const modalImportUsers = ref(null);
const paginationInactive = ref(null);
const activeFilterCount = ref(null);
const addUserMenuItems = ref([
  {
    value: 'add_user',
    title: i18n.global.t('Add user'),
    onClick: () => (modalCreateUser.value.modalAddUser.dialog = true),
  },
  {
    value: 'import_users',
    title: i18n.global.t('Import users'),
    onClick: () => (importUsersRef.value.modalImportUsers.dialog = true),
  },
]);
const optionsMenuItems = computed(() => {
  return [
    {
      value: 'export_users',
      title: i18n.global.t('Export all users'),
      onClick: () =>
        window.open(backendAutoLogin('/users/export', { company_id: id?.value }), '_blank'),
    },
    {
      show: !isAdminOrAbove(),
      value: 'view_deactivated',
      title: i18n.global.t('View deactivated users'),
      onClick: () => (deletedUsers.value.deletedItems.dialog = true),
    },
    {
      error: true,
      disabled: !selectedUsers?.value?.length,
      value: 'add_user',
      title: i18n.global.t('Deactivate selected users'),
      onClick: () => deactivateUsers(),
    },
  ];
});
const trashesHeaders = ref([
  {
    align: 'start',
    key: 'fullname',
    sortable: true,
    title: i18n.global.t('User'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);
const filterHeaders = ref([
  {
    name: 'firstname',
    key: 'firstname',
    title: i18n.global.t('Name'),
    icon: '',
  },
  {
    name: 'access_level',
    key: 'access_level',
    title: i18n.global.t('Access level'),
    icon: '',
  },
  {
    name: 'manager_id',
    key: 'manager_id',
    title: i18n.global.t('Manager'),
    icon: '',
  },
  {
    name: 'hire_date',
    key: 'hire_date',
    title: i18n.global.t('Hire date'),
    icon: '',
  },
]);

const fetchInactiveUsers = async () => {
  try {
    const { data } = await axiosService.get(`/users/inactives`);
    inactiveUsers.value = data?.users;
    paginationInactive.value = data?.meta?.pagination;
  } catch (error) {
    console.log(error);
  }
};

const addLabel = () => {
  addNewBlock.value = true;
};

const onRecallTagCategories = () => {
  fetchCompany();
  allTagCategories.value = tagCategories.value;
};

const saveLabelAndExit = async (name) => {
  try {
    await createTagCategory(name);
    addNewBlock.value = false;
    allTagCategories.value = tagCategories.value;
    tagCategoryName.value = null;

    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .setMsg('Tag category has been created successfully.')
      .displaySnackBar();
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .setMsg('Error creating Tag category.')
      .displaySnackBar();
  }
};

const deleteLabel = async (id) => {
  addNewBlock.value = false;
  tagCategoryName.value = null;

  // if (id) {
  //   try {
  //     await deleteTagCatagory(id)
  //     allTagCategories.value.splice(allTagCategories.value.findIndex(category => category?.id === id), 1)

  //     snackbar.setBgColor('onSurface').setCustomClass('mb-4').setMsg('Tag category has been deleted successfully.').displaySnackBar();
  //   } catch (error) {
  //     snackbar.setBgColor('onSurface').setCustomClass('mb-4').setMsg('Error deleting Tag category.').displaySnackBar();
  //   }
  // } else {
  //   addNewBlock.value = false
  //   tagCategoryName.value = null
  // }
};

const fetchNewPage = async (page) => {
  try {
    const { data } = await axiosService.get(`/users/inactives`, {
      params: {
        'page[number]': page,
        'page[per]': 10,
      },
    });
    inactiveUsers.value = data?.users;
  } catch (error) {
    console.log('Error fetching new page');
  }
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    const res = await axiosService.get(`/users/inactives`, {
      params: { title: value },
    });
    inactiveUsers.value = res?.data?.users;
  } catch (error) {
    console.log(error);
  }
}, 300);

const restoreDeactivatedUser = debounce(async (userId) => {
  try {
    await restoreInactiveUser(userId);
    fetchInactiveUsers();
    fetchUsers();

    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .setMsg('User has been reactivated successfully.')
      .displaySnackBar();
  } catch (error) {
    console.log(error);
  }
}, 300);

const deactivateUsers = async () => {
  try {
    await axiosService.patch(`/users/disabled_users`, {
      users_ids: selectedUsers?.value,
    });
    fetchInactiveUsers();
    fetchUsers();

    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .setMsg('Users deactivated successfully.')
      .displaySnackBar();
  } catch (e) {
    console.log('Cannot remove users, error occured', e);
  }
};

const setCategories = () => {
  allTagCategories.value = Object.create(tagCategories.value);
};

const reloadAfterSearch = debounce((title, page = 1) => {
  setUsers(null);
  updateParams({
    title: title,
    'page[number]': page,
  });
  fetchUsers();
}, 300);

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value;
};

const fetchUsersAfterSearch = async (data) => {
  if (data) {
    params.value = {
      filter_data: JSON.stringify(data),
    };
  } else {
    params.value = {};
  }

  await fetchUsers();
};

const updateActiveFilterCount = (value) => {
  activeFilterCount.value = value;
};

const clearAllFilters = async () => {
  params.value = {};
  activeFilterCount.value = null;

  await fetchUsers();
};
</script>

<template>
  <div class="w-full flex flex-col md:!flex-row gap-4 md:!items-center">
    <div class="w-full flex gap-2 items-center">
      <svn-pro-text-field
        v-model="searchText"
        :label="$t('Search')"
        prepend-inner-icon="custom:mingcute:search-2-line"
        variant="outlined"
        @update:model-value="updateText"
        @click:clear="reset"
      />

      <svn-pro-menu v-if="!isMdScreen" v-model="menu" :items="menuItems">
        <template #activator>
          <svn-pro-icon-button
            color="onSurfaceVariant"
            icon="custom:mingcute:more-2-line"
            variant="text"
          />
        </template>
      </svn-pro-menu>
    </div>

    <svn-pro-select
      v-model="status"
      :items="selectList"
      :label="$t('Completion')"
      class="md:!w-[210px]"
      item-title="display"
      item-value="value"
      variant="outlined"
      @update:model-value="update"
    />

    <svn-pro-menu v-if="isMdScreen" v-model="menu" :disabled="isTrashed" :items="menuItems">
      <template #activator>
        <svn-pro-icon-button
          :disabled="isTrashed"
          color="onSurfaceVariant"
          icon="custom:mingcute:more-2-line"
          variant="text"
        />
      </template>
    </svn-pro-menu>
  </div>

  <svn-pro-dialog-validation
    ref="sendInvitation"
    :action-one-title="$t('Send')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('An email will be sent to all participants with a link to their interview.')"
    :title="$t('Send the interview ?')"
    @click-primary-button="sendInvitationOrReminder"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <pop-up-set-another-participant
    ref="otherParticipantRef"
    :campaign-id="campaign?.id"
    :set="set"
    @refetch-page="fetchCurrentCampaign"
  >
    <template #activator>
      {{ null }}
    </template>
  </pop-up-set-another-participant>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';
import { useCompanyStore } from '@/store/company.js';
import { useCampaignStore } from '@/store/campaign.js';
import { CampaignTypeBackend } from '@/constants/CampaignTypeEnum.js';
import PopUpSetAnotherParticipant from '../../../components/popUpComponents/PopUpSetAnotherParticipant.vue';

const emit = defineEmits(['fetch']);

const props = defineProps({
  set: { type: Array, required: true },
  campaign: { type: Object, default: null },
  isTrashed: { type: Boolean, default: true },
  overview: { type: String, default: null },
});

const { isMdScreen } = storeToRefs(useMobileStore());
const { tagCategories } = storeToRefs(useCompanyStore());

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchCampaignInterview, fetchCampaignFeedback, fetchCampaignSurvey } = useCampaignStore();

let timer;
const status = ref('');
const menu = ref(false);
const searchText = ref();
const employeeTags = ref([]);
const sendInvitation = ref(null);
const invitationType = ref('invite');
const filteredTagsCategory = ref([]);
const otherParticipantRef = ref(null);
const selectList = ref([
  { display: t('All'), value: '' },
  { display: t('Not started'), value: 'not_started' },
  { display: t('In progress'), value: 'in_progress' },
  { display: t('Submitted'), value: 'submitted' },
]);
const menuItems = ref([
  {
    id: 'add_participant',
    title: i18n.global.t('Add participant'),
    onClick: () => (otherParticipantRef.value.otherParticipant.dialog = true),
  },
  {
    id: 'send_invitation_to_all',
    title: i18n.global.t('Send invitation to all'),
    onClick: () => setInvitationType('invite'),
  },
  {
    id: 'send_reminder_to_all',
    title: i18n.global.t('Send reminder to all'),
    onClick: () => setInvitationType('remind'),
  },
]);

const update = () => {
  emit('fetch', {
    from: props?.overview,
    status: status?.value,
    text: searchText?.value,
  });
};

const reset = () => {
  searchText.value = null;
  status.value = '';

  emit('fetch', {
    from: props?.overview,
    text: searchText?.value,
    status: status?.value,
  });
};

const updateText = (e) => {
  if (timer) clearTimeout(timer);

  timer = setTimeout(() => {
    searchText.value = e;
    emit('fetch', {
      from: props?.overview,
      status: status?.value,
      text: searchText?.value,
    });
  }, 300);
};

const setInvitationType = (type = 'invite') => {
  invitationType.value = type;
  sendInvitation.value.dialogRef.dialog = true;
};

const sendInvitationOrReminder = async () => {
  try {
    await axiosService.get(
      `/interview_app/campaigns/${props.campaign?.id || route?.params?.id}/send_notification_email`,
      {
        params: {
          email_type: invitationType?.value,
        },
      },
    );
    snackbar
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
      .setBgColor('onSurface')
      .displaySnackBar(
        invitationType?.value === 'invite'
          ? 'Invitation is on its way!'
          : 'Reminder is on its way!',
      );
    invitationType.value = 'invite';
  } catch (error) {
    snackbar
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]')
      .setBgColor('onSurface')
      .displaySnackBar(
        invitationType?.value === 'invite'
          ? 'Error sending invitation.'
          : 'Error sending reminder.',
      );
  }
};

onMounted(() => {
  switch (props.campaign?.campaign_type) {
    case 'one_to_one':
      employeeTags.value =
        props.set
          ?.map((interview) => {
            return interview?.employee_interview?.employee?.tags;
          })
          .flat(1) || [];
      break;
    case 'feedback_360':
      employeeTags.value =
        props.set
          ?.map((feedback) => {
            return feedback?.participant?.tags;
          })
          .flat(1) || [];
      break;
    case 'survey':
      employeeTags.value =
        props.set
          ?.map((survey) => {
            return survey?.employee?.tags;
          })
          .flat(1) || [];
      break;
  }

  if (tagCategories?.value?.length) {
    filteredTagsCategory.value = tagCategories?.value?.map((category) => {
      return {
        id: category?.id,
        name: category?.name,
        tags: category?.tags?.filter((tag) => employeeTags?.value?.some((t) => t?.id === tag?.id)),
      };
    });
  }
});

const fetchCurrentCampaign = async () => {
  switch (props?.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      await fetchCampaignInterview(props?.campaign?.id || route?.params?.id);
      return;
    case CampaignTypeBackend.FEEDBACK_360:
      await fetchCampaignFeedback(props?.campaign?.id || route?.params?.id);
      return;
    case CampaignTypeBackend.SURVEY:
      await fetchCampaignSurvey(props?.campaign?.id || route?.params?.id);
      return;
    default:
      return;
  }
};
</script>
<template>
  <div v-if="campaignDraft" class="w-full flex flex-col gap-6">
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-4">
        <svn-pro-text subtitle-large medium>
          {{ $t('Choose a campaign type') }}
        </svn-pro-text>

        <v-item-group
          v-model="campaignDraft.campaign_type"
          mandatory
          @update:model-value="updateCampaignDraftKind"
        >
          <div class="flex flex-col gap-4 lg:grid lg:grid-cols-2">
            <svn-pro-extended-radio-button
              v-for="campaignType in campaignTypes"
              :key="campaignType?.id"
              :value="campaignType?.id"
              :icon="campaignType?.icon"
              :title="campaignType?.title"
              :subtitle="$t(campaignType?.subtitle)"
            />
          </div>
        </v-item-group>
      </div>

      <div class="flex flex-col gap-4">
        <svn-pro-text subtitle-large medium>
          {{ $t('Choose a campaign title') }}
        </svn-pro-text>
        <svn-pro-text-field
          v-model="campaignDraft.title"
          :error="campaignTitleError"
          :label="$t('Campaign title*')"
          @update:model-value="updateCampaignTitle"
        />
      </div>
    </div>

    <!-- Previous & Next button button -->
    <div class="w-full flex justify-end items-center gap-2">
      <svn-pro-button
        variant="outlined"
        disabled
        :text="$t('Previous')"
      />

      <svn-pro-button
        variant="flat"
        :text="$t('Next')"
        :loading="loading"
        prepend-icon="custom:mingcute:arrow-right-line"
        :disabled="isDeadlineBeforeStartline(campaignDraft?.startline, campaignDraft?.deadline)"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import filters from "@/tools/filters.js";
import { ref, watch } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";

const emit = defineEmits(["next"]);

const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const snackbar = useSnackbar();
const { fetchInterviewForms } = useCompanyStore();
const { updateCampaignDraft } = useCampaignDraftStore();


const loading = ref(false);
const campaignKind = ref('OneToOne');
const campaignTitle = ref(null);
const campaignTitleError = ref(false);
const campaignTypes = ref([
  {
    id: CampaignTypeEnum?.ONE_TO_ONE,
    icon: "noto:handshake",
    title: "One to one",
    subtitle: "Interview between an interviewer and interviewee.",
  },
  {
    id: CampaignTypeEnum?.FEEDBACK_360,
    icon: "noto:memo",
    title: "Feedback 360",
    subtitle: "Participants receive feedbacks from their reviewers.",
  },
  {
    id: CampaignTypeEnum?.SURVEY,
    icon: "noto:bar-chart",
    title: "Survey",
    subtitle: "Collect data to take the temperature of employees.",
  },
])

const next = debounce(async () => {
  loading.value = true;

  if (!campaignDraft?.value?.title) {
    campaignTitleError.value = true

    snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar()
  } else {
    try {
      await updateCampaignDraft({
        campaign_type: campaignDraft?.value?.campaign_type,
        title: campaignDraft?.value?.title,
      });
      emit("next");
    } catch (error) {
      snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again.").displaySnackBar()
    }
  }
  loading.value = false
}, 300);

const updateCampaignDraftKind = async (type) => {
  campaignDraft.value.campaign_type = type
  const formType = 'InterviewApp::Forms::Templates::' + type

  await fetchInterviewForms(formType, { disableCache: true });
}

const isDeadlineBeforeStartline = (startline, deadline) => {
  return startline && deadline && filters.compareDates(startline, deadline)
}

const updateCampaignTitle = (title) => {
  campaignDraft.value.title = title
}

watch(campaignDraft, (newValue, oldValue) => {
  campaignKind.value = campaignDraft?.value?.campaign_type || 'OneToOne'
})

watch(campaignDraft.title, (newValue, oldValue) => {
  if (newValue && campaignTitleError?.value) {
    campaignTitleError.value = false
  }
})
</script>
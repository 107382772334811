<template>
  <svn-pro-modal
    ref="dialogRef"
    :max-width="639"
    :min-with="600"
    :primary-loading="loading"
    :title="$t('Recommend Aleph')"
    :width="'auto'"
    actionOneTitle="Send"
    @click-primary-button="clickPrimaryButton"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-6">
        <svn-pro-text body-medium color="onSurfaceVariant" regular>
          {{
            $t(
              'Do you want to recommend Aleph to someone? Please fill out this form, our team will contact the interested person very soon!',
            )
          }}
        </svn-pro-text>

        <div class="flex flex-col gap-8">
          <svn-pro-text-field
            v-model="email"
            :error="emailError.error"
            :error-messages="emailError.errorMessage"
            :placeholder="'Target persons email*'"
            variant="outlined"
          />

          <svn-pro-text-area
            v-model="message"
            :error="messageError.error"
            :error-messages="messageError.errorMessage"
            :max-rows="6"
            :placeholder="$t('Your message*')"
            :rows="6"
            variant="outlined"
          />
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import { useUserStore } from '../../../store/user';
import i18n from '@/plugins/i18n.js';

const snackbar = useSnackbar();

const { email: userEmail } = storeToRefs(useUserStore());

const email = ref('');
const message = ref('');
const loading = ref(false);
const dialogRef = ref(null);
const primaryButtonLoading = ref(false);
const emailError = ref({ error: false, errorMessage: '', hideDetails: true });
const messageError = ref({ error: false, errorMessage: '', hideDetails: true });

const clickPrimaryButton = async () => {
  loading.value = true;
  if (!email?.value) {
    emailError.value = { error: true, errorMessage: '*required', hideDetails: true };
  }
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: '*required', hideDetails: true };
  }
  if (!emailHasCorrectFormat?.value) {
    emailError.value = {
      error: true,
      errorMessage: i18n.global.t('Wrong email format !'),
      hideDetails: false,
    };
  }
  if (email?.value && message?.value && emailHasCorrectFormat?.value) {
    primaryButtonLoading.value = true;
    try {
      await axiosService.post('/global_mailer/comp_sales_recommend_aleph', {
        user_a_email: userEmail?.value,
        user_b_email: email?.value,
        user_message: message?.value,
      });
      dialogRef.value.dialog = false;
      primaryButtonLoading.value = false;

      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setBgColor('onSurface');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();

      resetForm();
    } catch (error) {
      primaryButtonLoading.value = false;

      console.log(error);
      snackbar.setMsg('Error sending form, please try again.');
      snackbar.setBgColor('error');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();
    }
  }
  loading.value = false;
};

const resetForm = () => {
  email.value = '';
  message.value = '';
};

const emailHasCorrectFormat = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email?.value,
  );
});

watch(email, (newValue, oldValue) => {
  if (newValue !== '' && emailError?.value?.error === true) {
    emailError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(message, (newValue, oldValue) => {
  if (newValue !== '' && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

watch(emailHasCorrectFormat, (newValue, oldValue) => {
  if (newValue === true) {
    emailError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

defineExpose({
  dialogRef,
});
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>

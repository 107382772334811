<template>
  <svn-pro-modal
    ref="modalNewTarget"
    :height="!isMobile ? 516 : ''"
    :title="$t('Edit template tags')"
    :width="!isMobile ? 528 : ''"
  >
    <template #activator="{ props }">
      <slot :props="props" name="activator" />
    </template>
    <template #text>
      <div class="flex flex-col gap-6 items-start">
        <div class="w-full flex flex-row gap-4 items-start">
          <!-- Search -->
          <svn-pro-combobox
            :key="generation"
            v-model="entityTags"
            :clearable="false"
            :items="companyCategories"
            :placeholder="$t('Search for tags')"
            :return-object="true"
            class="custom-combobox"
            multiple
            variant="outlined"
            @update:model-value="updateOrCreateTag"
          >
            <template #selection="data">
              <!-- Do not display selected items in the input field -->
            </template>
          </svn-pro-combobox>
        </div>
        <div v-if="entityTags?.length" class="flex flex-row gap-4 flex-wrap">
          <svn-pro-chip
            v-for="entityTag in entityTags"
            key="entityTag.id"
            :is-slot-append="true"
            :text="entityTag.title"
            class=""
          >
            <template #append>
              <Icon
                class="ml-2 cursor-pointer"
                height="18"
                icon="mingcute:close-line"
                width="18"
                @click="removeTag(entityTag)"
              />
            </template>
          </svn-pro-chip>
        </div>

        <svn-pro-empty-states
          v-if="!entityTags?.length"
          :supporting-text="
            $t('It seems there is no tags added, search for tags and add or create them.')
          "
          :title="$t('No tags added')"
          :variant="'index'"
          class="528px"
          icon="mingcute:tag-line"
          size="compact"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { onMounted, ref } from 'vue';
import { useMobileStore } from '@/store/mobile';
import axiosService from '@/tools/axios-service.js';

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  entityId: { type: Number, required: true },
  fetchTagsFromEntityPath: { type: String, required: true },
  route: { type: String, required: true },
  templateCategories: { type: Object, required: true },
});

const emit = defineEmits(['update-interview-forms-tags']);

onMounted(() => {
  fetchEntityTags();
  fetchCompanyTags();
});

const searchText = ref('');
const generation = ref(0);
const entityTags = ref([]);
const entityTagsOldValue = ref();
const companyCategories = ref([]);
const modalNewTarget = ref(null);

const fetchEntityTags = async () => {
  try {
    const res = await axiosService.get(`${props?.fetchTagsFromEntityPath}/${props?.entityId}`);
    entityTags.value = props.templateCategories;
    entityTagsOldValue.value = props.templateCategories;
  } catch (e) {
    console.log('error', e);
  }
};

const fetchCompanyTags = async (title = '') => {
  try {
    const res = await axiosService.get('/interview_app/interviews/categories', {
      params: { title: title },
    });

    companyCategories.value = res?.data?.categories;
  } catch (e) {
    console.log('error', e);
  }
};

const createTag = async (text) => {
  if (companyCategories?.value.find((tag) => tag.title == text)) return;

  try {
    if (props?.route === 'forms/templates') {
      try {
        const res = await axiosService.post(
          `/interview_app/forms/templates/${props?.entityId}/tags`,
          { tag: { title: text } },
        );
        companyCategories?.value?.push(res?.data?.category);
        entityTags.value = entityTags.value.map((entityTagOrText) => {
          if (typeof entityTagOrText == 'string' && entityTagOrText === res?.data?.category.title) {
            return res?.data?.category;
          } else {
            return entityTagOrText;
          }
        });

        emit('update-interview-forms-tags', props?.entityId, res?.data?.category);
        generation.value = generation.value + 1;
        searchText.value = '';
      } catch (e) {
        console.log("couldn't create a tag", e);
      }
    } else {
      const res = await axiosService.post(
        `/interview_app/${props?.route}/${props?.entityId}/create_tags`,
        { category_title: text },
      );
      companyCategories?.value?.push(res?.data?.category);
      searchText.value = '';
    }
  } catch (error) {
    console.log(error);
  }
};

const toggleSelectedTag = async (tag) => {
  try {
    await axiosService.post(`/interview_app/${props?.route}/${props?.entityId}/toggle_tag`, {
      tag: tag,
    });
    emit('update-interview-forms-tags', props?.entityId, tag);
  } catch (error) {
    console.log(error);
  }
};

const updateOrCreateTag = async (tags) => {
  let tagToAdd = tags.find((tag) => typeof tag === 'string');
  if (tagToAdd) {
    await createTag(tagToAdd);
  } else if (tags.length > entityTagsOldValue.value.length) {
    tagToAdd = tags.find((tag) => {
      return entityTagsOldValue.value.every((oldTag) => oldTag.title !== tag.title);
    });
    toggleSelectedTag(tagToAdd);
  } else {
    tagToAdd = entityTagsOldValue.value.find((oldTag) => {
      return tags.every((tag) => oldTag.title != tag.title);
    });
    toggleSelectedTag(tagToAdd);
  }
  entityTagsOldValue.value = entityTags.value;
};

const removeTag = async (tag) => {
  await toggleSelectedTag(tag);
  entityTags.value = entityTags.value.filter((entityTag) => entityTag.id !== tag.id);
  generation.value = generation.value + 1;
};
defineExpose({
  modalNewTarget,
});
</script>
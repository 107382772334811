<template>
  <svn-pro-modal
    ref="modalSettings"
    :action-one-title="$t('Save')"
    :action-two-title="changed ? $t('Cancel') : undefined"
    :close-button-closes-modal="false"
    :persistent="true"
    :primary-disabled="!changed"
    :primary-loading="updateLoading"
    :title="$t('Profile details')"
    no-click-animation
    @click-close="openConfirmDialog"
    @click-primary-button="updateUser"
    @click-secondary-button="openConfirmDialog"
    @click-outside="openConfirmDialog"
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-8 items-start self-stretch">
        <!-- Personal information -->
        <div class="w-full flex flex-col gap-4">
          <svn-pro-text medium semi-bold>
            {{ $t('Personal information') }}
          </svn-pro-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
            <!-- Firstname -->
            <svn-pro-text-field
              v-model="editUser.firstname"
              :error="firstnameError"
              :label="$t('First name*')"
              variant="outlined"
              @input="updateFirstname"
            />

            <!-- Lastname -->
            <svn-pro-text-field
              v-model="editUser.lastname"
              :error="lastnameError"
              :label="$t('Last name*')"
              variant="outlined"
              @input="updateLastname"
            />

            <!-- Email -->
            <svn-pro-text-field
              v-model="editUser.email"
              :error="emailError"
              :label="$t('Email*')"
              variant="outlined"
              @input="updateEmail"
            />

            <!-- Deadline -->
            <pop-up-edit-date @save="updateBirthDate">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-model="editUser.birthdate"
                  :label="$t('Birth date')"
                  clear-icon="mdi-close"
                  clearable
                  color="primary"
                  prepend-inner-icon="custom:mingcute:calendar-2-line"
                  v-bind="props"
                  variant="outlined"
                />
              </template>
            </pop-up-edit-date>
          </div>
        </div>

        <!-- Hr information -->
        <div class="w-full flex flex-col gap-4">
          <svn-pro-text medium semi-bold>
            {{ $t('HR information') }}
          </svn-pro-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
            <!-- Access level -->
            <svn-pro-select
              v-if="isAdminOrAbove()"
              v-model="editUser.accessLevel"
              :items="isSuperAdmin() ? AccessLevelListSuperAdmin : AccessLevelList"
              :label="$t('Access level')"
              class="grow"
              @update:model-value="changed = true"
            />

            <!-- Manager -->
            <svn-pro-autocomplete
              v-model="editUser.manager"
              :disabled="!isAdminOrAbove()"
              :items="managers"
              :label="$t('Manager')"
              item-title="fullname"
              return-object
              @update:model-value="changed = true"
            />

            <!-- Hire Date -->
            <pop-up-edit-date @save="updateHireDate">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-model="editUser.hiredate"
                  :label="$t('Hire date')"
                  clear-icon="mdi-close"
                  clearable
                  color="primary"
                  prepend-inner-icon="custom:mingcute:calendar-2-line"
                  v-bind="props"
                  variant="outlined"
                />
              </template>
            </pop-up-edit-date>

            <!-- Tags -->
            <svn-pro-autocomplete
              v-for="(category, index) in tagCategories"
              :key="editUser.tags[category.id]"
              v-model="editUser.tags[category.id]"
              :items="category.tags"
              :label="capitalize(category.name)"
              item-title="tagName"
              return-object
              @update:search="tagCategorySearch[index] = $event"
              @update:model-value="changed = true"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>

  <!-- Quit without saving || Click on close -->
  <svn-pro-dialog-validation
    ref="confirmDialog"
    :action-one-title="$t('Quit anyway')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('If you quit without saving, your updates will be lost.')"
    :title="$t('Updates will be lost')"
    icon="noto:warning"
    @click-primary-button="clickWithoutSaving"
    @click-secondary-button="modalSettings.dialog = true"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import moment from 'moment';
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useTools from '@/tools/useTools.js';
import axios from '../../../tools/axios-service.js';
import { useUserStore } from '@/store/user.js';
import caseStyles from '@/tools/caseStyles.js';
import { useSnackbar } from '@/store/snackbar.js';
import { useCompanyStore } from '@/store/company.js';
import { onBeforeRouteLeave } from 'vue-router';
import _ from 'lodash';
import { AccessLevelList, AccessLevelListSuperAdmin } from '@/constants/types.js';
import PopUpEditDate from '../../../components/popUpComponents/PopUpEditDate.vue';

const props = defineProps({
  user: { type: Object, required: true },
  updateUserProfile: { type: Function, required: true },
});

onMounted(async () => {
  setUser();
  await getManagers();
});

const { tagCategories, managers } = storeToRefs(useCompanyStore());

const snackbar = useSnackbar();
const { sortTagsWithTagsCategory } = useTools();
const { isSuperAdmin, isAdminOrAbove } = useUserStore();
const { fetchCompanyManagers, fetchCompany } = useCompanyStore();

const changed = ref(false);
const firstnameError = ref(false);
const lastnameError = ref(false);
const emailError = ref(false);
const tagCategorySearch = ref(tagCategories.value.map((_) => ''));
const editUser = ref({
  id: null,
  accessLevel: null,
  accessLevelPreset: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birthdate: null,
  hiredate: null,
  gender: null,
  avatar: null,
  uploaded_avatar: null,
  lang: null,
  avatars: {},
  tags: null,
  jobTitle: '',
  manager_id: null,
});
const managerLoading = ref(true);
const updateLoading = ref(false);
const confirmDialog = ref(null);
const modalSettings = ref(null);

const updateUser = async () => {
  updateLoading.value = true;
  try {
    if (editUser.value.firstname === null || editUser.value.firstname === '')
      firstnameError.value = true;
    if (editUser.value.lastname === null || editUser.value.lastname === '')
      lastnameError.value = true;
    if (editUser.value.email === null || editUser.value.email === '') emailError.value = true;
    if (firstnameError?.value || lastnameError?.value || emailError?.value) {
      return;
    }
    const formData = new FormData();

    if (editUser.value.accessLevel)
      formData.append('user[access_level]', editUser.value.accessLevel);
    if (editUser.value.firstname) formData.append('user[firstname]', editUser.value.firstname);
    if (editUser.value.lastname) formData.append('user[lastname]', editUser.value.lastname);
    if (editUser.value.email) formData.append('user[email]', editUser.value.email);
    if (editUser.value.birthdate)
      formData.append('user[birth_date]', JSON.stringify(editUser.value.birthdate));
    if (editUser.value.hiredate)
      formData.append('user[hire_date]', JSON.stringify(editUser.value.hiredate));
    if (editUser.value.gender) formData.append('user[gender]', editUser.value.gender);
    if (editUser.value.lang) formData.append('user[lang]', editUser.value.lang);
    if (editUser.value.jobTitle) formData.append('user[job_title]', editUser.value.jobTitle);
    if (editUser.value.manager) {
      formData.append('user[manager_id]', editUser.value.manager?.id);
    } else {
      formData.append('user[manager_id]', '');
    }
    if (editUser.value.tags)
      formData.append(
        'user[tags]',
        JSON.stringify(caseStyles.convertKeysToSnake(editUser.value.tags)),
      );

    const headers = { 'Content-Type': 'multipart/form-data' };
    await axios.patch(`/users/${editUser.value.id}`, formData, {
      headers,
    });

    props.updateUserProfile();
    fetchCompany();

    snackbar.setBgColor('onSurface').displaySnackBar('Settings have been updated.', 3000);
  } catch (e) {
    snackbar.setBgColor('error').displaySnackBar('Error updating your settings', 3000);
  } finally {
    updateLoading.value = false;
  }
  changed.value = false;
  modalSettings.value.dialog = false;
};

const setUser = () => {
  editUser.value = _.cloneDeep(props.user); // Creates a deep copy
  editUser.value.birthdate = props.user.birthDate;
  editUser.value.hiredate = props.user.hireDate;
  editUser.value.tags = sortTagsWithTagsCategory(props.user.tags, tagCategories.value);
};

const getManagers = async () => {
  try {
    await fetchCompanyManagers();
    managers.value = managers.value.filter((manager) => {
      return manager.id !== editUser.value.id;
    });
  } catch (e) {
    console.log('error', e);
  } finally {
    managerLoading.value = false;
  }
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

onBeforeRouteLeave(async (_, __) => {
  return await new Promise((resolve, _) => {
    if (!changed.value) resolve(true);
    else {
      leaveResolve = resolve;
      leave.value = true;
    }
  });
});

const openConfirmDialog = () => {
  modalSettings.value.dialog = false;
  if (changed.value) {
    confirmDialog.value.dialogRef.dialog = true;
  }
};

const clickWithoutSaving = () => {
  confirmDialog.value.dialogRef.dialog = false;
  modalSettings.value.dialog = false;
  setUser();
  changed.value = false;
};

const updateBirthDate = (value) => {
  changed.value = true;
  editUser.value.birthdate = moment(value).format('DD/MM/YYYY');
};

const updateHireDate = (value) => {
  changed.value = true;
  editUser.value.hiredate = moment(value).format('DD/MM/YYYY');
};

const updateFirstname = () => {
  changed.value = true;
  if (firstnameError?.value && editUser?.value?.firstname) {
    firstnameError.value = false;
  }
};

const updateLastname = () => {
  changed.value = true;
  if (lastnameError?.value && editUser?.value?.lastname) {
    lastnameError.value = false;
  }
};

const updateEmail = () => {
  changed.value = true;
  if (emailError?.value && editUser?.value?.email) {
    emailError.value = false;
  }
};

watch(editUser, (newValue) => {
  if (newValue) {
    editUser.value = newValue;
  }
});

defineExpose({
  modalSettings,
});
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(input) {
  color: #333333 !important;
  font-weight: 400 !important;
}

.white-space-wrap :deep(.v-btn__content) {
  white-space: normal;
}

.unclearable :deep(.v-field__clearable) {
  display: none;
}
</style>

<template>
  <div class="w-full flex flex-col gap-8">
    <div class="flex flex-col gap-6">
      <svn-pro-title class="text-center md:!text-start" h6 medium>
        {{ $t('Summary of your campaign') }}
      </svn-pro-title>

      <div class="w-full flex flex-col gap-2">
        <!-- Campaign type -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Campaign Type') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{ campaignDraft.campaign_type }}
            </svn-pro-text>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Campaign title -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Campaign title') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{ campaignDraft.title }}
            </svn-pro-text>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Recurrent Campaign -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Recurrent campaign') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{ campaignDraft.is_recurrent ? $t(`Yes`) : $t('No') }}
            </svn-pro-text>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Campaign period -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Campaign period') }} :
            </svn-pro-text>

            <div class="flex items-center self-center md:!self-start gap-2">
              <svn-pro-text body-large medium>
                {{
                  new Date(campaignDraft?.startline).toLocaleString(
                    lang === 'fr' ? 'fr-FR' : 'en-GB',
                    {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    },
                  )
                }}
              </svn-pro-text>

              <div>
                <Icon
                  class="text-onSurface"
                  height="24"
                  icon="mingcute:arrow-right-line"
                  width="24"
                />
              </div>

              <svn-pro-text body-large medium>
                {{
                  new Date(campaignDraft?.deadline).toLocaleString(
                    lang === 'fr' ? 'fr-FR' : 'en-GB',
                    {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    },
                  )
                }}
              </svn-pro-text>
            </div>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Participants count -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Participants') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{
                campaignDraft?.interviewee_selection_method === 'manual'
                  ? campaignDraft?.interviewee_ids?.length
                  : participantsCount
              }}
            </svn-pro-text>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Person in charge -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Person in charge') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{
                $t(
                  campaignDraft?.interviewer_selection_method === 'manager'
                    ? 'Direct manager'
                    : 'Specific manager',
                )
              }}
            </svn-pro-text>
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Default person in charge -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Default person in charge') }} :
            </svn-pro-text>

            <svn-pro-info-tag
              :text="defaultInterviewer?.fullname"
              class="w-fit self-center md:!self-start"
              tag-size="compact"
              tag-style="filled"
              tag-type="info"
            />
          </div>

          <svn-pro-divider class="border-opacity-100" color="[#767680]" />
        </div>

        <!-- Template -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Template(s) used') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{
                campaignDraft?.templates_selection_method === 'single' && defaultInterviewForm
                  ? defaultInterviewForm?.title
                  : $t('Multiples templates')
              }}
            </svn-pro-text>
          </div>

          <svn-pro-divider
            v-if="campaignDraft?.campaign_type !== CampaignTypeEnum?.ONE_TO_ONE"
            class="border-opacity-100"
            color="[#767680]"
          />
        </div>

        <!-- Survey elements -->
        <!-- Anonymise answers -->
        <div
          v-if="campaignDraft?.campaign_type === CampaignTypeEnum?.SURVEY"
          class="flex flex-col gap-2"
        >
          <div class="flex flex-col gap-2 md:!flex-row">
            <svn-pro-text body-large class="text-center md:!text-start" regular>
              {{ $t('Anonymise answers') }} :
            </svn-pro-text>

            <svn-pro-text body-large class="text-center md:!text-start" medium>
              {{ campaignDraft?.anonymize_answers ? $t('Yes') : $t('No') }}
            </svn-pro-text>
          </div>
        </div>

        <!-- Feedback elements -->
        <div
          v-if="campaignDraft?.campaign_type === CampaignTypeEnum?.FEEDBACK_360"
          class="flex flex-col gap-2"
        >
          <!-- Participant chooses reviewers -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 md:!flex-row">
              <svn-pro-text body-large class="text-center md:!text-start" regular>
                {{ $t('Participants choose their reviewers') }} :
              </svn-pro-text>

              <svn-pro-text body-large class="text-center md:!text-start" medium>
                {{ $t(campaignDraft.can_choose_pairs ? 'Yes' : 'No') }}
              </svn-pro-text>
            </div>

            <svn-pro-divider class="border-opacity-100" color="[#767680]" />
          </div>

          <!-- Self evaluation -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 md:!flex-row">
              <svn-pro-text body-large class="text-center md:!text-start" regular>
                {{ $t('Participants choose their reviewers') }} :
              </svn-pro-text>

              <svn-pro-text body-large class="text-center md:!text-start" medium>
                {{ $t(campaignDraft?.can_self_evaluate ? 'Yes' : 'No') }}
              </svn-pro-text>
            </div>

            <svn-pro-divider class="border-opacity-100" color="[#767680]" />
          </div>

          <!-- View reviewers answers -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2 md:!flex-row">
              <svn-pro-text body-large class="text-center md:!text-start" regular>
                {{ $t('Participants view reviewers answers') }} :
              </svn-pro-text>

              <svn-pro-text body-large class="text-center md:!text-start" medium>
                {{ $t(campaignDraft?.can_view_pairs_answers ? 'Yes' : 'No') }}
              </svn-pro-text>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Launch button -->
    <svn-pro-button
      :text="$t('Launch campaign')"
      class="w-full md:!w-[256px]"
      variant="flat"
      @click="LaunchCampaign"
    />
  </div>

  <!-- Dialog Launch now -->
  <svn-pro-dialog-validation
    ref="dialogLaunchNow"
    :action-one-title="$t('Yes, send emails')"
    :action-two-title="$t('No, thanks')"
    :content-text="`Do you wish to send invitations by email for this campaign? They will be sent on ${i18n.global.d(campaignDraft?.startline, 'short')}.`"
    :title="$t('Send invitation emails ?')"
    width="312"
    @click-primary-button="sendInvitationEmails(true)"
    @click-secondary-button="sendInvitationEmails(false)"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <!-- Dialog Launch later -->
  <svn-pro-dialog-validation
    ref="dialogLaunchLater"
    :action-one-title="$t('Launch on later')"
    :action-two-title="$t('Launch now')"
    :content-text="`The campaign start date is later than the current date.`"
    :title="$t('Launch this campaign on the start date ?')"
    @click-primary-button="sendInvitationEmails(true, true)"
    @click-secondary-button="sendInvitationEmails(false, true)"
  >
    <template #activator>
      {{ null }}
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import axios from 'axios';
import i18n from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useUserStore } from '@/store/user.js';
import { useSnackbar } from '@/store/snackbar.js';
import { useCompanyStore } from '@/store/company.js';
import CampaignTypeEnum from '@/constants/CampaignTypeEnum.js';
import { useCampaignDraftStore } from '@/store/campaign-draft.js';

const { lang } = storeToRefs(useUserStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { managers, interviewForms } = storeToRefs(useCompanyStore());

const router = useRouter();
const snackbar = useSnackbar();
const { createCampaign } = useCampaignDraftStore();
const { fetchCompanyManagers, fetchInterviewForms } = useCompanyStore();

onMounted(() => {
  fetchInterviewForms();
  fetchCompanyManagers();
  getParticipantsCount();
});

const emit = defineEmits(['previous', 'next']);

const dialogLaunchNow = ref(false);
const dialogLaunchLater = ref(false);
const participantsCount = ref(0);
const defaultInterviewer = computed(() => {
  return managers?.value?.find(
    (manager) => manager?.id === campaignDraft?.value?.default_interviewer_id,
  );
});
const defaultInterviewForm = computed(() => {
  return interviewForms?.value?.find(
    (interviewForm) => interviewForm?.id === campaignDraft?.value?.default_template_id,
  );
});

const sendInvitationEmails = async (send = true, later = false) => {
  try {
    await createCampaign({ sendEmail: send, launchSchedule: later });
    await router.push({ name: 'campaigns' });
  } catch {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass('mb-4')
      .setMsg('An error occured, please try again')
      .displaySnackBar();
  }
};

const LaunchCampaign = async () => {
  let today = new Date();
  let startline = new Date(campaignDraft?.value?.startline);

  if (startline > today) {
    dialogLaunchLater.value.dialogRef.dialog = true;
  } else {
    dialogLaunchNow.value.dialogRef.dialog = true;
  }
};

const getParticipantsCount = async () => {
  try {
    const { data } = await axios.get('/users/filter_by_tag_categories', {
      params: {
        tag_ids: campaignDraft?.value?.participant_filter_tag_ids,
      },
    });
    participantsCount.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};
</script>
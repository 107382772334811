<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center md:!items-start pt-5"
    >
      <!-- Header Container -->
      <div class="w-full flex flex-col items-center md:items-start gap-1 px-5 md:!px-12">
        <svn-pro-header-section
          :size="isMdScreen ? 'default' : 'compact'"
          :subtitle="
            $t('Here is the list of all your targets. This target list is visible by your manager.')
          "
          :title="$t('My Roadmap')"
        />
      </div>

      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 px-5 md:!px-12 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Targets') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <modal-create-new-target
              ref="refModalCreateNewTarget"
              :users-from-overlay="[user?.id]"
              from="my_roadmaps"
            >
              <template #activator="{ props }">
                <svn-pro-button
                  :text="$t('New target')"
                  class="hidden md:flex"
                  prepend-icon="custom:mingcute:add-line"
                  v-bind="props"
                  variant="flat"
                />
              </template>
            </modal-create-new-target>

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-fill"
                  v-bind="props"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <modal-view-deleted-items
                  ref="deletedItems"
                  :headers="trashesHeaders"
                  :items="trashes"
                  content-type="target"
                  @restore="restoreDeletedItems"
                  @view-item="viewSelectedItem"
                  @close-modal="closeModal"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyDeletedItems"
                >
                  <template #activator="{ props }">
                    <v-list-item :active="false" v-bind="props" value="view_deleted">
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted targets') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Tabs -->
        <v-tabs v-model="tab" :grow="isMobile" class="px-5 md:!px-12" color="primary">
          <!-- Current targets Tab -->
          <v-tab
            :text="`${$t('Current')} (${unarchivedPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="current"
          />

          <!-- Archived targets Tab -->
          <v-tab
            :text="`${$t('Archived')} (${archivedPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="archived"
          />
        </v-tabs>

        <svn-pro-text-field
          v-model="currentSearch"
          :placeholder="$t('Search')"
          class="px-5 md:!px-12"
          prepend-inner-icon="custom:mingcute:search-2-line"
          variant="outlined"
          @update:model-value="search"
        />

        <!-- Windows -->
        <v-window v-model="tab">
          <v-window-item value="current">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-12">
              <my-roadmaps-table
                :loading="loading"
                :pagination="unarchivedPagination"
                :search="currentSearch"
                :targets="unarchived"
                type="unarchived"
                @update="update"
                @reload-page="fetchUnarchived($event, currentSearch)"
                @delete-target="deleteTarget"
                @open-modal-new-target="openModalNewTarget"
              />
            </div>
          </v-window-item>

          <v-window-item value="archived">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-12">
              <my-roadmaps-table
                :loading="loading"
                :pagination="archivedPagination"
                :search="archivedSearch"
                :targets="archived"
                type="archived"
                @update="update"
                @reload-page="fetchArchived($event, currentSearch)"
                @delete-target="deleteTarget"
                @open-modal-new-target="openModalNewTarget"
              />
            </div>
          </v-window-item>
        </v-window>
      </div>
    </div>

    <svn-pro-floating-action-button
      v-if="!isMdScreen"
      :text="$t('New target')"
      class="fixed bottom-4 right-4"
      extended
      prepend-icon="custom:mingcute:add-fill"
      variant="primary"
      @click="openModalNewTarget"
    />
  </div>
</template>

<script setup>
import axiosService from '@/tools/axios-service.js';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user.js';
import MyRoadmapsTable from '@/components/roadmapApp/myRoadmaps/table.vue';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import { useRoadmapTargetStore } from '@/store/roadmap-target.js';
import { debounce } from 'lodash';
import { useMobileStore } from '@/store/mobile';
import i18n from '@/plugins/i18n.js';
import ModalViewDeletedItems from '../../components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import ModalCreateNewTarget from '../../components/BktPopUp/Modals/ModalCreateNewTarget.vue';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { deletePermanentlyRoadmapTarget, restoreRoadmapTarget, deleteRoadmapTarget } =
  useRoadmapTargetStore();

const currentUser = storeToRefs(useUserStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const user = ref(null);
const tab = ref('current');
const unarchived = ref(null);
const unarchivedPagination = ref(null);
const archived = ref(null);
const trashes = ref([]);
const trashesPagination = ref({});
const archivedPagination = ref(null);
const loading = ref(false);
const refModalCreateNewTarget = ref(null);
const deletedItems = ref(null);
const currentSearch = ref('');
const archivedSearch = ref('');
const targetToDelete = ref(null);
const trashesHeaders = ref([
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
  {
    align: 'start',
    key: 'delete',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);

onMounted(async () => {
  await update();
  await fetchTrashes();
});

const viewSelectedItem = (id) => {
  router.push({ name: 'roadmaps_targets_show', params: { id: id } });
};

const search = debounce((title) => {
  reloadAfterSearch(title);
}, 400);

const reloadAfterSearch = async (title) => {
  if (tab.value === 'current') {
    fetchUnarchived(1, title);
  } else {
    fetchArchived(1, title);
  }
};

const update = async () => {
  if (route.params.employee_id) {
    const res = await axiosService.get(`/users/${route.params.employee_id}`);

    user.value = res.data.user;
  } else {
    user.value = {
      id: currentUser.id.value,
      firstname: currentUser.firstname.value,
      lastname: currentUser.lastname.value,
      avatar: currentUser.avatar.value,
      job_title: currentUser.job_title.value,
    };
  }

  try {
    loading.value = true;
    fetchUnarchived();
    await fetchArchived();
  } catch (e) {
    useSnackbar().setBgColor('onSurface').displaySnackBar(e?.message);
  }
  loading.value = false;
};

const deleteTarget = async (id) => {
  try {
    await deleteRoadmapTarget(id);
    snackbar.setBgColor('onSurface').displaySnackBar('Target has been deleted successfully.');

    if (tab.value === 'current') {
      const trash = unarchived.value.find((trash) => trash.id === id);
      trash.deleted_at = new Date();
      trashes.value.push(trash);
      unarchived.value = unarchived.value.filter((trash) => trash.id !== id);
    } else if (tab.value === 'archived') {
      const trash = archived.value.find((trash) => trash.id === id);
      trash.deleted_at = new Date();
      trashes.value.push(trash);
      archived.value = archived.value.filter((trash) => trash.id !== id);
    }
  } catch (error) {
    snackbar.setBgColor('error').displaySnackBar('Error while deleting your target !');
  }
};

const fetchUnarchived = async (page = 1, title = '') => {
  unarchived.value = null;
  const unarchivedReq = await axiosService.get(
    `/roadmaps/employees/${user.value.id}/unarchived_target`,
    {
      params: {
        'page[number]': page,
        title,
      },
    },
  );
  unarchived.value = unarchivedReq?.data?.objective_elements;
  unarchivedPagination.value = unarchivedReq?.data?.meta?.pagination;
};

const fetchArchived = async (page = 1, title = '') => {
  archived.value = null;
  const archivedReq = await axiosService.get(
    `/roadmaps/employees/${user.value.id}/archived_target`,
    {
      params: {
        'page[number]': page,
        title,
      },
    },
  );
  archived.value = archivedReq?.data?.objective_elements;
  archivedPagination.value = archivedReq?.data?.meta?.pagination;
};

const fetchTrashes = async (title = null) => {
  const trashesReq = await axiosService.get(`/roadmaps/employees/${user.value.id}/target_trashes`, {
    params: { title },
  });
  trashes.value = trashesReq?.data?.objective_elements;
  trashesPagination.value = trashesReq?.data?.meta?.pagination;
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashes(value);
  } catch (e) {
    console.error(e);
  }
}, 300);

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id);
    unarchived.value.push(trashes.value.find((trash) => trash.id === id));
    trashes.value = trashes.value.filter((trash) => trash.id !== id);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Target has been restored successfully.');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while restoring a target');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5');
    snackbar.displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async () => {
  try {
    await deletePermanentlyRoadmapTarget(targetToDelete?.value);
    trashes.value = trashes.value.filter((trash) => trash.id !== targetToDelete?.value);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Target has been deleted successfully.');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while deleting permanently a training');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5');
    snackbar.displaySnackBar();
  }
  targetToDelete.value = null;
};

const closeModal = (id) => {
  deletedItems.value.deletedItems.dialog = false;
  deletedItems.value.deleteTargetRef.dialogRef.dialog = true;

  targetToDelete.value = id;
};

const openModalNewTarget = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true;
};
</script>

import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';
import axiosService from '@/tools/axios-service.js';

export const useLearnPlaylistStore = defineStore({
  id: 'learn-playlist',
  state: () => ({
    id: null,
    selectedModules: [],
    learnPlaylist: {},
    learnPlaylists: [],
    learnTrashPlaylists: [],
    totalPlaylistPages: 0,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async resetStates() {
      this.selectedModules = [];
      this.learnPlaylist = {};
      this.learnPlaylists = [];
      this.learnTrashPlaylists = [];
    },
    async fetchPlaylist(id) {
      this.resetStates();
      this.learnPlaylist = {};
      const res = await axiosService.get(`/learn/playlists/${id}`);
      this.learnPlaylist = res.data;
      this.selectedModules = this.learnPlaylist.learn_module_ids;
    },
    async fetchPlaylists(text = '', themeIds = [], favorite = false, page = 1) {
      const res = await axiosService.get('/learn/playlists', {
        params: {
          text: text,
          theme_ids: themeIds,
          favoritable_playlist: favorite,
          'page[number]': page,
        },
      });
      this.learnPlaylists = res.data.learn_playlists;
      this.totalPlaylistPages = res.data.meta.pagination.total_pages;
      return res;
    },
    async fetchTrashPlaylists(text = '') {
      const res = await axiosService.get(`/learn/playlists/trashes`, {
        params: {
          text: text,
        },
      });

      this.learnTrashPlaylists = res.data.learn_playlists;
    },
    async fetchPlaylistsOnlyWithPages(page = 1, text = '') {
      const res = await axiosService.get(`/learn/playlists`, {
        params: {
          'page[number]': page,
          text: text,
        },
      });
      // this.learnPlaylists = res.data.learn_playlists
      return res;
    },
    async createPlaylist(title, description) {
      const res = await axiosService.post('/learn/playlists', {
        title: title,
        description: description,
      });
      return res.data.id;
    },
    async toggleModuleSelection(module) {
      const found = this.selectedModules.find((el) => el === module?.id);

      if (found) {
        this.selectedModules.splice(
          this.selectedModules.findIndex((el) => el === module?.id),
          1,
        );
      } else {
        this.selectedModules.push(module?.id);
      }
    },
    async removeModuleFromPlaylist(playlistId, moduleId) {
      const found = this.learnPlaylist.learn_module_ids.find((el) => el === moduleId);

      if (found) {
        this.selectedModules = [...this.learnPlaylist.learn_module_ids];
        this.selectedModules.splice(
          this.learnPlaylist.learn_module_ids.findIndex((element) => element === moduleId),
          1,
        );
      }
      const res = await axiosService.put(`/learn/playlists/${playlistId}`, {
        learn_module_ids: this.selectedModules,
      });
      this.learnPlaylist = res.data;
    },
    async updatePlaylist(id, title, description) {
      const res = await axiosService.put(`/learn/playlists/${id}`, {
        title: title,
        description: description,
      });
      this.learnPlaylist = res.data;
    },
    async updatePlaylistInfos(id, list) {
      const res = await axiosService.put(`/learn/playlists/${id}`, {
        learn_module_ids: list,
      });
      this.learnPlaylist = res.data;
    },
    async togglePlaylistInfos(playlist, moduleId) {
      if (!playlist.learn_module_ids.includes(moduleId)) {
        playlist.learn_module_ids.push(moduleId);
        const res = await axiosService.put(`/learn/playlists/${playlist.id}`, {
          learn_module_ids: playlist.learn_module_ids,
        });
      } else {
        const res = await axiosService.put(`/learn/playlists/${playlist.id}`, {
          learn_module_ids: playlist.learn_module_ids.filter((id) => id != moduleId),
        });
      }
      this.fetchPlaylists();
    },
    async updateModuleIds(id, ids) {
      const res = await axiosService.put(`/learn/playlists/${id}`, {
        learn_module_ids: ids,
      });

      this.learnPlaylist = res.data;
    },
    async restorePlaylist(id) {
      await this.updateEntity(`/learn/playlists/${id}/restore`);

      this.learnPlaylists.push(this.learnTrashPlaylists.find((trash) => trash?.id === id));
      this.removePlaylistFromTrash(id);
    },
    async deletePlaylist(id) {
      await this.deleteEntity(`/learn/playlists/${id}`);

      const trashPlaylist = this.learnPlaylists.find((trash) => trash?.id === id);
      if (trashPlaylist) {
        trashPlaylist.deleted_at = new Date();
        this.learnTrashPlaylists.push(trashPlaylist);
      }
      this.learnPlaylists = this.learnPlaylists.filter((playlist) => playlist?.id !== id);
    },
    async deletePlaylistPermanently(id) {
      await this.deleteEntity(`/learn/playlists/${id}/destroy_permanently`);
      this.removePlaylistFromTrash(id);
    },
    removePlaylistFromTrash(id) {
      this.learnTrashPlaylists = this.learnTrashPlaylists.filter((trash) => trash?.id !== id);
    },
    async toggleFavorite(id) {
      const res = await axiosService.post(`/learn/playlists/${id}/favorite`);

      if (this.learnPlaylists.length) {
        this.learnPlaylists?.forEach((playlist) => {
          if (playlist?.id === id) {
            playlist.connected_user_favorited = res?.data?.connected_user_favorited;
          }
        });
      } else {
        this.learnPlaylist.connected_user_favorited = res.data.connected_user_favorited;
      }
    },
  },
});

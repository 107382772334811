<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full md:max-w-[614px] xl:max-w-[560px] mx-auto flex flex-col gap-4 md:gap-6 xl:gap-10 items-center px-5 md:!px-0 pt-5 md:pt-6 desktop:pt-10"
    >
      <div v-if="from != 'interview_answer'" class="w-full flex items-center justify-between">
        <svn-pro-title h5 medium>
          {{ targetId ? $t('Edit target') : $t('Create a target') }}
        </svn-pro-title>

        <svn-pro-icon-button
          color="onSurfaceVariant"
          icon="custom:mingcute:close-line"
          variant="text"
          @click="router.back()"
        />
      </div>

      <div class="w-full flex flex-col items-start gap-12">
        <div class="w-full flex flex-col items-start gap-10">
          <div
            v-if="route.query.fromRoute !== 'my_roadmaps' && from != 'interview_answer'"
            class="w-full flex flex-col gap-4"
          >
            <svn-pro-title h6 medium>
              {{ $t('Target employees') }}
            </svn-pro-title>

            <svn-pro-autocomplete
              v-if="isUpdatingUsers"
              :disabled="true"
              :label="$t('Search employees')"
              :loading="true"
            />

            <svn-pro-autocomplete
              v-else
              v-model="selectedUsers"
              :disabled="overlayMode"
              :items="users"
              :label="$t('Search employees')"
              item-title="fullname"
              item-value="id"
              multiple
              with-select-all
            />
          </div>

          <div class="w-full flex flex-col gap-4">
            <svn-pro-title h6 medium>
              {{ $t('Informations') }}
            </svn-pro-title>

            <svn-pro-text-field
              v-model="title"
              :disabled="indicator?.attribute_disablement?.title"
              :error="titleError"
              :label="$t('Title*')"
              variant="outlined"
            />

            <svn-pro-text-area v-model="desc" :label="$t('Description')" :max-rows="5" :rows="5" />

            <pop-up-edit-date @save="updateDueDate">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-model="date"
                  :disabled="
                    indicator.attribute_disablement?.due_date ||
                    (overlayMode && objectiveTemplateId)
                  "
                  :label="$t('Deadline')"
                  clearable
                  color="primary"
                  prepend-inner-icon="custom:mingcute:calendar-2-line"
                  v-bind="props"
                  variant="outlined"
                />
              </template>
            </pop-up-edit-date>
          </div>

          <!-- Target indicator -->
          <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-4">
              <svn-pro-title h6 medium>
                {{ $t('Target indicator') }}
              </svn-pro-title>

              <v-item-group mandatory>
                <div class="w-full flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-4">
                  <v-item
                    v-for="item in getBoxes"
                    :key="item?.type"
                    v-slot="{ isSelected, toggle }"
                    :value="item?.type"
                  >
                    <v-card
                      :class="item?.selected ? 'border-primary bgOpacity' : 'border-corderColor'"
                      :disabled="item?.disabled"
                      class="w-full flex-1 border border-borderColor border-opacity-100"
                      color="primary"
                      link
                      variant="outlined"
                      @click="cardClicked(toggle, item)"
                    >
                      <template #default>
                        <div class="w-full h-full flex items-center gap-2 p-4 rounded">
                          <div class="flex w-full items-center gap-2">
                            <div>
                              <Icon
                                :icon="item?.icon"
                                class="text-onSurface"
                                height="40"
                                width="40"
                              />
                            </div>

                            <div class="flex flex-col items-start">
                              <svn-pro-text body-large medium>
                                {{ item?.title }}
                              </svn-pro-text>

                              <svn-pro-text caption color="onSurfaceVariant">
                                {{ item?.shortDesc }}
                              </svn-pro-text>
                            </div>
                          </div>

                          <div class="flex items-start self-stretch">
                            <svn-pro-radio-button
                              :model-value="true"
                              :value="item?.selected"
                              color="primary"
                            />
                          </div>
                        </div>
                      </template>
                    </v-card>
                  </v-item>
                </div>
              </v-item-group>
            </div>

            <!-- Indicator values -->
            <div class="flex flex-col gap-4">
              <svn-pro-text medium subtitle-large>
                {{ getSelectedIndicatorDescription() }}
              </svn-pro-text>

              <div class="flex flex-col md:flex-row gap-4 flex-1">
                <!-- Starting value -->
                <svn-pro-text-field
                  v-if="indicator?.indicator_type !== 'multi_choice'"
                  v-model="indicator.starting_value"
                  :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                  :error="startingValueError"
                  :label="`${$t('Starting value')}*`"
                  :type="indicator?.indicator_type === 'boolean' ? 'text' : 'number'"
                  prepend-inner-icon="custom:mingcute:flag-1-fill"
                  variant="outlined"
                />

                <!-- target value -->
                <svn-pro-text-field
                  v-if="indicator?.indicator_type !== 'multi_choice'"
                  v-model="indicator.target_value"
                  :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                  :error="targetValueError"
                  :label="`${$t('Target value')}*`"
                  :type="indicator?.indicator_type === 'boolean' ? 'text' : 'number'"
                  prepend-inner-icon="custom:mingcute:target-line"
                  variant="outlined"
                />

                <!-- MCQ list -->
                <div
                  v-if="indicator?.indicator_type === 'multi_choice'"
                  class="w-full flex flex-col gap-4"
                >
                  <div
                    v-for="(multiChoiceValue, idxInput) in indicator?.multi_choice_list"
                    :key="idxInput"
                    class="w-full flex flex-1 gap-2 items-center"
                  >
                    <svn-pro-checkbox
                      v-model="multiChoiceValue.checkbox"
                      :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                      color="primary"
                    />

                    <svn-pro-text-field
                      :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                      :model-value="multiChoiceValue.option"
                      :placeholder="$t('Option') + ` ${idxInput + 1}`"
                      class="w-full"
                      density="comfortable"
                      variant="outlined"
                      @update:model-value="updateOption(idxInput, $event)"
                    />

                    <svn-pro-icon-button
                      :disabled="
                        idxInput === 0 ||
                        indicator?.attribute_disablement?.indicator_values ||
                        isTrashed
                      "
                      color="onSurfaceVariant"
                      icon="custom:mingcute:close-line"
                      variant="text"
                      @click="removeOption(idxInput)"
                    />
                  </div>

                  <svn-pro-button
                    :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                    :text="$t('Add an option')"
                    class="w-fit"
                    prepend-icon="custom:mingcute:add-line"
                    variant="tonal"
                    @click="addOption"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <svn-pro-button
          :text="$t('Save')"
          class="w-full md:w-1/2 md:self-center"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import { computed, onMounted, ref, watch } from 'vue';
import { useInterviewStore } from '@/store/interview';
import { useRoadmapTemplateStore } from '@/store/roadmap-template.js';
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';
import PopUpEditDate from '../../../components/popUpComponents/PopUpEditDate.vue';

const props = defineProps([
  'overlayMode',
  'usersFromOverlay',
  'targetId',
  'objectiveTemplateId',
  'isEditingTarget',
  'createDraft',
  'from',
]);

onMounted(async () => {
  if (route.query.fromRoute === 'my_roadmaps') {
    users.value = route?.query?.usersFromOverlay || [];
    selectedUsers.value = route?.query?.usersFromOverlay || [];
  } else if (props?.from === 'interview_answer') {
    users.value = props?.usersFromOverlay || [];
    selectedUsers.value = props?.usersFromOverlay || [];
  } else {
    await getUsers();

    const routeUsersFromOverlay = route?.query?.usersFromOverlay;
    if (routeUsersFromOverlay && routeUsersFromOverlay.length) {
      const ids = routeUsersFromOverlay?.map((id) => Number(id));
      users.value = users.value.filter((user) => ids.includes(user.id));
    }
  }

  if (route?.query?.objectiveTemplateId || props?.objectiveTemplateId) {
    const entity = await fetchRoadmapTemplate(
      route?.query?.objectiveTemplateId || props?.objectiveTemplateId,
    );

    mergeToCurrentTemplate(entity);
  } else if (props.targetId && !props.isEditingTarget) {
    const target = await fetchTarget(props.targetId);

    mergeToCurrentTemplate(target);
  } else if (props.isEditingTarget) {
    try {
      const res = await axiosService.get(`/roadmaps/target_drafts/${props?.targetId}`);

      targetDraft.value = res.data?.objective_draft;
    } catch (e) {
      snackbar.setBgColor('error');
      snackbar.setMsg('Error fetching target draft');
      snackbar.displaySnackBar();
    }
    mergeToCurrentTemplate(targetDraft.value);
  }
  buildingPage.value = false;
});

const emit = defineEmits(['close']);

const { id, campaignId, employee } = storeToRefs(useInterviewStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchRoadmapTemplate } = useRoadmapTemplateStore();
const { fetchCreatedSnapshots } = useTargetSuggestionsStore();

snackbar.checkStatus();

const targetDraft = ref(null);
const buildingPage = ref(true);
const loading = ref(false);
const title = ref(null);
const startingValueError = ref(false);
const targetValueError = ref(false);
const isTrashed = ref(false);
const titleError = ref(false);
const desc = ref(null);
const date = ref(null);
const indicator = ref({
  indicator_type: 'boolean',
  starting_value: null,
  target_value: null,
  multi_choice_list: [{ option: '', checkbox: true }],
});
const boxes = ref([
  {
    type: 'boolean',
    icon: 'mingcute:toggle-left-line',
    title: 'True/False',
    shortDesc: 'For non-quantifiable targets, choose true and false values.',
    longDesc:
      'Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.',
    selected: false,
  },
  {
    type: 'numeric_value',
    icon: 'mingcute:hashtag-line',
    title: 'Number',
    shortDesc: 'For quantifiable objectives, chose a start and target value.',
    longDesc: 'Define a starting and a target value to measure the objective completion.',
    selected: false,
  },
  {
    type: 'percentage',
    icon: 'mingcute:sale-line',
    title: 'Percent',
    shortDesc: 'Useful to measure easily a quantifiable objective.',
    longDesc: 'Use a percentage based value to measure the objective completion.',
    selected: false,
  },
  {
    type: 'multi_choice',
    icon: 'mingcute:checkbox-line',
    title: 'Multi-choice',
    shortDesc: 'For non-quantifiable targets, add and edit multiple choices.',
    longDesc:
      "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.",
    selected: false,
  },
]);
const users = ref([]);
const isUpdatingUsers = ref(true);
const selectedUsers = ref(
  route?.query?.usersFromOverlay
    ? typeof route?.query?.usersFromOverlay === 'string'
      ? [parseInt(route?.query?.usersFromOverlay)]
      : route?.query?.usersFromOverlay?.map((item) => parseInt(item))
    : [],
);
const getBoxes = computed(() => {
  return boxes.value.map((box) => {
    return {
      ...box,
      selected: indicator?.value?.indicator_type === box.type,
      disabled: indicator?.value?.attribute_disablement?.indicator || isTrashed.value,
    };
  });
});

const fetchTarget = async (id) => {
  try {
    const res = await axiosService.get(`/roadmaps/targets/${id}`);

    return res.data['objective/element'];
  } catch (e) {
    console.log("couldn't fetch target");
    return null;
  }
};

const mergeToCurrentTemplate = (template) => {
  title.value = template.title;
  desc.value = template.description;
  isTrashed.value = template.status === 'trashed';
  date.value = template.due_date;
  indicator.value.indicator_type = template.objective_indicator.indicator_type;
  indicator.value.attribute_disablement = template?.attribute_disablement;
  indicator.value.starting_value = template.objective_indicator.options.starting_value;
  indicator.value.target_value = template.objective_indicator.options.target_value;
  indicator.value.multi_choice_list = [];

  if (indicator.value.indicator_type === 'multi_choice') {
    indicator.value.multi_choice_list = template.objective_indicator?.options?.multi_choice_list;
  }
};

const getUsers = async (text = '') => {
  try {
    const { data } = await axiosService.get('/companies/get_users');

    users.value = data;
    if (users.value.length === 1) {
      selectedUsers.value = [];
      selectedUsers.value.push(users.value[0].id);
    }
  } catch (e) {
    console.log('error', e);
  } finally {
    isUpdatingUsers.value = false;
  }
};

const handleSubmit = async () => {
  if (indicator?.value?.indicator_type === 'multi_choice') {
    if (!title.value) {
      titleError.value = true;
    }
  } else {
    if (!title.value) {
      titleError.value = true;
    }
    if (!indicator?.value?.starting_value) {
      startingValueError.value = true;
    }
    if (!indicator?.value?.target_value) {
      targetValueError.value = true;
    }
    if (!title.value || !indicator?.value?.starting_value || !indicator?.value?.target_value) {
      snackbar.setBgColor('onSurface').displaySnackBar('Please fill all required fields (*).');
    }
  }
  if (!selectedUsers.value.length) {
    snackbar.setBgColor('error').displaySnackBar('You need to set at least one employee');
  }

  loading.value = true;
  try {
    const toSend = {
      title: title.value,
      description: desc.value,
      attribute_disablement: indicator.value.attribute_disablement,
      indicator: indicator.value,
      user_ids: selectedUsers.value,
      due_date: date.value,
      create_draft: props.createDraft,
      campaign_id: campaignId?.value,
    };

    if (props.targetId && !props.isEditingTarget) {
      await axiosService.put(`/roadmaps/targets/${props.targetId}`, toSend);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Target has been updated successfully.');
      snackbar.displaySnackBar();
    } else if (props.isEditingTarget) {
      const { data } = await axiosService.put(`/roadmaps/target_drafts/${props.targetId}`, toSend);
      let newTargetData = data;

      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Target has been updated successfully.');
      snackbar.displaySnackBar();
      emit('close', newTargetData);
    } else if (!props.createDraft) {
      await axiosService.post('/roadmaps/targets', toSend);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Target has been created successfully.');
      snackbar.displaySnackBar();
    } else {
      await axiosService.post('/roadmaps/target_drafts', toSend);
      await fetchCreatedSnapshots(employee?.value?.id, id?.value);

      snackbar.setBgColor('onSurface');
      snackbar.setMsg('New target proposal added.');
      snackbar.displaySnackBar();
      emit('close');
    }
    if (!props.overlayMode) await router.push(history.state.back);
    else emit('close');
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while creating your target !');
    snackbar.displaySnackBar();
  } finally {
    loading.value = false;
  }
};

const cardClicked = (toggle, item) => {
  toggle();
  if (isTrashed?.value) return null;
  if (!indicator?.value?.attribute_disablement?.indicator) {
    indicator.value.indicator_type = item?.type;
  }
};

const addOption = () => {
  if (!indicator?.value?.attribute_disablement?.indicator_values) {
    const obj = { ...indicator?.value };
    obj.multi_choice_list.push({ option: '', checkbox: false });
  }
};

const updateOption = (idxInput, newOption) => {
  if (indicator?.value?.multi_choice_list?.length > idxInput) {
    const obj = { ...indicator?.value };
    obj.multi_choice_list[idxInput]['option'] = newOption;
  }
};

const removeOption = (idx) => {
  if (!indicator?.value?.attribute_disablement?.indicator_values) {
    if (idx !== 0 && !isTrashed?.value) {
      const obj = { ...indicator?.value };
      obj.multi_choice_list.splice(idx, 1);
    }
  }
};

const getSelectedIndicatorDescription = () => {
  switch (indicator?.value?.indicator_type) {
    case 'boolean':
      return boxes?.value?.[0]?.longDesc;
    case 'numeric_value':
      return boxes?.value?.[1]?.longDesc;
    case 'percentage':
      return boxes?.value?.[2]?.longDesc;
    case 'multi_choice':
      return boxes?.value?.[3]?.longDesc;
  }
};

const updateDueDate = (value) => {
  date.value = moment(value).format('DD/MM/YYYY');
};

watch(title, (newValue, oldValue) => {
  if (newValue && titleError.value) {
    titleError.value = false;
  }
});
</script>

<style scoped>
.bgOpacity {
  background: rgba(62, 82, 202, 0.16);
}
</style>
<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center pt-5 px-5 md:!px-12"
    >
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Templates') + $t(` (${templatePagination?.total_count || 0})`) }} {{}}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <dialog-create-template ref="createTemplateRef">
              <template #activator="{ props }">
                <svn-pro-button
                  :text="$t('Create template')"
                  class="hidden md:flex"
                  prepend-icon="custom:mingcute:add-line"
                  v-bind="props"
                  variant="flat"
                />
              </template>
            </dialog-create-template>

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-fill"
                  v-bind="props"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <!-- Modal Deleted Items to CUSTOMIZE -->
                <modal-view-deleted-items
                  ref="deletedItemsRef"
                  :headers="trashesHeaders"
                  :items="trashInterviewForms"
                  :pagination="templateTrashesPagination"
                  content-type="template"
                  @restore="restoreDeletedItems"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyDeletedItems"
                  @view-item="viewDeletedItem"
                  @close-modal="closeModal"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      :active="false"
                      density="compact"
                      v-bind="props"
                      value="view_deleted"
                    >
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted templates') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <div class="flex items-center gap-4 flex-row">
          <!-- Search -->
          <svn-pro-text-field
            v-model="search"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            variant="outlined"
            @update:model-value="reloadAfterSearch"
          />

          <!-- Toggle Filter button -->
          <svn-pro-button
            :append="activeFilterCount ? true : false"
            :prepend-icon="'custom:mingcute:filter-2-line'"
            :text="$t('Filter')"
            :variant="activeFilterCount ? 'tonal' : 'text'"
            @click="toggleFilterVisibility"
          >
            <template v-if="activeFilterCount" #append>
              {{ `(${activeFilterCount})` }}
            </template>
          </svn-pro-button>
        </div>

        <!-- Filters -->
        <svn-filter-results
          :headers="filterHeaders"
          :items="interviewForms"
          :should-show="shouldShow"
          class="px-5 xl:!px-0"
          table-name="interview_app_forms"
          @clear-all-filters="clearAllFilters"
          @refetch-data="fetchTargetsAfterFilter"
          @update-active-filter-count="updateActiveFilterCount"
        />

        <interview-template-table :search="search" @createTemplate="createTemplate" />
      </div>
    </div>
  </div>

  <svn-pro-floating-action-button
    v-if="!isMdScreen"
    :text="$t('Create Template')"
    class="fixed bottom-4 right-4"
    extended
    prepend-icon="custom:mingcute:add-line"
    variant="primary"
    @click="createTemplate"
  />
</template>

<script setup>
import { useInterviewFormStore } from '@/store/interview-form.js';
import InterviewTemplateTable from '@/components/interviewApp/template/table.vue';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from '@/plugins/i18n';
import { useMobileStore } from '@/store/mobile.js';
import { useSnackbar } from '@/store/snackbar';
import { debounce } from 'lodash';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import SvnFilterResults from '../../../components/SvnFilterResults.vue';
import DialogCreateTemplate from '@/components/BktPopUp/Dialogs/DialogCreateTemplate.vue';

const breadcrumbsStore = useBreadcrumbsStore();
const { addNode, update } = breadcrumbsStore;

const createTemplateRef = ref(null);
const router = useRouter();

const {
  interviewForms,
  params,
  templatePagination,
  templateTrashesPagination,
  search,
  trashInterviewForms,
} = storeToRefs(useInterviewFormStore());

const {
  fetchInterviewForms,
  updateParams,
  fetchTrashInterviewForms,
  restoreInterviewForm,
  deleteInterviewFormPermanently,
} = useInterviewFormStore();

const snackbar = useSnackbar();

const mobileStore = useMobileStore();

const { isMobile, isMdScreen } = storeToRefs(mobileStore);

const { t } = useI18n();

const deletedItemsRef = ref(null);

const templateToDelete = ref(null);

const activeFilterCount = ref(null);

const shouldShow = ref(false);

const trashesHeaders = ref([
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Template title'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
  {
    align: 'start',
    key: 'delete',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);

const filterHeaders = ref([
  {
    name: 'title',
    key: 'title',
    title: 'Template title',
    icon: 'ic-baseline-title',
  },
  {
    name: 'template_type',
    key: 'template_type',
    title: 'Type',
    icon: 'ic-outline-arrow-drop-down-circle',
  },
  {
    name: 'questions_count',
    key: 'questions_count',
    title: 'Questions',
    icon: 'ic-person-outline',
  },
  {
    name: 'updated_at',
    key: 'updated_at',
    title: 'Updated at',
    icon: 'akar-icons:calendar',
  },
  {
    name: 'tags',
    key: 'tags',
    title: 'Tags',
    icon: 'ph-tag-bold',
  },
]);

const fetchTargetsAfterFilter = async (data) => {
  if (data) {
    params.value = {
      filter_data: JSON.stringify(data),
    };
  } else {
    params.value = {};
  }

  await fetchInterviewForms();
};

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value;
};

const createTemplate = () => {
  createTemplateRef.value.modalNewTarget.dialog = true;
};

const updateActiveFilterCount = (value) => {
  activeFilterCount.value = value;
};

const clearAllFilters = async () => {
  params.value = {};
  activeFilterCount.value = null;

  await fetchInterviewForms();
};

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false;
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true;

  templateToDelete.value = id;
};

onMounted(async () => {
  try {
    await fetchTrashInterviewForms();
    await fetchInterviewForms();
  } catch (error) {
    console.log(error);
  }
});

const restoreDeletedItems = async (id) => {
  try {
    await restoreInterviewForm(id);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Template has been restored successfully.');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while restoring a interview form');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async () => {
  deletedItemsRef.value.deletedItems.dialog = true;
  try {
    await deleteInterviewFormPermanently(templateToDelete.value);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Template has been deleted successfully.');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while deleting permanently a interview form');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  }
};

const viewDeletedItem = async (id) => {
  try {
    goToInterviewForm('Interview form edit', id);
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while showing deleted template');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  }
};

const goToInterviewForm = (title, interviewFormId) => {
  addNode(title, `interviews/templates/${interviewFormId}/edit`);
  update();
  router.push({ name: 'interviews_template_edit', params: { id: interviewFormId } });
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashInterviewForms(value);
  } catch (e) {
    console.error(e);
  }
}, 300);

const reloadAfterSearch = debounce((text) => {
  search.value = text;
  updateParams({
    title: text,
    'page[number]': 1,
  });
  fetchInterviewForms();
}, 300);
</script>

import { acceptHMRUpdate, defineStore } from 'pinia';
import axiosService from '@/tools/axios-service.js';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';

export const useInterviewStore = defineStore({
  id: 'interview',
  state: () => ({
    interview: null,
    isInterviewLoading: false,
    id: null,
    campaign: null,
    campaignId: null,
    date: null,
    employee: null,
    endsAt: null,
    interviewForm: null,
    interviewer: null,
    interviewKind: null,
    isFinalUpdate: false,
    isLastInterviewCompletedAndLocked: false,
    starts_at: null,
    status: null,
    label: null,
    interviewAnswers: null,
    locked: false,
    interviewTouched: false,
    employeeAnswers: [],
    targetDraftHistories: [],
    targetHistories: [],
    managerAnswers: [],
    participantFullname: null,
    participantsCount: 0,
    isEmployeeInterviewSubmitted: false,
    interviewsByUser: [],
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    clearInterview() {
      this.isInterviewLoading = false;
      this.id = null;
      this.campaign = null;
      this.campaignId = null;
      this.date = null;
      this.employee = null;
      this.endsAt = null;
      this.interviewForm = null;
      this.interviewer = null;
      this.interviewKind = null;
      this.isFinalUpdate = false;
      this.isLastInterviewCompletedAndLocked = false;
      this.isEmployeeInterviewSubmitted = false;
      this.starts_at = null;
      this.interviewTouched = false;
      this.status = null;
      this.interviewAnswers = [];
      this.targetDraftHistories = [];
      this.targetHistories = [];
      this.locked = false;
      this.employeeAnswers = [];
      this.label = null;
      this.managerAnswers = [];
      this.participantFullname = null;
      this.participantsCount = 0;
    },
    async fetchInterview(id) {
      try {
        this.clearInterview();
        this.isInterviewLoading = true;
        const interview = await this.fetchEntity(
          `/interview_app/interviews/${id}.json`,
          'interview',
        );

        this.id = interview.id;
        this.campaign = interview.campaign;
        this.campaignId = interview.campaign_id;
        this.date = interview.date;
        this.employee = interview.employee;
        this.endsAt = interview.ends_at;
        this.label = interview.label;
        this.interviewForm = interview.interview_form;
        if (this.interviewForm.questions)
          this.interviewForm.questions = this.interviewForm.questions.map((question) => ({
            ...question,
            isQuestionLoading: false,
          }));
        this.interviewer = interview.interviewer;
        this.targetDraftHistories = interview.target_draft_histories;
        this.targetHistories = interview.target_histories;
        this.interviewKind = interview.label.toLowerCase();
        this.isFinalUpdate = interview.is_final_update;
        this.isLastInterviewCompletedAndLocked = interview.is_last_interview_completed_and_locked;
        this.isEmployeeInterviewSubmitted = interview.is_employee_interview_submitted;
        this.starts_at = interview.starts_at;
        this.status = interview.status;
        this.interviewAnswers = interview.interview_app_answers;
        this.locked = interview.locked;
        if (this.isEmployeeInterviewSubmitted) {
          this.employeeAnswers = interview.employee_answers;
        }
        this.managerAnswers = interview.manager_answers;
        this.participantFullname = interview.participant_fullname;
        this.participantsCount = interview.participants_count;
      } catch (e) {
        console.log('fail while fetching interview', e);
      } finally {
        this.isInterviewLoading = false;
      }
    },
    async getInterviewsByUser(userId, level = 'as_participant', searchText = '', page = 1) {
      this.loading = true;
      this.interviewsByUser = [];

      try {
        const res = await axiosService.get(`/users/${userId}/interviews`, {
          params: {
            level: level,
            search: searchText,
            'page[number]': page,
          },
        });

        this.pagination = res.data.meta.pagination;

        if (res.data.interviews) {
          this.interviewsByUser = res.data.interviews;
        } else if (res.data.feedbacks) {
          this.interviewsByUser = res.data.feedbacks;
        } else {
          this.interviewsByUser = [];
        }

        this.loading = false;

        return this.interviewsByUser;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAdditionalInterviewsByUserId(
      userId,
      level = 'as_participant',
      searchText = '',
      page = 1,
    ) {
      const res = await axiosService.get(`/users/${userId}/interviews`, {
        params: {
          level: level,
          search: searchText,
          'page[number]': page,
        },
      });

      this.pagination = res.data.meta.pagination;
      if (res.data.interviews.length) {
        this.interviewsByUser = [...this.interviewsByUser, ...res.data.interviews];
      }
      return this.interviewsByUser;
    },
    async updateInterviewAnswer(interviewQuestionIndex, interviewQuestionId, params) {
      this.isInterviewLoading = true;
      try {
        this.interviewForm.questions[interviewQuestionIndex].isQuestionLoading = true;

        const interviewStore = useInterviewStore();
        const ret = await interviewStore.updateEntity(
          `/interview_app/interviews/${this.id}/questions/${interviewQuestionId}/answers`,
          params,
        );
        if (ret.data.created) {
          const res = await axiosService.get(`/interview_app/interviews/${this.id}/answers`);
        }
      } catch (e) {
        console.log("couldn't update");
      } finally {
        this.interviewForm.questions[interviewQuestionIndex].isQuestionLoading = false;
        this.isInterviewLoading = false;
      }
    },
    async lock() {
      try {
        this.isInterviewLoading = true;
        await axiosService.put(`/interview_app/interviews/${this.id}/lock?from_interview=true`);

        this.locked = true;
      } catch (e) {
        console.log("couldn't lock", e);
      } finally {
        this.isInterviewLoading = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInterviewStore, import.meta.hot));
}

<template>
  <div
    v-if="campaignFeedbacks"
    class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100"
  >
    <my-interview-card-header
      :campaign-deadline="campaignDeadline"
      :campaign-name="campaignTitle"
      :campaign-type="campaignType"
    />

    <template v-for="(feedback, index) in campaignFeedbacks" :key="feedback.id">
      <div class="w-full flex flex-col gap-6 items-start">
        <div class="w-full flex flex-col gap-4">
          <div class="w-full flex justify-between">
            <div class="flex gap-2 items-center">
              <svn-pro-avatar
                :firstname="feedback?.participant?.fullname.split(' ')[0]"
                :image="feedback?.participant?.avatar?.['50']"
                :size="40"
                :type="feedback?.participant?.avatar?.['50'] ? 'photo' : 'monogram'"
              />

              <div class="flex flex-col">
                <svn-pro-text class="line-clamp-1" medium subtitle-large>
                  {{ feedback?.participant?.fullname }}
                </svn-pro-text>

                <svn-pro-text body-medium class="line-clamp-1" color="onSurfaceVariant" medium>
                  {{ feedback?.participant?.job_title }}
                </svn-pro-text>
              </div>
            </div>

            <!-- Menu -->
            <svn-pro-menu>
              <template #activator>
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-line"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <!-- See participant profile -->
                <v-list-item
                  :active="false"
                  :to="`/people/user_show/${feedback?.participant?.id}?page=interviews`"
                  target="_blank"
                  v-bind="props"
                  value="see_participant_profile"
                >
                  {{ $t('See participant profile') }}
                </v-list-item>

                <!-- Send Invitation -->
                <v-list-item
                  :active="false"
                  value="send_invitation_email"
                  @click="sendInvitation(feedback?.id)"
                >
                  {{ $t('Send invitation email') }}
                </v-list-item>

                <!-- Send Reminder -->
                <v-list-item
                  :active="false"
                  value="send_reminder_email"
                  @click="sendReminder(feedback?.id)"
                >
                  {{ $t('Send reminder email') }}
                </v-list-item>

                <!-- Set person in charge -->
                <pop-up-set-another-interviewer
                  :campaign-id="campaignId"
                  :employee-id="feedback?.participant?.id"
                  @refetch-campaigns="fetchCampaign(campaignId)"
                >
                  <template #activator="{ props }">
                    <v-list-item :active="false" v-bind="props" value="set_person_incharge">
                      {{ $t('Set another person in charge') }}
                    </v-list-item>
                  </template>
                </pop-up-set-another-interviewer>
              </template>
            </svn-pro-menu>
          </div>

          <div v-if="feedback.interviews.length !== 0" class="flex flex-wrap gap-3">
            <template v-for="interview in feedback.interviews" :key="interview?.id">
              <div
                v-if="interview?.employee"
                class="flex flex-col rounded-[8px] bg-surfaceLight px-2 py-1"
              >
                <svn-pro-text body-medium medium>
                  {{ interview?.employee?.fullname }}
                </svn-pro-text>

                <interview-status-chip :status="interview?.status" />
              </div>
            </template>
          </div>

          <div class="flex flex-col gap-3 lg:!flex-row lg:!self-end">
            <!-- Consult answers -->
            <svn-pro-button
              v-if="feedback.interviews.length > 0"
              :disabled="
                feedback?.interviews?.filter((interview) => interview?.status === 'submitted')
                  ?.length === 0
              "
              :text="
                $t('Consult answers ()', {
                  answers: feedback?.interviews?.filter(
                    (interview) => interview?.status === 'submitted',
                  )?.length,
                })
              "
              :to="{ name: 'feedback_answer', params: { id: feedback?.id } }"
              variant="outlined"
            />

            <!-- Manage Reviewers -->
            <pop-up-manage-pairs
              v-if="feedback?.interviews"
              :campaign-id="campaignId"
              :feedback-id="feedback?.id"
              :feedback-interviews="feedback?.interviews"
              :participant-id="currentUserId"
            >
              <template #activator="{ props }">
                <svn-pro-button
                  :text="$t('Manage reviewers')"
                  prepend-icon="custom:mingcute:user-add-2-line"
                  v-bind="props"
                  variant="flat"
                />
              </template>
            </pop-up-manage-pairs>
          </div>
        </div>

        <v-divider
          v-if="index !== campaignFeedbacks?.length - 1"
          class="w-full border-opacity-100 border-[#767680]"
        />
      </div>
    </template>
  </div>

  <svn-pro-dialog-validation
    ref="invitationRef"
    :action-one-title="$t('Send')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('An email will be sent to the participant with a link to this interview.')"
    :max-width="480"
    :title="$t(`Participant will receive an invitation`)"
    @click-primary-button="sendInvitationEmail('invite', selectedInterviewId)"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="reminderRef"
    :action-one-title="$t('Send')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('An email will be sent to the participant with a link to this interview.')"
    :max-width="480"
    :title="$t(`Participant will receive a reminder`)"
    @click-primary-button="sendInvitationEmail('reminder', selectedInterviewId)"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user.js';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import { useCampaignStore } from '@/store/campaign.js';
import InterviewStatusChip from '../InterviewStatusChip.vue';
import PopUpManagePairs from '../../popUpComponents/PopUpManagePairs.vue';
import MyInterviewCardHeader from '@/components/interviewApp/InterviewCardHeader.vue';
import PopUpSetAnotherInterviewer from '../../popUpComponents/PopUpSetAnotherInterviewer.vue';

const props = defineProps({
  campaignId: { type: Number, required: true },
  campaignType: { type: String, required: true },
  campaignTitle: { type: String, required: true },
  campaignDeadline: { type: String, required: true },
  campaignFeedbacks: { type: undefined, required: true, default: () => [] },
});

const { isMdScreen } = storeToRefs(useMobileStore());
const { id: currentUserId } = storeToRefs(useUserStore());

const snackbar = useSnackbar();
const { fetchCampaign } = useCampaignStore();

const reminderRef = ref(null);
const invitationRef = ref(null);
const selectedInterviewId = ref(null);

const sendInvitation = (id) => {
  invitationRef.value.dialogRef.dialog = true;
  selectedInterviewId.value = id;
};

const sendReminder = () => {
  reminderRef.value.dialogRef.dialog = true;
  selectedInterviewId.value = id;
};

const sendInvitationEmail = async (emailType, feedbackId) => {
  try {
    await axiosService.get(`/interview_app/campaigns/${props.campaignId}/send_notification_email`, {
      params: {
        email_type: emailType,
        feedback_id: feedbackId,
      },
    });
    snackbar
      .setMultiLine(true)
      .setMsg(
        emailType === 'invite'
          ? 'Invitation sent. The employee will receive an email in a few moments.'
          : 'Reminder sent. The employee will receive an email in a few moments.',
      )
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setBgColor('onSurface')
      .displaySnackBar();
  } catch (error) {
    snackbar
      .setMsg('Error sending email.')
      .setCustomClass('mb-4')
      .setBgColor('onSurface')
      .displaySnackBar();
  }
};
</script>
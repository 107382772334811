<template>
  <div class="w-full mx-auto max-w-[752px] flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col md:!flex-row gap-4">
        <!-- Question -->
        <svn-pro-select
          v-model="answer"
          :items="answers_items"
          :label="$t('Question')"
          item-title="title"
          item-value="value"
          @update:model-value="
            async () => {
              loading = true;
              if (answer?.charts) {
                switch (answer.type) {
                  case InterviewQuestionType.RATING:
                    ratingData.datasets[0].data = Object.values(answer?.charts);
                    ratingData.labels = Object.keys(answer?.charts);
                    break;
                  case InterviewQuestionType.MCQ:
                    mcqData.datasets[0].data = Object.values(answer?.charts);
                    mcqData.labels = Object.keys(answer?.charts);
                    break;
                }
              }
              await fetchSurveyAnswers(
                campaign?.default_interview_form_id,
                answer?.id,
                Number.isInteger(tag) ? tag : null,
              );
              loading = false;
            }
          "
        />

        <!-- tag & tag category -->
        <div class="flex flex-col gap-4 md:!flex-row">
          <svn-pro-select
            v-model="tagCat"
            :items="tag_cats"
            :label="$t('Tag category')"
            class="w-full md:!w-[176px]"
            item-title="title"
            item-value="value"
            @update:model-value="
              () => {
                tag = 'all';
                if (tagCat === 'all') {
                  fetchSurveyAnswers(campaign?.default_interview_form_id, answer?.id);
                }
              }
            "
          />

          <svn-pro-select
            v-model="tag"
            :disabled="tagCat === 'all'"
            :items="tags[tagCat]"
            class="w-full md:!w-[176px]"
            item-title="title"
            item-value="value"
            label="Tag"
            @update:model-value="
              fetchSurveyAnswers(campaign?.default_interview_form_id, answer?.id, tag)
            "
          />
        </div>
      </div>

      <!-- Graph -->
      <div class="w-full flex flex-col rounded-[8px] border border-opacity-100 border-borderColor">
        <div class="flex flex-col px-4 py-5">
          <svn-pro-title h6 medium>
            {{ answer?.text }}
          </svn-pro-title>

          <svn-pro-text color="onSurfaceVariant" regular subtitle-medium>
            {{ answers?.length }} {{ $t(`answer${answers?.length > 1 ? 's' : ''}`) }}
          </svn-pro-text>
        </div>

        <div
          v-if="
            answer?.type === InterviewQuestionType?.RATING ||
            answer?.type === InterviewQuestionType?.MCQ
          "
          class="w-full flex flex-col items-center justify-center px-4 py-5 border-t border-opacity-100 border-borderColor"
        >
          <BktBar
            :key="answer?.id"
            :data="
              answer?.type === InterviewQuestionType?.RATING
                ? ratingData
                : answer?.type === InterviewQuestionType?.MCQ
                  ? mcqData
                  : null
            "
            :options="
              answer?.type === InterviewQuestionType?.RATING
                ? RatingOptions
                : answer?.type === InterviewQuestionType?.MCQ
                  ? McqOptions
                  : null
            "
          />
        </div>
      </div>
    </div>

    <!-- Answers list -->
    <div class="flex flex-col gap-4">
      <svn-pro-checkbox v-model="showPinned" :label="$t('Show only pinned answers')" />

      <!-- Loader -->
      <div v-if="loading" class="flex items-center justify-center py-5">
        <svn-pro-progress-circular :size="48" :width="5" bg-color="transparent" indeterminate />
      </div>

      <!-- Answers -->
      <bkt-survey-all-answers
        v-else
        :answer="answer"
        :answers="showPinned ? pinned_answers : answers"
        :interview-form-id="campaign?.default_interview_form_id"
        :is-anonymous="isAnonymous"
        :is-trashed="isTrashed"
        :show-pinned="showPinned"
        @pin="pin"
        @refetch-answers="fetchSurveyQuestionsFunction"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/tools/axios-service.js';
import { useSurveyStore } from '@/store/survey.js';
import BktBar from '@/components/chart/BktBar.vue';
import { InterviewQuestionType } from '@/constants/types';
import BktSurveyAllAnswers from '@/components/surveyApp/BktSurveyAllAnswers.vue';

const props = defineProps({
  isTrashed: { type: Boolean, default: true },
  campaign: {
    type: Object,
    default: () => {},
  },
});

const { t } = useI18n();
const route = useRoute();
const { fetchSurveyQuestions, fetchSurveyAnswers, surveyPinAnswers } = useSurveyStore();

const { questions, answers, pinned_answers } = storeToRefs(useSurveyStore());

onMounted(async () => {
  loading.value = true;
  fetchSurveyQuestionsFunction();
  await getTagCategory();
});

const tag = ref('all');
const answer = ref(null);
const tagCat = ref('all');
const loading = ref(false);
const answers_items = ref([]);
const showPinned = ref(false);
const CHART_COLORS = ref(['#3E52CA']);
const mcqData = ref({
  labels: answer?.value ? Object.keys(answer?.value?.charts) : [],
  datasets: [
    {
      backgroundColor: CHART_COLORS?.value?.[0],
      data: answer?.value ? Object.values(answer?.value?.charts) : [],
      skipNull: true,
      maxBarThickness: 7,
    },
  ],
});
const ratingData = ref({
  labels: answer?.value ? Object.keys(answer?.value?.charts) : [],
  datasets: [
    {
      backgroundColor: CHART_COLORS?.value?.[0],
      data: answer?.value ? Object.values(answer?.value?.charts) : [],
      skipNull: true,
    },
  ],
});
const McqOptions = ref({
  scaleShowValues: true,
  scales: {
    y: {
      ticks: {
        autoSkip: false,
      },
    },
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      display: false,
    },
  },
});
const RatingOptions = ref({
  legend: {
    display: false,
    title: {
      display: false,
    },
  },
  scaleShowValues: true,
  scales: {
    y: {
      ticks: {
        autoSkip: false,
      },
    },
  },
  maintainAspectRatio: false,
  indexAxis: 'x',
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      display: false,
    },
  },
});
const tag_cats = ref([
  {
    title: t('All'),
    value: 'all',
  },
]);
const tags = ref({
  all: [
    {
      title: t('All'),
      value: 'all',
    },
  ],
});
const isAnonymous = ref(true);

const fetchSurveyQuestionsFunction = async () => {
  await fetchSurveyQuestions(route?.params?.id).then(async () => {
    answers_items.value = questions?.value
      .filter((quest) => {
        return [
          InterviewQuestionType?.RATING,
          InterviewQuestionType?.MCQ,
          InterviewQuestionType?.OPEN,
        ].includes(quest?.type);
      })
      .map((question, idx) => ({
        title: idx + 1 + '. ' + question?.text,
        value: question,
      }));

    if (!answer?.value) {
      answer.value = answers_items?.value?.[0]?.value;

      if (answer?.value) {
        switch (answer.value?.type) {
          case InterviewQuestionType?.RATING:
            ratingData.value.datasets[0].data = Object.values(answer.value?.charts);
            ratingData.value.labels = Object.keys(answer.value?.charts);
            break;
          case InterviewQuestionType?.MCQ:
            mcqData.value.datasets[0].data = Object.values(answer.value?.charts);
            mcqData.value.labels = Object.keys(answer.value?.charts);
            break;
          default:
            break;
        }
      }
    }

    if (props?.campaign?.default_interview_form_id && answer?.value?.id) {
      if (tagCat?.value !== 'all') {
        await fetchSurveyAnswers(
          props?.campaign?.default_interview_form_id,
          answer?.value?.id,
          tag?.value,
        );
        loading.value = false;
      } else {
        await fetchSurveyAnswers(props?.campaign?.default_interview_form_id, answer?.value?.id);
        loading.value = false;
      }
    }

    isAnonymous.value = props?.campaign?.anonymize_answers;
  });
};

const getTagCategory = async () => {
  const res = await axios.get('/companies/tag_categories', {
    params: {
      job_title: true,
      format: 'json',
    },
  });
  if (!res.data.tag_categories) return tag_cats.value;

  res.data.tag_categories.forEach((cat) => {
    tag_cats.value = tag_cats?.value?.concat({
      title: cat?.name,
      value: cat?.id,
    });
    tags.value[cat?.id] = cat?.tags?.map((tag) => {
      return {
        title: tag?.tag_name,
        value: tag?.id,
      };
    });
  });
};

const pin = (idx, question_id, interview_form_id, interview_answer_id, pin_answer) => {
  if (showPinned?.value) {
    pinned_answers.value[idx].pinned = pin_answer;
  } else {
    answers.value[idx].pinned = pin_answer;
  }
  surveyPinAnswers(question_id, interview_form_id, interview_answer_id, pin_answer);
  fetchSurveyQuestionsFunction();
};
</script>
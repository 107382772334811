import { acceptHMRUpdate, defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';
import axiosService from '@/tools/axios-service.js';

export const useUserStore = defineStore('user', {
  state: () => ({
    inactiveUsers: null,
    user: null,
    id: null,
    firstname: null,
    fullname: null,
    lastname: null,
    interviews_submitted: null,
    modules_acquired: null,
    current_targets: null,
    email: null,
    avatar: null,
    avatars: null,
    accessLevel: null,
    users: null,
    company_id: null,
    company_tags: null,
    job_title: null,
    lang: null,
    employeeIds: [],
    tags: [],
    timezone: null,
    timezoneInfo: null,
    selectedUsers: [],
    ...genericStates,
  }),
  actions: {
    ...genericFunctions,
    setStates(user) {
      this.user = user;
      this.id = user.id;
      this.firstname = user.firstname;
      this.lastname = user.lastname;
      this.fullname = user.fullname;
      this.interviews_submitted = user.interviews_submitted;
      this.modules_acquired = user.modules_acquired;
      this.current_targets = user.current_targets;
      this.email = user.email;
      this.avatar = user.avatar;
      this.avatars = user.avatars;
      this.accessLevel = user.access_level;
      this.company_id = user.company_id;
      this.job_title = user.job_title;
      this.lang = user.lang;
      this.employeeIds = user.employee_ids;
      this.tags = user.tags;
      this.timezone = user.timezone;
      this.timezoneInfo = user.timezone_info;
    },
    async fetchUsers() {
      this.selectedUsers = [];
      this.users = null;
      const userStore = useUserStore();
      await userStore.fetchEntity('/users.json', 'users');
    },
    async fetchUsersForImport() {
      this.users = null;
      const userStore = useUserStore();
      await userStore.fetchEntity('/users.json', 'users');
    },
    async fetchInactiveUsers() {
      const userStore = useUserStore();
      await userStore.fetchEntity('/users/inactives', 'inactiveUsers', 'users');
    },
    async fetchUser() {
      const res = await axiosService.get('/users/me');

      this.setStates(res.data.user);
    },
    async updateMe(opt) {
      return this.updateEntity(`/users/${this.id}`, opt);
    },
    async restoreInactiveUser(id) {
      return this.updateEntity(`/users/${id}/restore`);
    },
    async superAdminSearchUser(value) {
      const res = await axiosService.get('/users/users_search', {
        params: { text: value, global: true, 'page[size]': 7 },
      });

      return res.data.users;
    },
    async searchEmployess(value = '', number = 1) {
      const res = await axiosService.get('/users/users_search', {
        params: {
          text: value,
          only_employees: true,
          'page[size]': 20,
          'page[number]': number,
          view: 'fullname_avatar_with_email',
        },
      });

      return res.data;
    },
    isEmployeeOrAbove() {
      return (
        this.accessLevel === 'employee' ||
        this.accessLevel === 'manager' ||
        this.accessLevel === 'manager_creator' ||
        this.accessLevel === 'admin' ||
        this.accessLevel === 'super_admin'
      );
    },
    isManagerOrAbove() {
      return (
        this.accessLevel === 'manager' ||
        this.accessLevel === 'manager_creator' ||
        this.accessLevel === 'admin' ||
        this.accessLevel === 'super_admin'
      );
    },
    isManagerCreatorOrAbove() {
      return (
        this.accessLevel === 'manager_creator' ||
        this.accessLevel === 'admin' ||
        this.accessLevel === 'super_admin'
      );
    },
    isAdminOrAbove() {
      return this.accessLevel === 'admin' || this.accessLevel === 'super_admin';
    },
    isSuperAdmin() {
      return this.accessLevel === 'super_admin';
    },
    // below access
    isManagerCreatorOrBelow() {
      return (
        this.accessLevel === 'manager_creator' ||
        this.accessLevel === 'manager' ||
        this.accessLevel === 'employee'
      );
    },
    isMe(id) {
      return id === this.id;
    },
    isAuthenticated() {
      return this.id !== null;
    },
    resetUser() {
      this.users = [];
      this.selectedUsers = [];
    },
    async getUserById(id) {
      if (!this.isMe()) {
        try {
          return await this.fetchEntity(`/users/${id}`, 'user');
        } catch (e) {
          if (e?.response?.status === 404) {
            this.router.push({ name: 'not_found' });
          }
        }
      } else {
        return this.user;
      }
    },
    amICreatorOfEntityOrAdmin(creatorId) {
      return (
        (this.accessLevel === 'manager_creator' && this.id === creatorId) || this.isAdminOrAbove()
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

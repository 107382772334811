<template>
  <svn-pro-modal
    v-model="dialogDuplicateModule"
    :width="isMdScreen ? '580' : '100%'"
    :title="$t(`Duplicate module`)"
    no-click-animation
    :actionOneTitle="$t('Duplicate')"
    :close-button-closes-modal="false"
    :primary-loading="loading"
    :sticky-bottom="isMdScreen ? true : false"
    @click-close="dialogDuplicateModule = false"
    @click-primary-button="duplidateModule"
    @click-outside="deleteDialog = false"
    @click-secondary-button="dialogDuplicateModule = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="flex flex-col gap-4">
        <svn-pro-text
          regular
          body-medium
        >
          {{ $t('Chose your title for this duplicate module. All of content will be duplicated.') }}
        </svn-pro-text>

        <!-- Duplicate title -->
        <svn-pro-text-field
          v-model="duplicateTitle"
          variant="outlined"
          color="onSurfaceVariant"
          :label="$t('Module title')"
          :counter="true"
          maxlength="70"
          :errorMessages="errorMessage"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const dialogDuplicateModule = ref(false)
const errorMessage = ref('')
const props = defineProps({
  moduleTitle: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  }
})

const duplicateTitle = ref(props.moduleTitle)

const { isMdScreen } = storeToRefs(useMobileStore())

const emit = defineEmits(['duplicate-module'])

const duplidateModule = () => {
  errorMessage.value = !duplicateTitle.value ? 'Required*' : ''
  if (errorMessage.value) return
  emit('duplicate-module', duplicateTitle.value)
}
watch(props, (newValue, oldValue) => {
  duplicateTitle.value = newValue.moduleTitle
})

watch(duplicateTitle, (newValue, oldValue) => {
  if (newValue) {
    errorMessage.value = ''
  }
})
defineExpose({
  dialogDuplicateModule
})
</script>

<style scoped>
:deep(.v-field) {
  border-radius: 8px;
}
</style>

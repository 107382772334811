<template>
  <v-infinite-scroll
    class="px-5 md:!px-0 overflow-hidden"
    :items="campaigns"
    data-testid="container_campaigns"
    @load="onLoad"
  >
    <div class="flex flex-col gap-4">
      <div v-for="(campaign, index) in campaigns" :key="index">
        <MyTeamInterviewOneToOneCard
          v-if="campaign.campaign_type === CampaignTypeBackend.ONE_TO_ONE"
          :campaign-interviews="campaign.interviews"
          :campaign-type="campaign.campaign_type"
          :campaign-deadline="campaign.deadline"
          :campaign-title="campaign.title"
          :campaign-id="campaign.id"
        />

        <MyTeamInterviewFeedbackCard
          v-if="campaign.campaign_type === CampaignTypeBackend.FEEDBACK_360"
          :campaign-feedbacks="campaign.person_in_charge_feedbacks"
          :campaign-type="campaign.campaign_type"
          :campaign-deadline="campaign.deadline"
          :campaign-title="campaign.title"
          :campaign-id="campaign.id"
        />

        <MyTeamInterviewsSurveyCard
          v-else-if="campaign.campaign_type === CampaignTypeBackend.SURVEY"
          :id="campaign.id"
          :title="campaign.title"
          :submitted-answers="campaign.submitted_interviews"
          :total-answers="campaign.total_interviews"
          :deadline="campaign.deadline"
          :campaign-type="campaign.campaign_type"
        />
      </div>
    </div>

    <template #empty>
      <div v-if="!campaigns?.length" class="h-[338px] flex flex-col justify-center items-center gap-2 px-6 py-4">
        <div>
          <Icon
            icon="noto:winking-face"
            height="32"
            width="32"
          />
        </div>

        <div class="w-full max-w-[608px] flex flex-col justify-center items-center">
          <svn-pro-title h6 medium>
            {{ $t('Nothing to show for now') }}
          </svn-pro-title>

          <p
            class="text-base font-normal leaing-6 tracking-[0.5px] text-center"
            v-html="getEmptyText(tab)"
          />
        </div>
      </div>

      <svn-pro-text v-else subtitle-medium regular class="text-center">
        {{ $t('No more interview to show.') }}
      </svn-pro-text>
    </template>

    <template #loading>
      <div class="w-full flex flex-col justify-center items-center py-4">
        <svn-pro-progress-circular
          :size="28"
          :width="4"
          indeterminate
          color="primary"
        />
      </div>
    </template>
  </v-infinite-scroll>
</template>

<script setup>
import { onMounted } from "vue";
import i18n from "@/plugins/i18n";
import { Icon } from '@iconify/vue';
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import MyTeamInterviewOneToOneCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewOneToOneCard.vue";
import MyTeamInterviewFeedbackCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewFeedbackCard.vue";
import MyTeamInterviewsSurveyCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewsSurveyCard.vue";

const props = defineProps({
  tab: { type: String, default: '' },
  campaigns: { type: Array, required: true },
});

const emits = defineEmits(["pagination"]);

onMounted(async() => {
  try {
    await searchCompanyUsers('', 10);
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar("Error fetching users.")
  }
});

const snackbar = useSnackbar();
const { searchCompanyUsers } = useCompanyStore();

const getEmptyText = () => {
  switch (props?.tab) {
    case 'all':
      return i18n.global.t('All your team\'s interviews and surveys will show up here.')
    case 'to_do':
      return i18n.global.t('Your team\'s current interviews and surveys will show up here.')
    case 'done':
      return i18n.global.t('Interviews are “Done” when all participants have submitted their answers (including Cross Review, if present).')
    case 'default':
      return ''
  }
}

const onLoad = async ({ done }) => {
  emits("pagination", done)
}
</script>
<template>
  <svn-pro-data-table
    :headers="interviewForms?.length ? headersTmp : []"
    :items="interviewForms ?? []"
    :items-per-page="-1"
    :loading="interviewForms ? loading : true"
    :page="pagination?.current_page"
    :pagination-total-pages="pagination?.total_pages"
    item-value="id"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr
        class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]"
        @click="
          $router.push({
            name: 'interviews_template_edit',
            params: { id: item?.id },
          })
        "
      >
        <td>
          <svn-pro-data-table-cell :cell-width="undefined" :text="item?.title" cell-type="text" />
        </td>

        <td>
          <svn-pro-data-table-cell
            :cell-width="undefined"
            :text="$t(interviewFormTypeToStr[item?.template_type])"
            cell-type="text"
          />
        </td>

        <!-- <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.interview_question_count"
          />
        </td> -->

        <td>
          <svn-pro-data-table-cell
            :break-all="true"
            :cell-width="undefined"
            :text="$filters.formatDate(item?.updated_at)"
            cell-type="text"
          />
        </td>

        <td>
          <svn-tags-cell
            :break-all="false"
            :tags="item?.categories?.map((cat) => cat?.title)"
            tag-style="filled"
            width-class="w-[110px]"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                color="surface"
                icon="custom:mingcute:more-2-line"
                variant="flat"
              />
            </template>

            <template #dropdown>
              <dialog-edit-template-tags
                :entity-id="item?.id"
                :template-categories="item.categories"
                fetch-tags-from-entity-path="categories_from_template"
                route="forms/templates"
                @update-interview-forms-tags="updateInterviewFormsTags"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" value="edit_tags">
                    {{ $t('Edit template tags') }}
                  </v-list-item>
                </template>
              </dialog-edit-template-tags>

              <v-list-item value="duplicate" @click="duplicate(item?.id)">
                {{ $t('Duplicate') }}
              </v-list-item>

              <svn-pro-dialog-validation
                :action-one-title="$t('Delete')"
                :action-two-title="$t('Cancel')"
                :content-text="
                  $t(
                    'Deleted templates are stored for 30 days. After this period, they will be permanently deleted.',
                  )
                "
                :max-width="560"
                :title="$t('Template will be deleted')"
                icon="noto:warning"
                @click-primary-button="deleteInterviewFormPop(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item
                    :active="false"
                    density="compact"
                    v-bind="props"
                    value="delete_template"
                  >
                    <svn-pro-text body-large color="error" regular>
                      {{ $t('Delete') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>
    <template #no-data>
      <svn-pro-empty-states
        :action-primary-title="$t('Create template')"
        :size="isMdScreen ? 'default' : 'compact'"
        :supporting-text="$t('It seems you have no template for the moment.')"
        :title="$t('Nothing to show for now')"
        :variant="search ? 'results' : 'index'"
        actions
        prepend-primary="custom:mingcute:add-line"
        @click-primary="createTemplate"
      />
    </template>

    <template #loading> </template>
  </svn-pro-data-table>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';
import { interviewFormTypeToStr } from '@/constants/types.js';
import { useInterviewFormStore } from '@/store/interview-form.js';
import DialogEditTemplateTags from '@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue';

const props = defineProps({
  search: { type: String, default: '' },
});

const emit = defineEmits(['create-template']);

const router = useRouter();
const snackbar = useSnackbar();
const { updateParams, fetchInterviewForms, deleteInterviewForm, fetchCampaignDraftsExist } =
  useInterviewFormStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { interviewForms, pagination, loading } = storeToRefs(useInterviewFormStore());

const headersTmp = [
  {
    align: 'start',
    key: 'title',
    sortable: false,
    title: i18n.global.t('Template title'),
    minWidth: 300,
  },
  {
    align: 'start',
    key: 'type',
    sortable: false,
    title: i18n.global.t('Type'),
    width: 144,
    minWidth: 144,
  },
  //  {
  //    align: "start",
  //    key: "questions",
  //    sortable: false,
  //    title: i18n.global.t('Questions'),
  //    minWidth: 100,
  //  },
  {
    align: 'start',
    key: 'last_update',
    sortable: false,
    title: i18n.global.t('Last Update'),
    width: 125,
    minWidth: 125,
  },
  {
    align: 'start',
    key: 'tags',
    sortable: false,
    title: i18n.global.t('Tags'),
    width: 130,
    minWidth: 130,
  },
  {
    align: 'start',
    key: ' ',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
];

const duplicate = async (id) => {
  try {
    const res = await axiosService.post(`/interview_app/forms/templates/${id}/duplicate`);

    await router.push({
      name: 'interviews_template_edit',
      params: { id: res.data.template_id },
    });
  } catch (e) {
    console.log('error', e);
  }
};

const createTemplate = () => {
  emit('createTemplate');
};

const deleteInterviewFormPop = async (id) => {
  try {
    const campaignDraftsExist = await fetchCampaignDraftsExist(id);

    if (campaignDraftsExist) {
      await deleteInterviewForm(id);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Template has been deleted successfully.');
      snackbar.displaySnackBar();
    } else {
      await deleteInterviewForm(id);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Template has been deleted successfully.');
      snackbar.setCustomClass(isMdScreen.value ? 'mb-0' : 'mb-[80px]');
      snackbar.displaySnackBar();
    }
  } catch (error) {
    console.log(error);
  }
};

const fetchNewPage = (e) => {
  updateParams({
    'page[number]': e,
  });
  fetchInterviewForms();
};

const updateInterviewFormsTags = (id, tag) => {
  interviewForms.value.forEach((form) => {
    if (form.id === id) {
      const formTags = form.categories;
      const formTag = formTags.find((t) => t.id === tag.id);
      if (formTag) {
        form.categories = form.categories.filter((t) => t.id !== tag.id);
        console.log(formTag);
      } else {
        form.categories.push(tag);
      }
    }
  });
};
</script>

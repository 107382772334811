export const en = {
  'Set campaign': 'Set campaign',
  'Interview between an interviewer and interviewee.': 'Interview between an interviewer and interviewee.',
  'Choose a campaign title': 'Choisir un nom de campagne',
  'Create a campaign': 'Create a campaign',
  'Your team\'s current interviews and surveys will show up here.': 'Your team\'s current interviews and surveys will show up here.',
  'Interviews are “Done” when all participants have submitted their answers (including Cross Review, if present).': 'Interviews are “Done” when all participants have submitted their answers (including Cross Review, if present).',
  'All your team\'s interviews and surveys will show up here.': 'All your team\'s interviews and surveys will show up here.',
  'All your upcoming campaigns will show up here.': 'All your upcoming campaigns will show up here.',
  'All your current campaigns will show up here.': 'All your current campaigns will show up here.',
  'Hmm, it seems you have no current templates. Create your first template!': 'Hmm, it seems you have no current templates. Create your first template!',
  'Hmm, it seems there is no target yet. Create your first target': 'Hmm, il semble qu\'il n\'y ait pas encore d\'objectif. Créez votre premier objectif',
  'Hmm, it seems you have no archived target yet.': 'Hmm, it seems you have no archived target yet.',
  'Hmm, it seems you have no current target yet.': 'Hmm, it seems you have no current target yet.',
  'Here is the list of all your targets. This target list is visible by your manager.': 'Here is the list of all your targets. This target list is visible by your manager.',
  'Training banner (Accepts only .jpg, .png and .jpeg file formats)' : 'Training banner (Accepts only .jpg, .png and .jpeg file formats)',
  'No module yet': 'No module yet',
  'Here is the list of all your trainings.': 'Here is the list of all your trainings.',
  'It seems you have no template for the moment.': 'It seems you have no template for the moment.',
  'Your completed interviews and surveys will show up here.': 'Your completed interviews and surveys will show up here.',
  'Your current interviews and surveys will show up here.': 'Your current interviews and surveys will show up here.',
  'All your interviews and surveys will show up here.' : 'All your interviews and surveys will show up here.',
  'Here is the list of all your interviews. Complete them all!': 'Here is the list of all your interviews. Complete them all!',
  'Coming soon': 'Coming soon',
  'A few clicks away from accessing all your apps ✨':
    'A few clicks away from accessing all your apps ✨',
  'Loading ...': 'Loading ...',
  Interviews: 'Interviews',
  'editor is empty': 'editor is empty',
  'unauthorized duplicate option': 'unauthorized duplicate option',
  Deadline: 'Deadline',
  'Select a manager': 'Select a manager*',
  'Welcome on board !': 'Welcome on board !',
  'Please enter your email.': 'Please enter your email.',
  Email: 'Email',
  Continue: 'Continue',
  'Connect with Google': 'Connect with Google',
  Password: 'Password',
  'Reset password': 'Reset password',
  'Fill the fields please': 'Fill the fields please',
  "This password doesn't match this email": "This password doesn't match this email",
  "Email can't be blank": "Email can't be blank",
  'Sign in': 'Sign in',
  'Oops, it seems this email is not registered 🧐':
    'Oops, it seems this email is not registered 🧐',
  'You may have miswritten your email, please try again.':
    'You may have miswritten your email, please try again.',
  'Try again': 'Try again',
  'Contact Aleph support': 'Contact Aleph Support',
  Profile: 'Profile',
  Logout: 'Logout',
  Home: 'Home',
  'My interviews': 'My Interviews',
  'My team interviews': 'My team interviews',
  'My Team Interviews': 'My Team Interviews',
  Campaigns: 'Campaigns',
  Templates: 'Templates',
  Reports: 'Reports',
  Learn: 'Learn',
  Roadmaps: 'Roadmaps',
  'My roadmaps': 'My roadmaps',
  'My team roadmaps': 'My team roadmaps',
  'My learn - coming soon': 'My learn - coming soon',
  'My team learn - coming soon': 'My team learn - coming soon',
  People: 'People',
  'Welcome on Booklet': 'Welcome on Booklet',
  Interview: 'Interview',
  'Annual reviews, onboardings, discovery reports and many more are all processed here.':
    'Annual reviews, onboardings, discovery reports and many more are all processed here.',
  'Go to Interview': 'Go to Interview',
  'Professional trainings, skill upgrades, and all kinds of contents in a single app.':
    'Professional trainings, skill upgrades, and all kinds of contents in a single app.',
  Roadmap: 'Roadmap',
  'View, create and update all your targets and team targets. Follow their completion here.':
    'View, create and update all your targets and team targets. Follow their completion here.',
  'Go to Roadmap': 'Go to Roadmap',
  'Settings': 'Settings',
  'It’s required to acquire this module.': 'It’s required to acquire this module.',
  Information: 'Information',
  'Looking for tutos or information ?\n Find it all here.':
    'Looking for tutos or information ?\n Find it all here.',
  'Go to Information': 'Go to Information',
  '🇫🇷 Big Mamma PlayBook (FR)': '🇫🇷 Big Mamma PlayBook (FR)',
  '🇬🇧 Big Mamma PlayBook (EN)': '🇬🇧 Big Mamma PlayBook (EN)',
  '📘 Tuto : How to use Booklet': '📘 Tuto : How to use Booklet',
  '💪 Values & behaviors BM': '💪 Values & behaviors BM',
  '✍️ Notation from 1 to 5': '✍️ Notation from 1 to 5',
  'BOOKLET 2.0 (all video tutorials)': 'BOOKLET 2.0 (all video tutorials)',
  'BOOKLET 2.0 (for 2022 new joiners)': 'BOOKLET 2.0 (for 2022 new joiners)',
  'BOOKLET 2.0 (for Managers to close 2021 review)':
    'BOOKLET 2.0 (for Managers to close 2021 review)',
  'BOOKLET 2.0 (for Managers to set 2022 objectives)':
    'BOOKLET 2.0 (for Managers to set 2022 objectives)',
  'Set SMART Objectives': 'Set SMART Objectives',
  Reset: 'Reset',
  Next: 'Next',
  'Export all users': 'Export all users',
  Search: 'Search',
  'Default manager': 'Default interviewer',
  'Search tags': 'Search tags',
  'Add users': 'Add users',
  'To do': 'To do',
  'Access level': 'Access level',
  Firstname: 'Firstname',
  Lastname: 'Lastname',
  Manager: 'Manager',
  'Birth date': 'Birth date',
  'Hire date': 'Hire date',
  Gender: 'Gender',
  'Gender-female': 'Female',
  'Gender-male': 'Male',
  'Gender-other': 'Other',
  'Upload photo': 'Upload photo',
  'Save changes': 'Save changes',
  Born: 'Born',
  'Interviews done': 'Interviews done',
  'Interviews todo': 'Interviews todo',
  Completed: 'Completed',
  'Contents': 'Contenus',
  "Access to 'my interviews'": "Access to 'my interviews'",
  "Access to 'my team interviews'": "Access to 'my team interviews'",
  'Can create campaigns': 'Can create campaigns',
  'Can view/edit campaigns': 'Can view/edit campaigns',
  'Only campaigns he/she created': 'Only campaigns he/she created',
  'Can create templates': 'Can create templates',
  'Can view/edit templates': 'Can view/edit templates',
  'Only templates he/she created': 'Only templates he/she created',
  'Can create reports': 'Can create reports',
  'Can view reports': 'Can view reports',
  'Only reports he/she created': 'Only reports he/she created',
  'Only for himself/herself': 'Only for himself/herself',
  'Only for himself/herself or team': 'Only for himself/herself or team',
  "Access to 'target'": "Access to 'target'",
  'Only targets he/she created': 'Only targets he/she created',
  "Access to 'employees'": "Access to 'employees'",
  'Can create targets': 'Can create targets',
  "Access to 'my roadmaps'": "Access to 'my roadmaps'",
  "Access to 'my team roadmaps'": "Access to 'my team roadmaps'",
  'View/edit his profile': 'View/edit his profile',
  'View/edit other profile': 'View/edit other profile',
  'Access to employee list': 'Access to employee list',
  'Only for his/her team': 'Only for his/her team',
  'Can create employees': 'Can create employees',
  'Can remove employees': 'Can remove employees',
  'Can export employees': 'Can export employees',
  'Can edit access level': 'Can edit access level',
  'No status to show': 'No status to show',
  'Hello,': 'Hello,',
  'Here are your interviews as interviewee.': 'Here are your interviews as interviewee.',
  'Interviews to submit': 'Interviews to submit',
  'Campaigns to submit': 'Campaigns to submit',
  'Ongoing to submit': 'Ongoing to submit',
  'View my answers': 'View my answers',
  'View Cross Review answers': 'View Cross Review answers',
  'Continue my interview': 'Continue my interview',
  'Continue self evaluation': 'Continue self evaluation',
  'Employee has not submitted interview': 'Employee has not submitted interview',
  'View employee answer': 'View employee answer',
  'Start Cross Review': 'Start Cross Review',
  'Continue Cross Review': 'Continue Cross Review',
  'All interviews submitted': 'All interviews submitted',
  '2 interviews submitted, Cross Review in progress':
    '2 interviews submitted, Cross Review in progress',
  '2 interviews submitted, Cross Review available':
    '2 interviews submitted, Cross Review available',
  '1 interview submitted': '1 interview submitted',
  '1 interview submitted and 1 interview in progress':
    '1 interview submitted and 1 interview in progress',
  '1 interview in progress': '1 interview in progress',
  '2 interviews in progress': '2 interviews in progress',
  'No interview started': 'No interview started',
  Now: 'Now',
  'Last 6 months': 'Last 6 months',
  'This year': 'This year',
  'All time': 'All time',
  during: 'during',
  Period: 'Period',
  Developer: 'Developer',
  Chapters: 'Chapters',
  'No deadline': 'No deadline',
  'Edit deadline': 'Edit deadline',
  'Choose option': 'Choose option',
  'download pdf': 'download pdf',
  'Template used': 'Template used',
  'View interviewee answers': 'View interviewee answers',
  Answers: 'Answers',
  All: 'All',
  'In progress': 'In progress',
  'In Progress': 'In Progress',
  'Not Completed': 'Not Completed',
  From: 'From',
  To: 'To',
  'Not started': 'Not started',
  Submitted: 'Submitted',
  'Not available yet': 'Not available yet',
  'Their different states are :': 'Their different states are :',
  'Up to 3 interviews can be requiered to submit :':
    'Up to 3 interviews can be requiered to submit :',
  'Interviewee (IE), Interviewer (IR) and Cross Review (CR).':
    'Interviewee (IE), Interviewer (IR) and Cross Review (CR).',
  Started: 'Started',
  'View answers': 'View answers',
  'Schedule a meeting': 'Schedule a meeting',
  'Create a whole new': 'Create a whole new',
  'Add target': 'Add target',
  'Choose a target template': 'Choose a target template',
  'Use existing template': 'Use existing template',
  'New Target': 'New Target',
  '500 internal error please contact support': '500 internal error please contact support',
  'Change password': 'Change password',
  'Confirm your new password': 'Confirm your new password',
  'Remove selected users': 'Remove selected users',
  'Are you sure you want to remove these People ?':
    'Are you sure you want to remove these People ?',
  'This is a permanent action': 'This is a permanent action',
  'Yes, delete': 'Yes, delete',
  Yes: 'Yes',
  No: 'No',
  'See interviewee profile': 'See interviewee profile',
  'Set another interviewer': 'Set another interviewer',
  'Send invitation email': 'Send invitation email',
  'Send reminder email': 'Send reminder email',
  Unlock: 'Unlock',
  Review: 'Review',
  'Edit campaign tags': 'Edit campaign tags',
  'Send email to participants': 'Send email to participants',
  Delete: 'Delete',
  'My team interview': 'My team interview',
  'Add participant': 'Add participant',
  'Send invitation to all': 'Send invitation to all',
  'Create campaign': 'Create campaign',
  'Do you wish to send invitations by email for this campaign ?':
    'Do you wish to send invitations by email for this campaign ?',
  'Send invitations emails': 'Send invitations emails',
  'No, thanks': 'No, thanks',
  'Yes, send by emails': 'Yes, send by emails',
  'Campaign dates': 'Campaign dates',
  'Campaign Participants': 'Campaign Participants',
  'Template(s) used': 'Template(s) used',
  'New report': 'New report',
  'Choose report type': 'Choose report type',
  'Global data': 'Global data',
  'Global data contains all information about interviews (name, job title, completion, deadlines ...). You can handle results as you wish.':
    'Global data contains all information about interviews (name, job title, completion, deadlines ...). You can handle results as you wish.',
  'Analytics are selected datas already analysed with ratios. Very useful to see quickly how your campaigns are progressing.':
    'Analytics are selected datas already analysed with ratios. Very useful to see quickly how your campaigns are progressing.',
  'Create report': 'Create report',
  report: 'report',
  'Saving...': 'Saving...',
  'Up to date': 'Up to date',
  Template: 'Template',
  Saved: 'Saved',
  'Create template': 'Create template',
  'What type of template you want to create ?': 'What type of template you want to create ?',
  template: 'template',
  'All roadmaps': 'All roadmaps',
  'employee target': 'employee target',
  Targets: 'Targets',
  save: 'save',
  'Target templates': 'Templates',
  'New template': 'New template',
  'Update template': 'Update template',
  'Create a template': 'Create a template',
  Title: 'Title',
  'Fill the title please': 'Fill the title please',
  Description: 'Description',
  'Type your text here': 'Type your text here',
  Indicator: 'Indicator',
  'Create your password': 'Create your password',
  "Let's start": "Let's start",
  'Create password': 'Create password',
  'Check your mail 📩': 'Check your mail 📩',
  'We just sent an email with your new password to the address:':
    'We just sent an email with your new password to the address:',
  'Use it to create a new password. If you did not receive it, check your spams, or click on the button below to send it again.':
    'Use it to create a new password. If you did not receive it, check your spams, or click on the button below to send it again.',
  'Try Again': 'Try Again',
  'Reset your password': 'Reset your password',
  'Password confirmation': 'Password confirmation',
  Menu: 'Menu',
  'Filter by tags': 'Filter by tags',
  'Tags are used to filter your index.': 'Tags are used to filter your index.',
  'No user available': 'No user available',
  'Search the participant you wish to add': 'Search the participant you wish to add',
  'Add new participant': 'Add new participant',
  'You can create, modify and delete tags to classify your items.':
    'You can create, modify and delete tags to classify your items.',
  'Each tags can be contained in a category, click on':
    'Each tags can be contained in a category, click on',
  'Create category': 'Create category',
  'to create one.': 'to create one.',
  'Click on': 'Click on',
  'on an item to manage its tags.': 'on an item to manage its tags.',
  Back: 'Back',
  Close: 'Close',
  'Move tags in category': 'Move tags in category',
  'When a tag just has been created and add to an item,':
    'When a tag just has been created and add to an item,',
  'it will appear in the category': 'it will appear in the category',
  Others: 'Others',
  'You can change its category by clicking on the tag list':
    'You can change its category by clicking on the tag list',
  'you want. Then type the tag you want to move and select it.':
    'you want. Then type the tag you want to move and select it.',
  "'s Interview with": "{interviewee}'s Interview with {interviewer}",
  'Feedback of ': 'Feedback of {interviewee}',
  Interviewer: 'Interviewer',
  Interviewee: 'Interviewee',
  Comments: 'Comments',
  Answer: 'Answer',
  'no deadline': 'no deadline',
  'New target': 'New target',
  'Start my interview': 'Start my interview',
  'No answer yet': 'No answer yet',
  'View interviewer answer': 'View interviewer answer',
  IE: 'IE',
  IR: 'IR',
  CR: 'CR',
  display: 'display',
  'Manage interviews': 'Manage interviews',
  Creator: 'Creator',
  'Created at': 'Created at',
  Type: 'Type',
  Downloads: 'Downloads',
  'Are you sure you want to delete this report ?<br/>(This is a permanent action)':
    'Are you sure you want to delete this report ?<br/>(This is a permanent action)',
  'Deleting ...': 'Deleting ...',
  'InterviewApp::Questions::Chapter': 'InterviewApp::Questions::Chapter',
  'Chapter name': 'Chapter name',
  'Multi choice': 'Multi choice',
  'Multi choice name': 'Multi choice name',
  'Answer options': 'Answer options',
  Option: 'Option',
  'Add an option': 'Add an option',
  'Allow comments': 'Allow comments',
  'Open question': 'Open question',
  'Open question name': 'Open question name',
  'InterviewApp::Questions::Rating': 'InterviewApp::Questions::Rating',
  'Max rating': 'Max rating',
  'Template tags': 'Template tags',
  'Add tags to your template in order to search through templates efficiently and create accurate reports':
    'Add tags to your template in order to search through templates efficiently and create accurate reports',
  'create the following tag': 'create the following tag',
  'Template type': 'Template type',
  'Select the template type': 'Select the template type',
  'These are people who have to answer this form during a campaign. Cross review is an additional step':
    'These are people who have to answer this form during a campaign. Cross review is an additional step',
  'Template name': 'Template name',
  'Video link (Youtube)': 'Video link (Youtube)',
  'This question is visible for': 'This question is visible for',
  'Roadmap block name': 'Roadmap block name',
  'Roadmap block': 'Roadmap block',
  'Add this block so participants could create, edit and update their targets.':
    'Add this block so participants could create, edit and update their targets.',
  'add Roadmap block': 'add Roadmap block',
  'You can add only one Roadmap bloc in your template.':
    'You can add only one Roadmap bloc in your template.',
  'It will appear at the end of your template.': 'It will appear at the end of your template.',
  'Got it': 'Got it',
  'remove Roadmap block': 'remove Roadmap block',
  'see template preview': 'see template preview',
  'Template used: Interview title': 'Template used: Interview title',
  "employee name's Interview with interviewer name":
    "employee name's Interview with interviewer name",
  'Deadline : 00/00/0000': 'Deadline : 00/00/0000',
  'Edit template tags': 'Edit template tags',
  Duplicate: 'Duplicate',
  'Template title': 'Template title',
  Questions: 'Questions',
  'Last Update': 'Last Update',
  Tags: 'Tags',
  'Are you sure you want to delete this template ?<br/>(This is a permanent action)':
    'Are you sure you want to delete this template ?<br/>(This is a permanent action)',
  Previous: 'Previous',
  'Select a tag or create new one': 'Select a tag or create new one',
  Create: 'Create',
  Save: 'Save',
  'Export users': 'Export users',
  Export: 'Export',
  'Downloading CSV file !': 'Downloading CSV file !',
  'Your export file will be available in a few moments.':
    'Your export file will be available in a few moments.',
  'Great !': 'Great !',
  'Add a new user': 'Add a new user',
  Employee: 'Employee',
  'Manager Creator': 'Manager Creator',
  Admin: 'Admin',
  'Access Level': 'Access Level',
  'Create & Send Invitation': 'Create & Send Invitation',
  'Please fill the firstname.': 'Please fill the firstname.',
  'Please fill the firstname, the lastname.': 'Please fill the firstname, the lastname.',
  'Please fill the firstname, the email.': 'Please fill the firstname, the email.',
  'Please fill the lastname.': 'Please fill the lastname.',
  'Please fill the lastname, the email.': 'Please fill the lastname, the email.',
  'Please fill the email.': 'Please fill the email.',
  'User created successfully': 'User created successfully',
  'Select another participant': 'Select another participant',
  'Choose the people you wish to add': 'Choose the people you wish to add',
  'Choose an interviewer from this campaign': 'Choose an interviewer from this campaign',
  'Choose an interviewer': 'Choose an interviewer',
  'Select a people': 'Select a people',
  'Select an interviewer': 'Select an interviewer',
  'Add people': 'Add people',
  'Select another interviewer': 'Select another interviewer',
  'Select another interviewee': 'Select another interviewee',
  Apply: 'Apply',
  to: 'to',
  'Date and time': 'Date and time',
  'Send an email to notify people and schedule meeting in Agenda':
    'Send an email to notify people and schedule meeting in Agenda',
  employee: 'employee',
  manager: 'manager',
  'manager - creator': 'manager - creator',
  administrator: 'administrator',
  'View Team Roadmap': 'View Team Roadmap',
  'View Team Roadmap of his/her manager': 'View Team Roadmap of his/her manager',
  'View Targets': 'View Targets',
  'actual value': 'actual value',
  'target value': 'target value',
  'Archive target': 'Archive target',
  'Unarchive target': 'Unarchive target',
  Completion: 'Completion',
  'target has been archived': 'target has been archived',
  'target has been unarchived': 'target has been unarchived',
  'target has been deleted': 'target has been deleted',
  'Starting value': 'Starting value',
  'Current value': 'Current value',
  'Target value': 'Target value',
  'Update answer': 'Update answer',
  'Target title': 'Target title',
  'See details': 'See details',
  'True/False': 'True/False',
  'Numeric value': 'Numeric value',
  Percentage: 'Percentage',
  'Last events': 'Last events',
  Switch: 'Switch',
  Validated: 'Validated',
  'Not validated': 'Not validated',
  'Option changed': 'Option changed',
  'View changes': 'View changes',
  'See all updates': 'See all updates',
  'Hide all updates': 'Hide all updates',
  'Due date': 'Due date',
  'Last update': 'Last update',
  'For non quantifiable objectives, you can choose the value of the two booleans.':
    'For non quantifiable objectives, you can choose the value of the two booleans.',
  'Define the two words you want to measure the objective. Word 1 will be the\ndefault value, then you could switch to word 2.':
    'Define the two words you want to measure the objective. Word 1 will be the\ndefault value, then you could switch to word 2.',
  'For quantifiable objectives, choose a value (persons, money, apples, ...), a start and target value.':
    'For quantifiable objectives, choose a value (persons, money, apples, ...), a start and target value.',
  'Define a starting and a target value to measure the objective completion.':
    'Define a starting and a target value to measure the objective completion.',
  'Useful to measure easily a quantifiable objective.':
    'Useful to measure easily a quantifiable objective.',
  'Use a percentage based value to measure the objective completion.':
    'Use a percentage based value to measure the objective completion.',
  'For non quantifiable objectives, you can add and edit choices to rate or qualify your objective.':
    'For non quantifiable objectives, you can add and edit choices to rate or qualify your objective.',
  'Add options to qualify the objective completion.':
    'Add options to qualify the objective completion.',
  'See more': 'See more',
  'Processing...': 'Processing...',
  'Hey ! It seems there is no': 'Hey ! It seems there is no',
  'to show,': 'to show,',
  'how about create your first ?': 'how about create your first ?',
  'Create my first': 'Create my first',
  'Oops, it seems no item can be found with the tags ':
    'Oops, it seems no item can be found with the tags ',
  'Oops, it seems no item can be found with the status':
    'Oops, it seems no item can be found with the status',
  'Oops, it seems no item can be found with the user categories.':
    'Oops, it seems no item can be found with the user categories.',
  'Oops, it seems no item can be found with the search':
    'Oops, it seems no item can be found with the search',
  'Please try something else.': 'Please try something else.',
  'Archived targets': 'Archived targets',
  'Current targets': 'Current targets',
  'Move an existing tag to this category': 'Move an existing tag to this category',
  "This tag doesn't exist.": 'This tag doesn’t exist.',
  'Select existing tag': 'Select existing tag',
  Rename: 'Rename',
  'Are you sure you want to delete this category ?':
    'Are you sure you want to delete this category ?',
  "Tags from this category won't be delete, they will move to the category 'Other'.":
    'Tags from this category won’t be delete, they will move to the category ‘Other’.',
  'Interviewee (IE)': 'Interviewee (IE)',
  'Interviewer (IR)': 'Interviewer (IR)',
  Both: 'Both',
  'Cross review': 'Cross review',
  'My Roadmap': 'My Roadmap',
  'My Team Roadmaps': 'My Team Roadmaps',
  'Information updated': 'Information updated',
  'This email address already exist in our database':
    'This email address already exist in our database',
  'Your password have to be at least 8 characters long.':
    'Your password have to be at least 8 characters long.',
  'You must confirm your password !': 'You must confirm your password !',
  "Passwords aren't the same !": "Passwords aren't the same!",
  Name: 'Name',
  'Job Title': 'Job Title',
  'Access Preset': 'Access Preset',
  'Choose interviewee(s) you want to participate on this campaign':
    'Choose interviewee(s) you want to participate on this campaign',
  'Selected People': 'Selected People',
  'Choose how you want to add interviewer': 'Choose how you want to add interviewer',
  Manual: 'Manual',
  Auto: 'Auto',
  'Choose participants from your people list': 'Choose participants from your people list',
  'Direct manager - Select a default interviewer': 'Direct manager - Select a default interviewer',
  'In case of interviewees not linked to a manager in the database,':
    'In case of interviewees not linked to a manager in the database,',
  'please choose a default interviewer': 'please choose a default interviewer',
  'The interviewer will handle all interviews for selected participants':
    'The interviewer will handle all interviews for selected participants',
  'Select the Interviewer': 'Select the Interviewer',
  'Direct manager': 'Direct manager',
  'Template(s)': 'Template(s)',
  'Launch!': 'Launch!',
  'Start date*': 'Start date*',
  'Selected participants : ': 'Selected participants : ',
  'Choose how you want to display template(s)': 'Choose how you want to display template(s)',
  'Some tags have no template assigned.': 'Some tags have no template assigned.',
  'If you continue, the default template will be assigned to those tags':
    'If you continue, the default template will be assigned to those tags',
  'Assign templates': 'Assign templates',
  'Select a default template': 'Select a default template',
  'Choose a specific user as the campaign sole interviewer':
    'Choose a specific user as the campaign sole interviewer',
  'Choose how you want to add participants': 'Choose how you want to add participants',
  'Single template': 'Single template',
  'Multiple templates': 'Multiple templates',
  'Select participants from your people list': 'Select participants from your people list',
  'Multiple templates - Category': 'Multiple templates - Category',
  'Choose a category to assign different templates to this campaign.':
    'Choose a category to assign different templates to this campaign.',
  'Select a category': 'Select a category',
  'Multiple templates - Assign templates': 'Multiple templates - Assign templates',
  'Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.':
    'Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.',
  'Add participants by their arrival date in the company':
    'Add participants by their arrival date in the company',
  'Please choose a category first': 'Please choose a category first',
  'Your campaign summary': 'Your campaign summary',
  'In case of interviewees not linked to a manager in the database, please choose a default interviewer':
    'In case of interviewees not linked to a manager in the database, please choose a default interviewer',
  'Apply dates for your campaign': 'Apply dates for your campaign',
  'Deadline is only informative, reviews can be done even if the deadline is outdated.':
    'Deadline is only informative, reviews can be done even if the deadline is outdated.',
  'Campaign deadline :': 'Campaign deadline :',
  'Settings': 'Settings',
  Participants: 'Participants',
  'Review & Launch': 'Review & Launch',
  'Looking for interviewer': 'Looking for interviewer',
  'Multiples templates': 'Multiples templates',
  'Are you sure you want to delete this campaign draft ?<br/>(This is a permanent action)':
    'Are you sure you want to delete this campaign draft ?<br/>(This is a permanent action)',
  'Choose a campaign name': 'Choose a campaign name',
  'Define your campaign period': 'Define your campaign period',
  'Starts on': 'Starts on:',
  'The campaign will become available from the start date.':
    'The campaign will become available from the start date.',
  'Pictures and names of participants are anonymised.':
    'Pictures and names of participants are anonymised.',
  'Ends on': 'Ends on :',
  'The end date is purely informative and the campaign will remain available thereafter.':
    'The end date is purely informative and the campaign will remain available thereafter.',
  'End date*': 'End date*',
  'Choose who will be in charge of the One to one': 'Choose who will be in charge of the One to one',
  'End date cannot be anterior to start date !': 'End date cannot be anterior to start date !',
  'Choose interviewee(s) you want to participate this campaign':
    'Choose interviewee(s) you want to participate this campaign',
  'Select users': 'Select users',
  'Users are loading...': 'Users are loading...',
  'New campaign': 'New campaign',
  Current: 'Current',
  'Unlock Cross Review (CR)': 'Unlock Cross Review (CR)',
  'Remove from campaign': 'Remove from campaign',
  'Invitation is on its way !': 'Invitation is on its way !',
  'The employee will receive an email in a few moments.':
    'The employee will receive an email in a few moments.',
  'Reminder is on its way !': 'Reminder is on its way !',
  'Are you sure you want to remove this participant ?':
    'Are you sure you want to remove this participant ?',
  'Are you sure you want to remove this participant ?<br/>(This is a permanent action)':
    'Are you sure you want to remove this participant ?<br/>(This is a permanent action)',
  'Campaign title': 'Campaign title',
  'Are you sure you want to send emails to all participants ?':
    'Are you sure you want to send emails to all participants ?',
  'All participants will receive an email about this campaign.':
    'All participants will receive an email about this campaign.',
  'Sending ...': 'Sending ...',
  'Yes, do it': 'Yes, do it',
  'The participants will receive an email in a few moments.':
    'The participants will receive an email in a few moments.',
  submit: 'submit',
  'Submit confirmation': 'Submit confirmation',
  'Are you sure to submit your answers?': 'Are you sure to submit your answers?',
  'Your answers are going to be locked!': 'Your answers are going to be locked!',
  'I accept': 'I accept',
  'Your interview has been submitted, your answers are locked.':
    'Your interview has been submitted, your answers are locked.',
  Analytics: 'Analytics',
  'Answers are all answers submited in campaigns': 'Answers are all answers submited in campaigns',
  'Choose a Category to analyze': 'Choose a Category to analyze',
  'Select tag category*': 'Select tag category*',
  'Select campaign from following period': 'Select campaign from following period',
  'Select All': 'Select All',
  'Create Report': 'Create Report',
  'look at the tags you selected': 'look at the tags you selected',
  'Only the interviewee will answer this template. The interviewer can still view the answers.':
    'Only the interviewee will answer this template. The interviewer can still view the answers.',
  'Only the interviewer will answer this template.':
    'Only the interviewer will answer this template.',
  "Both can answer this template. Only the interviewer can view the interviewee's answers.":
    "Both can answer this template. Only the interviewer can view the interviewee's answers.",
  'Interviewee + Interviewer': 'Interviewee + Interviewer',
  'Cross Review': 'Cross Review',
  'In addition of the both template, the Cross Review is a last step for a final version.':
    'In addition of the both template, the Cross Review is a last step for a final version.',
  'Here are your interviews as interviewer.': 'Here are your interviews as interviewer.',
  'Edit target': 'Edit target',
  'Create a target': 'Create a target',
  'no target value': 'no target value',
  'possible answers': 'possible answers',
  'Target informations': 'Target informations',
  'Target Indicator': 'Target Indicator',
  'Option 1 (target value)': 'Option 1 (target value)',
  'You need to set at least one employee': 'You need to set at least one employee',
  Target: 'Target',
  'Employee Roadmap': 'Employee Roadmap',
  'My team Roadmap': 'My team Roadmap',
  'Team Roadmap': 'Team Roadmap',
  'An error occurred when google gave us back your data':
    'An error occurred when google gave us back your data',
  "Couldn't connect sorry...": "Couldn't connect sorry...",
  'You have no token come back later': 'You have no token come back later',
  'Hey, password and password confirmation must be equal':
    'Hey, password and password confirmation must be equal',
  'Send it again': 'Send it again',
  'No email mode': 'No email mode',
  "UH OH! You're lost.": "UH OH! You're lost.",
  HOME: 'HOME',
  'The page you are looking for does not exist.': 'The page you are looking for does not exist.',
  'How you got here is a mystery. But you can click the button below':
    'How you got here is a mystery. But you can click the button below',
  'to go back to the homepage.': 'to go back to the homepage.',
  Language: 'Language',
  'Try to finish this step first': 'Try to finish this step first',
  Data: 'Data',
  'AS INTERVIEWEE': 'AS INTERVIEWEE',
  'Total number of interviews as interviewee done':
    'Total number of interviews as interviewee done',
  'AS INTERVIEWER': 'AS INTERVIEWER',
  'Total number of interviews as interviewer done':
    'Total number of interviews as interviewer done',
  'Interviews in progress': 'Interviews in progress',
  'Interviews not started': 'Interviews not started',
  'AS EMPLOYEE': 'AS EMPLOYEE',
  'Total number of targets as employee done': 'Total number of targets as employee done',
  'AS MANAGER': 'AS MANAGER',
  'Total number of targets as manager done': 'Total number of targets as manager done',
  'Targets completed': 'Targets completed',
  'Targets not completed': 'Targets not completed',
  'GLOBAL INFORMATIONS': 'GLOBAL INFORMATIONS',
  'create +': 'créer +',
  PASSWORD: 'PASSWORD',
  'Set another password': 'Set another password',
  'New password': 'New password',
  Cancel: 'Cancel',
  'Ignore modifications ?': 'Ignore modifications ?',
  'If you continue,': 'If you continue,',
  'you will lose them.': 'you will lose them.',
  Leave: 'Leave',
  'Keep editing': 'Keep editing',
  'This question is required for': 'This question is required for',
  'Type your campaign name here': 'Type your campaign name here',
  comments: 'comments',
  Uncompleted: 'Uncompleted',
  'Completion updated': 'Completion updated',
  'Every participants have the same template.': 'Every participants have the same template.',
  'Define dynamic templates depending on participants informations':
    'Define dynamic templates depending on participants informations',
  'You will see indicators once the target is created':
    'You will see indicator once the target is created',
  'Set your campaign': 'Set your campaign',
  'Select the participants': 'Select the participants',
  'Set your template(s)': 'Set your template(s)',
  'Launch your campaign !': 'Launch your campaign !',
  'One to One': 'One to One',
  'Interview between in interviewer and interviewee':
    'Interview between in interviewer and interviewee',
  'Feedback 360': 'Feedback 360',
  'Participants receive feedbacks from their reviewers':
    'Participants receive feedbacks from their reviewers',
  'Question between an interviewer and interviewee.':
    'Question between an interviewer and interviewee.',
  'Collect feedbacks from employees reviewers.': 'Collect feedbacks from employees reviewers.',
  'Who will answer this template?': 'Who will answer this template?',
  'Choose a campaign type': 'Choose a campaign type',
  'Choose parameters': 'Choose parameters',
  'Participants choose their reviewers': 'Participants choose their reviewers',
  'Participants can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.':
    'Participants can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.',
  'Self evaluation': 'Self evaluation',
  'Participants can answer questions too.': 'Participants can answer questions too.',
  'Participants view reviewers answers': 'Participants view reviewers answers',
  'Participants is able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'Participants is able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.',
  'Campaign will end on :': 'Campaign will end on :',
  'Campaign will start on :': 'Campaign will start on :',
  'Campaign will end on': 'Campaign will end on',
  'Campaign will start on': 'Campaign will start on',
  'Select a date': 'Select a date',
  'If requirement is enable, participants will have to answer it before submit':
    'If requirement is enable, participants will have to answer it before submit',
  'Choose participants': 'Choose participants',
  'Search for participants': 'Search for participants',
  'Choose who will be in charge of the Feedback 360':
    'Choose who will be in charge of the Feedback 360',
  'No training completed...yet!': 'No training completed...yet!',
  'Self-assessment': 'Self-assessment',
  'Sign in with Google': 'Sign in with Google',
  'Interested in discovering more about Aleph?': 'Interested in discovering more about Aleph?',
  'Face-to-face evaluation': 'Face-to-face evaluation',
  'You can describe your module using themes.': 'You can describe your module using themes.',
  'It\'s required to acquire this module.': 'It\'s required to acquire this module',
  'Explore the latest modules added to the catalog.': 'Explore the latest modules added to the catalog.',
  'Choose a default person in charge': 'Choisissez un responsable par défaut',
  'Choose who will be in charge of the Survey': 'Choose who will be in charge of the Survey',
  'Answers will be visible in the campaign in the section ‘Answers’':
    'Answers will be visible in the campaign in the section ‘Answers’',
  'All participants will have this template for the survey':
    'All participants will have this template for the survey',
  'Designate any user that will be in charge of the campaign.':
    'Designate any user that will be in charge of the campaign.',
  'Specific person': 'Specific person',
  'Direct Manager': 'Direct Manager',
  'Managers from database are in charge of the interview.':
    'Managers from database are in charge of the interview.',
  'Select any employee': 'Select any employee',
  'In case of participants are not linked to a manager in the database, please choose a default person in charge':
    'In case of participants are not linked to a manager in the database, please choose a default person in charge',
  'Choose a default manager': 'Choose a default manager',
  'Default template': 'Default template',
  'Summary of your campaign': 'Summary of your campaign',
  'Campaign Type': 'Campaign Type',
  'Launch campaign': 'Launch campaign',
  'Specific manager': 'Specific manager',
  'No pairs selected,': 'No pairs selected,',
  ' search for pairs and add them.': ' search for pairs and add them.',
  "Hmm, It seems there is no reviewers added yet, click on '+ Add reviewers' to add some.":
    "Hmm, It seems there is no reviewers added yet, click on '+ Add reviewers' to add some.",
  'Hmm, It seems there is no reviewers selected, search for reviewers and add them.':
    'Hmm, It seems there is no reviewers selected, search for reviewers and add them.',
  Add: 'Add',
  Added: 'Added',
  Remove: 'Remove',
  'Manage reviewers': 'Manage reviewers',
  not_started: 'Not started',
  in_progress: 'In progress',
  submitted: 'submitted',
  not_available_yet: 'Not available yet',
  'Not Available': 'Not Available',
  'See users profile': 'See users profile',
  'Choose reviewer': 'Choose reviewer',
  'Choose reviewers before continue.': 'Choose reviewers before continue.',
  'Consult answers': 'Consult answers',
  'Start answering': 'Start answering',
  'Manage reviewer': 'Manage reviewer',
  'Feedback of': 'Feedback of {participant}',
  'If you continue, answers will be delete.': 'If you continue, answers will be delete.',
  'This reviewer has already answered. If you remove him/her, answers will be deleted.':
    'This reviewer has already answered. If you remove him/her, answers will be deleted.',
  "See 's profile": "{reviewers}'s profile",
  'Report type affect the way datas are displayed one the file you extract':
    'Report type affect the way datas are displayed one the file you extract',
  'Global datas': 'Global datas',
  'All informations about interviews in a campaign.':
    'All informations about interviews in a campaign.',
  Analytic: 'Analytic',
  'Selected datas already analysed with ratios.': 'Selected datas already analysed with ratios.',
  'All answers submitted in campaigns.': 'All answers submitted in campaigns.',
  'Analyse completions of targets in your campaigns.':
    'Analyse completions of targets in your campaigns.',
  'Choose a category to analyse': 'Choose a category to analyse',
  'Select campaign(s)': 'Select campaign(s)',
  Generate: 'Generate',
  'Each tag of the selected category will be displayed with completion pourcentages.':
    'Each tag of the selected category will be displayed with completion pourcentages.',
  Confirm: 'Confirm',
  'One to one': 'One to one',
  'Display all users...': 'Display all users...',
  'New contents to discover !': 'New contents to discover !',
  'New contents have been added to the catalog, go take a look to it.':
    'New contents have been added to the catalog, go take a look to it.',
  'Check new content': 'Check new content',
  'Must do contents': 'Must do contents',
  'You have - more must do content, remember to complete them !':
    'You have {count} more must do content, remember to complete them !',
  'See my must do': 'See my must do',
  'Contents acquired': 'Contents acquired',
  'Contents started': 'Contents started',
  Favorites: 'Favorites',
  'Want to learn more ?': 'Want to learn more ?',
  'Find a content': 'Find a content',
  'You have no must do content to do, but there are a lot of content to discover in the catalog anyways !':
    'You have no must do content to do, but there are a lot of content to discover in the catalog anyways !',
  'What will be your next skill, User ? 😎': 'What will be your next skill, {firstname} ? 😎',
  "You have no must do for the moment, come later to see what's new !":
    'You have no must do for the moment, come later to see what’s new !',
  'You have no started contents for the moment, they will appear here when you will open a content !':
    'You have no started contents for the moment, they will appear here when you will open a content !',
  'You have no favorite contents for the moment, click on a content card to make it favorite !':
    'You have no favorite contents for the moment, click on a content card to make it favorite !',
  'Please first acquire contents and see them all here, for now, there are none.':
    'Please first acquire contents and see them all here, for now, there are none.',
  'No draft for the moments.': 'No draft for the moments.',
  'You can still access every other contents.': 'You can still access every other contents.',
  'See all contents': 'See all contents',
  'Link copied to clipboard !': 'Link copied to clipboard !',
  'Error : Link not copied to clipboard !': 'Error : Link not copied to clipboard !',
  Catalog: 'Catalog',
  'Delete content': 'Delete content',
  'Duplicate content': 'Duplicate content',
  'Search content': 'Search content',
  'Create a module': 'Create a module',
  Start: 'Start',
  'Choose an evaluation type': 'Choose an evaluation type',
  'Add a quiz': 'Add a quiz',
  'Add an approval': 'Add an approval',
  Acquired: 'Acquired',
  'Acquired this month': 'Acquired this month',
  'Must do content': 'Must do content',
  "Choose a population who will see this content in their 'Must know' section.":
    "Choose a population who will see this content in their 'Must know' section.",
  Category: 'Category',
  'Choose or create the categories this content is from.':
    'Choose or create the categories this content is from.',
  Empty: 'Empty',
  'Name your content': 'Name your content',
  'Back to catalog': 'Back to catalog',
  Titles: 'Titles',
  Text: 'Text',
  Pictures: 'Pictures',
  Videos: 'Videos',
  'Understand eveything about modules': 'Understand eveything about modules',
  'A module is a page where all informations about the content is available. Select differents elements like':
    'A module is a page where all informations about the content is available. Select differents elements like',
  titles: 'titles',
  texts: 'texts',
  pictures: 'pictures',
  videos: 'videos',
  'to build it': 'to build it',
  'You have 2 options to evaluate your content.': 'You have 2 options to evaluate your content.',
  'First option is': 'First option is',
  Approval: 'Approval',
  "Employee must answer 'Do you fully learned this content ?' by Yes or No":
    "Employee must answer 'Do you fully learned this content ?' by Yes or No",
  'Second option is a': 'Second option is a',
  Quiz: 'Quiz',
  'you created.': 'you created',
  'Content is considere as acquired if employee reach 100% of right answers':
    'Content is considere as acquired if employee reach 100% of right answers',
  'Read the module and tell us if it is acquired here :':
    'Read the module and tell us if it is acquired here :',
  'Created by': 'Created by',
  'Last update on _ at _': 'Last update on {date} at {time}',
  'You have chosen Quiz evaluation.': 'You choose quiz evaluation.',
  'Edit quiz': 'Edit quiz',
  'Change for approval': 'Change for approval',
  'You have chosen Approval evaluation.': 'You have chosen Approval evaluation.',
  'Change for Quiz': 'Change for quiz',
  'Read the module and tell us if it is acquired here:':
    'Read the module and tell us if it is acquired here:',
  'Answer this quiz to acquire this content': 'Answer this quiz to acquire this content',
  'Access the module': 'Access the module',
  '🙌 You’ve reached the end !': '🙌 You’ve reached the end !',
  'You can now evaluate yourself with a quiz on the module. Are you ready yet ?':
    'You can now evaluate yourself with a quiz on the module. Are you ready yet ?',
  'Close module': 'Close module',
  'I did not learn this module yet': 'I did not learn this module yet',
  "I didn't learned this module yet": 'I did not learn this module yet',
  'I fully understood it !': 'I fully understood it !',
  'Do you fully learned this module ?': 'Do you fully learned this module ?',
  'Tell us if you considere this content acquired, or if you need to take another look at the content. Of course you will still have access to this module.':
    'Tell us if you considere this content acquired, or if you need to take another look at the content. Of course you will still have access to this module.',
  'Of course !': 'Of course !',
  'Specific people': 'Specific people',
  'Display all managers...': 'Display all managers...',
  'Select another person in charge': 'Select another person in charge',
  Summary: 'Summary',
  'Right answer(s)': 'Right answer(s)',
  'Confirm quiz and see answers': 'Confirm quiz and see answers',
  'Missing answer': 'Missing answer',
  '👌 Here is one more acquired content': '👌 Here is one more acquired content',
  'Congratulations, you just acquire a new content, keep going like this ! It will still be accessible in your acquired contents section.':
    'Congratulations, you just acquire a new content, keep going like this ! It will still be accessible in your acquired contents section.',
  'Back to content': 'Back to content',
  "You can try this quiz as much as you want. Having all correct answers will make this content acquired for you. Let's up your right answers rate at 100% !":
    "You can try this quiz as much as you want. Having all correct answers will make this content acquired for you. Let's up your right answers rate at 100% !",
  'Maybe later': 'Maybe later',
  "Let's retry now !": "Let's retry now !",
  Publish: 'Publish',
  Module: 'Module',
  'New content': 'New content',
  'You have no must do for the moment, come later to see what’s new !':
    'You have no must do for the moment, come later to see what’s new !',
  'You can still access every other contents': 'You can still access every other contents',
  Upload: 'Upload',
  'Or drag the file here': 'Or drag the file here',
  'Sort by': 'Sort by',
  'Are you sure you want to delete this content ?':
    'Are you sure you want to delete this content ?',
  '(This is a permanent action)': '(This is a permanent action)',
  'How do I choose an evaluation for my content ?':
    'How do I choose an evaluation for my content ?',
  'Start the quiz': 'Start the quiz',
  'Enter a title': 'Enter a title',
  'Content learned !': 'Content learned !',
  'Must do': 'Must do',
  Drafts: 'Drafts',
  'Answer option': 'Answer option',
  'Content not already learned': 'Content not already learned',
  'Change my answer': 'Change my answer',
  'Partially right': 'Partially right',
  Approved: 'Approved',
  'See result': 'See result',
  'Are you sure you want to delete this tag ?': 'Are you sure you want to delete this tag ?',
  'This tag will be removed from every templates and campaigns it is associate':
    'This tag will be removed from every templates and campaigns it is associate',
  'Video URL': 'Video URL',
  'Enter a text': 'Enter a text',
  'Allow the participant to view reviewer answers':
    'Allow the participant to view reviewer answers',
  'The participant is able to view answers from his/her reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'The participant is able to view answers from his/her reviewers. Otherwise, only the person in charge of the feedback could see answers.',
  'Allow the participant to choose his/her reviewer':
    'Allow the participant to choose his/her reviewer',
  'Anonymise answers': 'Anonymise answers',
  'People viewing answers': 'People viewing answers',
  'The participant can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.':
    'The participant can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.',
  'The participant has to answer the feedback questions too.':
    'The participant has to answer the feedback questions too.',
  'Edit the module': 'Edit the module',
  'Campaign period': 'Campaign period',
  'Person in charge': 'Person in charge',
  'Default person in charge': 'Default person in charge',
  'Interviewee only': 'Interviewee only',
  'Interviewer only': 'Interviewer only',
  'Both (Interviewee + Interviewer)': 'Both (Interviewee + Interviewer)',
  'Cross Review (Both can answer + a conclusion step for final answers)':
    'Cross Review (Both can answer + a conclusion step for final answers)',
  Reviewers: 'Reviewers',
  Required: 'Required',
  'No reviewers selected': 'No reviewers selected',
  'Set another person in charge': 'Set another person in charge',
  'Choose reviewers': 'Choose reviewers',
  'Hey, please fill, default interviewer': 'Hey, please fill, default interviewer',
  'The participant are able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'The participant are able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.',
  'The participant view reviewers answers': 'The participant view reviewers answers',
  Overview: 'Overview',
  'Interview sets completion - All': 'Interview sets completion - All',
  'Feedback completion - All': 'Feedback completion - All',
  'Survey completion - All': 'Survey completion - All',
  'Quick view of your campaign completion': 'Quick view of your campaign completion',
  'Interviews completion - Individual': 'Interviews completion - Individual',
  'Feedback - Individual': 'Feedback - Individual',
  'Secondary text': 'Secondary text',
  'Interview completion - Category': 'Interview completion - Category',
  'Feedback completion - Category': 'Feedback completion - Category',
  'Compare completion by categories': 'Compare completion by categories',
  'Interview sets completion - Individual': 'Interview sets completion - Individual',
  '': '',
  'Send reminder to all': 'Send reminder to all',
  Pinned: 'Pinned',
  answer: 'answer',
  answers: 'answers',
  'Add a comment': 'Add a comment',
  You: 'You',
  'See participant profile': 'See participant profile',
  '+ Add participant': '+ Add participant',
  Survey: 'Survey',
  'Do not participate': 'Do not participate',
  'Start the survey': 'Start the survey',
  'Continue the survey': 'Continue the survey',
  'If you confirm, you will not be able to participate anymore.':
    'If you confirm, you will not be able to participate anymore.',
  'This survey will no longer appear in your ': 'This survey will no longer appear in your ',
  'section.': 'section.',
  'Yes, confirm': 'Yes, confirm',
  'Deadline : ': 'Deadline : {date}',
  See: "See {pairs}'s profile",
  'Error deleting interview !': 'Error deleting interview !',
  'No answers yet': 'No answers yet',
  'Survey completion - Category': 'Survey completion - Category',
  'No comments yet': 'No comments yet',
  'Survey - x participants':
    'Survey - 0 participant | Survey - 1 participant | Survey - {count} participants',
  'Completion of each steps of the interview': 'Completion of each steps of the interview',
  'This survey will no longer appear in your My interviews section.':
    'This survey will no longer appear in your <strong>My interviews</strong> section.',
  'Successfully added reviewer': 'Successfully added reviewer!',
  'You have no action to do on this interview.': 'You have no action to do on this interview.',
  'Here are all the interviews you are in charge of.':
    'Here are all the interviews you are in charge of.',
  'Start my feedback on': 'Start my feedback on {firstname}',
  'Continue my feedback on': 'Continue my feedback on {firstname}',
  'No reviewer for the moment': 'No reviewer for the moment',
  'Start self evaluation': 'Start self evaluation',
  Cross: 'Cross',
  Participant: 'Participant',
  'Continue my survey': 'Continue my survey',
  'Start survey': 'Start survey',
  'Hmm, it seems you have no users yet.': 'Hmm, it seems you have no users yet.',
  'Create your first user !': 'Create your first user !',
  'Hmm, it seems you have no campaigns yet.': 'Hmm, it seems you have no campaigns yet.',
  'Create your first campaign !': 'Create your first campaign !',
  'Add new feedback': 'Add new feedback',
  'Are you sure you want to delete this campaign ?':
    'Are you sure you want to delete this campaign ?',
  'Consult answers ()': 'Consult answers ({answers})',
  "View interviewee's answers": "View interviewee's answers",
  'No interview': 'No interview',
  'Interviewer (you)': 'Interviewer (you)',
  'Manage Reviewers': 'Manage Reviewers',
  'Downloading PDF...': 'Downloading PDF...',
  'Delete target': 'Delete target',
  'Are you sure you want to delete this target ?<br/>(This is a permanent action)':
    'Are you sure you want to delete this target ?<br/>(This is a permanent action)',
  'No participant found !': 'No participant found !',
  'Updates and comments': 'Updates and comments',
  'has updated value to': 'has updated value to',
  'No updates and comments yet.': 'No updates and comments yet.',
  'Not set yet': 'Not set yet',
  reviewer: 'reviewer',
  'Survey of': 'Survey of',
  'Review has been unlocked.': '{role} Review has been unlocked.',
  'Review has been submitted.': '{role} Review has been submitted.',
  'Submit Cross Review (CR)': 'Submit Cross Review (CR)',
  Submit: 'Submit',
  Done: 'Done',
  'Status updated': 'Status updated',
  'Title updated': 'Title updated',
  'Description updated': 'Description updated',
  'Your target has been updated!': 'Your target has been updated!',
  'Error! Your changes have not been saved.': 'Error! Your changes have not been saved.',
  'Your changes have not been saved!': 'Your changes have not been saved',
  Retry: 'Retry',
  'Do you want to save your changes ?': 'Do you want to save your changes ?',
  'You forgot to save your changes.': 'You forgot to save your changes.',
  Discard: 'Discard',
  'Delete campaign': 'Delete campaign',
  'Are you sure you want to delete this target ?': 'Are you sure you want to delete this target ?',
  'No, cancel': 'No, cancel',
  'Yes, delete this campaign': 'Yes, delete this campaign',
  'This is a permanent action.': 'This is a permanent action.',
  'Yes, delete this tag': 'Yes, delete this tag',
  'Yes, delete this category': 'Yes, delete this category',
  'Delete tag': 'Delete tag',
  'Yes, delete this content': 'Yes, delete this content',
  'Yes, delete this report': 'Yes, delete this report',
  'Yes, delete this template': 'Yes, delete this template',
  'Are you sure you want to delete this report ?': 'Are you sure you want to delete this report ?',
  'Are you sure you want to delete this template ?':
    'Are you sure you want to delete this template ?',
  'Slack notifications have been redirected to the channel':
    "Slack notifications have been redirected to the channel '{channelName}'",
  'submitted his answers for the interview.':
    '{ name } submitted his answers for the interview "{ campaignName }".',
  'unlocked your interview.': '{ name } unlocked your interview "{ campaignName }".',
  'You have started the interview.': 'You have started the "{ name }" interview.',
  'You have submitted the interview.': 'You have submitted the "{ name }" interview.',
  Updates: 'Updates',
  'No history for the moment.': 'No history for the moment.',
  'You have unlocked the interview.': 'You have unlocked the interview "{ name }".',
  'Survey participants': 'Survey participants',
  'Collect data to take the temperature of employees.':
    'Collect data to take the temperature of employees.',
  'InterviewApp::Questions::Paragraph': 'InterviewApp::Questions::Paragraph',
  'Search the participant you wish to add to the campaign.':
    'Search the participant you wish to add to the campaign.',
  'Error adding participant !': 'Error adding participant !',
  'Error adding participants !': 'Error adding participants !',
  'Successfully added participant !': 'Successfully added participant !',
  'Successfully added participants !': 'Successfully added participants !',
  'No user found, please try again !': 'No user found, please try again !',
  'Search the participants you wish to add to the campaign.':
    'Search the participants you wish to add to the campaign.',
  'Fill this field if you have registration number for your employee. This field is optional.':
    'Fill this field if you have registration number for your employee. This field is optional.',
  'Employee registration number': 'Employee registration number',
  "There can't be two identical options!": "There can't be two identical options!",
  'Manager team roadmaps': '{fullname} team roadmaps',
  'Your pdf is ready.': 'Your pdf is ready.',
  'Click here to download your pdf.': 'Click here to download your pdf.',
  'Your pdf is being generated.': 'Your pdf is being generated.',
  'Wait a few seconds to download it. You will get notified when file generation is complete.':
    'Wait a few seconds to download it. You will get notified when file generation is complete.',
  'An error occurred': 'An error occurred',
  'An error occurred while downloading your pdf, please try again later':
    'An error occurred while downloading your pdf, please try again later',
  'Download pdf': 'Download pdf',
  'Attention: Deleting the template will result in the deletion of all campaign drafts linked to this template.':
    'Attention: Deleting the template will result in the deletion of all campaign drafts linked to this template.',
  'Will start': 'Will start',
  Incoming: 'Incoming',
  Upcoming: 'Upcoming',
  Tomorrow: 'Tomorrow',
  Today: 'Today',
  'In day(s)': 'No day | In 1 day | In {n} days',
  'In week(s)': 'No week | In 1 week | In {n} weeks',
  'In month(s)': 'No month | In 1 month | In {n} months',
  'In over a year': 'In over a year',
  'Not launched yet': 'Not launched yet',
  'Manual selection': 'Manual selection',
  'Category...': 'Category...',
  'By category': 'By category',
  'Selected participants (all employees in the categories below) : ':
    'Selected participants (all employees in the categories below) : ',
  'Hmm, it seems you have not selected a category yet. Search for a category in the search bar above.':
    'Hmm, it seems you have not selected a category yet. Search for a category in the search bar above.',
  'All categories': 'All categories',
  'No tag matching was found.': "No tag matching '{tagName}' was found.",
  'Clear all': 'Clear all',
  'Error deleting campaign !': 'Error deleting campaign !',
  'Error relaunching campaign !': 'Error relaunching campaign !',
  'Relaunch campaign': 'Relaunch campaign',
  'Delete campaign and stop recurrence': 'Delete campaign and stop recurrence',
  'Choose your settings': 'Choose your settings',
  'Recurrent campaign': 'Recurrent campaign',
  'A recurrent campaign is a campaign which is repeated several times.':
    'A recurrent campaign is a campaign which is repeated several times.',
  'Campaign will last : ': 'Campaign will last : ',
  'Enter a duration': 'Enter a duration',
  'Duration unit': 'Duration unit',
  Days: 'Day(s)',
  Weeks: 'Week(s)',
  Months: 'Month(s)',
  Years: 'Year(s)',
  'Repeat :': 'Repeat :',
  Repeat: 'Repeat',
  'Every week': 'Every week',
  'Every month': 'Every month',
  'Every 6 months': 'Every 6 months',
  'Every year': 'Every year',
  'Select period': 'Select period',
  'The duration of the campaign.': 'The duration of the campaign.',
  'The periodicity of the campaign.': 'The periodicity of the campaign.',
  'Same day each month': 'Same day each month',
  'First day of the month': 'First day of the month',
  'Last day of the month': 'Last day of the month',
  'Decline survey': 'Decline survey',
  'Same day each 6 months': 'Same day each 6 months',
  'Same day each year': 'Same day each year',
  'Hey, please fill, starting date': 'Hey, please fill, starting date',
  'Hello,': 'Hello, {firstname}',
  'Tag to move to this category': 'Tag to move to this category',
  'New category name': 'New category name',
  'Enter a category name': 'Enter a category name',
  'View targets': 'View targets',
  'All Roadmaps': 'All Roadmaps',
  'Not yet set': 'Not yet set',
  'Here you can find your targets.': 'Here you can find your targets.',
  'Here you can find all the roadmaps you are in charge of.':
    'Here you can find all the roadmaps you are in charge of.',
  'Hmm, it seems you have no targets yet. Create your first target !':
    'Hmm, it seems you have no targets yet. Create your first target !',
  'Delete template': 'Delete template',
  'Your template has been deleted !': 'Your template has been deleted !',
  'Error deleting your template !': 'Error deleting your template !',
  'Due date : ': 'Due date : {dueDate}',
  'Last update : ': 'Last update : {lastUpdate}',
  'Update emplate': 'Update emplate',
  'Congratulations! You have reached your target ':
    'Congratulations! You have reached your target ',
  'Hmm, it seems you have no archived targets.': 'Hmm, it seems you have no archived targets.',
  'Congratulations, you have reached your target':
    'Congratulations, you have reached your target "{ targetName }."',
  'left to complete your target!': '{ value } left to complete your target!',
  'percent left to complete your target!': '{ value }% left to complete your target!',
  Informations: 'Informations',
  'Title*': 'Title*',
  '*Mandatory fields': '*Mandatory fields',
  'Are you sure you want to archive this target ?':
    'Are you sure you want to archive this target ?',
  'You will find this target in the "Archived targets" tab.':
    'You will find this target in the "Archived targets" tab.',
  'Yes, archive this target': 'Yes, archive this target',
  'Your target has been archived.': 'Your target has been archived.',
  'Are you sure you want to unarchive this target ?':
    'Are you sure you want to unarchive this target ?',
  'You will find this target in the "Current targets" tab.':
    'You will find this target in the "Current targets" tab.',
  'Yes, unarchive this target': 'Yes, unarchive this target',
  'Your target has been unarchived.': 'Your target has been unarchived.',
  'Your target has been deleted.': 'Your target has been deleted.',
  'Error while deleting your target !': 'Error while deleting your target !',
  'Error while archiving your target !': 'Error while archiving your target !',
  'Error while unarchiving your target !': 'Error while unarchiving your target !',
  'Error while searching': 'Error while searching',
  'Error while switching tab': 'Error while switching tab',
  'Error  fetching modules': ' Error fetching modules',
  'Error  fetching trainings': ' Error fetching trainings',
  'Create your own target': 'Create your own target',
  'Hmm, it seems you have no template yet.': 'It seems you have no template yet.',
  'Start from scratch': 'Start from scratch',
  'Begin with a blank slate': 'Begin with a blank slate',
  Boolean: 'Boolean',
  Number: 'Number',
  Percent: 'Percent',
  'No template used': 'No template used',
  'Multi-choice': 'Multi-choice',
  'When creating or updating a target, this field will be editable.':
    'When creating or updating a target, this field will be editable.',
  'When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.':
    'When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.',
  'Targets templates': 'Targets templates',
  'Numeric Value': 'Numeric Value',
  'Target Value': 'Target Value',
  'Current Value': 'Current Value',
  'Starting Value': 'Starting Value',
  'Edit title': 'Edit title',
  'Edit description': 'Edit description',
  'Edit due date': 'Edit due date',
  'View update': 'View update',
  'Add comment': 'Add comment',
  'Update completion': 'Update completion',
  'Edit Title': 'Edit title',
  'Edit Description': 'Edit Description',
  'Edit Due date': 'Edit due date',
  'New Title': 'New Title',
  'New Description': 'New Description',
  'The current answer does not correspond to the target value.':
    'The current answer does not correspond to the target value.',
  'Target completed!': 'Target completed!',
  'Target achieved on': 'Target achieved on',
  'You have not replied yet. Start answering by clicking on "Update answer".':
    'You have not replied yet. Start answering by clicking on "Update answer".',
  'No recent events yet.': 'No recent events yet',
  'Duration number should be greater than 0': 'Duration should be greater than 0',
  'You have submitted answers to the interview.':
    'You have submitted answers to the interview "{ name }".',
  'Manage co-pilots': 'Manage co-pilots',
  'Manage Co-pilots': 'Manage co-pilots',
  'Copilot added successfully': 'Copilot added successfully',
  'Error while adding copilot !': 'Error while adding copilot !',
  'Copilot removed successfully': 'Copilot removed successfully',
  'Error while removing copilot !': 'Error while removing copilot !',
  'Error getting copilots list !': 'Error getting copilots list !',
  'The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.':
    'The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.',
  'Hmm, It seems there is no co-pilots selected, search for co-pilots and add them.':
    'Hmm, It seems there is no co-pilots selected, search for co-pilots and add them.',
  'Error getting Manager creators list !': 'Error getting Manager creators list !',
  'When do you want to launch this campaign ?': 'When do you want to launch this campaign ?',
  'The campaign start date is later than the current date.':
    'The campaign start date is later than the current date.',
  'Launch now': 'Launch now',
  'Launch on': 'Launch on {date}',
  'Send invitation emails': 'Send invitation emails',
  'Do you wish to send invitations by email for this campaign ? They will be sent now.':
    'Do you wish to send invitations by email for this campaign ? They will be sent now.',
  'Yes, send emails': 'Yes, send emails',
  'Do you wish to send invitations by email for this campaign ? They will be sent on':
    'Do you wish to send invitations by email for this campaign ? They will be sent on {date}',
  'Select a day between 1st and 28th to be able to select the monthly repetition':
    'Select a day between 1st and 28th to be able to select the monthly repetition',
  'Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.':
    'Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.',
  "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.":
    "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.",
  'Roadmap - Update target': 'Roadmap - Update target',
  'Your suggestion could not be created ! Please try again':
    'Your suggestion could not be created ! Please try again',
  'Roadmap - Create target': 'Roadmap - Create target',
  'Roadmap - Update Target(s)': 'Roadmap - Update Target(s)',
  'Roadmap - Create Target(s)': 'Roadmap - Create Target(s)',
  'Your target has been created successfully.': 'Your target has been created successfully.',
  'Your target has been updated successfully.': 'Your target has been updated successfully.',
  'Error while creating your target !': 'Error while creating your target !',
  Ongoing: 'En cours',
  true: 'Yes',
  false: 'No',
  'We just sent an email to reset your password to the address':
    'We just sent an email to reset your password to the address',
  "You are currently logged in company's workspace":
    "You are currently logged in {company}'s workspace",
  'Need any help from Aleph ?': 'Need any help from Aleph ?',
  'Contact our team !': 'Contact our team !',
  'No due date': 'No due date',
  'No more history !': 'No more history !',
  Updated: 'Updated',
  Created: 'Created',
  'If you confirm, all target creations and updates will be applied. You can follow up this in Roadmap app.':
    'If you confirm, all target creations and updates will be applied. You can follow up this in Roadmap app.',
  'These targets will be updated and/or created': 'These targets will be updated and/or created',
  'No suggestion to show for the moment.': 'No suggestion to show for the moment.',
  'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.':
    'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.',
  'This answer will be applied to the target when you submit this interview.':
    'This answer will be applied to the target when you submit this interview.',
  'Suggested updates': 'Suggested updates',
  'Suggested value': 'Suggested value',
  'Suggested value :': 'Suggested value : { value }',
  'Target info': 'Target info',
  'Final update': 'Final update',
  'Answers will be locked': 'Answers will be locked',
  'Submit this interview will lock your answers. Ask to an admin to unlock it if needed.':
    'Submit this interview will lock your answers. Ask to an admin to unlock it if needed.',
  'Error fetching targets !': 'Error fetching targets !',
  'Error fetching target draft': 'Error fetching target draft',
  'Error updating suggestion !': 'Error updating suggestion !',
  'This target is still a draft. Complete all interviews of the set to create it.':
    'This target is still a draft. Complete all interviews of the set to create it.',
  'Campaign recurrences will end on :': 'Campaign recurrences will end on :',
  'At :': 'At :',
  'The recurrent campaign will start at the time defined below.':
    'The recurrent campaign will start at the time defined below.',
  Zone: 'Zone',
  'Edit starting date': 'Edit starting date',
  'Manage recurrence': 'Manage recurrence',
  'Duration :': 'Duration :',
  Duration: 'Duration',
  'How long the campaign will last.': 'How long the campaign will last.',
  'Will launch at :': 'Will launch at :',
  'Campaign settings': 'Campaign settings',
  'Stop repetition on :': 'Stop repetition on :',
  'Recurrence settings': 'Recurrence settings',
  Time: 'Time',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  '1st': '1st',
  '2nd': '2nd',
  '3rd': '3rd',
  '4th': '4th',
  '5th': '5th',
  '6th': '6th',
  '7th': '7th',
  '8th': '8th',
  '9th': '9th',
  '10th': '10th',
  '11th': '11th',
  '12th': '12th',
  '13th': '13th',
  '14th': '14th',
  '15th': '15th',
  '16th': '16th',
  '17th': '17th',
  '18th': '18th',
  '19th': '19th',
  '20th': '20th',
  '21th': '21th',
  '22th': '22th',
  '23th': '23th',
  '24th': '24th',
  '25th': '25th',
  '26th': '26th',
  '27th': '27th',
  '28th': '28th',
  '29th': '29th',
  '30th': '30th',
  '31th': '31th',
  'January - July': 'January - July',
  'February - August': 'February - August',
  'March - September': 'March - September',
  'April - October': 'April - October',
  'May - November': 'May - November',
  'June - December': 'June - December',
  'July - January': 'July - January',
  'August - February': 'August - February',
  'September - March': 'September - March',
  'October - April': 'October - April',
  'November - May': 'November - May',
  'December - June': 'December - June',
  every_week: 'week',
  every_month: 'month',
  every_six_month: '6 months',
  every_year: 'year',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
  'Campaigns will be launched at ': 'Campaigns will be launched at ',
  ' They will last ': ' They will last ',
  ', repeating each ': ', repeating each ',
  ' on the ': ' on the ',
  '. They will end on the ': '. They will end on the ',
  'Your changes have not been saved !': 'Your changes have not been saved !',
  'Your changes have been saved !': 'Your changes have been saved !',
  'Quit without saving': 'Quit without saving',
  'Your changes will be lost. Save changes before closing ?':
    'Your changes will be lost. Save changes before closing ?',
  'Save changes before closing ?': 'Save changes before closing ?',
  'Do you want to save you changes ?': 'Do you want to save you changes ?',
  'Go back': 'Go back',
  'user commented': '{user} commented',
  "user's roadmaps": "{user}'s My Roadmap",
  'Comment added - ': 'Comment added - ',
  'It seems that we cannot update the list. Reload the page or contact the support':
    'It seems that we cannot update the list. Reload the page or contact the support',
  'The interviewer has not been updated. Please try again.':
    'The interviewer has not been updated. Please try again.',
  'No manager': 'No manager',
  'Final value': 'Final value',
  'Final value :': 'Final value : { value }',
  'This interview is already submitted, so you cannot add any new targets for the moment.':
    'This interview is already submitted, so you cannot add any new targets for the moment.',
  'Could not update suggestion ! Please try again.':
    'Could not update suggestion ! Please try again.',
  'See all': 'See all',
  'See less': 'See less',
  'No description': 'No description',
  'Target not achieved yet': 'Target not achieved yet',
  'Indicator type': 'Indicator type',
  'Select all': 'Select all',
  'Enlightened Novice': 'Enlightened Novice',
  'Knowledge Explorer': 'Knowledge Explorer',
  'Thirsty for Knowledge': 'Thirsty for Knowledge',
  'Curriculum Master': 'Curriculum Master',
  'The Approver': 'The Approver',
  'Positive Supporter': 'Positive Supporter',
  "L'Évaluateur Éclairé": "L'Évaluateur Éclairé",
  'Master of Emotions': 'Master of Emotions',
  'Accomplished Apprentice': 'Accomplished Apprentice',
  'Trained and Competent': 'Trained and Competent',
  'Certified Expert': 'Certified Expert',
  'Training Champion': 'Training Champion',
  'The Selector': 'The Selector',
  'Content Lover': 'Content Lover',
  'Informed Collector': 'Informed Collector',
  'Resource Guardian': 'Resource Guardian',
  Badges: 'Badges',
  'Acquire a module': 'Acquire a module',
  'React to a module': 'React to a module',
  'Complete a training': 'Complete a training',
  'Favorite a module': 'Favorite a module',
  Trainings: 'Trainings',
  'Not yet': 'Not yet',
  'Create training': 'Create training',
  'New training': 'New training',
  Launched: 'Launched',
  'Hmm, it seems you have no training yet. Create your first training !':
    'Hmm, it seems you have no training yet. Create your first training !',
  'Hmm, it seems you have no upcoming training.': 'Hmm, it seems you have no upcoming training.',
  'Launch date': 'Launch date',
  Edit: 'Edit',
  'Edit training': 'Edit training',
  'Delete training': 'Delete training',
  'Are you sure you want to delete this training ?':
    'Are you sure you want to delete this training ?',
  'Participants : ': 'Participants : {participants}',
  "user's trainings": 'Trainings of {user}',
  'My Trainings': 'My Trainings',
  Welcome: 'Welcome { firstname }',
  'Trainings completed': '{ contentAquiredCount } Trainings completed',
  'Modules acquired': '{ modulesAquiredCount } Modules acquired',
  'You have more must do trainings, remember to complete them !':
    'You have no training to do | You have 1 training to do, remember to complete it! | You have {count} trainings to do, remember to complete them!',
  'Add this module to playlist ()': 'Add this module to playlist ({ selectedPlaylists })',
  'Add this modules to playlist ()': 'Add this modules to playlist ({ selectedPlaylists })',
  'New module': 'New module',
  'Module Title': 'Module title*',
  'Want to learn ? Take a look at our contents!': 'Want to learn ? Take a look at our contents!',
  Playlists: 'Playlists',
  Modules: 'Modules',
  modules: 'modules',
  'New playlist': 'New playlist',
  'Select module to add ()': 'Select module to add ({selectedModules})',
  'Select modules to add ()': 'Select modules to add ({selectedModules})',
  'Select playlist to add ()': 'Select playlist to add ({selectedPlaylists})',
  'Select playlists to add ()': 'Select playlists to add ({selectedPlaylists})',
  'Comments ()': 'Comments ({count})',
  'Estimated duration': 'Estimated duration',
  'No theme': 'No theme',
  Evaluation: 'Evaluation',
  'Evaluate this module with a quiz': 'Evaluate this module with a quiz',
  "Don't forget to take the quiz!": "Don't forget to take the quiz!",
  'To complete this module, evaluate yourself with a quiz.':
    'To complete this module, evaluate yourself with a quiz.',
  "Yes, it's all clear to me": "Yes, it's all clear to me",
  "Yes, it's all clear to me!": "Yes, it's all clear to me!",
  'I will do it later': 'I will do it later',
  'Start the quiz now': 'Start the quiz now',
  'No, I will come back later': 'No, I will come back later',
  'Did you learn all the following learning objectives?':
    'Did you learn all the following learning objectives?',
  'To mark this module as “acquired”, you must pass the assessment quiz.':
    'To mark this module as “acquired”, you must pass the assessment quiz.',
  Themes: 'Themes',
  'An approval is required to acquire this module if you do not chosoe quiz evaluation.The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”':
    'An approval is required to acquire this module if you do not choose quiz evaluation. The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”',
  'Duplicate module': 'Duplicate module',
  'Module duplicated.': 'Module duplicated.',
  'Delete module': 'Delete module',
  'Save as draft': 'Save as draft',
  'Items per page': 'Items per page',
  'Module title': 'Module title*',
  'Value': 'Valeur',
  'Unity' : 'Unity',
  'Start date': 'Start date',
  'Select a theme from the list': 'Select a theme from the list',
  'Enter a theme': 'Enter a theme',
  'Theme removed from module': 'Theme removed from module',
  'Theme added to module': 'Theme added to module',
  "🙌 You've reached the end! Did you learn all the following learning objectives?":
    "🙌 You've reached the end! Did you learn all the following learning objectives?",
  'Leave a comment': 'Leave a comment',
  'Hmmm, it seems there is no comment yet. Be the first !':
    'Hmmm, it seems there is no comment yet. Be the first !',
  'Next module': 'Next module',
  'Go to previous module': 'Go to previous module',
  'Your feedback helps us improve this module.': 'Your feedback helps us improve this module.',
  'Hmm, it seems there is no comment yet. Be the first !':
    'Hmm, it seems there is no comment yet. Be the first !',
  'This module has no more things to teach you. We hope you have learnt the best from it ! To acquire this module, evaluate yourself with a quiz. Are you ready yet ?':
    'This module has no more things to teach you. We hope you have learnt the best from it ! To acquire this module, evaluate yourself with a quiz. Are you ready yet ?',
  'No duration': 'No duration',
  '🙌 You’ve reached the end! Ready for the quiz?':
    '🙌 You’ve reached the end! Ready for the quiz?',
  'What did you think of the module ?': 'What did you think of the module ?',
  'I did not understand this module': 'I did not understand this module',
  'I have a question about...': 'I have a question about...',
  'I have a remark': 'I have a remark',
  'Do you really want to leave ?': 'Do you really want to leave ?',
  'If you quit now, your reaction will not be taken into account and your draft will be deleted.':
    'If you quit now, your reaction will not be taken into account and your draft will be deleted.',
  'My Learnings': 'My Learnings',
  'New modules to discover!': 'New modules to discover',
  'New modules have been added to the catalog, go take a look to it.':
    'New modules have been added to the catalog, go take a look to it.',
  "You don't have any training to do yet. Come back later to see your trainings !":
    "You don't have any training to do yet. Come back later to see your trainings !",
  "You haven't completed any training yet.": "You haven't completed any training yet.",
  Validate: 'Validate',
  'Increasing publication date': 'Increasing publication date',
  'Decreasing publication date': 'Decreasing publication date',
  'Increasing acquisition date': 'Increasing acquisition date',
  'Decreasing acquisition date': 'Decreasing acquisition date',
  'There are no modules. Create your first modules !':
    'There are no modules. Create your first modules !',
  'There are no modules.': 'There are no modules.',
  'There are no playlists. Create your first playlist !':
    'There are no playlists. Create your first playlist !',
  'There are no playlists.': 'There are no playlists.',
  'Add module to playlist': 'Add module to playlist',
  'Create playlist': 'Create playlist',
  'Create new playlist': 'Create new playlist',
  'Playlist title': 'Playlist title*',
  Draft: 'Draft',
  Content: 'Content',
  'No content in the training for the moment.': 'No content in the training for the moment.',
  'Are you sure you want to send reminder ?': 'Are you sure you want to send reminder ?',
  'Yes, send reminder': 'Yes, send reminder',
  'Anyone who has not finished the training will receive an e-mail reminder.':
    'Anyone who has not finished the training will receive an e-mail reminder.',
  'Yes, delete this training': 'Yes, delete this training',
  'No data available yet as training has not yet begun.':
    'No data available yet as training has not yet begun.',
  'Number of people completing the training': 'Number of people completing the training',
  'Training completion - All': 'Training completion - All',
  'Quick view of your training completion': 'Quick view of your training completion',
  'Training completion - Category': 'Training completion - Category',
  'Training completion - Module': 'Training completion - Module',
  'Compare completion by module': 'Compare completion by module',
  'Compare completion by category': 'Compare completion by category',
  'Participants list': 'Participants list',
  'Last module acquired on': 'Last module acquired on',
  'Training acquired on': 'Training acquired on',
  Trained: 'Trained',
  Update: 'Update',
  'This module has just been updated !': 'This module has just been updated !',
  'The lastest version of this content will be visible if you reload this page.':
    'The lastest version of this content will be visible if you reload this page.',
  'Reload this page': 'Reload this page',
  'Chose your title :': 'Chose your title :',
  'Copy of content': 'Copy of content',
  'Are you sure you want to delete this module ?': 'Are you sure you want to delete this module ?',
  'Yes, delete this module': 'Yes, delete this module',
  "Add content to the editor using the '+' button":
    "Add content to the editor using the '+' button",
  'No modules in the playlist, add some with the button below':
    'No modules in the playlist, add some with the button below',
  'Add modules': 'Add modules',
  'Update playlist title & description': 'Update playlist title & description',
  'Link copied to clipboard.': 'Link copied to clipboard.',
  'Module added to favorites': 'Module added to favorites',
  'Module removed from favorites': 'Module removed from favorites',
  'Playlist added to favorites': 'Playlist added to favorites',
  'Playlist removed from favorites': 'Playlist removed from favorites',
  'Edit playlist': 'Edit playlist',
  'Delete playlist': 'Delete playlist',
  'Are you sure you want to delete this playlist ?':
    'Are you sure you want to delete this playlist ?',
  'Yes, delete this playlist': 'Yes, delete this playlist',
  'No theme to display': 'No theme to display',
  'Add a question block': 'Add a question block',
  'Learners needs to answer right to each question to acquire the module':
    'Learners need to answer right to each question to acquire the module',
  'Add a free content block': 'Add a free content block',
  'Use this block to put titles, paragraphs, pictures and more in your quiz':
    'Use this block to put titles, paragraphs, pictures and more in your quiz',
  'Add answer option': 'Add answer option',
  Date: 'Date',
  'No module or playlist in training for the moment, add some with the buttons below':
    'No module or playlist in training for the moment, add some with the buttons below',
  'Add playlists': 'Add playlists',
  'Training title': 'Training title',
  'Create your first piece of content by modifying the title, description and image. You can also add an estimated duration, objectives and one or more themes just below.':
    'Create your first piece of content by modifying the title, description and image. You can also add an estimated duration, objectives and one or more themes just below.',
  'Update training': 'Update training',
  'Search for participants...': 'Search for participants...',
  'Launch training': 'Launch training',
  'Edit module': 'Edit module',
  'Content added': 'Content added',
  'Remove from training': 'Remove from training',
  'Are you sure you want to leave ?': 'Are you sure you want to leave ?',
  'Yes, leave': 'Yes, leave',
  'By leaving your changes will be lost.': 'By leaving your changes will be lost.',
  'I need more time': 'I need more time',
  "There's a problem with the content": "There's a problem with the content",
  "What's wrong with this module?": "What's wrong with this module?",
  'Congratulations, name, keep it up!': 'Congratulations, {name}, keep it up!',
  'One more module acquired !👏': 'One more module acquired !👏',
  Send: 'Send',
  'Tell us about the issue you have encounter': 'Tell us about the issue you have encounter',
  'Update acquisition date': 'Update acquisition date',
  'Module successfully added to playlist.': 'Module successfully added to playlist.',
  'Quit without publishing': 'Quit without publishing',
  'By saving changes, your updates will automatically be published.':
    'By saving changes, your updates will automatically be published.',
  'Your module has not been saved': 'Your module has not been saved',
  module: 'module',
  'Publish updates': 'Publish updates',
  'Do you want to publish updates ?': 'Do you want to publish updates ?',
  'Your module has been deleted': 'Your module has been deleted',
  'Are you sure you want to publish ?': 'Are you sure you want to publish this module ?',
  'Your module will be added to the Catalog and will be visible to everyone.':
    'Your module will be added to the Catalog and will be visible to everyone.',
  'Yes, publish': 'Yes, publish',
  'Module published!': 'Module published!',
  'Send invitation emails ?': 'Send invitation emails ?',
  'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.':
    'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.',
  'Yes, send them': 'Yes, send them',
  'Training saved successfully !': 'Training saved successfully !',
  'Your changes have been saved and your training has been launched':
    'Your changes have been saved and your training has been launched',
  'Error updating the playlist': 'Error updating the playlist',
  'Remove module from playlist': 'Remove module from playlist',
  'Your question is now visible in the comment section.':
    'Your question is now visible in the comment section.',
  'Your remark is now visible in the comment section.':
    'Your remark is now visible in the comment section.',
  'I have a remark about...': 'I have a remark about...',
  'Delete comment': 'Delete comment',
  'Module author': 'Module author',
  'See comments': 'See comments',
  'Comment deleted.': 'Comment deleted.',
  'Error adding comment': 'Error adding comment',
  'Error deleting comment.': 'Error deleting comment.',
  'By deleting it, your reaction will also be deleted.':
    'By deleting it, your reaction will also be deleted.',
  'Are you sure you want to delete this comment ?':
    'Are you sure you want to delete this comment ?',
  'Yes, delete this comment': 'Yes, delete this comment',
  'Are you sure you want to remove this reaction ?':
    'Are you sure you want to remove this reaction ?',
  'Yes, remove reaction': 'Yes, remove reaction',
  'By deleting it, the associated comment will also be deleted.':
    'By deleting it, the associated comment will also be deleted.',
  'Ask your question below': 'Ask your question below',
  'Make a remark below': 'Make a remark below',
  'I like': 'I like',
  'I recommend': 'I recommend',
  'I have a question': 'I have a question',
  'Start the quiz': 'Start the quiz',
  'Validate answers': 'Validate answers',
  'View quiz results': 'View quiz results',
  'Acquired on': 'Acquired on',
  'What happened ?': 'What happened ?',
  'Keep going ! Check your mistakes and try again 💪':
    'Keep going ! Check your mistakes and try again 💪',
  'Only right answers ! Congratulations, 👏':
    'Only right answers ! Congratulations, { firstname } 👏',
  '👌 Here is one more module acquired': '👌 Here is one more module acquired',
  '💪 Would you like to try again ?': '💪 Would you like to try again ?',
  'Back to module': 'Back to module',
  'Back to login': 'Back to login',
  'Please type and confirm your new password.': 'Please type and confirm your new password.',
  acquired: 'acquired',
  'Congratulations, you just acquired a new module, keep going like this ! It has now been added to your':
    'Congratulations, you just acquired a new module, keep going like this ! It has now been added to your',
  'You can try this quiz as much as you want. Having all correct answers will make this module':
    'You can try this quiz as much as you want. Having all correct answers will make this module',
  "for you. Let's up your right answers rate to 100% !":
    "for you. Let's up your right answers rate to 100% !",
  'Retry quiz': 'Retry quiz',
  'Resume the quiz !': 'Resume the quiz !',
  'Right answer': 'Right answer',
  'Partially right answer': 'Partially right answer',
  'Wrong answer': 'Wrong answer',
  'Error sending message.': 'Error sending message.',
  'View my badges': 'View my badges',
  'Message sent successfully.': 'Message sent successfully.',
  'Hmm, it seems we can’t find any results based on your search.':
    'Hmm, it seems we can’t find any results based on your search.',
  'Retake quiz': 'Retake quiz',
  'Go back to the module': 'Go back to the module',
  'Back to My Learnings': 'Back to My Learnings',
  'Deleted Modules': 'Deleted Modules',
  'Deleted Playlists': 'Deleted Playlists',
  'Deleted Trainings': 'Deleted Trainings',
  'Deleted Templates': 'Deleted Templates',
  'Deleted modules are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted modules are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted playlists are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted playlists are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted trainings are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted trainings are stored here for 30 days. After this period, they will be permanently deleted.',
  'Embed link': 'Embed link',
  Unsplash: 'Unsplash',
  'Upload a file': 'Upload a file',
  'Paste the image link here': 'Paste the image link here',
  'Embed image': 'Embed image',
  'Search for an image': 'Search for an image',
  'Drag & drop an image or': 'Drag & drop an image or',
  'choose file': 'choose file ',
  'to upload': 'to upload',
  By: 'By',
  'Module image changed successfully': 'Module image changed successfully',
  'Training image changed successfully': 'Training image changed successfully',
  'Error uploading training image': 'Error uploading training image',
  'No playlists to add for the moment': 'No playlists to add for the moment',
  'Module successfully added to playlists.': 'Module successfully added to playlists.',
  'Error fetching playlists': 'Error fetching playlists',
  'Delete permanently': 'Delete permanently',
  'Restore module': 'Restore module',
  'Deletion date': 'Deletion date',
  'Do you really want to delete this module permanently ?':
    'Do you really want to delete this module permanently ?',
  'Do you really want to delete this template permanently ?':
    'Do you really want to delete this template permanently ?',
  'Do you really want to delete this playlist permanently ?':
    'Do you really want to delete this playlist permanently ?',
  'Do you really want to delete this training permanently ?':
    'Do you really want to delete this training permanently ?',
  'Yes, delete it': 'Yes, delete it',
  'View deleted trainings': 'View deleted trainings',
  'View deleted playlists': 'View deleted playlists',
  'View deleted modules': 'View deleted modules',
  'View deleted templates': 'View deleted templates',
  'Add this module to playlists': 'Add this module to playlists',
  'There are no deleted items for the moment.': 'There are no deleted items for the moment.',
  Homepage: 'Homepage',
  'View deleted modules drafts': 'View deleted modules drafts',
  draft: 'draft',
  'Restore playlist': 'Restore playlist',
  'Restore interview form': 'Restore interview form',
  'Playlist restored successfully': 'Playlist restored successfully',
  'Interview form restored successfully': 'Interview form restored successfully',
  Restore: 'Restore',
  'This playlist has been deleted': 'This playlist has been deleted',
  'Deleted Drafts': 'Deleted Drafts',
  'Deleted drafts are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted drafts are stored here for 30 days. After this period, they will be permanently deleted.',
  'Go to learn': 'Go to learn',
  'Your training has been deleted.': 'Your training has been deleted.',
  'Error fetching categories': 'Error fetching categories',
  'Back to the module': 'Back to the module',
  'Restore draft': 'Restore draft',
  'Do you really want to delete this draft permanently ?':
    'Do you really want to delete this draft permanently ?',
  'Archive selected targets': 'Archive selected targets ({ selected })',
  'Delete selected targets': 'Delete selected targets ({ selected })',
  'Are you sure you want to archive this/these target(s) ?':
    'Are you sure you want to archive this/these target(s) ?',
  "You will find this/these target(s) in the 'Archived targets' tab.":
    "You will find this/these target(s) in the 'Archived targets' tab.",
  'Yes, archive': 'Yes, archive',
  'Yes, delete this target': 'Yes, delete this target',
  'Yes, delete these targets': 'Yes, delete these targets',
  'Your target(s) has/have been deleted.': 'Your target(s) has/have been deleted.',
  'Your target(s) has/have been archieved successfully.':
    'Your target(s) has/have been archieved successfully.',
  'Clear selected date': 'Clear selected date',
  'Custom and accessible progress for life.': 'Custom and accessible progress for life.',
  or: 'or',
  'Please enter your password.': 'Please enter your password.',
  'Forgot my password': 'Forgot my password',
  'Email not found': 'Email not found',
  'Log in': 'Log in',
  'Wrong password': 'Wrong password',
  'Please type and confirm your password.': 'Please type and confirm your password.',
  'Password confirmation': 'Password confirmation',
  "Passwords fields aren't the same": "Passwords fields aren't the same",
  'Error setting event': 'Error setting event',
  first_day: 'first day of the month',
  same_day: 'same day each month',
  last_day: 'last day of the month',
  'Training restored successfully': 'Training restored successfully',
  'Restore training': 'Restore training',
  'The date when the training is launched.': 'The date when the training is launched.',
  'Acquisition date has been updated': 'Acquisition date has been updated.',
  'Error updating acquisition date': 'Error updating acquisition date',
  'Training(s) containing only this module :': 'Training(s) containing only this module :',
  'Manager & Creator': 'Manager & Creator',
  'Employee & Creator': 'Employee & Creator',
  'No name available': 'No name available',
  Role: 'Role',
  Learnings: 'Learnings',
  'Hmm, it seems you have no training yet': 'Hmm, it seems you have no training yet',
  'Hmm, it seems you have no module yet': 'Hmm, it seems you have no module yet',
  'Hmm, it seems you have no target yet': 'Hmm, it seems you have no target yet',
  'As participant': 'As participant',
  'As reviewer': 'As reviewer',
  'Your password has been reset': 'Your password has been reset',
  'As person in charge': 'As person in charge',
  Progress: 'Progress',
  Campaign: 'Campaign',
  'Hmm, it seems you have no campaigns as participant':
    'Hmm, it seems you have no campaign as participant',
  'Hmm, it seems you have no campaigns as reviewer':
    'Hmm, it seems you have no campaign as reviewer',
  'Hmm, it seems you have no campaigns as person in charge':
    'Hmm, it seems you have no campaign as person in charge',
  'Review of': 'Review of',
  'In charge of': 'In charge of',
  'Birth date': 'Birth date',
  'Hire date': 'Hire date',
  'job title': 'job title',
  'All employees in the categories below': 'All employees in the categories below',
  Departement: 'Department',
  Langue: 'Language',
  Établissement: 'Institution',
  Pays: 'Country',
  'Drop your file here': 'Drop your file here',
  'Please choose another file. Only .jpeg, .jpg, or .png file formats are supported.':
    'Please choose another file. Only .jpeg, .jpg, or .png file formats are supported.',
  Company: 'Company',
  Personnalization: 'Personnalization',
  Labels: 'Labels',
  'Global informations': 'Global informations',
  'Name of your company': 'Name of your company',
  'Shared Informations': 'Shared Informations',
  'Add information': 'Add information',
  Link: 'Link',
  'Shared information has been deleted.': 'Shared information has been deleted.',
  'Add category': 'Add category',
  'Are you sure you want to remove this shared information ?':
    'Are you sure you want to remove this shared information ?',
  'This information will no longer be accessible for employees.':
    'This information will no longer be accessible for employees.',
  'Yes, remove': 'Yes, remove',
  Logos: 'Logos',
  Photos: 'Photos',
  'Homepage logo': 'Homepage logo',
  'Navbar logo': 'Navbar logo',
  'Drag and drop an image here or': 'Drag and drop an image here or',
  'Choose photo': 'Choose photo',
  'My Team Interviews': 'My Team Interviews',
  'Home Page': 'Home Page',
  'Import users': 'Import users',
  '*Required fields': '*Required fields',
  'Create and send invitation': 'Create and send invitation',
  'Please fill the firstname, the lastname, the email.':
    'Please fill the firstname, the lastname, the email.',
  'The user has been successfully created and has received an invitation e-mail.':
    'The user has been successfully created and has received an invitation e-mail.',
  'The user has been successfully created.': 'The user has been successfully created.',
  'Hmm, it seems there are no employees in your company yet. Add your first employees!':
    'Hmm, it seems there are no employees in your company yet. Add your first employees!',
  'Are you sure you want to remove these users from your company ?':
    'Are you sure you want to remove these users from your company ?',
  'The selected user(s) has/have been removed from company.':
    'The selected user(s) has/have been removed from company.',
  'Drag & drop or': 'Drag & drop or',
  'chose file': 'chose file',
  'to upload': 'to upload',
  'Only .xlsx format is accepted': 'Only .xlsx format is accepted',
  "You don't have a file ?": "You don't have a file ?",
  'Download and complete the template provided.': 'Download and complete the template provided.',
  "You can use the 'Help' tab in the file to fill in the template.":
    "You can use the 'Help' tab in the file to fill in the template.",
  'Download template': 'Download template',
  'Inspecting file...': 'Inspecting file...',
  'File ready for import': 'File ready for import',
  'No errors detected.': 'No errors detected.',
  Modifications: 'Modifications',
  'Send invitation emails to all new users': 'Send invitation emails to all new users',
  'Apply changes and import file': 'Apply changes and import file',
  'Are you sure you want to leave': 'Are you sure you want to leave ?',
  'The import operation will be abandonned and you will have to start it over.':
    'The import operation will be abandonned and you will have to start it over.',
  'Yes, leave': 'Yes, leave',
  'Importing...': 'Importing...',
  'Import completed !': 'Import completed !',
  Finish: 'Finish',
  'Drop your file here !': 'Drop your file here !',
  ' imported': ' imported',
  Users: 'Users',
  'Tag categories': 'Tag categories',
  created: 'created',
  updated: 'updated',
  deleted: 'deleted',
  'Add single user': 'Add single user',
  'Error during import': 'Error during import',
  'An error occured during import. Please try again.':
    'An error occured during import. Please try again.',
  'Your file contains errors': 'Your file contains errors',
  'Please correct the errors below and load the file again.':
    'Please correct the errors below and load the file again.',
  'Upload another file': 'Upload another file',
  'Tag category': 'Tag category',
  Tag: 'Tag',
  'Are you sure you want to delete this label ?': 'Are you sure you want to delete this label ?',
  'Your Tag has been deleted.': 'Your Tag has been deleted.',
  'Your Tag category has been deleted.': 'Your Tag category has been deleted.',
  'Error while deleting shared information.': 'Error while deleting shared information.',
  'Shared information has been saved.': 'Shared information has been saved.',
  'Change photo': 'Change photo',
  'Image updated successfully.': 'Image updated successfully.',
  'Error updating image.': 'Error updating image.',
  'Tag Category name has been saved.': 'Tag Category name has been saved.',
  'Tag Category has been created.': 'Tag Category has been created.',
  'In early 2024, Booklet will become Aleph.': 'In early 2024, Booklet will become Aleph.',
  'Know more': 'Know more',
  'Booklet will become Aleph': 'Booklet will become Aleph',
  "What's new ?": "What's new ?",
  'Booklet will become Aleph! The new branding identity includes a refreshed logo, color scheme, and overall aesthetic to convey this new direction and vision. Your login credentials will remain the same, and you will be able to access Aleph with the same username and password you used for Booklet.':
    'Booklet will become Aleph! The new branding identity includes a refreshed logo, color scheme, and overall aesthetic to convey this new direction and vision. Your login credentials will remain the same, and you will be able to access Aleph with the same username and password you used for Booklet.',
  'Why Aleph ?': 'Why Aleph ?',
  "Aleph is a reference to the first letter of the alphabet, and reflects the company's goal to provide a tool that offers customized and accessible progress for life.":
    "Aleph is a reference to the first letter of the alphabet, and reflects the company's goal to provide a tool that offers customized and accessible progress for life.",
  'Will my data still be accessible ?': 'Will my data still be accessible ?',
  'Yes, all information, data, and action previously done on Booklet will still be available on Aleph. Your data will still remain safe and secure with Aleph. This rebranding does not affect our commitment to protect your data with the highest standards of security and privacy.':
    'Yes, all information, data, and action previously done on Booklet will still be available on Aleph. Your data will still remain safe and secure with Aleph. This rebranding does not affect our commitment to protect your data with the highest standards of security and privacy.',
  'Please note that emails will be adressed by Aleph Team':
    'Please note that emails will be adressed by Aleph Team',
  'early 2024': 'early 2024',
  'Error, the selected file format is not correct. Accepted formats are: jpg, jpeg and png.':
    'Error, the selected file format is not correct. Accepted formats are: jpg, jpeg and png.',
  'Error updating Company name.': 'Error updating Company name.',
  'Company name updated.': 'The Company name has been updated.',
  'New tag': 'New tag',
  'This tag already exists in this category': 'This tag already exists in this category.',
  'The tag name cannot be empty.': 'The tag name cannot be empty.',
  'Error creating Tag category.': 'Error creating Tag category.',
  'Error deleting Tag category': 'Error deleting Tag category',
  'Error updating Tag name.': 'Error updating Tag name.',
  'Error updating category name.': 'Error updating category name.',
  'Error creating new Tag.': 'Error creating new Tag.',
  'Error deleting Tag.': 'Error deleting Tag.',
  'Your profile photo has been updated': 'Your profile photo has been updated',
  'Your profile photo has been removed': 'Your profile photo has been removed',
  'Edit profile photo': 'Edit profile photo',
  'Delete current photo': 'Delete current photo',
  'Import photo': 'Import photo',
  'To submit the form, all required fields must be completed.':
    'To submit the form, all required fields must be completed.',
  'Tag category': 'Tag category',
  'Do you really want to delete this target permanently ?':
    'Do you really want to delete this target permanently ?',
  'Do you really want to delete this campaign permanently ?':
    'Do you really want to delete this campaign permanently ?',
  'Do you really want to delete this report permanently ?':
    'Do you really want to delete this report permanently ?',
  'Do you really want to delete this template permanently ?':
    'Do you really want to delete this template permanently ?',
  'Happy Monday, !': 'Happy Monday, {firstname}!',
  'Hello , have a great Tuesday!': 'Hello {firstname}, have a great Tuesday!',
  'Hey, . Happy Wednesday!': 'Hey, {firstname}. Happy Wednesday!',
  'Greetings, . Enjoy your Thursday!': 'Greetings, {firstname}. Enjoy your Thursday!',
  "Hello , it's Friday!": "Hello {firstname}, it's Friday!",
  'Happy Saturday, !': 'Happy Saturday, {firstname}!',
  'Good day, . Have a peaceful Sunday!': 'Good day, {firstname}. Have a peaceful Sunday!',
  'This question requires an answer.': 'This question requires an answer.',
  'Error updating user data': 'Error updating user data',
  'This module is in trash.': 'This module is in trash.',
  'This playlist is in trash.': 'This playlist is in trash.',
  'This module draft is in trash.': 'This module draft is in trash.',
  'This training is in trash.': 'This training is in trash.',
  'This template is in trash.': 'This template is in trash.',
  'Module deleted successfully': 'Module deleted successfully',
  'Module draft deleted successfully': 'Module draft deleted successfully',
  'Playlist deleted successfully': 'Playlist deleted successfully',
  'Training deleted successfully': 'Training deleted successfully',
  'Interview form deleted successfully': 'Interview form deleted successfully',
  'Module restored successfully, module has been moved to draft':
    'Module restored successfully, module has been moved to draft',
  'Error while showing deleted training': 'Error while showing deleted training',
  'Error while showing deleted module': 'Error while showing deleted module',
  'Error while showing deleted playlist': 'Error while showing deleted playlist',
  'Error while showing deleted interview form': 'Error while showing deleted interview form',
  'Error while deleting permanently a training': 'Error while deleting permanently a training',
  'Error while deleting permanently a playlist': 'Error while deleting permanently a playlist',
  'Error while deleting permanently a module': 'Error while deleting permanently a module',
  'Error while deleting permanently a interview form':
    'Error while deleting permanently a interview form',
  'Error while restoring a training': 'Error while restoring a training',
  'Error while restoring a playlist': 'Error while restoring a playlist',
  'Error while restoring a module': 'Error while restoring a module',
  'Error while restoring a interview form': 'Error while restoring a interview form',
  'Do you really want to delete this template permanently':
    'Do you really want to delete this template permanently',
  'Souhaitez-vous vraiment supprimer ce modèle de manière définitive ?':
    'Do you want to permanently delete this interview form',
  'Restore campaign': 'Restore campaign',
  'Restore report': 'Restore report',
  'Restore target': 'Restore target',
  'Restore template': 'Restore template',
  'Target restored successfully': 'Target restored successfully',
  'Target deleted successfully': 'Target deleted successfully',
  'Report restored successfully': 'Report restored successfully',
  'Template restored successfully': 'Template restored successfully',
  'Report successfully permanently deleted': 'Rapport supprimé définitivement avec succès',
  'Template deleted successfully': 'Template deleted successfully',
  'View deleted campaigns': 'View deleted campaigns',
  'View deleted template': 'View deleted template',
  'View deleted reports': 'View deleted reports',
  'Deleted Campaigns': 'Deleted Campaigns',
  'Deleted Targets': 'Deleted Targets',
  'Deleted Templates': 'Deleted Templates',
  'Deleted Reports': 'Deleted Reports',
  'Deleted targets are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted targets are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted reports are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted reports are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted campaigns are stored here for 30 days. After this period, they will be permanently deleted.':
    'Deleted campaigns are stored here for 30 days. After this period, they will be permanently deleted.',
  'Deleted campaigns are stored for 30 days. After this period, they will be permanently deleted.':
    'Deleted campaigns are stored for 30 days. After this period, they will be permanently deleted.',
  'You must restore the report to download it': 'You must restore the report to download it',
  'Error while restoring this template': 'Error while restoring this template',
  'Template restored successfully': 'Template restored successfully',
  'Error while deleting permanently a template': 'Error while deleting permanently a template',
  'Error while restoring this target': 'Error while restoring this target',
  'Error while deleting permanently a target': 'Error while deleting permanently a target',
  'Campaign restored successfully': 'Campaign restored successfully',
  'Error while restoring a campaign': 'Error while restoring a campaign',
  'Campaign has been permanently deleted': 'Campaign has been permanently deleted',
  'Error while deleting permanently a campaign': 'Error while deleting permanently a campaign',
  'This campaign is in trash.': 'This campaign is in trash.',
  'This template is in trash.': 'This template is in trash.',
  'This target is in trash.': 'This target is in trash.',
  'View deleted targets': 'View deleted targets',
  'Hmm, it seems you have no deleted trainings.': 'Hmm, it seems you have no deleted trainings.',
  'Hmm, it seems you have no deleted modules.': 'Hmm, it seems you have no deleted modules.',
  'Hmm, it seems you have no deleted playlists.': 'Hmm, it seems you have no deleted playlists.',
  'Hmm, it seems you have no deleted reports.': 'Hmm, it seems you have no deleted reports.',
  'Hmm, it seems you have no deleted templates.': 'Hmm, it seems you have no deleted templates.',
  'Hmm, it seems you have no deleted campaigns.': 'Hmm, it seems you have no deleted campaigns.',
  'Hmm, it seems you have no deleted targets.': 'Hmm, it seems you have no deleted targets.',
  "Hmm, it seems we can't find any results based on your search.":
    "Hmm, it seems we can't find any results based on your search.",
  'Link expired': 'Link expired',
  'Date & Time': 'Date & Time',
  'Time Zone detected : ': 'Time Zone detected : ',
  'Time Zone: ': 'Time Zone: ',
  'Add to favorites': 'Add to favorites',
  'Copy link': 'Copy link',
  'Module duplicated.': 'Module duplicated.',
  'This module has been deleted': 'This module has been deleted',
  'This field is required': 'Ce champ est requis',
  'The email is not valid': 'The email is not valid',
  'This email is already taken': 'This email is already taken',
  'Please fill the firstname, the lastname and the email':
    'Please fill the firstname, the lastname and the email',
  'Please fill the firstname and the email': 'Please fill the firstname and the email',
  'Please fill the lastname and the email': 'Please fill the lastname and the email',
  'Please fill the email': 'Please fill the email',
  'No new content added during import.': 'No new content added during import.',
  'On line': 'On line ',
  'Add filter': 'Add filter',
  Filters: 'Filters',
  contains: 'contains',
  'Restore campaign': 'Restore campaign',
  'One to One': 'One to One',
  Feedback: 'Feedback',
  'View deactivated users': 'View deactivated users',
  'Deactivated users': 'Deactivated users',
  'Deactivated users data still remains on Aleph, but these accounts are no longer accessible by their owner. Their email address can not be used for another account.':
    'Deactivated users data still remains on Aleph, but these accounts are no longer accessible by their owner. Their email address can not be used for another account.',
  'User has been reactivated successfully.': 'User has been reactivated successfully.',
  'Reactivate user': 'Reactivate user',
  'Hmm, it seems you have no inactive users yet.': 'Hmm, it seems you have no inactive users yet.',
  'Deactivate selected users': 'Deactivate selected users',
  'Campaign type': 'Campaign type',
  is: 'is',
  contains: 'contains',
  'This email is not recognized. Please try again.':
    'This email is not recognized. Please try again.',
  'Target description': 'Target description',
  'Impossible to import': 'Impossible to import',
  'Your file format is not accepted. Only .xlsx format is accepted.':
    'Your file format is not accepted. Only .xlsx format is accepted.',
  'Back to People': 'Back to People',
  'An approval is required to acquire this module if you do not chose quiz evaluation.The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”':
    'An approval is required to acquire this module if you do not chose quiz evaluation.The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”',
  'Select one or many managers': 'Select one or many managers',
  'Your suggested update': 'Your suggested update',
  Comment: 'Comment',
  'Add participants': 'Add participants',
  'Select a template': 'Select a template*',
  'Hmm, it seems there are no employees in your company yet.':
    'Hmm, it seems there are no employees in your company yet.',
  'Add your first employees!': 'Add your first employees!',
  'Remove participant': 'Remove participant',
  'Request a demo': 'Request a demo',
  "Would you like to learn about Aleph's features and how it can benefit your company? Our team is ready to show you with a demo!":
    "Would you like to learn about Aleph's features and how it can benefit your company? Our team is ready to show you with a demo!",
  Firstname: 'Firstname',
  Lastname: 'Lastname',
  'Email*': 'Email*',
  Send: 'Send',
  'Your message*': 'Your message*',
  'Recommend Aleph': 'Recommend Aleph',
  'Do you want to recommend Aleph to someone? Please fill out this form, our team will contact the interested person very soon!':
    'Do you want to recommend Aleph to someone? Please fill out this form, our team will contact the interested person very soon!',
  'Wrong email format !': 'Wrong email format !',
  'Add user': 'Add user',
  'This module has no more things to teach you. We hope you have learnt the best from it !':
    'This module has no more things to teach you. We hope you have learnt the best from it !',
  'assessment by': 'assessment by',
  'started at': 'started at',
  on: 'on',
  'validated answers at': 'validated answers at',
  '🙌 You’ve reached the end! Please notify your expert before starting the evaluation.':
    '🙌 You’ve reached the end! Please notify your expert before starting the evaluation.',
  '🙌 You’ve reached the end! Validate your evaluation to complete this module.':
    '🙌 You’ve reached the end! Validate your evaluation to complete this module.',
  '✅ Evaluation done, you have acquired this module!':
    '✅ Evaluation done, you have acquired this module!',
  'To successfully complete this module, your answers must be reviewed in real time by your expert.':
    'To successfully complete this module, your answers must be reviewed in real time by your expert.',
  'This module has been acquired, but you can still restart the evaluation.':
    'This module has been acquired, but you can still restart the evaluation.',
  'Start evaluation': 'Start evaluation',
  'Continue evaluation': 'Continue evaluation',
  'Restart evaluation': 'Restart evaluation',
  'Face-to-face evaluation': 'Face-to-face evaluation',
  'Select your expert': 'Select your expert',
  'Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.':
    'Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.',
  'My expert': 'My expert',
  'One more module acquired 👏': 'One more module acquired 👏',
  'Validate answers': 'Validate answers',
  'Congratulations, keep it up! Your answers have been sent to':
    'Congratulations, {user}, keep it up! Your answers have been sent to {expertFirstname} {expertLastname}.',
  'Go to catalog': 'Go to catalog',
  'Please fill all the required (*) fields.': 'Please fill all the required (*) fields.',
  'Question title': 'Question title',
  'Add an open question block': 'Add an open question block',
  'Learners can answer your question in an open field':
    'Learners can answer your question in an open field',
  "The learner's answers will be evaluated in real time by an expert, ensuring accurate assessment and personalized feedback.":
    "The learner's answers will be evaluated in real time by an expert, ensuring accurate assessment and personalized feedback.",
  'The learner must pass the quiz with a score of 100% correct answers to successfully complete this section.':
    'The learner must pass the quiz with a score of 100% correct answers to successfully complete this section.',
  'The learner must confirm they have fully understood the module before proceeding.':
    'The learner must confirm they have fully understood the module before proceeding.',
  'Evaluation type': 'Evaluation type',
  'An error occurred, please try again': 'An error occurred, please try again',
  'Error uploading training image, file size exceeding 10Mb.':
    'Error uploading training image, file size exceeding 10Mb.',
  'Training updated successfully.': 'Training updated successfully.',
  'Training created successfully.': 'Training created successfully.',
  "Don't forget the face-to-face evaluation!": "Don't forget the face-to-face evaluation!",
  'Start evaluation now': 'Start evaluation now',
  'To mark this module as “acquired”, you must pass the face-to-face evaluation.':
    'To mark this module as “acquired”, you must pass the face-to-face evaluation.',
  'Evaluation type must be set.': 'Evaluation type must be set.',
  'Target completed': 'Target completed',
  'Current target': 'Current target',
  'Training completed': 'Training completed',
  'Module acquired': 'Module acquired',
  'Shared information': 'Shared information',
  'Help & support': 'Help & support',
  'If you have any questions or require assistance, please feel free to reach out to the Aleph team by filling out this form. We will treat and respond to your message very soon!':
    'If you have any questions or require assistance, please feel free to reach out to the Aleph team by filling out this form. We will treat and respond to your message very soon!',
  'Your issue*': 'Your issue*',
  'Please fill out all the required(*) fields.': 'Please fill out all the required(*) fields.',
  'Your form has been sent. Thank you!': 'Your form has been sent. Thank you!',
  'Error sending form, please try again.': 'Error sending form, please try again.',
  interviews_submitted_count: '{ count } Interviews submitted',
  interviews_submitted_no_count: '{ count } Interview submitted',
  modules_acquired_count: '{ count } Modules acquired',
  modules_acquired_no_count: '{ count } Module acquired',
  current_targets_count: '{ count } Current Targets',
  current_targets_no_count: '{ count } Current Target',
  'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.':
    'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.',
  Customization: 'Customization',
  'Company name': 'Company name',
  'Shared Information': 'Shared Information',
  'Links you put as SHARED INFORMATION will show up on the homepage and be accessible to all users. Use it to share guidelines or global information to your employees.':
    'Links you put as SHARED INFORMATION will show up on the homepage and be accessible to all users. Use it to share guidelines or global information to your employees.',
  'COMPANY LOGO': 'COMPANY LOGO',
  BANNER: 'BANNER',
  'Enhance the identity of your workspace by uploading your company logo in  Aleph. Make your mark in the navbar, creating a branded and  professional look for the tool.':
    'Enhance the identity of your workspace by uploading your company logo in  Aleph. Make your mark in the navbar, creating a branded and  professional look for the tool.',
  'My company logo': 'My company logo',
  'Only *.jpg, *.png and *.jpeg file formats are supported. The newly uploaded image will replace the current logo.':
    'Only *.jpg, *.png and *.jpeg file formats are supported. The newly uploaded image will replace the current logo.',
  'Elevate collaboration and visual communication with our new Banner  Upload feature in Aleph. Share event highlights, announcements, and team  achievements through eye-catching banners visible to all employees.  Simply upload and engage with your colleagues in this dynamic space.':
    'Elevate collaboration and visual communication with our new Banner  Upload feature in Aleph. Share event highlights, announcements, and team  achievements through eye-catching banners visible to all employees.  Simply upload and engage with your colleagues in this dynamic space.',
  'Homepage banner': 'Homepage banner',
  'My Interviews banner': 'My Interviews banner',
  'My Team Interviews banner': 'My Team Interviews banner',
  'Users list': 'Users list',
  Categories: 'Categories',
  'CATEGORIES AND TAGS': 'CATEGORIES AND TAGS',
  'Edit your categories and tags Aleph to fit your needs by easily editing employee data. Keep things organized and up-to-date with a few simple clicks.':
    'Edit your categories and tags Aleph to fit your needs by easily editing employee data. Keep things organized and up-to-date with a few simple clicks.',
  'Show all': 'Show all',
  'Show less': 'Show less',
  'Your modifications will be lost.': 'Your modifications will be lost.',
  'If you leave without save, your modifications will be lost.':
    'If you leave without save, your modifications will be lost.',
  'Save and quit': 'Save and quit',
  'Personal information': 'Personal information',
  'Hr information': 'Hr information',
  'Date & time': 'Date & time',
  'Your settings have been updated': 'Your settings have been updated',
  'First name': 'First name',
  'Last name': 'Last name',
  'Deleted reports are stored for 30 days. After this period, they will be permanently deleted.':
    'Deleted reports are stored for 30 days. After this period, they will be permanently deleted.',
  'Report will be deleted': 'Report will be deleted',
  'It seems you have no report for the moment.': 'It seems you have no report for the moment.',
  'Oops, we didn’t find any results matching your search.':
    'Oops, we didn’t find any results matching your search.',
  'Nothing to show for now': 'Nothing to show for now',
  'Report has been deleted successfully.': 'Report has been deleted successfully.',
  'Error while deleting permanently a report': 'Error while deleting permanently a report',
  'Report has been restored successfully.': 'Report has been restored successfully.',
  'Error while restoring a report': 'Error while restoring a report',
  'You must restore the report to download it': 'You must restore the report to download it',
  'Error deleting your report !': 'Error deleting your report !',
  'Please fill all required fields.': 'Please fill all required fields.',
  'Please select at least one campaign.': 'Please select at least one campaign.',
  'First name*': 'First name*',
  'Last name*': 'Last name*',
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  'HR information': 'HR information',
  'Create user': 'Create user',
  'New user': 'New user',
  'Help & Support': 'Help & Support',
  'Here you can manage all Aleph accounts within your company.':
    'Here you can manage all Aleph accounts within your company.',
  "Here is all contents created in your company.": "Here is all contents created in your company.",
    User: 'user',
  'Profile picture': 'Profile picture',
  'No tags added': 'No tags added',
  'It seems there is no tags added, search for tags and add or create them.':
    'It seems there is no tags added, search for tags and add or create them.',
  'Search for tags': 'Search for tags',
  'Visible for interviewer': 'Visible for interviewer',
  'Required for interviewer': 'Required for interviewer',
  'Visible for interviewee': 'Visible for interviewee',
  'Required for interviewee': 'Required for interviewee',
  comment: 'comment | comments',
  Aleph: 'Aleph',
  'Edit photo': 'Edit photo',
  Dashboard: 'Dashboard',
  'Profile details': 'Profile details',
  'Updates will be lost': 'Updates will be lost',
  'If you quit without saving, your updates will be lost.':
    'If you quit without saving, your updates will be lost.',
  'Quit anyway': 'Quit anyway',
  'Hmm, it seems you have no modules.': 'Hmm, it seems you have no modules.',
  'Remove current photo': 'Remove current photo',
  'Drag & drop here or upload a file. Only .jpg, .png and .jpeg file formats are supported, max 10 Mb.':
    'Drag & drop here or upload a file. Only .jpg, .png and .jpeg file formats are supported, max 10 Mb.',
  'Aleph Language': 'Aleph Language',
  'Deleted on': 'Deleted on',
  Filter: 'Filter',
  "Import won't be done": "Import won't be done",
  'Quit import': 'Quit import',
  'If you quit, you will have to restart the import again.':
    'If you quit, you will have to restart the import again.',
  'user will be deleted': 'user will be deleted',
  'Restore user': 'Restore user',
  'Drag & drop here or upload a file.': 'Drag & drop here or upload a file.',
  'Only .xlsx format is accepted.': 'Only .xlsx format is accepted.',
  'New category': 'New category',
  'Categories and Tags': 'Categories and Tags',
  'Use categories such as Country, Department... to organize Users data. Inside each category, you can create as many item (tags) as needed.':
    'Use categories such as Country, Department... to organize Users data. Inside each category, you can create as many item (tags) as needed.',
  'Delete category?': 'Delete category?',
  'This category and all the associated tags will be permanently lost.':
    'This category and all the associated tags will be permanently lost.',
  'Category name': 'Category name',
  reply: 'reply',
  replies: 'replies',
  'Total duration': 'Total duration',
  'Comment will be deleted': 'Comment will be deleted',
  'Do it later': 'Do it later',
  'I like it': 'I like it',
  'Module will be acquired': 'Module will be acquired',
  'To mark this module as “acquired”, you must confirm that you have understood it.':
    'To mark this module as “acquired”, you must confirm that you have understood it.',
  'What did you think of the module?': 'What did you think of the module?',
  'Module acquired!': 'Module acquired!',
  'Comment deleted successfully.': 'Comment deleted successfully.',
  "Hello and welcome! I'm Aleph AI, your assistant for creating and enriching your training content. Whether it's writing your modules, exploring new ideas, or delving deeper into specific topics, I'm here to support you every step of the way. Don't hesitate to call on me to bring your educational projects to life!":
    "Hello and welcome! I'm Aleph AI, your assistant for creating and enriching your training content. Whether it's writing your modules, exploring new ideas, or delving deeper into specific topics, I'm here to support you every step of the way. Don't hesitate to call on me to bring your educational projects to life!",
  'An issue occurred with the AI response': 'An issue occurred with the AI response',
  'Search and select by categories': 'Search and select by categories',
};

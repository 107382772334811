<template>
  <v-btn
    class="flex justify-start items-center text-none text-base font-normal w-full py-1 h-14 bkt-bg-light-grey10-hover"
    variant="text"
    @click.stop="modal = true"
  >
    {{ $t(data.title) }}
  </v-btn>
  <v-dialog v-model="modal" class="w-full sm:w-1/2">
    <v-card class="p-6 !rounded-xl w-[503px] mx-auto !shadow">
      <div
        class="cursor-pointer mb-2 w-full flex items-right"
        @click="
          () => {
            modal = false;
          }
        "
      >
        <v-spacer />
        <Icon color="darkGrey" height="24" icon="mdi-close" width="24" />
      </div>
      <div class="w-full flex flex-col justify-center items-center mb-4">
        <icon color="fakeBlack" height="32" icon="ph:tag-bold" width="32" />
      </div>
      <div class="flex justify-center items-center mb-8">
        <h1 class="text-xl self-center font-medium">
          {{ data.title }}
        </h1>
      </div>

      <svn-autocomplete
        v-model="entityTags"
        :items="companyCategories"
        checkbox
        chips
        class="min-w-full"
        closable-chips
        close-icon="mdi-close-circle"
        color="blue-grey-lighten-2"
        item-title="title"
        item-value="title"
        label="Search here for tags"
        multiple
        @input="searchText = $event"
        @update:model-value="updateIntFormTags($event)"
      >
        <template #prepend-details>
          {{ null }}
        </template>

        <template #append-content>
          {{ null }}
        </template>

        <template #no-data>
          <div
            v-if="searchText.length !== 0"
            class="flex h-10 cursor-pointer hover:bg-gray-200 items-center"
            @click="createTag(searchText)"
          >
            <svn-text base class="ml-4 mr-2">
              {{ $t('Create') }}
            </svn-text>

            <svn-chip
              v-if="searchText"
              :text="searchText"
              class="!bg-light-grey !text-sm !text-black border-none !rounded-full !px-3 !cursor-pointer"
            />
          </div>
          <div v-else class="flex h-10 cursor-pointer hover:bg-gray-200 items-center">
            <svn-text base class="ml-4 mr-2">
              {{ 'Type something to search for tags' }}
            </svn-text>
          </div>
        </template>
      </svn-autocomplete>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import axiosService from '@/tools/axios-service.js';
import { Icon } from '@iconify/vue/dist/iconify.js';

const emit = defineEmits(['refresh']);
const data = defineProps({
  status: { type: Boolean, required: true },
  title: { type: String, default: '' },
  entityId: { type: Number, required: true },
  fetchTagsFromEntityPath: { type: String, required: true },
  route: { type: String, required: true },
});

const modal = ref(false);

const entityTags = ref([]);
let tagsBeforeChange = [];
const companyCategories = ref([]);
const searchText = ref('');

const fetchEntityTags = async () => {
  try {
    const res = await axiosService.get(
      $routes.generate(data.fetchTagsFromEntityPath, { id: data.entityId }),
    );

    entityTags.value = res.data.categories;
    tagsBeforeChange = res.data.categories;
  } catch (e) {
    console.log('error', e);
  }
};
const fetchCompanyTags = async (title = '') => {
  try {
    const res = await axiosService.get('/interview_app/interviews/categories', {
      params: { title: title },
    });

    companyCategories.value = res.data.categories;
  } catch (e) {
    console.log('error', e);
  }
};
const createTag = async (text) => {
  if (data.route === 'interview_forms') {
    try {
      const res = await axiosService.post(`/interview_app/forms/templates/${data.entityId}/tags`, {
        tag: { title: text },
      });

      const category = res.data.category;
      entityTags.value.push(category.title);
    } catch (e) {
      console.log("couldn't create a tag", e);
    } finally {
      searchText.value = '';
    }
  } else {
    const res = await axiosService.post(`/interviews/${data.route}/${data.entityId}/create_tags`, {
      category_title: text,
    });
    const category = res.data.category;
    entityTags.value.push(category.title);
    searchText.value = '';
  }
  emit('refresh');
};

watch(searchText, async (newValue) => {
  await fetchCompanyTags(newValue);
});

watch(entityTags, async () => {
  await updateTags();
});

const updateTags = async () => {
  let toToggle = entityTags.value
    .map((item) => {
      return item.title ?? item;
    })
    .filter((item) => !tagsBeforeChange.map((tagBef) => tagBef.title ?? tagBef).includes(item));
  toToggle = toToggle.concat(
    tagsBeforeChange
      .map((item) => item.title ?? item)
      .filter((item) => !entityTags.value.map((enti) => enti.title ?? enti).includes(item)),
  );

  try {
    for (let i = 0; i < toToggle.length; i++) {
      await axiosService.post(`/interviews/${data.route}/${data.entityId}/toggle_tag`, {
        tag: toToggle[i],
      });
    }

    emit('refresh');
  } catch (e) {
    console.error(e);
  }
};

fetchEntityTags();
fetchCompanyTags();
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <svn-pro-text subtitle-large medium>
        {{ $t("Multiple templates - Category") }}
      </svn-pro-text>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t("Choose a category to assign different templates to this campaign.") }}
      </svn-pro-text>
    </div>

    <div class="w-full flex items-center gap-4">
      <svn-pro-select
        v-model="campaignDraft.multi_templates_tag_category_id"
        :items="tagCategories"
        :label="$t('Select a category*')"
        item-title="name"
        item-value="id"
        :error="error"
        class="flex-1 sm:!w-1/2"
        @update:model-value="lookPatsTags"
      >
        <template
          #selection="{ item }"
        >
          <div class="w-full truncate">
            {{ item?.title }}
          </div>
        </template>
      </svn-pro-select>

      <v-spacer class="hidden sm:!flex" />
    </div>

    <div class="flex flex-col gap-2">
      <svn-pro-text subtitle-large medium>
        {{ $t("Multiple templates - Category") }}
      </svn-pro-text>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t("Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.") }}
      </svn-pro-text>
    </div>

    <div
      v-if="campaignDraft?.multi_templates_tag_category_id"
      class="flex flex-col gap-4"
    >
      <div
        v-for="(tag, idx) in participantTags"
        :key="idx"
        class="flex flex-col gap-4 items-center"
      >
        <div class="w-full flex md:!flex-row md:!items-center flex-col gap-2 md:!px-2">
          <svn-pro-text subtitle-large medium class="break-all line-clamp-1 md:!flex-1">
            {{ tag?.tag_name }}
          </svn-pro-text>

          <svn-pro-autocomplete
            :model-value="fetchInterviewFormId(tag?.id)"
            :label="$t('Select a template')"
            :items="interviewForms"
            class="flex-1"
            item-title="title"
            item-value="id"
            :infiniteScrollLoading="infiniteScrollLoading"
            @intersect="loadMoreData"
            @update:model-value="updateMultiTemplatesTagsIds({
              tag_id: tag?.id,
              form_id: $event,
            })"
          />
        </div>

        <svn-pro-divider color="[#767680]" class="w-full" />
      </div>

      <!-- Default template -->
      <div class="w-full flex flex-col sm:!flex-row gap-2 items-center md:!px-2">
        <svn-pro-text subtitle-large medium class="whitespace-nowrap w-full">
          {{ $t('Default template') }}
        </svn-pro-text>

        <templates-single :error="errorDefault" :full-width="true" />
      </div>
    </div>

    <v-alert
      v-else
      :text="$t('Please choose a category first')"
      icon="custom:noto-warning"
      rounded="0"
      class="!rounded-[12px]"
    />
  </div>

  <!-- <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div>
        <h4 class="text-base font-medium">
          {{ $t("Multiple templates - Assign templates") }}
        </h4>
        <p class="text-dark-grey text-sm">
          {{ $t("Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.") }}
        </p>
      </div>
      <v-select
        v-model="campaignDraft.multi_templates_tag_category_id"
        :items="tagCategories"
        :label="$t('Select a category')"
        item-title="name"
        variant="outlined"
        base-color="middleGrey"
        color="primary"
        hide-details
        item-value="id"
        class="w-full"
        @update:model-value="lookPatsTags"
      >
        <template
          #selection="{item}"
        >
          <div class="w-full truncate">
            {{ item.title }}
          </div>
        </template>
      </v-select>
    </div>

    <div
      class="flex flex-col gap-4"
    >
      <div class="flex flex-col">
        <h4 class="text-base font-medium">
          {{ $t("Multiple templates - Assign templates") }}
        </h4>
        <p class="text-dark-grey text-sm">
          {{
            $t(
              "Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags."
            )
          }}
        </p>
      </div>

      <div
        v-if="campaignDraft.multi_templates_tag_category_id"
        class="flex flex-col gap-3"
      >
        <div
          v-for="(tag, idx) in participantTags"
          :key="idx"
          class="flex flex-column sm:!flex-row justify-between items-center"
        >
          <div class="flex items-center">
            <div class="bg-black h-2 w-2 rounded-full mx-3" />
            <p class="semibold">
              {{ tag.tag_name }}
            </p>
          </div>

          <svn-autocomplete
            :model-value="fetchInterviewFormId(tag.id)"
            :label="$t('Select a template')"
            :items="interviewForms"
            item-title="title"
            :loading="loadingInterviewForms"
            class="text-black !max-w-[280px]"
            color="primary"
            prepend-inner-icon=""
            @update:model-value="
              updateMultiTemplatesTagsIds({
                tag_id: tag.id,
                form_id: $event,
              })
            "
          >
            <template #main-content>
              {{ null }}
            </template>

            <template #prepend-details>
              {{ null }}
            </template>

            <template #append-content>
              {{ null }}
            </template>
          </svn-autocomplete>

        </div>
        <div
          class="flex flex-column sm:!flex-row justify-between items-center my-3"
        >
          <div class="flex items-center">
            <div class="bg-black h-2 w-2 rounded-full mx-3" />
            <p class="semibold">
              {{ $t('Default template') }}
            </p>
          </div>
          <templates-single
            class-custom="!max-w-[280px]"
          />
        </div>
      </div>
      <v-alert
        v-else
        :text="$t('Please choose a category first')"
        icon="mdi-alert"
      />
    </div>
  </div> -->
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useCompanyStore } from "@/store/company.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import TemplatesSingle from "@/components/interviewApp/campaignDraft/templates/TemplatesSingle.vue";
import { interviewFormApi } from '@/apis/interview_form.api';

const props = defineProps({
  error: { type: Boolean, default: false },
  errorDefault: { type: Boolean, default: false },
})

const { fetchParticipantTags } = useCampaignDraftStore();

const { tagCategories } = storeToRefs(useCompanyStore());
const { campaignDraft, participantTags } = storeToRefs(useCampaignDraftStore());

const loadingInterviewForms = ref(true);
const infiniteScrollLoading = ref(false);
const pagination = ref({})
const interviewForms = ref([])

onMounted(async () => {
  const data = await fetchInterviewForms(1)
  interviewForms.value = data.interview_app_forms
  pagination.value = data.meta.pagination
  loadingInterviewForms.value = false;
});

const fetchInterviewForms = async(page = 1) => {
  let formType = ''
  if (campaignDraft?.value?.campaign_type == 'Survey') {
    formType = 'InterviewApp::Forms::Templates::Survey'
  } else if (campaignDraft?.value?.campaign_type == 'Feedback') {
    formType = 'InterviewApp::Forms::Templates::Feedback'
  } else {
    formType = 'InterviewApp::Forms::Templates::OneToOne'
  }
  return await interviewFormApi.fetch(formType, page);
}

const loadMoreData = async() => {
  if (pagination.value?.current_page < pagination.value?.total_pages) {
    infiniteScrollLoading.value = true
    pagination.value.current_page = pagination.value.current_page + 1
    const data = await fetchInterviewForms(pagination.value.current_page)
    interviewForms.value = [...interviewForms.value, ...data.interview_app_forms]
    pagination.value = data.meta.pagination
    infiniteScrollLoading.value = false;
  }
}

const updateMultiTemplatesTagsIds = (entry) => {
  const idx = campaignDraft?.value?.multi_templates_tags_ids.findIndex((item) => item?.tag_id === entry?.tag_id);

  if (idx !== -1) {
    campaignDraft.value.multi_templates_tags_ids[idx] = entry;
  } else {
    campaignDraft.value.multi_templates_tags_ids.push(entry);
  }
};

const fetchInterviewFormId = (tagId) => {
  const match = campaignDraft?.value?.multi_templates_tags_ids.find((item) => item?.tag_id === tagId);

  return match ? match?.form_id : null;
};

const lookPatsTags = (arg) => {
  campaignDraft.value.multi_templates_tags_ids = [];
  fetchParticipantTags(arg);
};
</script>

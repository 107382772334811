<template>
  <div :key="filterKey" :class="shouldShow ? 'flex' : 'hidden'" class="w-full flex-wrap gap-2">
    <svn-filter-item-chip
      v-for="item in filterList"
      :key="item?.id"
      :data="items"
      :item="item"
      @update-selected-data-filters="updateSelectedDataFilters(item?.id, $event)"
    />

    <svn-pro-button
      v-if="activeFilterCount"
      :text="$t('Clear all')"
      variant="text"
      @click="clearSelectedFilters"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import filters from '@/tools/filters';
import { computed, onMounted, ref, watch } from 'vue';
import { useFilterStore } from '@/store/filters';
import axiosService from '@/tools/axios-service.js';
import SvnFilterItemChip from './SvnFilterItemChip.vue';

onMounted(() => {
  getFilterColumns();
  for (let filter of filterList?.value) {
    initializeModels(filter);
  }
});

const props = defineProps({
  items: { type: Array, default: () => [] },
  headers: { type: Array, default: () => [] },
  shouldShow: { type: Boolean, default: false },
  tableName: { type: String, default: '', required: true },
});

const emit = defineEmits([
  'update-active-filter-count',
  'update-selected-data-filters',
  'refetch-data',
  'clear-all-filters',
]);

const { columns } = storeToRefs(useFilterStore());

const { fetchFilterMetadata } = useFilterStore();

const filterKey = ref(0);
const column = ref(null);
const loading = ref(true);
const filterList = ref([]);
const filterData = ref([]);
const totalPages = ref(null);
const selectedFilters = ref([]);
const formattedFilteredData = ref([]);
const filterOperatorOption = ref(null);
const activeFilterCount = computed(() => {
  return filterList?.value?.filter((el) => el?.value?.length).length;
});

const initializeModels = async (filter) => {
  column.value = columns?.value?.find((el) => el?.name === filter?.key);

  if (!formattedFilteredData?.value?.length) {
    if (column?.value?.answer_options?.type === 'async') {
      if (column?.value?.answer_options?.endpoint === '/users') {
        if (column?.value?.name === 'manager_id') {
          try {
            const { data } = await axiosService.get(`/users/users_search`, {
              params: {
                only_managers: true,
                'page[per]': 25,
              },
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        } else if (column?.value?.name === 'objectivable_id') {
          try {
            const { data } = await axiosService.get(`/users`, {
              params: {
                'page[per]': 25,
              },
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else {
      formattedFilteredData.value = column?.value?.answer_options?.items;
    }
  }
  if (!filterOperatorOption?.value) {
    filterOperatorOption.value = getSelectItemsAccordingToType(filter?.type)?.[0];
  }
};

const getFilterColumns = async () => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(props?.tableName);
      for (let column of columns?.value) {
        let foundCol = props?.headers?.find((el) => el?.name === column?.name);
        if (foundCol) {
          let newCol = {
            ...column,
            value: '',
            operator: null,
            key: foundCol?.key,
            name: foundCol?.title,
            icon: foundCol?.icon || '',
            id: filters.generateUniqueId(),
          };
          filterList?.value?.push(newCol);
        }
      }
    } catch (error) {
      console.log('Error');
    }
  }
};

const getSelectItemsAccordingToType = (type) => {
  switch (type) {
    case 'date':
      return ['before', 'after'];
    case 'number':
      return ['equal', 'not_equal', 'greater_than', 'less_than'];
    default:
      return ['contains'];
  }
};

const clearSelectedFilters = () => {
  filterData.value = [];
  selectedFilters.value = [];
  for (let filter of filterList?.value) {
    filter.value = null;
  }
  filterKey.value += 1;

  emit('clear-all-filters');
};

const updateSelectedDataFilters = (id, data) => {
  filterList.value.find((el) => el?.id === id).value = data?.value;
  filterList.value.find((el) => el?.id === id).operator = data?.operator;

  let filter = filterData?.value?.find((element) => element?.id === id);
  let finalValue = null;

  if (filter) {
    filter.operator = data?.event?.operator;
    filter.value = data?.event?.value;
  } else {
    filterData?.value?.push({
      id: id,
      name: filterList?.value?.find((el) => el?.id === id)?.key,
      operator: data?.operator?.toLowerCase(),
      value: data?.value,
    });
  }

  if (data?.value?.length) {
    finalValue = filterData?.value;
  } else {
    filterData?.value?.splice(
      filterData?.value?.findIndex((el) => el?.id === id),
      1,
    );
    if (filterData?.value?.length) {
      finalValue = filterData?.value;
    } else {
      finalValue = {};
    }
  }

  emit('refetch-data', filterData?.value);
};

watch(activeFilterCount, (newValue, oldValue) => {
  emit('update-active-filter-count', activeFilterCount?.value);
});
</script>

<style scoped></style>

<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1136px] mx-auto flex flex-col gap-4 items-center md:!items-start pt-5"
    >
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 px-5 md:!px-0 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Trainings') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <svn-pro-button
              v-if="isManagerCreatorOrAbove()"
              :text="$t('New training')"
              class="hidden md:flex"
              prepend-icon="custom:mingcute:add-line"
              variant="flat"
              @click="goToNewTraining"
            />

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-fill"
                  v-bind="props"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item
                  :active="false"
                  value="view_deleted"
                  @click="deletedItems.deletedItems.dialog = true"
                >
                  <svn-pro-text body-large regular>
                    {{ $t('View deleted trainings') }}
                  </svn-pro-text>
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Tabs -->
        <v-tabs v-model="tab" :grow="!isMdScreen" class="px-5 md:!px-0" color="primary">
          <!-- Current targets Tab -->
          <v-tab
            :text="`${$t('Launched')} (${publishedPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="published"
          />

          <!-- Archived targets Tab -->
          <v-tab
            v-if="isManagerCreatorOrAbove()"
            :text="`${$t('Upcoming')} (${upcomingPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="scheduled"
          />
        </v-tabs>

        <!-- Search -->
        <svn-pro-text-field
          v-model="currentSearch"
          :placeholder="$t('Search')"
          class="px-5 md:!px-0"
          prepend-inner-icon="custom:mingcute:search-2-line"
          variant="outlined"
          @update:model-value="search"
        />

        <!-- Windows -->
        <v-window v-model="tab">
          <v-window-item value="published">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-0">
              <learn-table
                :loading="loading"
                :pagination="publishedPagination"
                :search="currentSearch"
                :trainings="publishedTrainings"
                type="published"
                @update="update"
                @reload-page="fetchTrainingsLaunched"
                @delete-training="destroyTraining"
              />
            </div>
          </v-window-item>

          <v-window-item v-if="isManagerCreatorOrAbove()" value="scheduled">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-0">
              <learn-table
                :loading="loading"
                :pagination="upcomingPagination"
                :search="currentSearch"
                :trainings="upcomingTrainings"
                type="scheduled"
                @update="update"
                @reload-page="fetchTrainingsUpcoming"
                @delete-training="destroyTraining"
              />
            </div>
          </v-window-item>
        </v-window>
      </div>
    </div>
  </div>

  <modal-view-deleted-items
    ref="deletedItems"
    :headers="trashesHeaders"
    :items="trashTrainings"
    content-type="training"
    @restore="restoreDeletedItems"
    @view-item="viewSelectedItem"
    @close-modal="closeModal"
    @toggle-search="toggleSearchDeletedItems"
    @delete-permanently="deletePermanentlyDeletedItems"
  >
    <template #activator="{ props }">
      <div class="hidden opacity-0" />
    </template>
  </modal-view-deleted-items>

  <svn-pro-button
    v-if="!isMdScreen"
    :text="$t('New training')"
    class="fixed bottom-4 right-4"
    height="56"
    prepend-icon="custom:mingcute-add-fill"
    variant="flat"
    @click="goToNewTraining"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useLearnTrainingStore } from '@/store/learn-trainings';
import LearnTable from '@/components/trainingApp/myTrainings/LearnTable.vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user.js';
import { useSnackbar } from '@/store/snackbar';
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';

const router = useRouter();
const snackbar = useSnackbar();
const mobileStore = useMobileStore();
const { isManagerCreatorOrAbove } = useUserStore();
const { deleteTraining, fetchTrashTrainings, restoreTraining, deleteTrainingPermanently } =
  useLearnTrainingStore();

const { isMobile, isMdScreen } = storeToRefs(mobileStore);
const { trashTrainings, learnTrainings } = storeToRefs(useLearnTrainingStore());

const loading = ref(false);
const tab = ref('published');
const publishedTrainings = ref([]);
const upcomingTrainings = ref([]);
const publishedPagination = ref({});
const upcomingPagination = ref({});
const currentSearch = ref('');
const deletedItems = ref(null);
const trashesHeaders = ref([
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Trainings'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
  {
    align: 'start',
    key: 'delete',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);
const trainingToDelete = ref(null);

onMounted(async () => {
  await update();
  await fetchTrashTrainings();
});

const viewSelectedItem = (id) => {
  router.push({ name: 'training_edit', params: { id: id } });
};

const search = debounce((title) => {
  reloadAfterSearch(title);
}, 300);

const reloadAfterSearch = async (title) => {
  try {
    if (tab?.value == 'published') {
      await fetchTrainingsLaunched(1, title);
    } else if (tab?.value == 'scheduled') {
      await fetchTrainingsUpcoming(1, title);
    }
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error deleting training.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  }
};

const update = async () => {
  loading.value = true;
  try {
    await fetchTrainingsLaunched();
    await fetchTrainingsUpcoming();
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-[88px]' : 'mb-5')
      .displaySnackBar('Error, ' + error);
  }
  loading.value = false;
};

const fetchTrainingsLaunched = async (page = 1, title = '') => {
  publishedTrainings.value = null;
  const res = await axiosService.get(`/learn/trainings`, {
    params: {
      'page[number]': page,
      text: title,
    },
  });
  publishedTrainings.value = res?.data?.learn_trainings;
  publishedPagination.value = res?.data?.meta?.pagination;
};

const fetchTrainingsUpcoming = async (page = 1, title = '') => {
  upcomingTrainings.value = null;
  const res = await axiosService.get(`/learn/trainings/scheduled_trainings`, {
    params: {
      'page[number]': page,
      text: title,
    },
  });
  upcomingTrainings.value = res?.data?.learn_trainings;
  upcomingPagination.value = res?.data?.meta?.pagination;
};

const goToNewTraining = () => {
  router.push({ name: 'training_new' });
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashTrainings(value);
  } catch (e) {
    console.error(e);
  }
}, 300);

const restoreDeletedItems = async (id) => {
  try {
    await restoreTraining(id);
    deletedItems.value.deletedItems.dialog = false;
    publishedTrainings.value.push(learnTrainings.value.find((trash) => trash.id === id));
    trashTrainings.value = trashTrainings.value.filter((trash) => trash.id !== id);
    snackbar
      .setBgColor('onSurface')
      .setMsg('Training has been restored successfuly.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error restoring training.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async () => {
  try {
    await deleteTrainingPermanently(trainingToDelete?.value);
    trashTrainings.value = trashTrainings.value.filter(
      (trash) => trash.id !== trainingToDelete?.value,
    );
    snackbar
      .setBgColor('onSurface')
      .setMsg('Training has been deleted successfuly.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error deleting training.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  }
  trainingToDelete.value = null;
};

const closeModal = (id) => {
  deletedItems.value.deletedItems.dialog = false;
  deletedItems.value.deleteTargetRef.dialogRef.dialog = true;

  trainingToDelete.value = id;
};

const destroyTraining = async (id) => {
  await deleteTraining(id);
  snackbar
    .setBgColor('onSurface')
    .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
    .displaySnackBar('Training has been deleted successfully.');

  if (tab.value === 'published') {
    const trashTraining = publishedTrainings.value.find((training) => training?.id === id);
    trashTraining.deleted_at = new Date();
    trashTrainings.value.unshift(trashTraining);
    publishedTrainings.value = publishedTrainings.value.filter((training) => training.id != id);
  } else if (tab.value === 'scheduled') {
    const trashTraining = upcomingTrainings.value.find((training) => training?.id === id);
    trashTraining.deleted_at = new Date();
    trashTrainings.value.unshift(trashTraining);
    upcomingTrainings.value = upcomingTrainings.value.filter((training) => training.id != id);
  }
};
</script>
